import Repeat from '@material-ui/icons/Repeat';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, IconButton } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { MeshTransferTypes } from '../../generated/graphql';
import PickingTransfers from '../PickingTransfers/PickingTransfers';
import PurgeTransfers from '../PurgeTransfers/PurgeTransfers';
import SecondBar from "../SecondBar/SecondBar";
import StandardView from "../StandardView/StandardView";
import styles from './Transfers.module.scss';

const Transfers = () => {

    const [transferView, setTransferView] = useState<MeshTransferTypes>(MeshTransferTypes.Purge)

    const handleClick = () => {
        const view = transferView === MeshTransferTypes.Purge ? MeshTransferTypes.Picking : MeshTransferTypes.Purge
        setTransferView(view)
    }

    const headers = transferView === MeshTransferTypes.Purge ? ["Corte", "Armazém"] : ["Armazém", "Corte"]
    const classes = clsx([styles.SecondBar, styles.TransfersHeader])

    return (
        <>
            <StandardView />
            <div style={{ paddingBottom: 70 }}>
                <SecondBar>
                    <Grid container justifyContent="space-evenly" alignItems="center" className={classes}>
                        <span style={{ marginRight: 40, marginLeft: 15 }}></span>
                        <span>{headers[0]}</span>
                        <KeyboardArrowRightIcon />
                        <span>{headers[1]}</span>
                        <IconButton onClick={handleClick} style={{ marginLeft: 40, marginRight: 15 }}>
                            <Repeat color="primary" />
                        </IconButton>
                    </Grid>
                </SecondBar>

                {transferView === MeshTransferTypes.Purge ? <PurgeTransfers /> : <PickingTransfers />}

            </div>
        </>
    )
}

export default Transfers

import { KeyboardDatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { FC } from 'react';
const { format } = require('date-fns');

interface InlineDatePickerProps {
    field: any,
    form: any,
    props: any,
    label?: string,
    fullWidth?: boolean,
    disabled?: boolean
}

const InlineDatePicker: FC<InlineDatePickerProps> = ({ field, form, ...props }) => {

    const formikContext = useFormikContext()
    const { name, value, onChange } = field

    const handleChange = (date: any, _?: Maybe<string>) => {
        try {
            const event = {
                target: {
                    name,
                    value: format(date, 'yyyy-MM-dd')
                }
            }
            onChange(event)
        } catch (e) {
            console.log('e', e);
        }
    }

    const handleError = (_: any) => { }

    return (
        <KeyboardDatePicker
            name={name}
            value={value}
            onChange={handleChange}
            variant="inline"
            label={props.label}
            InputAdornmentProps={{ position: "end" }}
            format="dd/MM/yyyy"
            onError={handleError}
            disabled={formikContext.isSubmitting || props.disabled}
            fullWidth={props.fullWidth}
        />
    )
}

export default InlineDatePicker

import { Grid } from '@mui/material';
import { Pagination as MuiPagination } from '@mui/material/';
import React, { FC } from 'react';
import { DecodedValueMap } from 'use-query-params';
import Config from '../../services/Config';
import styles from './Pagination.module.scss';

interface PaginationProps {
    collection: any
    page: number
    setPage: DecodedValueMap<{}>
}


export const getPaginationCount = (totalCount?: number) => {
    return totalCount ? Math.ceil(totalCount / Config.PAGE_SIZE) : 1
}

/**
 * A collection tem que implementar uma OffsetPaging paging strategy
 * O query tem que pedir o totalcount
 *
 */
const Pagination: FC<PaginationProps> = props => {
    const { collection, page, setPage } = props
    const count = getPaginationCount(collection?.totalCount)

    const handleChange = (_: any, page: number) => {
        // @ts-ignore
        setPage("page", page)
    }

    if (!collection?.nodes?.length) {
        return null
    }

    return (
        <Grid container justifyContent="center" className={styles.PaginationWrapper}>
            <MuiPagination count={count} page={page} onChange={handleChange} showFirstButton showLastButton />
        </Grid>
    )
}

export default Pagination

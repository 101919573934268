import { Card, CardContent, Fab, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LockIcon from '@material-ui/icons/Lock';
import PublicIcon from '@material-ui/icons/Public';
import React, { FC, useState } from 'react';
import { Ticket, TicketAttributesFragment, TicketsQuery, useTicketsQuery } from '../../generated/graphql';
import { ticketStateName } from '../../services/Utils';
import AutoTextField from '../AutoTextField/AutoTextField';
import CreateTicket from '../CreateTicket/CreateTicket';
import CustomModal from '../CustomModal/CustomModal';
import Loading from '../Loading/Loading';
import SimpleSelector from '../SimpleSelector/SimpleSelector';
import TicketDetails from '../TicketDetails/TicketDetails';
import styles from './TicketsGrid.module.scss';

interface TicketPreviewProps {
    ticket: TicketAttributesFragment
    show: any
}

const TicketPreview: FC<TicketPreviewProps> = (props) => {

    const { ticket, show } = props

    const stateColor = (state: string) => {
        const classes: any = {
            NEW: styles.TicketNew,
            PROGRESS: styles.TicketProgress,
            CLOSED: styles.TicketClosed,
            PENDING: styles.TicketPending,
            CANCELED: styles.TicketCanceled
        }
        return classes[state]
    }

    const stateTooltip = ticket.state ? 'Estado ' + ticketStateName(ticket.state) : 'indefinido';
    const privacyTooltip = ticket.isPublic ? 'Este ticket é público' : 'Este ticket é privado';

    return (
        <Card className={styles.TicketPreview} onClick={() => show(ticket)}>
            <CardContent className={styles.Wrapper}>

                <Grid container direction="column" justify="space-between" className={styles.Content}>

                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid container item xs={10} >
                            <Typography variant="body1" className={styles.TicketName}>
                                # {ticket.id} {ticket.name}
                            </Typography>
                        </Grid>
                        <Tooltip title={stateTooltip}>
                            <FiberManualRecordIcon
                                className={ticket.state && stateColor(ticket.state)} />
                        </Tooltip>
                    </Grid>

                    <Typography variant="body2" className={styles.TicketDescription}>
                        {ticket.description?.substring(0, 95)}
                        {ticket.description && ticket.description?.length > 95 ? '...' : ''}
                    </Typography>

                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Tooltip title={privacyTooltip}>
                            {ticket.isPublic ? <PublicIcon className={styles.TicketPrivacy} /> : <LockIcon className={styles.TicketPrivacy} />}
                        </Tooltip>
                        <Typography variant="body2">
                            {ticket.user?.name}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
}

const TicketsEmptyState = () => {
    return (
        <div className={styles.TicketsEmptyState}>
            Não existem tickets para esta pesquisa.
        </div>
    )
}

interface TicketsGridProps {
    data: TicketsQuery | undefined,
    show: (ticket: Ticket) => void
}

const TicketsGrid: FC<TicketsGridProps> = ({ data, show }) => {
    if (!data) return <Loading />
    if (!data.tickets.edges.length) return <TicketsEmptyState />
    return (
        <Grid container>
            {data.tickets.edges.map(
                edge => <TicketPreview key={edge.node.id} ticket={edge.node} show={show} />)}
        </Grid>
    )
}

const ticketsQuery = (search: string, states: string[]) => {
    return {
        variables: {
            filter: {
                and: [
                    { state: { in: states } }, {
                        or: [
                            { name: { iLike: `%${search}%` } },
                            { description: { iLike: `%${search}%` } },
                            { id: { eq: parseInt(search) } }]
                    }]
            }
        }
    }
}

const TicketsView = () => {

    const [search, setSearch] = useState<string>("")
    const [states, setStates] = useState<string[]>(["NEW", "PROGRESS", "PENDING"])
    const { data, loading, refetch } = useTicketsQuery(ticketsQuery(search, states));
    const [showTicket, setShowTicket] = useState(0)
    const [showCreate, setShowCreate] = useState(false)

    if (loading) {
        return <Loading />
    }

    const show = (ticket: Ticket) => {
        setShowTicket(ticket.id)
    }

    const action = () => {
        setShowCreate(true)
    }

    const onComplete = () => {
        setShowCreate(false);
        refetch();
    }

    const onSearch = (data: any) => {
        setSearch(data.search)
    }

    const onFilter = (event: any) => {
        setStates(event.target.value)
    }

    return (
        <Grid container direction="column" className={styles.TicketsGrid} alignItems="center">
            <Typography variant="h2">
                Tickets de Suporte
            </Typography>
            <Typography variant="subtitle1" paragraph>
                Reportar incidentes ou sugerir novas funcionalidades
            </Typography>

            <Grid container justify="flex-start" alignItems="flex-end" className={styles.FilterBar}>
                <Grid item xs={3}>
                    <AutoTextField
                        name="search"
                        onSave={onSearch}
                        placeholder="pesquisar"
                        value={search}
                        interval={500}
                        fullWidth={true}
                        clearable={true}
                        InputProps={{
                            autoComplete: "off",
                            autoFocus: true
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SimpleSelector
                        label="Estado"
                        name="states"
                        options={
                            [
                                { id: ["NEW", "PROGRESS", "PENDING"], name: 'Tickets Abertos' },
                                { id: "NEW", name: ticketStateName("NEW") },
                                { id: "PENDING", name: ticketStateName("PENDING") },
                                { id: "PROGRESS", name: ticketStateName("PROGRESS") },
                                { id: "CLOSED", name: ticketStateName("CLOSED") },
                                { id: "CANCELED", name: ticketStateName("CANCELED") }
                            ]}
                        value={states}
                        onChange={onFilter}
                    />
                </Grid>
            </Grid>
            <TicketsGrid data={data} show={show} />
            <CustomModal isActive={Boolean(showTicket)} handleClose={() => setShowTicket(0)}>
                <TicketDetails id={showTicket} />
            </CustomModal>
            <CustomModal isActive={showCreate} handleClose={() => setShowCreate(false)}>
                <CreateTicket onComplete={onComplete} />
            </CustomModal>
            <Fab color="primary" onClick={action} className={styles.FabButton}>
                <AddIcon />
            </Fab>
        </Grid >
    )
}

export default TicketsView;

import Quagga from 'quagga'
import React, { Component } from 'react'

class Scanner extends Component<any> {
    componentDidMount() {
        Quagga.init(
            {
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        width: window.innerHeight,
                        height: window.innerWidth,
                        // height: 600,
                        /* width: "400",
                         * height: "800", */
                        // aspectRatio: { min: 1, max: 100 },
                        // aspectRatio: 1.2,
                        facingMode: 'environment',
                    },
                    area: { // defines rectangle of the detection/localization area
                        top: "10%",    // top offset
                        right: "10%",  // right offset
                        left: "10%",   // left offset
                        bottom: "10%"  // bottom offset
                    },
                },
                locator: {
                    halfSample: true,
                    patchSize: "large", // x-small, small, medium, large, x-large
                    debug: {
                        showCanvas: true,
                        showPatches: false,
                        showFoundPatches: false,
                        showSkeleton: false,
                        showLabels: false,
                        showPatchLabels: false,
                        showRemainingPatchLabels: false,
                        boxFromPatches: {
                            showTransformed: true,
                            showTransformedBox: true,
                            showBB: true
                        }
                    }
                },
                numOfWorkers: 4,
                decoder: {
                    readers: ['code_128_reader'],
                    debug: {
                        drawBoundingBox: true,
                        showFrequency: true,
                        drawScanline: true,
                        showPattern: true
                    },
                },
                locate: true,
            },
            // @ts-ignore
            function(err) {
                if (err) {
                    return console.log(err)
                }
                Quagga.start()
            },
        )
        Quagga.onDetected(this._onDetected)
    }
    componentWillUnmount() {
        Quagga.offDetected(this._onDetected)
        Quagga.stop()
    }

    // @ts-ignore
    _onDetected = result => {
        // @ts-ignore
        this.props.onDetected(result)
    }

    render() {
        return (
            <div id="interactive" className="viewport" style={{ overflow: "hidden", height: "93vh" }} />
        )
    }


}



export default Scanner

import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC } from 'react';
import Config from '../../services/Config';
import { firstProperty } from '../../services/Utils';

interface DataFilterProps {
    field?: string
    label?: string
    endpoint?: string
    data: any
    value: any
    onChange: (data: any) => void
    variant?: StandardTextFieldProps["variant"]
    name: string,
    disableUnderline?: boolean
    noOptionsText?: string
}

const DataFilter: FC<DataFilterProps> = (props) => {

    let {
        name,
        data,
        endpoint = "",
        value,
        onChange,
        variant = "standard",
        field = "id",
        label = "Selecionar",
        disableUnderline = false,
        noOptionsText = Config.NO_OPTIONS_TEXT
    } = props;

    if (!data) return null

    if (!endpoint) {
        endpoint = firstProperty(data, true)
    }

    const handle = (_: any, data: any) => {
        onChange(data?.id || "")
    }

    const setOptions = () => {
        let options = []
        if (data) {
            options = data[endpoint].edges.map((edge: any) => {
                return { id: edge.node[field], name: edge.node.name }
            })
        }
        return options
    }

    const setValue = () => {
        const found = data[endpoint]?.edges.find((edge: any) => edge?.node[field] === value)
        if (found) {
            return { id: found.node[field], name: found.node.name }
        }
        return null
    }

    // https://v4.mui.com/components/autocomplete/
    // https://v4.mui.com/components/autocomplete/#api
    return (
        <Autocomplete
            id={name}
            options={setOptions()}
            onChange={handle}
            value={setValue()}
            getOptionLabel={(option: any) => option?.name}
            noOptionsText={noOptionsText}
            renderInput={(params) => {
                // @ts-ignore
                params.InputProps.disableUnderline = disableUnderline
                return (
                    <TextField {...params} label={label} variant={variant} />
                )
            }
            }
        />
    )
}


export default DataFilter;

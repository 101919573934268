import { Maybe } from '../../generated/graphql';

// se o código de entrada for null or undefined retorna null
export const getMeshCode = (id?: Maybe<number>) => {
    if (id === null || id === undefined) return null
    return "M" + id.toString().padStart(6, "0")
}

// letra m seguido de 6 digitos
export const isValidMeshCode = (code?: Maybe<string>) => {
    if (!code) return false
    const r = /^M([0-9]{6})$/
    return code.match(r)
}

// letras maisculas
// um grupo de 2 letras seguido de 3 números
export const isValidLocationCode = (code?: Maybe<string>) => {
    if (!code) return false
    // CA101
    let r = /^([A-Z]{2})([0-9]{3})$/
    return code.match(r)
}

// é um código válido se for um código de malha
// ou de localização
export const isValidBarcode = (code?: Maybe<string>) => {
    return isValidMeshCode(code) || isValidLocationCode(code)
}

export const meshCode2Id = (code: string): number | null => {
    if (!isValidMeshCode(code)) return null
    const id = code.substring(1, 7)
    return parseInt(id)
}

export const locationCodeArea = (code: string): string | null => {
    if (isValidLocationCode(code)) {
        return code.substring(0, 1)
    }
    return null
}

export const locationCodeAreaFull = (code: string): string | null => {
    const initial = locationCodeArea(code)
    return initial === "C" ? "Armazém Corte" : "Armazém Malha Amostras"
}


export const locationCodeStand = (code: string): string | null => {
    if (isValidLocationCode(code)) {
        return code.substring(1, 2)
    }
    return null
}

export const locationCodeShelf = (code: string): string | null => {
    if (isValidLocationCode(code)) {
        return code.substring(2, 3)
    }
    return null
}

export const locationCodeContainer = (code: string): string | null => {
    if (isValidLocationCode(code)) {
        return code.substring(3, 5)
    }
    return null
}

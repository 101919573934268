import { Fade } from '@material-ui/core';
import React, { FC } from 'react';
import { useMeshesQuery, useSamplesQuery } from "../../generated/graphql";
import CurrentQuantity from '../CurrentQuantity/CurrentQuantity';
import { getMeshCode } from '../MeshView/MeshBarcode';
import { SampleMeshWizardMode } from './SampleMeshWizard';
import styles from './SampleMeshWizard.module.scss';

interface SampleMeshWizardPrimaryProps {
    selection: number[]
    mode: string
}

interface ShowMeshesProps {
    ids: number[]
}

interface ShowSamplesProps {
    ids: number[]
}

const SampleMeshWizardPrimary: FC<SampleMeshWizardPrimaryProps> = (props) => {
    const { mode, selection } = props

    let header
    let component

    if (mode === SampleMeshWizardMode.MESHES) {
        header = "Malhas"
        component = <ShowMeshes ids={selection as number[]} />
    } else {
        header = "Amostras"
        component = <ShowSamples ids={selection} />
    }

    return (
        <div className={styles.SampleMeshWizardPrimary}>
            <h2>{header}</h2>
            {component}
        </div>
    )
}


const FadeIn = (props: any) => {
    return (
        <Fade in={!props.loading} timeout={200}>
            <ul>
                {props.children}
            </ul>
        </Fade>
    )
}

/**
 * meshes são nodes
 */
const ShowMeshes: FC<ShowMeshesProps> = (props) => {
    const { data, loading } = useMeshesQuery({ variables: { filter: { id: { in: props.ids } } } })
    return (
        <FadeIn loading={loading}>
            {data?.meshes?.nodes.map(node =>
                <li key={node.id}>
                    {getMeshCode(node.id)}
                    <span className={styles.CurrentQuantity}><CurrentQuantity mesh={node} /></span>
                </li>)}
        </FadeIn>
    )
}

/**
 * samples são nodes
 */
const ShowSamples: FC<ShowSamplesProps> = (props) => {
    const ids = props.ids.map(id => id.toString())
    const { data, loading } = useSamplesQuery({ variables: { filter: { id: { in: ids } } } })
    return (
        <FadeIn loading={loading}>
            {data?.samples?.nodes.map(node => (
                <li key={node.id}>
                    #{node.orderReference} {node?.sampleType?.name}
                    <span className={styles.Order}>({node.order})</span>
                </li>)
            )}
        </FadeIn>
    )
}

export default SampleMeshWizardPrimary

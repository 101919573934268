import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FastField, useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';
import { useAuth } from '../../services/auth';
import { useHasPermission } from '../../services/permissions';
import { DeviationQuantity } from './Deviation';
import FieldText from './FieldText';
import meshStyles from './MeshView.module.scss';


export const unitCodeToName = (unitCode: string) => {
    return {
        KGS: "Quilos",
        MTS: "Metros",
        PCS: "Peças"
    }[unitCode]
}

interface UnitSelectorProps {
    hide?: boolean
}

const UnitSelector: FC<UnitSelectorProps> = props => {
    const { hide } = props
    const { setFieldValue, values } = useFormikContext()

    // @ts-ignore
    const value = values?.quantityUnit

    if (hide) {
        return null
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFieldValue("quantityUnit", event?.target?.value)
    }

    return (
        <RadioGroup row value={value} onChange={handleChange}>
            <FormControlLabel
                value="KGS"
                control={<Radio color="primary" />}
                label="Quilos"
                labelPlacement="end"
            />
            <FormControlLabel
                value="MTS"
                control={<Radio color="primary" />}
                label="Metros"
                labelPlacement="end"
            />
            <FormControlLabel
                value="PCS"
                control={<Radio color="primary" />}
                label="Peças"
                labelPlacement="end"
            />
        </RadioGroup>
    )
}


const QuantitySection = (props: any) => {
    const { editing } = props
    const { values } = useFormikContext()
    const auth = useAuth()

    // @ts-ignore
    const { quantitySent, quantityReceived, quantityUnit } = values

    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")
    const userEdit = editing && !auth?.user?.isSupervisor

    return (
        <div className={meshStyles.Section}>
            <div className={meshStyles.SectionTitleWrapper}>
                <span className={meshStyles.SectionTitle}>Quantidade</span>
                <span className={meshStyles.SectionNote}>
                    {unitCodeToName(quantityUnit)}
                </span>
            </div>

            <Grid container>
                <UnitSelector hide={userEdit} />
            </Grid>

            <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={3}>
                    <FastField
                        component={FieldText}
                        name="quantitySent"
                        type="number"
                        label="Enviada"
                        disabled={userEdit || !canUpdate}
                    />

                </Grid>
                <Grid item xs={3}>
                    <FastField
                        component={FieldText}
                        name="quantityReceived"
                        type="number"
                        label="Recebida"
                        disabled={userEdit || !canUpdate}
                    />
                </Grid>
                <Grid item xs={3}>
                    <div style={{ paddingBottom: 10, marginLeft: -15 }}>
                        <DeviationQuantity
                            received={quantityReceived}
                            sent={quantitySent}
                            unit={quantityUnit}
                            forwardRef={props.forwardedRef}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container className={meshStyles.LineForm}>
                <Grid item xs={10}>
                    <br />
                    <FastField
                        component={FieldText}
                        name="spoolCount"
                        type="number"
                        label="Rolos"
                        fullWidth
                        autoComplete="off"
                        disabled={!canUpdate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        </div >
    )
}

export default QuantitySection

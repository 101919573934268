import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './AutoSave.module.scss';

const AutoSave = ({ debounceMs = 1000 }) => {
    const formik = useFormikContext();
    const [isSaved, setIsSaved] = useState(null);
    // eslint-disable-next-line
    const debouncedSubmit = useCallback(
        _.debounce(() => {
            // @ts-ignore
            return formik.submitForm().then(() => setIsSaved(true));
        }, debounceMs),
        [formik.submitForm, debounceMs],
    );

    // @ts-ignore
    useEffect(() => {
        return debouncedSubmit
    }, [debouncedSubmit, formik.values]);

    return (
        <p className={styles.AutoSave}>
            {!!formik.isSubmitting
                ? <span className={styles.Saving}>A guardar alterações...</span>
                : isSaved
                    ? <span className={styles.Saved}>Alterações Guardadas.</span>
                    : null}
        </p>
    );
};

export default AutoSave;

import { useState } from "react";

type Value = string | number | boolean | object | undefined | null;

const useBulkActions = <T extends Value>() => {
    const [selected, setSelected] = useState<T[]>([])

    const selectMany = (values: T[]) => {
        setSelected(values)
    }

    const deselectAll = () => {
        setSelected([])
    }

    const select = (value: T) => {
        const selectedIndex = selected.indexOf(value);
        let newSelected: T[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    const isSelected = (value: T) => selected.indexOf(value) !== -1;

    return { selected, select, isSelected, deselectAll, selectMany }
}

export default useBulkActions

import React, { FC } from 'react';
import { useSuppliersQuery } from '../../generated/graphql';
import DataFilter from '../DataFilter/DataFilter';

interface SupplierFilterProps {
    value: any,
    onChange: any,
    disableUnderline?: boolean
}

const SupplierDataFilter: FC<SupplierFilterProps> = (props) => {

    const {
        value,
        onChange,
        disableUnderline = false
    } = props


    const { data } = useSuppliersQuery()

    return (
        <DataFilter
            data={data}
            label="Fornecedor"
            name="supplier"
            value={value}
            onChange={onChange}
            disableUnderline={disableUnderline}
        />
    )
}

export default SupplierDataFilter;

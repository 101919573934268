import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { ApolloError } from 'apollo-client';
import React, { FC } from 'react';
import { useCreateOneMeshLocationMutation, useDeleteOneMeshLocationMutation } from '../../generated/graphql';
import { useErrorMessages } from '../../services/Hooks';
import { useHasPermission } from '../../services/permissions';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Empty from '../Empty/Empty';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import MeshLocationSearch from '../MeshLocationSearch/MeshLocationSearch';
import meshViewStyles from './../MeshView/MeshView.module.scss';
import styles from './LocationSection.module.scss';

interface MeshLocationsProps {
    mesh: any,
    refetch: any
}

const MeshLocationItem = (props: any) => {
    const { meshLocation, onDelete, canDelete } = props
    return (
        <Grid
            item xs={10}
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.MeshLocationItem}
        >
            <div>
                <p className={styles.Reference}>
                    {meshLocation?.location?.name}
                </p>
            </div>

            {canDelete ? <ConfirmButton onClick={() => onDelete(meshLocation?.id)} >
                <DeleteIcon fontSize="small" color="primary" className={styles.Confirm} />
            </ConfirmButton> : null}
        </Grid>
    )
}

const LocationSection: FC<MeshLocationsProps> = (props) => {
    const { mesh, refetch } = props
    const { meshLocations = [] } = mesh

    const [createMeshLocation] = useCreateOneMeshLocationMutation()
    const [deleteMeshLocation] = useDeleteOneMeshLocationMutation()
    const [errors, setErrors] = useErrorMessages()


    const hasPermission = useHasPermission()
    const canCreate = hasPermission("MESH_LOCATION__CREATE")
    const canDelete = hasPermission("MESH_LOCATION__DELETE")

    const handleChange = async (event: any) => {
        const location = event.target.value
        try {
            await createMeshLocation({
                variables: {
                    input: { meshLocation: { location, mesh: mesh.id } }
                }
            }).then(refetch)
        } catch (e) {
            setErrors(e as ApolloError)
        }
    }

    const handleDelete = async (id: any) => {
        try {
            return await deleteMeshLocation({ variables: { input: { id } } }).then(refetch)
        } catch (e) {
            setErrors(e as ApolloError)
        }
    }

    return (
        <div className={meshViewStyles.Section}>
            <h3>Localizações</h3>
            {!meshLocations.length && <Empty message="Sem localizações associadas." />}

            <Grid container>
                {canCreate ? <Grid item xs={10}>
                    <MeshLocationSearch onChange={handleChange} />
                </Grid> : null}
                <ErrorMessages data={errors} />
            </Grid>


            <Grid container direction="column" id="sample-meshes-list" className={styles.MeshLocationList}>
                {meshLocations.map((meshLocation: any, index: number) => {
                    return <MeshLocationItem key={index} meshLocation={meshLocation} onDelete={handleDelete} canDelete={canDelete} />
                }
                )}
            </Grid>


        </div>
    )

};


export default LocationSection;

import { Button, Card, CardContent, Fade, Grid, IconButton, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Form, Formik, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../services/auth';
import Config from '../../services/Config';
import { useGuestRedirection, useMeshManager } from '../../services/Hooks';
import Autocompletes from '../Autocompletes/Autocompletes';
import AutoSave from '../AutoSave/AutoSave';
import Header from '../Header/Header';
import Leading from '../Leading/Leading';
import MeshConsumptions from '../MeshConsumptions/MeshConsumptions';
import MeshSamples from '../MeshSamples/MeshSamples';
import print from '../Print/Print';
import printLabel from '../Print/PrintLabel';
import SecondBar from '../SecondBar/SecondBar';
import Sidemenu from '../Sidemenu/Sidemenu';
import InspectionSection from './InspectionSection';
import KindAndColorSection from './KindAndColorSection';
import LocationSection from './LocationSection';
import meshValidationSchema from './MeshSchema';
import styles from './MeshView.module.scss';
import { getInitialValues } from './MeshViewUtils';
import OrderSection from './OrderSection';
import QuantitySection from './QuantitySection';
import ShowFormErrors from './ShowFormErrors';
import ShrinkageSection from './ShrinkageSection';
import SpiralitySection from './SpiralitySection';
import SupplierSection from './SupplierSection';
import WeightSection from './WeightSection';
import WidthSection from './WidthSection';



const MeshView = (props: any) => {
    useGuestRedirection()

    const { id } = props.match.params;
    const { data, loading, create, update, remove, refetch } = useMeshManager(id)
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // references
    const widthDeviationRef = useRef<HTMLSpanElement>(null)
    const weightDeviationRef = useRef<HTMLSpanElement>(null)
    const widthShrinkageRateRef = useRef<HTMLSpanElement>(null)
    const lengthShrinkageRateRef = useRef<HTMLSpanElement>(null)
    const quantityDifferenceRef = useRef<HTMLSpanElement>(null)
    const spiralityResultRef = useRef<HTMLSpanElement>(null)

    const initial = getInitialValues(data)

    const handleSubmit = async (values: FormikValues) => {
        if (!id) {
            return create(values).then(response => {
                if (response.data?.createOneMesh.id) {
                    const createdId = response.data?.createOneMesh.id
                    history.push(`/meshes/${createdId}?new`)
                } else {
                    enqueueSnackbar('Erro ao criar malha.', { variant: "error" });

                }
            }
            );
        }
        return update(values)
    }

    const handlePrint = (values: any) => {
        if (values) {
            const printData = {
                ...values,
                widthDeviation: widthDeviationRef?.current?.textContent,
                weightDeviation: weightDeviationRef?.current?.textContent,
                widthShrinkageRate: widthShrinkageRateRef.current?.textContent,
                lengthShrinkageRate: lengthShrinkageRateRef.current?.textContent,
                quantityDifference: quantityDifferenceRef.current?.textContent,
                spiralityResult: spiralityResultRef.current?.textContent,
                sampleMeshes: data?.mesh?.sampleMeshes
            }
            // @ts-ignore
            print(printData)
        }
    }

    const handlePrintLabel = (values: any) => {
        printLabel(values)
    }


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleRemove = () => {
        remove({ variables: { input: { id } } })
    };

    const handleGoBack = () => {
        if (location.search === '?new') {
            history.go(-2)
        } else {
            history.goBack()
        }
    }

    return (
        <>
            <Header />
            <Leading />
            <Sidemenu />


            <Formik validationSchema={meshValidationSchema} initialValues={initial} onSubmit={handleSubmit} enableReinitialize>
                {({ errors, values, submitForm }) => (
                    <>
                        <SecondBar>
                            <Grid container justifyContent="space-between" alignItems="center" className={styles.MeshViewToolbar}>
                                <div>
                                    <IconButton onClick={handleGoBack}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <span style={{ fontSize: 20, fontWeight: 500 }}>{id ? `Malha #${id}` : "Nova Malha"}</span>
                                </div>

                                <div className={styles.Actions}>
                                    {id && <Tooltip title="Imprimir Teste">
                                        <Button variant="contained" color="primary" startIcon={<PrintIcon />} onClick={() => handlePrint(values)} size="small">
                                            Teste
                                        </Button>
                                    </Tooltip>}

                                    {id && <Tooltip title="Imprimir Etiqueta">
                                        <Button variant="outlined" color="primary" startIcon={<LocalOfferIcon />} onClick={() => handlePrintLabel(values)} size="small">
                                            Etiqueta
                                        </Button>
                                    </Tooltip>}

                                    {id && auth?.user?.isSupervisor ? <>
                                        <IconButton onClick={handleClick}>
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={handleRemove}>
                                                Remover Malha
                                            </MenuItem>
                                        </Menu>
                                    </> : null}




                                    {!id && <Button
                                        variant="contained"
                                        color="primary"
                                        data-cy="submit" onClick={submitForm} size="small">
                                        <SaveIcon /> &nbsp; Criar Malha
                                    </Button>}
                                </div>
                            </Grid>


                        </SecondBar>
                        <Grid container className={styles.MeshView} justifyContent="center">
                            <Fade in={!loading} timeout={200}>
                                <Card className={styles.MeshCard}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {Config.ENABLE_MESH_AUTOCOMPLETE && <Autocompletes isActive={!id} />}
                                                <OrderSection />
                                                <SupplierSection />
                                                <KindAndColorSection />
                                            </Grid>

                                            <Grid item xs={4} className={styles.CenterCol}>

                                                <WidthSection forwardedRef={widthDeviationRef} editing={data?.mesh?.id ? true : false} />

                                                <WeightSection forwardedRef={weightDeviationRef} editing={data?.mesh?.id ? true : false} />

                                                <QuantitySection forwardedRef={quantityDifferenceRef} editing={data?.mesh?.id ? true : false} />

                                                <ShrinkageSection
                                                    widthRateRef={widthShrinkageRateRef}
                                                    lengthRateRef={lengthShrinkageRateRef}
                                                />

                                                <SpiralitySection forwardedRef={spiralityResultRef} />

                                            </Grid>


                                            <Grid item xs={4}>

                                                {data?.mesh && <MeshSamples mesh={data?.mesh} refetch={refetch} />}
                                                {data?.mesh ? <MeshConsumptions mesh={data?.mesh} refetch={refetch} /> : null}
                                                {data?.mesh && <LocationSection mesh={data?.mesh} refetch={refetch} />}
                                                <InspectionSection />


                                            </Grid>


                                        </Grid>

                                        <ShowFormErrors errors={errors} disable />

                                        <Form>

                                            {id ? <AutoSave /> : null}

                                        </Form>
                                    </CardContent>
                                </Card>
                            </Fade>
                        </Grid>
                    </>
                )
                }
            </Formik >
        </>
    )
}

export default MeshView;

import { Fade, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';
import { useSamplesQuery } from '../../generated/graphql';
import useBulkActions from '../../hooks/useBulkActions';
import Config from '../../services/Config';
import { useFilters } from '../../services/Hooks';
import AutoTextField from '../AutoTextField/AutoTextField';
import BrandDataFilter from '../BrandDataFilter/BrandDataFilter';
import { CreateSampleMeshBulkAction } from '../BulkActions/BulkActions';
import CustomModal from '../CustomModal/CustomModal';
import { EmptyView } from '../Empty/Empty';
import Pagination from '../Pagination/Pagination';
import SampleMeshWizard, { SampleMeshWizardMode } from '../SampleMeshWizard/SampleMeshWizard';
import SampleTile from '../SampleTile/SampleTile';
import SecondBar from '../SecondBar/SecondBar';
import StandardView from '../StandardView/StandardView';
import styles from './Samples.module.scss';

const samplesQuery = (search: string, brand: string, page: number) => {

    const pageSize = Config.PAGE_SIZE
    const offset = (page - 1) * pageSize

    let variables: any = {
        filter: { isVirtual: { is: false }, isArchived: { is: false } },
        paging: { limit: pageSize, offset }
    }

    if (search) {
        /**
         * Determina o tipo de pesquisa a efetuar
         * com valor singular ou com valor múltiplo (separado por ,)
         * só pesquisa no campo orderReference
         */
        if (search.includes(",")) {
            let elements = search.split(",")
            let ids = elements.map(id => parseInt(id))
            variables.filter = {
                ...variables.filter, orderReference: { in: ids }
            }
        } else {
            variables.filter = {
                ...variables.filter, orderReference: {
                    eq: parseInt(search)
                }
            }
        }
    }


    if (brand) {
        variables.filter = {
            ...variables.filter, brand: { id: { eq: parseInt(brand) } }
        }
    }

    return { variables }
}

const Samples = () => {

    const [filters, setFilter] = useFilters(["search", "brand", "page"])

    // @ts-ignore
    const search = filters.search

    // @ts-ignore
    const brand = filters.brand

    // @ts-ignore
    const page = filters.page

    const { data, loading } = useSamplesQuery({ ...samplesQuery(search, brand, page) })
    const { selected, select, isSelected, deselectAll } = useBulkActions<number>()
    const [open, setOpen] = useState<boolean>(false)


    const handleBulk = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
        deselectAll();
    }

    /**
     * temos que manter isto por causa do search, o pagination
     * já está a utilizar o setFilter diretamente
     */
    const _setFilter = (name: string, value: string | number) => {
        // @ts-ignore
        setFilter(name, value);
    }

    return (
        <>
            <StandardView />
            <SecondBar>
                <Grid container justifyContent="flex-start" alignItems="center" className={styles.FilterBar}>
                    <Grid item xs={2}>
                        <AutoTextField
                            name="search"
                            onSave={(data: any) => _setFilter("search", data.search)}
                            placeholder=" Pesquisar por referência"
                            value={search}
                            interval={500}
                            fullWidth={true}
                            clearable={true}
                            InputProps={{
                                autoComplete: "off",
                                autoFocus: true,
                                startAdornment: <SearchIcon />,
                                disableUnderline: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={10} container justifyContent="flex-end">
                        <Grid item xs={3} className={styles.Filter}>
                            <BrandDataFilter value={brand} onChange={(data: string) => _setFilter("brand", data)} disableUnderline />
                        </Grid>
                    </Grid>

                </Grid>

            </SecondBar>

            <CreateSampleMeshBulkAction selected={selected} deselectAll={deselectAll} onClick={handleBulk} />

            <Fade in={!loading} timeout={200}>
                <Grid container className={styles.Samples}>
                    {data?.samples?.nodes?.length ? data?.samples.nodes.map(
                        node => <SampleTile key={node.id} sample={node} isSelected={isSelected} onSelect={(sampleId) => select(sampleId)} />)
                        : <EmptyView message="Nenhuma amostra encontrada." />}
                </Grid>
            </Fade>

            <Pagination collection={data?.samples} page={page} setPage={setFilter} />

            <CustomModal isActive={open} maxWidth="lg" handleClose={() => setOpen(false)}>
                <SampleMeshWizard selection={selected} mode={SampleMeshWizardMode.SAMPLES} onClose={handleClose} />
            </CustomModal>

        </>
    )

}

export default Samples;

import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import { Maybe } from '../../generated/graphql';
import Config from '../../services/Config';
import { staticFile } from '../../services/Utils';
import styles from './Avatar.module.scss';

type AvatarProps = {
    name?: Maybe<string>,
    color?: Maybe<string>,
    image?: Maybe<string>
    width?: number
    height?: number
}

// este componente é baseado no componente existente no mypedrosa-dashboards
// com algumas alterações, ver se é possível fundir
const Avatar: FC<AvatarProps> = (props) => {
    const {
        color = '#ccc',
        image,
        width = 33,
        height = 33,
    } = props

    const defaultImage = Config.DEFAULT_USER_AVATAR
    const url = image && image !== defaultImage ? staticFile(image) : staticFile(defaultImage)

    return (
        <Grid container className={styles.Avatar} style={{ backgroundColor: color!, backgroundImage: `url(${url})`, width, height }} />
    )
}


export default Avatar;

import React from 'react';

const ConfigurationEditor = (props: any) => {

    let component = { ...props.component, props: props }

    if (!component) {
        return <div></div>
    }

    return component
}

export default ConfigurationEditor;

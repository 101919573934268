import { Grid, Tooltip } from '@material-ui/core';
import { Delete, Error } from '@material-ui/icons';
import { FC, useState } from 'react';
import { useSampleMeshMeshesWizardQuery, useSampleMeshSamplesWizardQuery } from '../../generated/graphql';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import CurrentQuantity from '../CurrentQuantity/CurrentQuantity';
import { getMeshCode } from '../MeshView/MeshBarcode';
import SampleMeshSearch, { SampleMeshSearchByMesh, SampleMeshSearchByMeshSelected } from '../SampleMeshSearch/SampleMeshSearch';
import { inMeshesMode, SampleMeshWizardMode } from './SampleMeshWizard';
import styles from './SampleMeshWizard.module.scss';

interface SampleMeshWizardSecondaryProps {
    selection: number[]
    selected: number[]
    mode: SampleMeshWizardMode
    onSelect: (data: SampleMeshSearchByMeshSelected) => void
    onRemove: (id: number) => Promise<boolean>
    onError: any
}

interface ShowAffectedProps {
    name?: string
    matches: number[]
    mode: SampleMeshWizardMode
}

interface SearchProps {
    mode: SampleMeshWizardMode
    onSelect: (data: any) => void
}

interface RemoveOptionProps {
    onRemove: (id: string) => Promise<boolean>
    id: any
    toast?: string
}


/**
 * mostra widget de pesquisa (por malha ou amostra) conforme o modo
 */
const Search: FC<SearchProps> = (props) => {
    const { mode, onSelect } = props
    if (mode === SampleMeshWizardMode.MESHES) {
        return <SampleMeshSearch onChange={onSelect} />
    }
    return <SampleMeshSearchByMesh onChange={onSelect} />
}

/**
 * retornar as amostras que já estão associadas às malhas
 * selecionadas
 */
const checkSampleMatches = (sample: any, selected: number[]) => {
    let matches: number[] = []
    selected.forEach(id => {
        sample?.sampleMeshes.forEach((sampleMesh: any) => {
            if (sampleMesh.mesh.id === id) {
                matches.push(id)
            }
        })
    })
    return matches
}

/**
 * retornar as malhas que já estão associadas às amostras
 * selecionadas
 */
const checkMeshMatches = (mesh: any, selected: number[]) => {
    let matches: number[] = []
    selected.forEach(id => {
        mesh?.sampleMeshes.forEach((sampleMesh: any) => {
            if (parseInt(sampleMesh?.sample?.id) === id) {
                matches.push(sampleMesh?.sample?.orderReference)
            }
        })
    })
    return matches
}

const checkMatches = (obj: any, mode: SampleMeshWizardMode, selection: number[]) => {
    if (!selection) return []
    return inMeshesMode(mode) ? checkSampleMatches(obj, selection) : checkMeshMatches(obj, selection)
}


/**
 * assinala visualmente as amostras que já se encontram
 * associadas às malhas selecionadas
 */
const ShowAffected: FC<ShowAffectedProps> = (props) => {
    const { name, matches, mode } = props
    if (!matches.length || !name) return null
    const matchList = matches.join(', ')
    const titleForMesh = `Amostra #${name} já está associada à(s) malha(s) ${matchList}`
    const titleForSample = `Malha #${name} já está associada à(s) amostra(s) ${matchList}`
    const title = inMeshesMode(mode) ? titleForMesh : titleForSample
    return matches.length > 0 ? <Tooltip title={title}><Error className={styles.ErrorIcon} /></Tooltip > : null
}

/**
 * componente que serve como contentor para os dados das amostras
 */
const SamplesWizardQuery = ({ children, selected }: { children: any, selected: number[] }) => {
    const ids = selected.map(sel => sel.toString())
    const { data, loading } = useSampleMeshSamplesWizardQuery({ variables: { filter: { id: { in: ids } } } })
    return children({ data, loading })
}

/**
 * componente que serve como contentor para os dados das malhas
 */
const MeshesWizardQuery = ({ children, selected }: { children: any, selected: number[] }) => {
    const { data, loading } = useSampleMeshMeshesWizardQuery({ variables: { filter: { id: { in: selected } } } })
    return children({ data, loading })
}


/**
 * componente que faz render do botão para remover associação
 */
const RemoveOption: FC<RemoveOptionProps> = (props) => {
    const { onRemove, toast = "Associação Removida", id } = props
    return (
        <ConfirmButton onClick={() => onRemove(id)} toast={toast}>
            <Delete />
        </ConfirmButton>
    )
}

const ListComponent = (props: any) => {
    const { data, onRemove, onError, selected, selection, mode } = props

    if (!selected.length) return null

    const handleRemove = (sampleId: string) => {
        return onRemove(sampleId)
    }

    let message: string
    let collection

    if (inMeshesMode(mode)) {
        message = "Amostra removida da lista de associações"
        collection = data?.samples?.nodes
    } else {
        message = "Malha removida da lista de associações"
        collection = data?.meshes?.nodes
    }

    let withError = 0

    return (
        <>
            <ul>
                {collection?.length && collection?.map(
                    (obj: any) => {
                        // não permitir associações duplicadas
                        let matches = checkMatches(obj, mode, selection)
                        if (matches.length > 0) withError = 1
                        return (
                            <li key={obj?.node?.id || obj?.id}>
                                <Grid container direction="row" alignItems="center">
                                    {
                                        inMeshesMode(mode) ?
                                            <span>
                                                {/* mostrar dados da amostra */}
                                                #{obj?.orderReference} - {obj?.sampleType?.name} <span className={styles.Order}>({obj?.order})</span> {obj?.brand?.name}
                                            </span>
                                            :
                                            <span>
                                                {/* mostrar dados da malha */}
                                                {getMeshCode(obj?.id)}
                                                <span className={styles.CurrentQuantity}>
                                                    <CurrentQuantity mesh={obj} />
                                                </span>
                                            </span>
                                    }

                                    <RemoveOption id={obj?.id} onRemove={handleRemove} toast={message} />
                                    <ShowAffected name={obj?.orderReference || getMeshCode(obj?.id)} matches={matches} mode={mode} />
                                </Grid>
                            </li>)
                    }
                )}
            </ul>
            <p>{withError ? onError(true) : onError(false)}</p>
        </>
    )
}

/**
 *
 */
const SampleMeshWizardSecondary: FC<SampleMeshWizardSecondaryProps> = (props) => {
    const { mode, onSelect, onRemove, onError, selected, selection } = props
    const [hasError, setHasError] = useState<boolean>(false)

    const handleError = (value: boolean) => {
        setHasError(value)
        onError(value)
    }

    let child = (props: any) => (
        <>
            {inMeshesMode(mode) ? <h2>Amostras</h2> : <h2>Malhas</h2>}
            <ListComponent {...props} selection={selection} selected={selected} onRemove={onRemove} onError={handleError} mode={mode} />
        </>
    )

    let parent

    if (inMeshesMode(mode)) {
        parent = (
            <SamplesWizardQuery selected={selected}>
                {child}
            </SamplesWizardQuery>
        )
    } else {
        parent = (
            <MeshesWizardQuery selected={selected}>
                {child}
            </MeshesWizardQuery>
        )
    }

    return (
        <>
            {parent}
            <Search mode={mode} onSelect={onSelect} />
            <p>{selected.length && hasError ? <span className={styles.ErrorText}>Remover os itens assinalados para continuar.</span> : null}</p>
        </>
    )
}

export default SampleMeshWizardSecondary

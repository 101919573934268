import { Checkbox, Fade, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { Grid } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMeshesQuery } from '../../generated/graphql';
import useBulkActions from '../../hooks/useBulkActions';
import Config from '../../services/Config';
import { useFilters } from '../../services/Hooks';
import { useHasPermission } from '../../services/permissions';
import AutoTextField from '../AutoTextField/AutoTextField';
import BrandDataFilter from '../BrandDataFilter/BrandDataFilter';
import { CreateSampleMeshBulkAction } from '../BulkActions/BulkActions';
import CurrentQuantity from '../CurrentQuantity/CurrentQuantity';
import CustomModal from '../CustomModal/CustomModal';
import { EmptyView } from '../Empty/Empty';
import FormatDate from '../FormatDate/FormatDate';
import MeshFabricTypeDataFilter from '../MeshFabricTypeDataFilter/MeshFabricTypeDataFilter';
import MeshLocations from '../MeshLocations/MeshLocations';
import { fullPurchaseOrder } from '../MeshView/MeshViewUtils';
import Pagination from '../Pagination/Pagination';
import PurchaseOrderTypeFilter from '../PurchaseOrderTypeFilter/PurchaseOrderType';
import SampleMeshWizard, { SampleMeshWizardMode } from '../SampleMeshWizard/SampleMeshWizard';
import SecondBar from '../SecondBar/SecondBar';
import StandardView from '../StandardView/StandardView';
import SupplierDataFilter from '../SupplierDataFilter/SupplierDataFilter';
import styles from './Meshes.module.scss';

const meshesQuery = (search: string, brand: string, supplier: string, location: string, purchaseOrderType: string, page: number, archived: string, meshFabricType: string) => {

    const pageSize = Config.PAGE_SIZE
    const offset = (page - 1) * pageSize

    let variables: any = {
        filter: {},
        sorting: [{ field: "id", direction: "DESC" }],
        paging: { limit: pageSize, offset }
    }

    // só ativar filter se existir
    if (search) {

        // verificar se é pesquisa multipla
        // pesquisa múltipla só se aplica
        // a ids de malha
        if (search.includes(",")) {
            let elements = search.split(",")
            let ids = elements.map(id => parseInt(id))
            variables.filter = {
                ...variables.filter, id: { in: ids }
            }
        } else {
            variables.filter = {
                ...variables.filter, or: [
                    {
                        id: {
                            eq: parseInt(search)
                        }
                    }, {
                        purchaseOrder: {
                            iLike: `%${search}%`
                        }
                    }]
            }
        }
    }

    if (brand) {
        variables.filter = {
            ...variables.filter, brand: { id: { eq: parseInt(brand) } }
        }
    }
    if (supplier) {
        variables.filter = {
            ...variables.filter, supplier: { id: { eq: parseInt(supplier) } }
        }
    }
    if (location) {
        variables.filter = {
            ...variables.filter, location: { id: { eq: parseInt(location) } }
        }
    }
    if (purchaseOrderType) {
        variables.filter = {
            ...variables.filter, purchaseOrderType: { id: { eq: parseInt(purchaseOrderType) } }
        }
    }
    if (meshFabricType) {
        variables.filter = {
            ...variables.filter, meshFabricType: { id: { eq: parseInt(meshFabricType) } }
        }
    }
    if (!archived || archived === "false") {
        variables.filter = {
            ...variables.filter, currentQuantity: { neq: 0 }
        }
    }

    return { variables }
}



const Meshes = () => {

    const [filters, setFilter] = useFilters(["search", "brand", "supplier", "location", "purchaseOrderType", "page", "archived", "meshFabricType"])

    // @ts-ignore
    const search = filters.search
    // @ts-ignore
    const brand = filters.brand
    // @ts-ignore
    const supplier = filters.supplier
    // @ts-ignore
    const location = filters.location
    // @ts-ignore
    const purchaseOrderType = filters.purchaseOrderType
    // @ts-ignore
    const meshFabricType = filters.meshFabricType
    // @ts-ignore
    const archived = filters.archived

    // @ts-ignore
    const page = filters.page


    const { data, loading } = useMeshesQuery({ ...meshesQuery(search, brand, supplier, location, purchaseOrderType, page, archived, meshFabricType), fetchPolicy: "no-cache" })
    const history = useHistory()
    const hasPermission = useHasPermission()
    const canCreate = hasPermission("MESH__CREATE")
    const { selected, select, selectMany, isSelected, deselectAll } = useBulkActions<number>()
    const [open, setOpen] = useState<boolean>(false)


    const handleClick = (id: number) => {
        if (!selected.length) {
            history.push(`/meshes/${id}`)
        } else {
            select(id)
        }
    }

    const selectAll = () => {
        const newSelected: number[] | undefined = data?.meshes.nodes.map(mesh => mesh.id)
        if (newSelected?.length) {
            selectMany(newSelected)
        }
    }

    const handleMainCheckbox = () => {
        selected.length > 1 ? deselectAll() : selectAll()
    }

    const handleCheckboxClick = (event: MouseEvent<HTMLButtonElement>, id: number) => {
        event.stopPropagation()
        select(id)
    };

    const handleBulk = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
        deselectAll();
    }

    const isIndeterminate = () => selected.length > 0 && selected.length !== data?.meshes?.nodes?.length
    const isMainCheckboxSelected = selected.length === data?.meshes.nodes.length
    const showArchived = archived === "true" ? true : false

    const _setFilter = (name: string, value: string | number) => {
        // @ts-ignore
        setFilter(name, value);
    }

    const handleArchived = (event: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setFilter("archived", event.target.checked)
    }

    return (
        <>
            <StandardView />
            <div className={styles.Meshes}>

                <CreateSampleMeshBulkAction selected={selected} deselectAll={deselectAll} onClick={handleBulk} />

                <SecondBar>
                    <Grid container justifyContent="flex-start" alignItems="center" className={styles.FilterBar}>
                        <Grid item xs={2}>
                            <AutoTextField
                                name="search"
                                onSave={(data: any) => _setFilter("search", data.search)}
                                placeholder=" Pesquisar requisição ou id malha"
                                value={search}
                                interval={500}
                                fullWidth={true}
                                clearable={true}
                                InputProps={{
                                    autoComplete: "off",
                                    autoFocus: true,
                                    startAdornment: <SearchIcon />,
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={10} container justifyContent="flex-end">

                            <Grid item xs={3} className={styles.Filter}>
                                <MeshFabricTypeDataFilter value={meshFabricType} onChange={(data: string) => _setFilter("meshFabricType", data)} disableUnderline />
                            </Grid>

                            <Grid item xs={3} className={styles.Filter}>
                                <PurchaseOrderTypeFilter value={purchaseOrderType} onChange={(data: string) => _setFilter("purchaseOrderType", data)} disableUnderline />
                            </Grid>

                            <Grid item xs={3} className={styles.Filter}>
                                <BrandDataFilter value={brand} onChange={(data: string) => _setFilter("brand", data)} disableUnderline />
                            </Grid>
                            <Grid item xs={3} className={styles.Filter}>
                                <SupplierDataFilter value={supplier} onChange={(data: string) => _setFilter("supplier", data)} disableUnderline />
                            </Grid>

                            <Grid item className={styles.Filter} style={{ marginTop: 10 }}>
                                <Tooltip title="Incluir arquivadas">
                                    <Switch checked={showArchived} onChange={handleArchived} />
                                </Tooltip>
                            </Grid>
                        </Grid>

                    </Grid>
                </SecondBar>
                <Fade in={!loading} timeout={200}>
                    <div className={styles.MeshesList}>
                        {data?.meshes?.nodes?.length ? <TableContainer component={Paper}>
                            <Table>

                                <TableHead style={{ backgroundColor: "#F5EAEA" }}>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={handleMainCheckbox}
                                                color="primary"
                                                checked={isMainCheckboxSelected}
                                                indeterminate={isIndeterminate()}
                                                inputProps={{
                                                    'aria-labelledby': "labelId",
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Requisição</TableCell>
                                        <TableCell>Fornecedor</TableCell>
                                        <TableCell>Tipo Malha</TableCell>
                                        <TableCell>Detalhes Tipo Malha</TableCell>
                                        <TableCell>Composição</TableCell>
                                        <TableCell>Cor</TableCell>
                                        <TableCell>Gramagem</TableCell>
                                        <TableCell>Quantidade Disponível</TableCell>
                                        <TableCell>Marca</TableCell>
                                        <TableCell>Data Receção</TableCell>
                                        <TableCell>Localizações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.meshes.nodes.map(mesh => {
                                        const isItemSelected = isSelected(mesh.id);
                                        return (

                                            <TableRow key={Math.random()} hover onClick={() => handleClick(mesh?.id)} style={{ cursor: "pointer" }} >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleCheckboxClick(event, mesh.id)}
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': "labelId",
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell>{mesh.id}</TableCell>
                                                <TableCell><span className={styles.Reference}>{fullPurchaseOrder(mesh)}</span></TableCell>
                                                <TableCell>{mesh.supplier.name}</TableCell>
                                                <TableCell>{mesh?.meshFabricType?.name || "-"}</TableCell>
                                                <TableCell>{mesh?.meshKind || "-"}</TableCell>
                                                <TableCell>{mesh?.composition || "-"}</TableCell>
                                                <TableCell>{mesh?.color || "-"}</TableCell>
                                                <TableCell>{mesh?.weightReceived} gr/m²</TableCell>

                                                <TableCell>
                                                    <CurrentQuantity mesh={mesh} />
                                                </TableCell>

                                                <TableCell>{mesh.brand.name}</TableCell>

                                                <TableCell><FormatDate date={mesh.receptionDate} format={"dd'/'MM'/'yyyy"} /></TableCell>
                                                <TableCell><MeshLocations mesh={mesh} /></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer> : <EmptyView message="Nenhuma malha encontrada." />}

                        <Pagination collection={data?.meshes} page={page} setPage={setFilter} />

                        {canCreate ? <Fab color="primary" className={styles.GlobalFabButton} onClick={() => history.push("/create-mesh")}>
                            <AddIcon />
                        </Fab> : null}

                    </div>
                </Fade >
            </div >
            <CustomModal isActive={open} maxWidth="lg" handleClose={() => setOpen(false)}>
                <SampleMeshWizard selection={selected} mode={SampleMeshWizardMode.MESHES} onClose={handleClose} />
            </CustomModal>
        </>
    )
}


export default Meshes;

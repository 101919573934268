import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params';
import Configurations from './components/Configurations/Configurations';
import Help from './components/Help/Help';
import Issues from './components/Issues/Issues';
import Login from './components/Login/Login';
import Meshes from './components/Meshes/Meshes';
import MeshView from './components/MeshView/MeshView';
import SampleDetailsView from './components/SampleDetailsView/SampleDetailsView';
import Samples from './components/Samples/Samples';
import Scan from './components/Scan/Scan';
import Transfers from './components/Transfers/Transfers';
import AuthProvider from './services/auth';
import client from "./services/Client";
import PermissionsProvider from './services/permissions';
import theme from './theme';

const queryStringifyOptions: ExtendedStringifyOptions = {
    transformSearchString: transformSearchStringJsonSafe,
};

function App() {
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <PermissionsProvider>
                    <Router>
                        <SnackbarProvider maxSnack={3}>
                            <MuiThemeProvider theme={theme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <QueryParamProvider
                                        ReactRouterRoute={Route}
                                        stringifyOptions={queryStringifyOptions}
                                    >
                                        <Route exact path="/" component={Help} />
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/ocorrencias" component={Issues} />
                                        <Route exact path="/ocorrencias/criar/:id" component={Issues} />
                                        <Route exact path="/suporte" component={Help} />
                                        <Route exact path="/meshes/" component={Meshes} />
                                        <Route exact path="/meshes/:id" component={MeshView} />
                                        <Route exact path="/create-mesh/" component={MeshView} />
                                        <Route exact path="/scan/" component={Scan} />
                                        <Route exact path="/transfers/" component={Transfers} />
                                        <Route exact path="/configurations/samples" component={Configurations} />
                                        <Route exact path="/configurations/sample-types" component={Configurations} />
                                        <Route exact path="/configurations/finishing-types" component={Configurations} />
                                        <Route exact path="/configurations/decorations" component={Configurations} />
                                        <Route exact path="/configurations/prices" component={Configurations} />
                                        <Route exact path="/configurations/notifications" component={Configurations} />
                                        <Route exact path="/configurationqs/checklists" component={Configurations} />
                                        <Route exact path="/configurations/suppliers" component={Configurations} />
                                        <Route exact path="/samples/" component={Samples} />
                                        <Route exact path="/samples/:id" component={SampleDetailsView} />
                                    </QueryParamProvider>
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </SnackbarProvider>
                    </Router>
                </PermissionsProvider>
            </AuthProvider>
        </ApolloProvider >

    );
}

export default App;

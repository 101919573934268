import { Grid } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { useAuth } from '../../services/auth';
import { useHasPermission } from '../../services/permissions';
import { DeviationWidth } from './Deviation';
import FieldText from './FieldText';
import styles from './MeshView.module.scss';

const WidthSection = (props: any) => {
    const { editing } = props
    const formikContext = useFormikContext()
    const auth = useAuth()

    // @ts-ignore
    const widthRequired = formikContext.values.widthRequired
    // @ts-ignore
    const widthReceived = formikContext.values.widthReceived

    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")
    const userEdit = editing && !auth?.user?.isSupervisor

    return (
        <div className={styles.Section}>
            <div className={styles.SectionTitleWrapper}>
                <span className={styles.SectionTitle}>Largura</span>
                <span className={styles.SectionNote}>Em cms</span>
            </div>

            <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={3}>
                    <FastField
                        name="widthRequired"
                        type="number"
                        label="Pretendida"
                        component={FieldText}
                        disabled={userEdit || !canUpdate}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FastField
                        name="widthReceived"
                        type="number"
                        component={FieldText}
                        label="Obtida"
                        disabled={userEdit || !canUpdate}
                    />
                </Grid>
                <Grid item xs={3}>
                    <div style={{ paddingBottom: 10, marginLeft: -15 }}>
                        <DeviationWidth
                            a={widthReceived}
                            b={widthRequired}
                            units="cm"
                            forwardedRef={props.forwardedRef}
                        />
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default WidthSection

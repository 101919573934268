import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";

export interface ToastReturnType {
    successToast: (message: string) => SnackbarKey
    errorToast: (message: string) => SnackbarKey
    feedback: (condition: boolean, successMessage: string, errorMessage: string) => any
    toast: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
    close: (key?: SnackbarKey) => void
}

const useToast = (): ToastReturnType => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const successToast = (message: string = "success") => {
        return enqueueSnackbar(message, { variant: "success" });
    }

    const errorToast = (message: string = "error") => {
        return enqueueSnackbar(message, { variant: "error" });
    }

    const feedback = (condition: boolean, successMessage: string, errorMessage: string) => {
        if (condition) {
            successToast(successMessage)
        } else {
            errorToast(errorMessage)
        }
    }

    const toast = enqueueSnackbar
    const close = closeSnackbar

    return { successToast, errorToast, toast, close, feedback }
}

export default useToast

import { Tooltip } from '@material-ui/core';
import { FC } from 'react';
import { Mesh } from '../../generated/graphql';
import { DeepPartial } from '../../typings/custom';


interface MeshLocationsProps {
    mesh: DeepPartial<Mesh>
}

// @ts-ignore
const contactMeshLocations = meshLocations => {
    let output = ''
    meshLocations.forEach((meshLocation: any, index: number) => {
        output += `${meshLocation?.location?.name}`
        if (index < meshLocations.length - 1) {
            output += '; '
        }
    })
    return output
}

const MeshLocations: FC<MeshLocationsProps> = props => {

    const { mesh } = props
    const { meshLocations } = mesh

    if (meshLocations && meshLocations?.length === 0) {
        return <div>-</div>
    }

    const allLocations = contactMeshLocations(mesh?.meshLocations)

    return (
        <div>
            <Tooltip title={allLocations}>
                <span>{allLocations}</span>
            </Tooltip>
        </div>
    )
}

export default MeshLocations;

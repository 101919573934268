import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSupportTopicQuery } from '../../generated/graphql';
import { staticFile } from '../../services/Utils';
import FormatDate from '../FormatDate/FormatDate';
import Loading from '../Loading/Loading';
import styles from './TopicDetails.module.scss';

const TopicDetails = (props: any) => {

    const id = props.id || props?.match?.params?.id;

    const { data, loading } = useSupportTopicQuery({ variables: { id } });

    if (loading) {
        return <Loading />
    }

    if (!data?.supportTopic) {
        return <div>Não foi possível encontrar o tópico</div>;
    }

    const topic = data.supportTopic;

    return (
        <div className={styles.TopicDetails}>
            <Typography variant="h4" gutterBottom>
                {topic.name}
            </Typography>
            <Typography variant="caption" paragraph>
                Última atualização: <FormatDate date={topic?.updatedAt} />
            </Typography>
            <Typography variant="body1" paragraph>
                {topic.description}
            </Typography>
            {topic.loom && <iframe title="detalhes do tópico de suporte" src={topic?.loom} className={styles.LoomFrame} allowFullScreen />}

            {topic.file && <Button variant="contained" color="default" size="small" style={{ float: 'right' }} onClick={() => topic.file && window.open(staticFile(topic.file))}>
                Descarregar PDF</Button>
            }
        </div>

    )
}

export default TopicDetails;

import { Grid } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import React from 'react';
import { useHasPermission } from '../../services/permissions';
import InlineDatePicker from './InlineDatePicker';
import styles from './InspectionSection.module.scss';
import meshStyles from './MeshView.module.scss';


const InspectionSection = () => {
    const { values } = useFormikContext()

    // @ts-ignore
    const name = values?.inspectedBy?.name

    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")

    return (
        <div className={meshStyles.Section}>
            <h3>Datas</h3>

            <Grid container className={meshStyles.LineForm} style={{ marginBottom: 20 }}>
                <Grid item xs={10}>
                    <FastField
                        component={InlineDatePicker}
                        name="inspectionDate"
                        label="Data Teste"
                        fullWidth
                        disabled={!canUpdate}
                    />

                </Grid>
            </Grid>

            <Grid container className={meshStyles.LineForm}>
                <Grid item xs={10}>
                    <FastField
                        component={InlineDatePicker}
                        name="receptionDate"
                        label="Data Receção"
                        fullWidth
                        disabled={!canUpdate}
                    />

                </Grid>
            </Grid>

            {name ? <p className={styles.InspectedBy}>
                Teste executado por <b>{name}</b></p> :
                <p></p>}
        </div>
    )
}


export default InspectionSection

import { format as doFormat, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import React from 'react';

/** returns a formatted string */
const FormatDate = ({ date, format }: { date: string, format?: string }) => {
    const formatString = format || "dd'/'MM'/'yyyy HH:mm"
    const parsedDate = parseISO(date);
    const formattedDate = doFormat(parsedDate, formatString, { locale: pt });
    return <span>{formattedDate}</span>
};

export default FormatDate;

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TicketComment, useCreateOneTicketCommentMutation, useTicketQuery } from '../../generated/graphql';
import { humanizedDate, humanizedDatetime } from '../../services/Constants';
import { downloadRequest, extractExtension, orderByProperty, staticFile, ticketStateName } from '../../services/Utils';
import BindHTML from '../BindHTML/BindHTML';
import FormatDate from '../FormatDate/FormatDate';
import Loading from '../Loading/Loading';
import styles from './TicketDetails.module.scss';


const Comment = ({ comment }: { comment: TicketComment }) => {
    let content = comment.content

    if (content) {
        let urlPattern = /[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi;
        let urls = content.match(urlPattern);
        if (urls && urls.length) {
            urls.forEach((url) => {
                content = content?.replace(url, '<a href="' + url + '" target="_blank">' + url + '</a>')
            })
        }
    }
    return (
        <Grid container justify="flex-start" className={styles.TicketComment}>
            <p className={styles.Author}>
                {comment.user?.name}
                <span className={styles.Spacer}>•</span>
                <span className={styles.Date}>
                    <FormatDate date={comment.createdAt} format={humanizedDatetime} />
                </span>
            </p>

            <Grid container className={styles.Content}>
                <BindHTML>
                    {content}
                </BindHTML>
                {comment?.ticketFiles?.map((file: any) => <TicketFilePreview file={file.file} orientation="horizontal" key={file.id} />)}
            </Grid>
        </Grid>
    );
};

const TicketComments = ({ comments }: { comments: any }) => {
    if (!comments) return null
    const sortedComments = comments.sort(orderByProperty('createdAt'))
    return (
        <Grid container justify="flex-end" style={{ marginBottom: '25px' }}>
            {sortedComments.map((comment: TicketComment) => <Comment key={comment.id} comment={comment} />)}
        </Grid>
    )

}

const NewFilePreview = ({ file, onRemove }: { file: File, onRemove: (file: File) => void }) => {
    let fileSize = file.size / 1000;
    return <Grid container justify="space-between" alignItems="center" className={styles.NewFilePreview}>
        <span>
            <b>{file.name}</b> ({fileSize.toFixed(1)} kB)
        </span>
        <IconButton size="small" onClick={() => onRemove(file)}>
            <CloseIcon />
        </IconButton>
    </Grid>
}

const CommentBox = ({ onCreate }: { onCreate: any }) => {
    const [value, setValue] = useState('');
    const [files, setFiles] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        // @ts-ignore
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles])
    }, [])


    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const resetCommentBox = () => {
        setValue('');
        setFiles([]);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onCreate(value, files).then(resetCommentBox);
    }

    const handleRemove = (file: File) => {
        setFiles(prevFiles => {
            const idx = prevFiles.findIndex(item => item === file)
            prevFiles.splice(idx, 1)
            return [...prevFiles]
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" alignItems="flex-end">
                <TextField
                    multiline
                    fullWidth
                    label="Escrever comentário"
                    value={value}
                    onChange={event => setValue(event.target.value)}
                />

                <Grid container>
                    {files.map(file => <NewFilePreview file={file} onRemove={handleRemove} />)}
                </Grid>

                <div style={{ marginTop: '10px' }}>
                    <span{...getRootProps()}>
                        <input {...getInputProps()} />
                        <Tooltip title="Anexar Ficheiro">
                            <IconButton >
                                <AttachFileIcon />
                            </IconButton>
                        </Tooltip>
                    </span>
                    <Button type="submit" color="primary" variant="contained">
                        comentar
                    </Button>
                </div>
            </Grid>
        </form >
    );
}

const TicketFilePreview = ({ file, orientation = 'vertical' }: { file: string, orientation?: 'horizontal' | 'vertical' }) => {

    const extension = extractExtension(file);

    const handleDownloadRequest = () => {
        downloadRequest(staticFile(file), 'download.' + extension);
    }

    if (orientation === 'horizontal') {
        return (
            <div className={styles.TicketPreviewHorizontal} onClick={handleDownloadRequest}>

                <Tooltip title="descarregar ficheiro">
                    <span>.{extension}</span>
                </Tooltip>
            </div>
        )
    }

    return (
        <div className={styles.TicketPreview} onClick={handleDownloadRequest}>
            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                <Tooltip title="descarregar ficheiro">
                    <div>
                        <CloudDownloadIcon />
                        <p>
                            {extension}
                        </p>
                    </div>
                </Tooltip>
            </Grid>
        </div>
    );
}

const TicketFilesDisplay = ({ files }: { files: any }) => {
    if (!files) {
        return null;
    }
    return (
        <Grid container>
            {
                files.map((file: any) => <TicketFilePreview key={file.file} file={file.file} />)
            }
        </Grid>
    )
}

const TicketDetails = (props: any) => {
    const id = props.id || props?.match?.params?.id

    const { data, loading, refetch } = useTicketQuery({ variables: { id } });
    const [createOneTicketComment] = useCreateOneTicketCommentMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data?.ticket) {
        return <div>Ticket não encontrado.</div>;
    }

    const handleCreate = async (content: any, files: any) => {
        await createOneTicketComment({
            variables: {
                input: { ticketComment: { content, ticket: ticket.id, ticketFiles: files } }
            }
        });
        return await refetch();
    }

    const ticket = data.ticket;

    return (
        <div className={styles.Wrapper}>
            <p className={styles.TicketState}>
                {ticket.state ? ticketStateName(ticket.state) : ''}
            </p>

            <p className={styles.Name}>
                #{ticket.id} - {ticket.name}
            </p>

            <p className={styles.AuthorDate}>
                por <b>{ticket.user?.name}</b> em <FormatDate date={ticket.createdAt} format={humanizedDate} />
            </p>

            <p className={styles.Description}>
                {ticket.description}
            </p>

            {
                ticket.ticketFiles && ticket.ticketFiles.length > 0 && <p className={styles.SectionHeader} style={{ marginBottom: '22px' }}>
                    Anexos
                </p>
            }

            <TicketFilesDisplay files={ticket.ticketFiles} />

            {
                ticket.ticketComments && ticket.ticketComments.length > 0 && <p className={styles.SectionHeader}>
                    Comentários
                </p>
            }


            <TicketComments comments={ticket.ticketComments} />

            <CommentBox onCreate={handleCreate} />
        </div>
    )
}

export default TicketDetails;

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';


interface SimpleSelectorProps {
    name: string,
    options: any,
    value: string | string[],
    onChange: any,
    fullWidth?: boolean
    label?: string,
    optionValue?: string,
    optionText?: string
}

const SimpleSelector = ({ name, options, value, onChange, label, fullWidth, optionValue = 'id', optionText = 'name' }: SimpleSelectorProps) => {

    if (!options) {
        return null;
    }

    if (!label) {
        label = name;
    }

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
                name={name}
                value={value}
                onChange={onChange}
                fullWidth={fullWidth}
            >
                {
                    options.map(
                        (option: any) => (
                            <MenuItem key={option[optionValue]} value={option[optionValue]}>
                                {option[optionText]}
                            </MenuItem>
                        )
                    )
                }
            </Select>
        </FormControl>
    );
};

export default SimpleSelector;

import { Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { PlaylistAdd } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { FC, ReactChild } from 'react';
import { useHasPermission } from '../../services/permissions';
import styles from './BulkActions.module.scss';

interface BulkActionsProps {
    selected: number[]
    deselectAll: () => void
    children: ReactChild | null
    selectedMessage?: string
}

interface CreateSampleMeshBulkActionProps {
    selected: number[]
    deselectAll: () => void
    onClick: () => void
}

const BulkActions: FC<BulkActionsProps> = props => {

    const {
        selected,
        deselectAll,
        children,
        selectedMessage = selected?.length > 1 ? "itens selecionados" : "item selecionado"
    } = props

    if (!selected || selected.length === 0) {
        return null;
    }

    return (
        <AppBar className={styles.BulkActionBar}>
            <Toolbar>
                <Grid container justifyContent="space-between">
                    <div>
                        <IconButton className={styles.menuButton} onClick={deselectAll}>
                            <CloseIcon />
                        </IconButton>
                        <span>{selected.length} {selectedMessage}</span>
                    </div>
                    <div>
                        {children}
                    </div>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};


/**
 * ação em massa que usa o sampleMeshWizard
 */
export const CreateSampleMeshBulkAction: FC<CreateSampleMeshBulkActionProps> = props => {
    const { selected, deselectAll, onClick } = props

    const hasPermission = useHasPermission()
    const canCreate = hasPermission("SAMPLE_MESH__CREATE")

    return (
        <BulkActions selected={selected} deselectAll={deselectAll}>
            {canCreate ?
                (
                    <Tooltip title="Associar Malhas a Amostras">
                        <IconButton className={styles.menuButton} onClick={onClick} size="medium">
                            <PlaylistAdd />
                        </IconButton>
                    </Tooltip>
                )
                : null}
        </BulkActions>
    )
}

export default BulkActions;

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as Yup from "yup";
import { useCreateOneTicketMutation } from '../../generated/graphql';
import styles from './CreateTicket.module.scss';

const CreateTicket = ({ onComplete }: { onComplete: any }) => {


    const [createTicket] = useCreateOneTicketMutation()
    const [files, setFiles] = useState([])
    const { enqueueSnackbar } = useSnackbar();

    const onDrop = useCallback(acceptedFiles => {
        // @ts-ignore
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const createTicketSchema = Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string(),
        isPublic: Yup.boolean()
    })

    return (
        <div>
            <Typography variant="h4" paragraph gutterBottom>
                Criar Ticket
            </Typography>

            <Typography variant="body2" paragraph>
                Por favor preencha a descrição com pormenor e sempre que possível anexe imagens para descrever o incidente ou sugestão.
            </Typography>


            <Formik
                validationSchema={createTicketSchema}
                onSubmit={(values, { resetForm }) => {
                    createTicket({
                        variables: {
                            input: {
                                ticket: {
                                    ...values, ticketFiles: files
                                }
                            }
                        }
                    }).then((response) => {
                        if (!response.data?.createOneTicket.id) {
                            onComplete()
                            enqueueSnackbar('Erro ao criar ticket. Por favor, tente novamente .', { variant: "error" });
                        }
                        else {
                            resetForm()
                            onComplete()
                        }
                    })
                }}
                initialValues={{
                    name: '',
                    description: '',
                    isPublic: false
                }}>
                {({ submitForm, values, isSubmitting, isValid, dirty }) => (
                    <Form>
                        <Field
                            component={TextField}
                            name="name"
                            type="text"
                            label="Nome do Ticket"
                            fullWidth
                            required
                            autoFocus
                        />
                        <Field
                            component={TextField}
                            name="description"
                            type="text"
                            label="Descrição"
                            fullWidth
                            multiline
                        />

                        <div {...getRootProps()} className={styles.Dropzone}>
                            <input {...getInputProps()} />
                            <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                                {
                                    isDragActive ? 'Clicar para anexar ficheiros' :
                                        'Clicar ou arrastar para anexar ficheiros'
                                }
                            </Typography>
                            <Typography variant="body2" style={{ textTransform: 'uppercase', fontWeight: 'bold', marginTop: '15px' }}>
                                {
                                    files.length ? `${files.length} ficheiros anexados` : ''
                                }
                            </Typography>
                        </div>

                        <p>
                            <Field
                                component={Switch}
                                name="isPublic"
                                type="checkbox"
                            />
                            Este ticket vai ser {values.isPublic ? ' público' : ' privado'}
                        </p>
                        <p>
                            <Button onClick={submitForm} style={{ float: 'right' }} variant="contained" size="medium" color="primary" disabled={Boolean(isSubmitting || !isValid || !dirty)}>
                                Criar
                            </Button>
                        </p>
                    </Form>
                )}

            </Formik>


        </div >
    )

}

export default CreateTicket;

import { useMutation } from '@apollo/react-hooks';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo_pedrosa.png';
import { useAuth } from '../../services/auth';
import Config from '../../services/Config';
import { SET_MENU } from '../../services/Local';
import Avatar from '../Avatar/Avatar';
import styles from './Header.module.scss';

const Header = () => {

    const [setMenu] = useMutation(SET_MENU);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory()
    const { user, logout } = useAuth()

    const handle = () => {
        setMenu({ variables: { state: true } });
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleLogout = () => {
        setIsMenuOpen(false);
        logout()
        history.push("/login")
    };

    const handleProfileMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    return (
        <AppBar position="fixed">
            <Toolbar className={styles.AppToolbar}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <IconButton color="inherit" aria-label="menu lateral" onClick={handle}>
                        <MenuIcon />
                    </IconButton>

                    <a href={Config.MAIN_APP}>
                        <img src={Logo} className={styles.Logo} alt="" />
                    </a>

                    <IconButton color="inherit" aria-label="menu utilizador"
                        onClick={handleProfileMenuOpen}>
                        <Avatar image={user?.avatar} color="#8b0000" />
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={isMenuOpen}
                        onClose={handleMenuClose}
                    >

                        <MenuItem>
                            <ListItemIcon>
                                <Avatar image={user?.avatar} />
                            </ListItemIcon>
                            <small>{user?.email}</small>
                        </MenuItem>

                        <Divider />
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">
                                Log out
                            </Typography>
                        </MenuItem>
                    </Menu>



                </Grid>

            </Toolbar>
        </AppBar >
    );
};

export default Header;

import { Grid, TextField } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import React from 'react';
import { useSuppliersQuery } from '../../generated/graphql';
import { useHasPermission } from '../../services/permissions';
import { meshSuppliersOptions, suppliersOptions } from '../../services/Utils';
import FieldText from './FieldText';
import styles from './MeshView.module.scss';
import { getOptionLabel } from './MeshViewUtils';

const SupplierSection = () => {
    const { data: suppliers } = useSuppliersQuery()
    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")

    // @ts-ignore
    const { values, errors } = useFormikContext()

    // @ts-ignore
    const { supplier } = values

    // @ts-ignore
    const errorsSupplier = errors['supplier']
    // @ts-ignore
    const errorsMeshSupplier = errors['meshSupplier']

    return (
        <div className={styles.Section} >

            <h3>Fornecedores</h3>

            <Grid container justifyContent="flex-start" spacing={2} className={styles.FormLine}>

                <Grid item xs={4}>
                    <FastField
                        component={FieldText}
                        name="supplierDocument"
                        label="Doc. Fornecedor"
                        disabled={!canUpdate}
                    />

                </Grid>

                <Grid item xs={6}>
                    <FastField
                        name="supplier"
                        component={Autocomplete}
                        disableClearable
                        options={suppliersOptions(suppliers)}
                        getOptionLabel={getOptionLabel}
                        value={supplier}
                        disabled={!canUpdate}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                data-cy="supplierInput"
                                error={errorsSupplier}
                                helperText={errorsSupplier}
                                label="Fornecedor"
                            />
                        )}
                    />

                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" spacing={2} className={styles.FormLine}>
                <Grid item xs={4}>
                    <FastField
                        component={FieldText}
                        name="meshSupplierDocument"
                        label="Req. Malheiro"
                        disabled={!canUpdate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FastField
                        name="meshSupplier"
                        component={Autocomplete}
                        options={meshSuppliersOptions(suppliers)}
                        getOptionLabel={getOptionLabel}
                        disabled={!canUpdate}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                error={errorsMeshSupplier}
                                helperText={errorsMeshSupplier}
                                label="Malheiro"
                            />
                        )}
                    />
                </Grid>

            </Grid>

        </div>
    )
}

export default SupplierSection

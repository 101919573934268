import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CoreTextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from "yup";
import { useCommonIssuesQuery, useCreateOneIssueMutation } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import Header from '../Header/Header';
import Leading from '../Leading/Leading';
import Sidemenu from '../Sidemenu/Sidemenu';
import styles from './Issues.module.scss';

const FormHeader = () => {
    return (
        <div className={styles.FormCard}>
            <Card>
                <CardContent className={styles.Content}>
                    <h1>Registo de ocorrências - Amostras</h1>
                    <h2>
                        Registo de qualquer acontecimento que resulte em desperdício significativo de tempo,
                        trabalho ou matéria-prima durante a execução do processo de Criação de Amostras.
                    </h2>
                </CardContent>
            </Card>
        </div>
    )
}

const FormContent = (props: any) => {

    const { mode, sample, onCompleted } = props;

    const [submissionError] = useState("");
    const { data } = useCommonIssuesQuery();
    const [createOneIssue] = useCreateOneIssueMutation()
    const { enqueueSnackbar } = useSnackbar();


    const createIssueSchema = Yup.object().shape({
        description: Yup.string().required("O campo descrição é obrigatório.")
    })

    const initialValues = {
        reference: '',
        order: '',
        description: '',
        sample: mode === 'sample' ? sample : ''
    }

    const handleSubmit = (values: any, { resetForm }: { resetForm: any }) => {
        createOneIssue({ variables: { input: { issue: { ...values } } } }).then(response => {

            if (response.data?.createOneIssue.id) {
                resetForm()
                onCompleted()
            } else {
                enqueueSnackbar('Erro ao criar ocorrência, por favor tente novamente .', { variant: "error" });
            }


        })
    }

    const options = data?.commonIssues.edges.length ? JSON.parse(data?.commonIssues.edges[0].node.value) : []

    return (
        <Formik
            validationSchema={createIssueSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ submitForm, isSubmitting, isValid, setFieldValue }) => {
                return (
                    <Form autoComplete="off">

                        <div className={styles.FormCard}>
                            <Card>
                                <CardContent className={styles.Content}>
                                    <h3>Ocorrência Recorrente?</h3>
                                    <p>Selecione uma das opções na lista ou preencha a descrição</p>

                                    <Autocomplete
                                        id="common"
                                        options={options}
                                        onChange={(_, value) => setFieldValue('description', value)}
                                        getOptionLabel={(process: any) => process}
                                        renderInput={(params) => <CoreTextField {...params} label="Ocorrência Recorrente" />}
                                    />

                                </CardContent>
                            </Card>
                        </div>

                        {mode === 'normal' && <div className={styles.FormCard}>
                            <Card>
                                <CardContent className={styles.Content}>
                                    <h3>Modelo da amostra *</h3>
                                    <p>Numero do modelo da amostra (ex: 200190)</p>

                                    <Field
                                        component={TextField}
                                        name="reference"
                                        type="text"
                                        label="Resposta"
                                        fullWidth
                                        required
                                        autoFocus={mode === "normal"}
                                    />

                                </CardContent>
                            </Card>
                        </div>
                        }

                        {mode === 'normal' &&

                            <div className={styles.FormCard}>

                                <Card>
                                    <CardContent className={styles.Content}>
                                        <h3>Pedido da amostra (Axxxxxx) *</h3>
                                        <p>Numero da encomenda da amostra (colocar apenas os números)</p>

                                        <Field
                                            component={TextField}
                                            name="order"
                                            type="text"
                                            label="Resposta"
                                            fullWidth
                                            required
                                        />

                                    </CardContent>
                                </Card>
                            </div>


                        }

                        <div className={styles.FormCard}>
                            <Card>
                                <CardContent className={styles.Content}>
                                    <h3>Descrição da ocorrência *</h3>
                                    <p>Descrever o problema ocorrido</p>

                                    <Field
                                        component={TextField}
                                        name="description"
                                        type="text"
                                        label="Resposta"
                                        fullWidth
                                        required
                                        multiline
                                    />
                                </CardContent>
                            </Card>
                        </div>

                        {submissionError && <div className={styles.FormCard}>
                            <Card>
                                <CardContent className={styles.Content} >
                                    <h3 style={{ color: "red" }}>
                                        {submissionError}
                                    </h3>
                                </CardContent>
                            </Card>
                        </div>}


                        <Button
                            onClick={submitForm}
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={Boolean(isSubmitting || !isValid)}
                            style={{ float: 'right', marginBottom: '15px' }}
                        >
                            Submeter
                        </Button>
                    </Form>
                )
            }
            }
        </Formik>
    )
}

const ThanksScreen = () => {
    return (
        <div className={styles.FormCard}>
            <Card>
                <CardContent className={styles.Content}>
                    <h2>Ocorrência criada com sucesso. Obrigado pela colaboração.</h2>
                </CardContent>
            </Card>
        </div>
    )
}

const Issues = () => {

    useGuestRedirection()
    const location = useLocation()
    const [thanks, setThanks] = useState(false);

    const mode = location.pathname.startsWith('/ocorrencias/criar/') ? 'sample' : 'normal';
    const sample = mode === 'sample' ? location.pathname.split('/')[3] : ''

    const handleCompleted = () => {
        setThanks(true);
    }

    return (
        <>
            <Header />
            <Leading />
            <Sidemenu />

            <Grid container direction="column" alignItems="center" className={styles.IssuesView}>

                <FormHeader />

                {thanks && <ThanksScreen />}

                {!thanks && <FormContent mode={mode} sample={sample} onCompleted={handleCompleted} />}


            </Grid>
        </>
    )
}

export default Issues;

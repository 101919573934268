import { default as Button, default as IconButton } from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { Add, Close, Delete } from '@material-ui/icons';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ApolloError } from 'apollo-client';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useCreateOneMeshLocationMutation, useDeleteOneMeshLocationMutation, useLocationsLazyQuery, useScanMeshLazyQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import { useHasPermission } from '../../services/permissions';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import FormatDate from '../FormatDate/FormatDate';
import { getMeshCode, isValidBarcode, locationCodeAreaFull, locationCodeContainer, locationCodeShelf, locationCodeStand, meshCode2Id } from '../MeshView/MeshBarcode';
import { fullPurchaseOrder } from '../MeshView/MeshViewUtils';
// @ts-ignore
import Beep from './../../assets/sounds/beep.mp3';
// @ts-ignore
import ErrorSound from './../../assets/sounds/error.wav';
import styles from './Scan.module.scss';
import Scanner from './Scanner';

const audio = new Audio(Beep)
const errorSound = new Audio(ErrorSound)

const DisplayMeshLocations = ({ locations, view, canDelete, refetch }: { locations: any, view: string, canDelete: boolean, refetch: any }) => {

    const [deleteMeshLocation] = useDeleteOneMeshLocationMutation()

    const handleDelete = async (meshLocationId: any) => {
        return deleteMeshLocation({ variables: { input: { id: meshLocationId } } }).then(() => refetch())
    }

    return (
        <>
            <h3 className={styles.Section}>{view === 'mesh' ? 'Localizações' : 'Malhas'}</h3>
            {!locations?.length ? <i>Sem malhas associadas.</i> : locations.map((meshLocation: any) => (
                <p className={styles.Location} key={meshLocation?.id}>
                    • {view === 'mesh' ? meshLocation?.location?.name : getMeshCode(meshLocation?.mesh?.id)}

                    {canDelete ? <ConfirmButton duration={1000} onClick={() => handleDelete(meshLocation?.id)} >
                        <Delete fontSize="small" color="primary" className={styles.Confirm} />
                    </ConfirmButton> : null}

                </p>
            ))}
        </>
    )
}

const DisplayMesh = ({ mesh, show, onDismiss, create, canCreate, canDelete, refetch }: { mesh: any, show: boolean, onDismiss: any, create: any, canCreate: boolean, canDelete: boolean, refetch: any }) => {
    if (!mesh || !show) return null
    return (
        <Overlay>
            <Grid container justifyContent="flex-start" alignContent="space-between" className={styles.Display}>
                <Grid style={{ width: "100%" }}>
                    <Grid container justifyContent="space-between">
                        <p className={styles.Code}>{getMeshCode(mesh.id)}</p>
                        <Button onClick={onDismiss}><Close /></Button>
                    </Grid>

                    <h3 className={styles.Section}>Detalhes</h3>
                    <p><b>Requisição</b>: {fullPurchaseOrder(mesh)} </p>
                    <p><b>Quantidade Disponível: </b> {mesh?.currentQuantity} {mesh?.quantityUnit === 'PCS' ? <span>peças</span> : <span>metros</span>}</p>
                    {mesh?.color ? <p><b>Cor: </b> {mesh.color}</p> : null}
                    {mesh?.composition ? <p><b>Composição: </b> {mesh.composition}</p> : null}
                    {mesh?.meshKind ? <p><b>Tipo: </b> {mesh.meshKind}</p> : null}
                    {mesh?.weightReceived ? <p><b>Gramagem: </b> {mesh.weightReceived} gr/m²</p> : null}
                    <p><b>Cliente</b>: {mesh?.brand?.name}</p>
                    <p><b>Data Receção</b>: <FormatDate date={mesh.receptionDate} format={"dd'/'MM'/'yyyy"} /> </p>
                    <p><b>Número Rolos</b>: {mesh.spoolCount}</p>


                    <DisplayMeshLocations locations={mesh?.meshLocations} view="mesh" canDelete={canDelete} refetch={refetch} />

                </Grid>
                {canCreate && <Grid container justifyContent="center">
                    <Button onClick={create} size="large" color="primary" variant="contained">
                        <Add /> &nbsp;
                        ASSOCIAR LOCALIZAÇÃO
                    </Button>
                </Grid>}
            </Grid>
        </Overlay>
    )
}

const DisplayLocation = ({ location, show, onDismiss, canDelete, refetch }: { location: any, show: boolean, onDismiss: any, canDelete: boolean, refetch: any }) => {
    if (!location || !show) return null
    return (
        <Overlay>
            <Grid container justifyContent="flex-start" alignContent="space-between" className={styles.Display}>
                <Grid style={{ width: "100%" }}>
                    <Grid container justifyContent="space-between">
                        <p className={styles.Code}>{location?.name}</p>
                        <Button onClick={onDismiss}><Close /></Button>
                    </Grid>
                    <p><b>Área:</b> {locationCodeAreaFull(location?.name)}</p>
                    <p><b>Estante:</b> {locationCodeStand(location?.name)}</p>
                    <p><b>Prateleira:</b> {locationCodeShelf(location?.name)}</p>
                    <p><b>Contentor:</b> {locationCodeContainer(location?.name)}</p>

                    <DisplayMeshLocations locations={location?.meshLocations} view="location" canDelete={canDelete} refetch={refetch} />
                </Grid>
            </Grid>
        </Overlay>
    )
}


const Overlay = (props: any) => {
    return (
        <Fade in={true} timeout={200}>
            <div className={styles.Overlay}>
                {props.children}
            </div>
        </Fade>
    )
}

const Instructions = (props: any) => {
    if (!props?.show) return null
    return (
        <div className={styles.Instructions}>
            <p className={styles.Back}>
                <IconButton onClick={props.goBack}>
                    <ArrowBack />
                </IconButton>
            </p>

            <p>Para associar a malha <b>{getMeshCode(props?.mesh?.id)}</b> a uma localização, faz scan de um código de localização.</p>
        </div>
    )
}


const Debug = ({ creating, mesh, location, show }: { creating: boolean, mesh: any, location: any, show: boolean }) => {
    if (!show) return null
    return (
        <div className={styles.Debug}>
            <p>creating: {creating ? "true" : "false"}</p>
            <p>mesh: {mesh?.id}</p>
            <p>location: {location?.id}</p>
        </div>
    )
}

const reload = () => {
    setTimeout(() => window.location.reload(), 1000);
}

const Scan = () => {

    useGuestRedirection()

    const debug = false

    const { enqueueSnackbar } = useSnackbar();

    const [load, { data: meshData, refetch: refetchMesh }] = useScanMeshLazyQuery({ fetchPolicy: "no-cache" })
    const [loadLocation, { data: locationData, refetch: refetchLocation }] = useLocationsLazyQuery({ fetchPolicy: "no-cache" })
    const [code, setCode] = useState<string>("")
    const [show, setShow] = useState<boolean>(false)
    const [creating, setCreating] = useState<boolean>(false);
    const [inflight, setInflight] = useState<boolean>(false);

    // permissões
    const hasPermission = useHasPermission()
    const canCreate = hasPermission("MESH_LOCATION__CREATE")
    const canDelete = hasPermission("MESH_LOCATION__DELETE")

    const prepareErrorMessage = (err: ApolloError) => {
        let message = "Erro ao associar malha"
        if (err.message.includes("duplicate")) {
            const mesh_id = getMeshCode(meshData?.mesh?.id)
            const location_name = locationData?.locations.edges[0].node.name
            message = `Erro! Malha ${mesh_id} já está associada à localização ${location_name}.`

        }
        return message
    }


    const [createMeshLocation] = useCreateOneMeshLocationMutation({
        onError(err) {
            errorSound.play()
            const message = prepareErrorMessage(err)
            enqueueSnackbar(message, { variant: "error", preventDuplicate: true, key: "mesherror" })
            setInflight(false)
            setCreating(false)
            setShow(false)
            reload()
        }
    })


    const getCodeType = (code: string) => {
        return code.length === 7 ? "MESH" : "LOCATION";
    }

    const handleDetecion = (result: any) => {

        const resultCode = result.codeResult.code
        const is_valid = isValidBarcode(resultCode)

        if (show || !is_valid) {
            return
        }

        if (code !== resultCode) {
            audio.play()
            const codeType = getCodeType(resultCode)

            if (codeType === "MESH") {
                if (creating) {
                    enqueueSnackbar("Erro! Código de malha, tem que ser código de localização", { variant: "error", preventDuplicate: true, key: "codeerror" })
                    return
                }
                // sabemos que não vai ser null
                // porque passou a validação em cima
                const id = meshCode2Id(resultCode)!
                load({ variables: { id: id.toString() } });
                setShow(true);
            } else {
                loadLocation({ variables: { filter: { name: { eq: resultCode } } } })
                setShow(true);
            }
            setCode(resultCode)
        }
    }

    const getLocationData = () => {
        const edges = locationData?.locations?.edges
        if (edges && edges?.length > 0) {
            const node = edges[0]?.node
            return node
        }
    }

    const getLocationId = () => {
        const node = getLocationData()
        if (node) {
            return node.id
        }
    }

    const mesh = meshData?.mesh?.id
    const location = getLocationId();
    if (creating && !inflight && mesh && location) {
        setInflight(true);
        // @ts-ignore
        createMeshLocation({ variables: { input: { meshLocation: { mesh, location } } } }).then(response => {
            if (response?.data?.createOneMeshLocation?.id) {
                const mesh_code = getMeshCode(mesh)
                const location_name = getLocationData()?.name
                enqueueSnackbar(`Malha ${mesh_code} associada com sucesso à localização ${location_name}.`, { variant: "success" });
                reload()
            }
        })
    }

    const closeDisplay = () => {
        setShow(false);
        setCode("");
    }

    const closeMeshDisplay = () => {
        load({ variables: { id: "0" } });
        closeDisplay()
    }


    const closeLocationDisplay = () => {
        loadLocation({ variables: { filter: { name: { eq: "" } } } })
        closeDisplay()
    }

    const onCreate = () => {
        setShow(false)
        setCreating(true)
    }

    const goBack = () => {
        setCreating(false)
        setShow(true)
    }

    return (
        <>
            <Scanner onDetected={handleDetecion} />
            <DisplayMesh mesh={meshData?.mesh} show={show && !creating && !inflight} onDismiss={closeMeshDisplay} create={onCreate} canCreate={canCreate} canDelete={canDelete} refetch={refetchMesh} />
            <DisplayLocation location={getLocationData()} show={show && !creating && !inflight && !meshData?.mesh} onDismiss={closeLocationDisplay} canDelete={canDelete} refetch={refetchLocation} />
            <Instructions show={creating} mesh={meshData?.mesh} goBack={goBack} />
            <Debug mesh={meshData?.mesh} location={getLocationData} creating={creating} show={debug} />
        </>
    )
}

export default Scan;

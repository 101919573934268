import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, MouseEvent, ReactChild } from 'react';
import styles from '../CustomModal/CustomModal.module.scss';

interface CustomModalProps {
    isActive?: boolean
    children: ReactChild | null
    title?: string
    handleClose: () => void
    showClose?: boolean
    maxWidth?: 'md' | 'lg' | 'sm'
    fullWidth?: boolean
}

const CustomModal: FC<CustomModalProps> = (props) => {
    const {
        isActive = false,
        children,
        title,
        handleClose,
        showClose = true,
        maxWidth = "sm",
        fullWidth = true } = props

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
    }

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            onClose={handleClose}
            className={styles.CustomModal}
            open={isActive}
        >
            <DialogTitle>{title}</DialogTitle>
            {
                showClose &&
                <IconButton
                    className={styles.CloseAction}
                    aria-label="close"
                    onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            }
            <DialogContent onClick={handleClick}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default CustomModal;

import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import React, { FC, ReactChild, ReactChildren } from 'react';
import styles from './SecondBar.module.scss';

interface SecondBarProps {
    children: ReactChild | ReactChildren,
    size?: "REGULAR" | "TALL"
}

const SecondBar: FC<SecondBarProps> = ({ children, size }) => {
    const classes = clsx(styles.SecondBar, size === "TALL" ? styles.Tall : styles.Regular)
    return (
        <Grid container className={classes}>
            {children}
        </Grid >
    )
}

export default SecondBar;

import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import styles from './Empty.module.scss';

interface EmptyProps {
    message?: string
}

interface EmptyViewProps {
    message: string
}

const Empty: FC<EmptyProps> = (props) => {
    const {
        message = "Sem items associacos"
    } = props

    return (
        <Grid container className={styles.Empty}>
            <Grid item xs={10} className={styles.Wrapper}>
                <p>{message}</p>
            </Grid>
        </Grid >
    )
}

export const EmptyView: FC<EmptyViewProps> = (props) => {
    const { message } = props
    return (
        <Grid container className={styles.EmptyView} justifyContent="center">
            <h1>{message}</h1>
        </Grid >
    )
}


export default Empty

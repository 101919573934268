import * as Yup from "yup";

// campos usados nas condições
const orderField = 'purchaseOrderType'
const washField = 'washType'
const unitField = 'quantityUnit'

// tipos de requisição

const FSEMA = 'FSEMA'
const SREQ = 'S/REQ'

// unidades
const PCS = 'PCS'

// transformação para numeros opcionais
// que podem ser numeros ou empty strings no form
// e que podem ser numeros ou null na api
const transformation = (value: string | number) => {
    return value ? value : null
}

const meshSupplierValidator = (data: any) => {
    return data?.name === FSEMA || data?.node?.name === FSEMA
}

const hasPurchaseOrder = (data: any) => {
    return (data?.name && data.name !== SREQ) || (data?.node?.name && data?.node?.name !== SREQ)
}

const unitFieldCheck = (unit: string) => {
    return unit !== PCS
}

const meshValidationSchema = Yup.object().shape({
    meshFabricType: Yup.mixed().required(),
    purchaseOrderType: Yup.mixed().required(),

    purchaseOrder: Yup.string().length(8).when(orderField, {
        is: hasPurchaseOrder,
        then: Yup.string().required(),
        otherwise: Yup.string()
    }),

    // brand
    brand: Yup.mixed().required(),
    // supplier

    supplierDocument: Yup.string().when(orderField, {
        is: hasPurchaseOrder,
        then: Yup.string().required(),
        otherwise: Yup.string()
    }),

    supplier: Yup.mixed().required(),

    meshSupplier: Yup.mixed().when(orderField, {
        is: meshSupplierValidator,
        then: Yup.mixed().required(),
        otherwise: Yup.mixed(),
    }),

    meshSupplierDocument: Yup.mixed().when(orderField, {
        is: meshSupplierValidator,
        then: Yup.string().required().length(8),
        otherwise: Yup.string().length(8),
    }),

    // width
    widthRequired: Yup.number().integer().nullable().min(0).transform(transformation),

    widthReceived: Yup.number().integer().min(0).when(unitField, {
        is: unitFieldCheck,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable().transform(transformation)
    }),

    // mesh
    meshKind: Yup.string().label("Tipo de Malha").when(orderField, {
        is: hasPurchaseOrder,
        then: Yup.string().required(),
        otherwise: Yup.string()
    }),

    color: Yup.string().label("Cor").when(orderField, {
        is: hasPurchaseOrder,
        then: Yup.string().required(),
        otherwise: Yup.string()
    }),

    composition: Yup.string().label("Composição").when(orderField, {
        is: hasPurchaseOrder,
        then: Yup.string().required(),
        otherwise: Yup.string()
    }),


    // weight
    weightRequired: Yup.number().integer().nullable().min(0).transform(transformation),

    weightReceived: Yup.number().integer().min(0).when(unitField, {
        is: unitFieldCheck,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable(),
    }),

    // wash
    washType: Yup.mixed(),

    // after wash
    widthBeforeWash: Yup.number().required().min(0).max(999.99),

    widthAfterWash: Yup.mixed().when(washField, {
        is: (washType) => washType,
        then: Yup.number().required().min(0).max(999999999),
        otherwise: Yup.number().nullable().min(0).max(999.99).transform(transformation),
    }),

    lengthBeforeWash: Yup.number().required().min(0).max(999.99),

    lengthAfterWash: Yup.mixed().when(washField, {
        is: (washType) => washType,
        then: Yup.number().required().min(0).max(999999999),
        otherwise: Yup.number().nullable().min(0).max(999.99).transform(transformation),
    }),

    spiralityDeviation: Yup.mixed().when(washField, {
        is: (washType) => washType,
        then: Yup.number().required().min(0).max(999999999),
        otherwise: Yup.number().nullable().min(0).max(999999999).transform(transformation),
    }),

    // quantity
    quantitySent: Yup.number().min(0).max(999.99).nullable().transform(transformation),
    quantityReceived: Yup.number().required().min(0).max(999.99),

    //
    spoolCount: Yup.number().when(unitField, {
        is: unitFieldCheck,
        then: Yup.number().integer().required().min(1),
        otherwise: Yup.number().integer().nullable().min(1).transform(transformation)
    })

}).strict(false)

export default meshValidationSchema

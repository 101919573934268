import React from 'react';

const ShowFormErrors = (errors: any, disable = false) => {
    if (disable) return null
    return (
        <div style={{ backgroundColor: "yellow" }}>
            {Object.keys(errors.errors).map((key: string) => <p key={key}>{key}</p>)}
        </div>
    )

}

export default ShowFormErrors

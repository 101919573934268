import { Fade, Grid, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useCreateOneSampleMeshMutation, useDeleteOneSampleMeshMutation, useSampleDetailsQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import { useHasPermission } from '../../services/permissions';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import CurrentQuantity from '../CurrentQuantity/CurrentQuantity';
import { EmptyView } from '../Empty/Empty';
import FormatDate from '../FormatDate/FormatDate';
import Header from '../Header/Header';
import Leading from '../Leading/Leading';
import { DeviationShrinkage } from '../MeshView/Deviation';
import { fullPurchaseOrder } from '../MeshView/MeshViewUtils';
import { SampleMeshSearchByMesh, SampleMeshSearchByMeshSelected } from '../SampleMeshSearch/SampleMeshSearch';
import SecondBar from '../SecondBar/SecondBar';
import Sidemenu from '../Sidemenu/Sidemenu';
import styles from './SampleDetailsView.module.scss';

interface MatchParams {
    id: string
}

const SampleDetailsView: FC<RouteComponentProps<MatchParams>> = props => {

    const { match } = props

    useGuestRedirection()

    const { id } = match.params;
    const { data, loading, refetch } = useSampleDetailsQuery({ variables: { id } })
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    const hasPermission = useHasPermission()
    const canCreate = hasPermission("SAMPLE_MESH__CREATE")
    const canDelete = hasPermission("SAMPLE_MESH__DELETE")

    const [create] = useCreateOneSampleMeshMutation({
        onError: () => {
            enqueueSnackbar('Erro ao associar malha. Verfique se já existe associação.', { variant: "error" });
        }
    })

    const [remove] = useDeleteOneSampleMeshMutation({
        onError: () => {
            enqueueSnackbar('Erro ao remover associação. Verfique se existe associação.', { variant: "error" });
        }
    })

    const [value, setValue] = useState(6)


    const handleGoBack = () => {
        history.goBack()
    }

    const handleTabChange = (_: any, value: number) => {

        const base = `${window.location.protocol}//${window.location.hostname}/`

        const tabMap = [
            "DETAILS",
            "HISTORY",
            "PRICES",
            "PHOTOS",
            "FILES",
            "CYCLES",
            "MESHES"
        ]

        const tab = tabMap[value]
        window.location.href = `${base}amostras/${id}?tab=${tab}`
        setValue(value);
    };

    const handleChange = (event: SampleMeshSearchByMeshSelected) => {
        create({
            variables: {
                input: { sampleMesh: { sample: parseInt(id), mesh: event.target.value } }
            }
        }).then(response => {
            if (response?.data?.createOneSampleMesh?.id) {
                refetch()
            }
        })
    }

    const handleRemove = async (id: number) => {
        return remove({
            variables: {
                input: { id: id.toString() }
            }
        }).then(response => {
            if (!response?.data?.deleteOneSampleMesh.id) {
                refetch()
            }
        })
    }

    const handleClick = (id: number) => {
        history.push(`/meshes/${id}`)
    }

    return <>
        <Header />
        <Leading />
        <Sidemenu />


        <SecondBar size="TALL">
            <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" className={styles.MeshViewToolbar}>
                <div>
                    <IconButton onClick={handleGoBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <span style={{ fontSize: 20, fontWeight: 500 }}>{data?.sample?.orderReference ? `Amostra #${data.sample.orderReference}` : "Nova Malha"}</span>
                </div>
                <div>
                    <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" centered textColor="secondary">
                        <Tab label="Detalhes da Amostra" style={{ minWidth: 0 }} />
                        <Tab label="Histórico de Estados" style={{ minWidth: 0 }} />
                        <Tab label="Preços" style={{ minWidth: 0 }} />
                        <Tab label="Fotos" style={{ minWidth: 0 }} />
                        <Tab label="Ficheiros" style={{ minWidth: 0 }} />
                        <Tab label="Registo Produção" />
                        <Tab label="Malhas" style={{ minWidth: 0 }} />
                    </Tabs>
                </div>

            </Grid>
        </SecondBar>

        {data?.sample ? <Grid container className={styles.SampleDetailsView} justifyContent="center">
            <Fade in={!loading} timeout={200}>
                <div>
                    <TableContainer component={Paper}>
                        <Table>

                            <TableHead style={{ backgroundColor: "#F5EAEA" }}>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Requisição</TableCell>
                                    <TableCell>Fornecedor</TableCell>
                                    <TableCell>Tipo Malha</TableCell>
                                    <TableCell>Composição</TableCell>
                                    <TableCell>Cor</TableCell>
                                    <TableCell>
                                        <Tooltip title="Gramagem Obtida">
                                            <span>Gram.</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Largura Obtida">
                                            <span>Larg.</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="% Encolhimento Largura">
                                            <span>
                                                % Enc. Larg.
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="% Encolhimento Comprimento">
                                            <span>
                                                % Enc. Comp.
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>Quantidade Disponível</TableCell>
                                    <TableCell>Marca</TableCell>
                                    <TableCell>Data Receção</TableCell>
                                    {canDelete ? <TableCell>Remover</TableCell> : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.sample?.sampleMeshes.map(sampleMesh => (

                                    <TableRow key={sampleMesh?.id} hover onClick={() => handleClick(sampleMesh?.mesh?.id)} style={{ cursor: "pointer" }}>
                                        <TableCell>{sampleMesh.mesh.id}</TableCell>
                                        <TableCell>< span className={styles.Reference}>{fullPurchaseOrder(sampleMesh.mesh)}</span></TableCell>
                                        <TableCell>{sampleMesh.mesh.supplier.name}</TableCell>
                                        <TableCell>{sampleMesh?.mesh?.meshFabricType?.name || "-"}</TableCell>
                                        <TableCell>{sampleMesh.mesh.composition}</TableCell>
                                        <TableCell>{sampleMesh.mesh.color}</TableCell>
                                        <TableCell>{sampleMesh.mesh.weightReceived} gr/m²</TableCell>
                                        <TableCell>{sampleMesh.mesh.widthReceived} cm</TableCell>

                                        <TableCell align="center">
                                            <DeviationShrinkage
                                                a={sampleMesh.mesh.widthAfterWash}
                                                b={sampleMesh.mesh.widthBeforeWash}
                                                units="%"
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <DeviationShrinkage
                                                a={sampleMesh.mesh.lengthAfterWash}
                                                b={sampleMesh.mesh.lengthBeforeWash}
                                                units="%"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <span className={styles.Quantity}>
                                                <CurrentQuantity mesh={sampleMesh?.mesh} />
                                            </span>
                                        </TableCell>

                                        <TableCell>{sampleMesh.mesh.brand.name}</TableCell>

                                        <TableCell><FormatDate date={sampleMesh.mesh.receptionDate} format={"dd'/'MM'/'yyyy"} /></TableCell>
                                        {canDelete ? <TableCell>
                                            <ConfirmButton onClick={() => handleRemove(sampleMesh?.id)}>
                                                <Delete />
                                            </ConfirmButton>
                                        </TableCell> : null}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container>
                        <Grid item xs={4}>
                            {canCreate ? <SampleMeshSearchByMesh onChange={handleChange} /> : null}
                        </Grid>
                    </Grid>
                </div>
            </Fade >
        </Grid > : <EmptyView message="Amostra não encontrada." />}
    </>
}


export default SampleDetailsView;

import { ApolloError } from 'apollo-client';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { FC } from 'react';
import Config from '../../services/Config';
import styles from './ErrorMessages.module.scss';

interface ErrorMessagesProps {
    data?: Maybe<ApolloError>
}

const ErrorMessages: FC<ErrorMessagesProps> = ({ data }) => {

    if (data === null || data === undefined) return null

    const translate = (message?: string): string => {
        const match = Config.ERROR_MAP.find(error => error.key === message)
        if (!match) return "Erro desconhecido!"
        return match.value
    }

    // se é um graphqlerrors não pode ser um networkerror e vice-versa

    if (data?.graphQLErrors.length > 0) {
        return (
            <div className={styles.ErrorMessages}>
                {data.graphQLErrors.map(error => (
                    <span key={Math.random()}>{translate(error.message)}</span>)
                )}
            </div>
        )
    } else {
        return (
            <div className={styles.ErrorMessages}>
                <span key={Math.random()}>{translate(data?.networkError?.message)}</span>
            </div>
        )
    }
}

export default ErrorMessages;

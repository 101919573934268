import { Grid } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { useAuth } from '../../services/auth';
import { useHasPermission } from '../../services/permissions';
import { DeviationWeight } from './Deviation';
import FieldText from './FieldText';
import styles from './MeshView.module.scss';


const WeightSection = (props: any) => {
    const { editing } = props
    const formikContext = useFormikContext()
    const auth = useAuth()

    // @ts-ignore
    const weightRequired = formikContext.values.weightRequired
    // @ts-ignore
    const weightReceived = formikContext.values.weightReceived

    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")
    const userEdit = editing && !auth?.user?.isSupervisor

    return (
        <div className={styles.Section}>
            <div className={styles.SectionTitleWrapper}>
                <span className={styles.SectionTitle}>Gramagem</span>
                <span className={styles.SectionNote}>Em gr/m²</span>
            </div>

            <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={3}>
                    <FastField
                        component={FieldText}
                        name="weightRequired"
                        label="Pretendida"
                        type="number"
                        disabled={userEdit || !canUpdate}
                    />

                </Grid>
                <Grid item xs={3}>
                    <FastField
                        component={FieldText}
                        name="weightReceived"
                        label="Obtida"
                        type="number"
                        disabled={userEdit || !canUpdate}
                    />

                </Grid>
                <Grid item xs={3}>
                    <div style={{ paddingBottom: 10, marginLeft: -15 }}>
                        <DeviationWeight
                            received={weightReceived}
                            required={weightRequired}
                            units="gr/m²"
                            forwardedRef={props.forwardedRef}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default WeightSection

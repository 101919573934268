import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ApolloError } from 'apollo-client';
import { FastField, Formik, FormikValues } from 'formik';
import { Autocomplete } from 'formik-mui';
import React, { FC } from 'react';
import * as Yup from "yup";
import { Mesh, useCreateOneMeshConsumptionMutation, useDeleteOneMeshConsumptionMutation } from '../../generated/graphql';
import { useAuth } from '../../services/auth';
import { useErrorMessages } from '../../services/Hooks';
import { useHasPermission } from '../../services/permissions';
import { DeepPartial } from '../../typings/custom';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import CurrentQuantity from '../CurrentQuantity/CurrentQuantity';
import Empty from '../Empty/Empty';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import FormatDate from '../FormatDate/FormatDate';
import { convertKilos2Meters, Meters2Kilos } from '../MeshView/Conversion';
import FieldText from '../MeshView/FieldText';
import meshViewStyles from '../MeshView/MeshView.module.scss';
import MoreInfoButton from '../MoreInfoButton/MoreInfoButton';
import Truncate from '../Truncate/Truncate';
import styles from './MeshConsumptions.module.scss';

interface MeshConsumptionsProps {
    mesh: DeepPartial<Mesh>
    refetch: any
}


const MeshConsumptionItem = (props: any) => {
    const { mesh, meshConsumption, onDelete, canDelete } = props
    return (
        <Grid
            item xs={10}
            container
            justify="space-between"
            alignItems="center"
            className={styles.MeshConsumptionItem}
        >
            <div>
                {
                    meshConsumption?.unit === 'PCS' ?
                        <p className={styles.Reference}>
                            {meshConsumption.quantity} peças
                        </p>
                        :
                        <p className={styles.Reference}>
                            {meshConsumption.quantity} mts | <Meters2Kilos width={mesh?.widthReceived} weight={mesh?.weightReceived} quantity={meshConsumption?.quantity} />
                            kgs
                        </p>

                }
                <div className={styles.Purpose}>
                    <Truncate text={meshConsumption?.purpose} />
                </div>
            </div>

            <div>
                <MoreInfoButton className={styles.Confirm}>
                    <div style={{ padding: 20 }}>
                        Registado por <b>{meshConsumption.user.name}</b> em <FormatDate date={meshConsumption.createdAt} />
                    </div>
                </MoreInfoButton>

                {canDelete ? <ConfirmButton onClick={() => onDelete(meshConsumption?.id)} >
                    <DeleteIcon fontSize="small" color="primary" className={styles.Confirm} />
                </ConfirmButton> : null}
            </div>
        </Grid>
    )
}

const MeshConsumptions: FC<MeshConsumptionsProps> = ({ mesh, refetch }) => {

    const [deleteMeshConsumption] = useDeleteOneMeshConsumptionMutation()
    const [createMeshConsumption] = useCreateOneMeshConsumptionMutation()
    const [errors, setErrors] = useErrorMessages()
    const auth = useAuth()

    const hasPermission = useHasPermission()
    const canCreate = hasPermission("MESH_CONSUMPTION__CREATE")
    const canDelete = hasPermission("MESH_CONSUMPTION__DELETE")


    const handleDelete = (id: string) => {
        return deleteMeshConsumption({ variables: { input: { id } } }).then(refetch)
    }

    const handleSubmit = async (values: FormikValues) => {
        let { quantity, purpose, unit } = values

        // só é preciso converter unidades se a unidade
        // do consumo for em quilos, em metros e peças
        // o valor introduzido é o valor final
        if (unit === 'quilos') {
            if (mesh?.widthReceived && mesh?.weightReceived) {
                quantity = convertKilos2Meters(mesh?.widthReceived, mesh?.weightReceived, quantity)
            } else {
                alert("Erro! Não foi possível executar ação.")
            }
        }

        const quantityUnit = unit === "peças" ? "PCS" : "MTS"

        if (mesh?.id) {
            setErrors(null)
            try {
                await createMeshConsumption({
                    variables: {
                        input: {
                            meshConsumption: {
                                quantity, purpose, mesh: mesh.id, unit: quantityUnit
                            }
                        }
                    }
                }).then(refetch)
            } catch (e) {
                setErrors(e as ApolloError)
            }
        }
    }

    const options = mesh?.quantityUnit === 'PCS' ? ["peças"] : ["metros", "quilos", "peças"]

    const schema = Yup.object().shape({
        quantity: Yup.number().required().when("auth", {
            is: () => auth?.user?.isSupervisor,
            then: Yup.number().required(),
            otherwise: Yup.number().required().positive()
        }).when("unit", {
            is: unit => unit !== "peças",
            then: Yup.number().required(),
            otherwise: Yup.number().integer()

        }),
        purpose: Yup.string().required().max(20).min(5)
    })


    const initialValues = {
        quantity: "",
        purpose: "",
        unit: mesh?.quantityUnit === 'PCS' ? "peças" : "metros"
    }

    const error = false
    const helperText = null

    return (
        <div className={meshViewStyles.Section}>
            <h3>Consumos</h3>
            <h5>
                <span style={{ color: "#666", marginRight: 5 }}>
                    Quantidade atual:
                </span>
                <CurrentQuantity mesh={mesh} />
            </h5>
            <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
                {({ submitForm }) => (
                    <>
                        {canCreate ? <Grid container justify="space-between">
                            <Grid item xs={3}>
                                <FastField
                                    name="unit"
                                    disableClearable
                                    component={Autocomplete}
                                    options={options}
                                    getOptionLabel={(value: any) => value || ""}
                                    renderInput={(params: any) => {
                                        return (
                                            <TextField
                                                {...params}
                                                label="Unidade"
                                                error={error}
                                                helperText={helperText}
                                                data-cy="unit-input"
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FastField
                                    data-cy="quantity"
                                    component={FieldText}
                                    name="quantity"
                                    label="quantidade"
                                    type="number"
                                />
                            </Grid>
                        </Grid> : null}

                        {canCreate ? <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={8}>
                                <FastField
                                    component={FieldText}
                                    name="purpose"
                                    label="destino"
                                />
                            </Grid>
                            <Grid item xs={3} onClick={submitForm}>
                                <Tooltip title="Adicionar Consumo">
                                    <IconButton>
                                        <AddIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid> : null}


                    </>
                )}
            </Formik>

            {!mesh?.meshConsumptions?.length && <Empty message="Sem consumos associados." />}

            <Grid container direction="column" className={styles.MeshConsumptionList}>
                {mesh?.meshConsumptions?.map(
                    meshConsumption => <MeshConsumptionItem
                        key={meshConsumption?.id} mesh={mesh} meshConsumption={meshConsumption}
                        onDelete={handleDelete} canDelete={canDelete} />)}

            </Grid>


            <ErrorMessages data={errors} />
        </div >
    )
}


export default MeshConsumptions;

import React, { FC } from 'react';
import { useBrandsQuery } from '../../generated/graphql';
import DataFilter from '../DataFilter/DataFilter';

interface BrandFilterProps {
    value: any,
    onChange: any,
    disableUnderline?: boolean
}

const BrandFilter: FC<BrandFilterProps> = (props) => {

    const {
        value,
        onChange,
        disableUnderline = false
    } = props

    const { data } = useBrandsQuery()

    return (
        <DataFilter
            data={data}
            label="Marca"
            name="brand"
            value={value}
            onChange={onChange}
            disableUnderline={disableUnderline}
        />
    )
}

export default BrandFilter;

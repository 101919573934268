import { ApolloError } from "apollo-client";
import { FormikValues } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { extractNodeValue, prepareCreateData } from "../components/MeshView/MeshViewUtils";
import { UpdateMesh, useCreateOneMeshMutation, useDeleteOneMeshMutation, useMeshDetailsQuery, useUpdateOneMeshMutation } from "../generated/graphql";
import { useAuth } from "./auth";
import Config from "./Config";

export const useGuestRedirection = () => {
    const history = useHistory();
    const location = useLocation();
    const auth_token = localStorage.getItem(Config.AUTH_TOKEN_KEY)
    if (!auth_token) {
        history.push('/login', { redirectTo: location.pathname })
    }
};

export const useUserRedirection = () => {
    const history = useHistory();
    const auth_token = localStorage.getItem(Config.AUTH_TOKEN_KEY);

    if (auth_token) {
        let url = '/';
        try {
            // @ts-ignore
            url = history.location.state.redirectTo;
        }
        catch (error) {
            url = Config.START_ROUTE
        }
        history.push(url);
    }
};

export const useChangeHandler = (obj: any, setObj: any, config: any = null) => {
    const func = (event: any) => {
        const field = event.target.name;
        const value = event.target.value;
        if (config && config.hasOwnProperty(field)) {
            const listValue = [value]
            setObj({ ...obj, [field]: listValue });
        } else {
            setObj({ ...obj, [field]: value });
        }

    }
    return func;
};


export const useMeshManager = (id: string) => {
    const { data, loading, refetch } = useMeshDetailsQuery({ variables: { id }, fetchPolicy: "no-cache" })
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const [createRequest] = useCreateOneMeshMutation()
    const [updateRequest] = useUpdateOneMeshMutation()
    const [remove] = useDeleteOneMeshMutation({
        onCompleted() {
            enqueueSnackbar("Malha removida com sucesso.", { variant: "success" })
            history.push('/meshes/')
        },
        onError() {
            enqueueSnackbar("Erro ao remover malha. Verifique que não existem consumos, amostras ou localizações associadas.", { variant: "error" })
        }
    })
    const auth = useAuth()

    const create = (values: any) => {
        const mesh = prepareCreateData(values, auth?.user?.id);
        // @ts-ignore
        return createRequest({ variables: { input: { mesh } } });
    }

    const update = async (values: FormikValues) => {
        // @ts-ignore
        const updateData: UpdateMesh = { ...values }

        // @ts-ignore
        delete updateData.id

        // @ts-ignore
        delete updateData.createdAt
        // @ts-ignore
        delete updateData.updatedAt
        // @ts-ignore
        delete updateData.inspectedBy

        return updateRequest({
            variables: {
                input: {
                    id, update: {
                        ...updateData,
                        widthRequired: values?.widthRequired || null,
                        weightRequired: values?.weightRequired || null,
                        quantitySent: values?.quantitySent || null,
                        widthAfterWash: values?.widthAfterWash || null,
                        lengthAfterWash: values?.lengthAfterWash || null,
                        spiralityDeviation: values?.spiralityDeviation || null,
                        brand: extractNodeValue(values.brand),
                        supplier: extractNodeValue(values.supplier),
                        meshSupplier: extractNodeValue(values.meshSupplier),
                        washType: extractNodeValue(values.washType),
                        purchaseOrderType: extractNodeValue(values.purchaseOrderType),
                        meshFabricType: extractNodeValue(values.meshFabricType),
                    }
                }
            }
        })

    }

    return { data, loading, refetch, create, update, remove }
}

/**
   ErrorMessages companion hook
   usage: setErrors(e as ApolloError)
**/
export const useErrorMessages = () => {
    return useState<ApolloError | null>(null)
}

/**
 * Valor por defeito para o argumento config do useFilters
 *
 */
export const useFiltersDefault = { page: [NumberParam, 1] }

/**
 * Carrega os valores dos filtros como query params, retorna o valor dos
 * filtros e uma função para atualizar os filtros
 *
 * @param filterNames array com o nome dos filtros que vão ser usados
 * @param config permite definir valores por defeito para cada um dos campos
 */
export const useFilters = (filterNames: string[], config: {} = useFiltersDefault) => {

    const filters = {}

    // por cada filtro define o valor por defeito
    // de acordo com o passado em config ou então se
    // nada for especificado o valor é ""
    filterNames.forEach(name => {
        if (config && Object.keys(config).includes(name)) {
            // @ts-ignore
            filters[name] = withDefault(config[name][0], config[name][1])
        } else {
            // @ts-ignore
            filters[name] = withDefault(StringParam, "")
        }
    })

    const [query, setQuery] = useQueryParams(filters)

    const setFilter = (name: string, value: string | number) => {
        setQuery({ ...query, [name]: value })
    }

    return [query, setFilter]

}

import React, { FC } from 'react';
import { usePurchaseOrderTypesQuery } from '../../generated/graphql';
import DataFilter from '../DataFilter/DataFilter';

interface PurchaseOrderTypeFilterProps {
    value: any,
    onChange: any,
    disableUnderline?: boolean
}

const PurchaseOrderTypeFilter: FC<PurchaseOrderTypeFilterProps> = (props) => {

    const {
        value,
        onChange,
        disableUnderline = false
    } = props


    const { data } = usePurchaseOrderTypesQuery()

    return (
        <DataFilter
            data={data}
            label="Categoria"
            name="purchaseOrderType"
            value={value}
            onChange={onChange}
            disableUnderline={disableUnderline}
        />
    )
}

export default PurchaseOrderTypeFilter;

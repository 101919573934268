import { jsPDF } from "jspdf";
import { MeshDetailsFragment } from '../../generated/graphql';
import { textToBase64Barcode } from '../../services/Utils';
import { formatDate } from "../../utils/dates";
import { truncate } from "../../utils/strings";
import { getMeshCode } from "../MeshView/MeshBarcode";
import { fullPurchaseOrder } from "../MeshView/MeshViewUtils";
import Naming from './Naming';
import Roboto from './Roboto';
import RobotoBold from './RobotoBold';

const printLabel = (payload: MeshDetailsFragment | undefined | null) => {

    if (!payload) return

    // @ts-ignore
    const meshFabricType = payload?.meshFabricType?.node?.name || payload?.meshFabricType?.name

    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
        orientation: "landscape",
        format: [99, 114]
    });

    // add regular
    doc.addFileToVFS('Roboto-Regular-normal.ttf', Roboto);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFontSize(10)

    // add bold
    doc.addFileToVFS('Roboto-Bold-normal.ttf', RobotoBold);
    doc.addFont('Roboto-Bold-normal.ttf', 'Roboto-Bold', 'normal');

    const code = textToBase64Barcode(getMeshCode(payload.id)) || ""
    const composition = doc.splitTextToSize(payload?.composition || "", 89)
    const receptionDate = formatDate(payload?.receptionDate)

    doc.addImage(code, 'JPEG', 12, -10, 50, 36, "", "NONE", 270)
    doc.setDrawColor(200, 200, 200); // stroke color

    doc.line(55, 5, 55, 94)

    doc.text(payload.supplier.name, 100, 5, { angle: 90, rotationDirection: 0 })
    doc.text(fullPurchaseOrder(payload), 100, 60, { angle: 90, rotationDirection: 0 })
    doc.text(meshFabricType, 90, 5, { angle: 90, rotationDirection: 0 })
    doc.text(payload?.weightReceived ? `${payload.weightReceived} GR/M²` : "", 90, 72, { angle: 90, rotationDirection: 0 })
    doc.text(composition, 80, 5, { angle: 90, rotationDirection: 0 })
    doc.text(truncate(payload?.color || "", 27), 60, 5, { angle: 90, rotationDirection: 0 })

    doc.text(receptionDate, 60, 71, { angle: 90, rotationDirection: 0 })

    doc.addImage(Naming, 'PNG', 2.3, 0, 26, 4, "", "NONE", 270)

    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');

}

export default printLabel;

import gql from 'graphql-tag';

export const IS_MENU_OPEN = gql`
query {
    isMenuOpen @client
}
`;

export const SET_MENU = gql`
mutation setMenu($state: Boolean!) {
    setMenu(state: $state) @client
}
`;

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddAliasesToBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddCheckinSampleStatesToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddChecklistItemsToChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMeshConsumptionsToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMeshLocationsToLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMeshLocationsToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSampleMeshesToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSampleMeshesToSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSamplePhotosToSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSupplierAliasesToSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTicketCommentsToTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTicketFilesToTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTicketFilesToTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddUsersToUserTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Alias = {
  __typename?: 'Alias';
  id: Scalars['ID'];
  alias: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  brand: Brand;
};

export type AliasAggregateGroupBy = {
  __typename?: 'AliasAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
};

export type AliasAvgAggregate = {
  __typename?: 'AliasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type AliasConnection = {
  __typename?: 'AliasConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Alias>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type AliasCountAggregate = {
  __typename?: 'AliasCountAggregate';
  id?: Maybe<Scalars['Int']>;
  alias?: Maybe<Scalars['Int']>;
};

export type AliasDeleteFilter = {
  and?: Maybe<Array<AliasDeleteFilter>>;
  or?: Maybe<Array<AliasDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  alias?: Maybe<StringFieldComparison>;
};

export type AliasDeleteResponse = {
  __typename?: 'AliasDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AliasFilter = {
  and?: Maybe<Array<AliasFilter>>;
  or?: Maybe<Array<AliasFilter>>;
  id?: Maybe<IdFilterComparison>;
  alias?: Maybe<StringFieldComparison>;
  brand?: Maybe<AliasFilterBrandFilter>;
};

export type AliasFilterBrandFilter = {
  and?: Maybe<Array<AliasFilterBrandFilter>>;
  or?: Maybe<Array<AliasFilterBrandFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type AliasMaxAggregate = {
  __typename?: 'AliasMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
};

export type AliasMinAggregate = {
  __typename?: 'AliasMinAggregate';
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
};

export type AliasSort = {
  field: AliasSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum AliasSortFields {
  Id = 'id',
  Alias = 'alias'
}

export type AliasSumAggregate = {
  __typename?: 'AliasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type AliasUpdateFilter = {
  and?: Maybe<Array<AliasUpdateFilter>>;
  or?: Maybe<Array<AliasUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  alias?: Maybe<StringFieldComparison>;
};

export type AutocompleteMesh = {
  __typename?: 'AutocompleteMesh';
  description: Scalars['String'];
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  widthRequired?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  brand?: Maybe<Brand>;
  supplier?: Maybe<Supplier>;
  meshFabricType?: Maybe<MeshFabricType>;
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  avatar: Scalars['String'];
  priority: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  commercial?: Maybe<User>;
  stylist?: Maybe<User>;
  aliases?: Maybe<Array<Alias>>;
};


export type BrandAliasesArgs = {
  filter?: Maybe<AliasFilter>;
  sorting?: Maybe<Array<AliasSort>>;
};

export type BrandAggregateGroupBy = {
  __typename?: 'BrandAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type BrandAvgAggregate = {
  __typename?: 'BrandAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<BrandEdge>;
};

export type BrandCountAggregate = {
  __typename?: 'BrandCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
};

export type BrandDeleteFilter = {
  and?: Maybe<Array<BrandDeleteFilter>>;
  or?: Maybe<Array<BrandDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type BrandDeleteResponse = {
  __typename?: 'BrandDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  commercial?: Maybe<User>;
  stylist?: Maybe<User>;
};

export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** The node containing the Brand */
  node: Brand;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type BrandFilter = {
  and?: Maybe<Array<BrandFilter>>;
  or?: Maybe<Array<BrandFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type BrandInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  avatar: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  commercial: UserInput;
  stylist: UserInput;
};

export type BrandMaxAggregate = {
  __typename?: 'BrandMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandMinAggregate = {
  __typename?: 'BrandMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandSort = {
  field: BrandSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum BrandSortFields {
  Id = 'id',
  Name = 'name',
  IsActive = 'isActive'
}

export type BrandSumAggregate = {
  __typename?: 'BrandSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BrandUpdateFilter = {
  and?: Maybe<Array<BrandUpdateFilter>>;
  or?: Maybe<Array<BrandUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type Checklist = {
  __typename?: 'Checklist';
  id: Scalars['Float'];
  name: Scalars['String'];
  event: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userType: UserType;
  checklistItems?: Maybe<Array<ChecklistItem>>;
};


export type ChecklistChecklistItemsArgs = {
  filter?: Maybe<ChecklistItemFilter>;
  sorting?: Maybe<Array<ChecklistItemSort>>;
};

export type ChecklistAggregateGroupBy = {
  __typename?: 'ChecklistAggregateGroupBy';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistConnection = {
  __typename?: 'ChecklistConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ChecklistEdge>;
};

export type ChecklistCountAggregate = {
  __typename?: 'ChecklistCountAggregate';
  event?: Maybe<Scalars['Int']>;
};

export type ChecklistEdge = {
  __typename?: 'ChecklistEdge';
  /** The node containing the Checklist */
  node: Checklist;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ChecklistFilter = {
  and?: Maybe<Array<ChecklistFilter>>;
  or?: Maybe<Array<ChecklistFilter>>;
  event?: Maybe<StringFieldComparison>;
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  id: Scalars['Float'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ChecklistItemAggregateGroupBy = {
  __typename?: 'ChecklistItemAggregateGroupBy';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemConnection = {
  __typename?: 'ChecklistItemConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ChecklistItemEdge>;
};

export type ChecklistItemCountAggregate = {
  __typename?: 'ChecklistItemCountAggregate';
  name?: Maybe<Scalars['Int']>;
};

export type ChecklistItemEdge = {
  __typename?: 'ChecklistItemEdge';
  /** The node containing the ChecklistItem */
  node: ChecklistItem;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ChecklistItemFilter = {
  and?: Maybe<Array<ChecklistItemFilter>>;
  or?: Maybe<Array<ChecklistItemFilter>>;
  name?: Maybe<StringFieldComparison>;
};

export type ChecklistItemMaxAggregate = {
  __typename?: 'ChecklistItemMaxAggregate';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemMinAggregate = {
  __typename?: 'ChecklistItemMinAggregate';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemSort = {
  field: ChecklistItemSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ChecklistItemSortFields {
  Name = 'name'
}

export type ChecklistMaxAggregate = {
  __typename?: 'ChecklistMaxAggregate';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistMinAggregate = {
  __typename?: 'ChecklistMinAggregate';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistSort = {
  field: ChecklistSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ChecklistSortFields {
  Event = 'event'
}

export type CommonIssue = {
  __typename?: 'CommonIssue';
  id: Scalars['Float'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommonIssueAggregateGroupBy = {
  __typename?: 'CommonIssueAggregateGroupBy';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueConnection = {
  __typename?: 'CommonIssueConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<CommonIssueEdge>;
};

export type CommonIssueCountAggregate = {
  __typename?: 'CommonIssueCountAggregate';
  key?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type CommonIssueEdge = {
  __typename?: 'CommonIssueEdge';
  /** The node containing the CommonIssue */
  node: CommonIssue;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type CommonIssueFilter = {
  and?: Maybe<Array<CommonIssueFilter>>;
  or?: Maybe<Array<CommonIssueFilter>>;
  key?: Maybe<StringFieldComparison>;
  value?: Maybe<StringFieldComparison>;
};

export type CommonIssueMaxAggregate = {
  __typename?: 'CommonIssueMaxAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueMinAggregate = {
  __typename?: 'CommonIssueMinAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueSort = {
  field: CommonIssueSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum CommonIssueSortFields {
  Key = 'key',
  Value = 'value'
}

export type Configuration = {
  __typename?: 'Configuration';
  id: Scalars['Float'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ConfigurationAggregateGroupBy = {
  __typename?: 'ConfigurationAggregateGroupBy';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationConnection = {
  __typename?: 'ConfigurationConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Configuration>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ConfigurationCountAggregate = {
  __typename?: 'ConfigurationCountAggregate';
  key?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type ConfigurationFilter = {
  and?: Maybe<Array<ConfigurationFilter>>;
  or?: Maybe<Array<ConfigurationFilter>>;
  key?: Maybe<StringFieldComparison>;
  value?: Maybe<StringFieldComparison>;
};

export type ConfigurationMaxAggregate = {
  __typename?: 'ConfigurationMaxAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationMinAggregate = {
  __typename?: 'ConfigurationMinAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationSort = {
  field: ConfigurationSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ConfigurationSortFields {
  Key = 'key',
  Value = 'value'
}


export type CreateAlias = {
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateBrand = {
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
};

export type CreateIssue = {
  reference: Scalars['String'];
  order: Scalars['String'];
  description: Scalars['String'];
  sample: Scalars['String'];
};

export type CreateLocation = {
  name: Scalars['String'];
};

export type CreateManyAliasesInput = {
  /** Array of records to create */
  aliases: Array<CreateAlias>;
};

export type CreateManyBrandsInput = {
  /** Array of records to create */
  brands: Array<CreateBrand>;
};

export type CreateManyLocationsInput = {
  /** Array of records to create */
  locations: Array<CreateLocation>;
};

export type CreateManyMeshFabricTypesInput = {
  /** Array of records to create */
  meshFabricTypes: Array<CreateMeshFabricType>;
};

export type CreateManyMeshLocationsInput = {
  /** Array of records to create */
  meshLocations: Array<CreateMeshLocation>;
};

export type CreateManyNotificationsInput = {
  /** Array of records to create */
  notifications: Array<CreateNotification>;
};

export type CreateManyPosterCompositionsInput = {
  /** Array of records to create */
  posterCompositions: Array<CreatePosterComposition>;
};

export type CreateManyPosterFabricTypesInput = {
  /** Array of records to create */
  posterFabricTypes: Array<CreatePosterFabricType>;
};

export type CreateManyPosterSeasonsInput = {
  /** Array of records to create */
  posterSeasons: Array<CreatePosterSeason>;
};

export type CreateManyPostersInput = {
  /** Array of records to create */
  posters: Array<CreatePoster>;
};

export type CreateManyPurchaseOrderTypesInput = {
  /** Array of records to create */
  purchaseOrderTypes: Array<CreatePurchaseOrderType>;
};

export type CreateManySampleMeshesInput = {
  sampleMeshes: Array<CreateSampleMesh>;
};

export type CreateManySampleStatesInput = {
  /** Array of records to create */
  sampleStates: Array<CreateSampleState>;
};

export type CreateManySampleTypesInput = {
  /** Array of records to create */
  sampleTypes: Array<CreateSampleType>;
};

export type CreateManySamplesInput = {
  /** Array of records to create */
  samples: Array<CreateSample>;
};

export type CreateManySupplierAliasesInput = {
  /** Array of records to create */
  supplierAliases: Array<CreateSupplierAlias>;
};

export type CreateManySuppliersInput = {
  /** Array of records to create */
  suppliers: Array<CreateSupplier>;
};

export type CreateManyUserTypesInput = {
  /** Array of records to create */
  userTypes: Array<CreateUserType>;
};

export type CreateManyWashTypesInput = {
  /** Array of records to create */
  washTypes: Array<CreateWashType>;
};

export type CreateMesh = {
  purchaseOrder?: Maybe<Scalars['String']>;
  brand: Scalars['ID'];
  supplierDocument?: Maybe<Scalars['String']>;
  supplier: Scalars['ID'];
  meshSupplier?: Maybe<Scalars['ID']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  quantityUnit: Scalars['String'];
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived: Scalars['Float'];
  spoolCount?: Maybe<Scalars['Int']>;
  debitedToClient?: Maybe<Scalars['Boolean']>;
  receptionDate: Scalars['DateTime'];
  inspectionDate: Scalars['DateTime'];
  purchaseOrderType: Scalars['ID'];
  washType?: Maybe<Scalars['ID']>;
  meshFabricType?: Maybe<Scalars['ID']>;
};

export type CreateMeshConsumption = {
  quantity: Scalars['Float'];
  purpose: Scalars['String'];
  mesh: Scalars['Float'];
  unit: Scalars['String'];
};

export type CreateMeshFabricType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  meshCount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateMeshLocation = {
  mesh: Scalars['ID'];
  location: Scalars['ID'];
};

export type CreateNotification = {
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<NotificationKinds>;
  priority?: Maybe<NotificationPriorities>;
  tab?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateOneAliasInput = {
  /** The record to create */
  alias: CreateAlias;
};

export type CreateOneBrandInput = {
  /** The record to create */
  brand: CreateBrand;
};

export type CreateOneIssueInput = {
  issue: CreateIssue;
};

export type CreateOneLocationInput = {
  /** The record to create */
  location: CreateLocation;
};

export type CreateOneMeshConsumptionInput = {
  meshConsumption: CreateMeshConsumption;
};

export type CreateOneMeshFabricTypeInput = {
  /** The record to create */
  meshFabricType: CreateMeshFabricType;
};

export type CreateOneMeshInput = {
  mesh: CreateMesh;
};

export type CreateOneMeshLocationInput = {
  /** The record to create */
  meshLocation: CreateMeshLocation;
};

export type CreateOneNotificationInput = {
  /** The record to create */
  notification: CreateNotification;
};

export type CreateOnePosterCompositionInput = {
  /** The record to create */
  posterComposition: CreatePosterComposition;
};

export type CreateOnePosterFabricTypeInput = {
  /** The record to create */
  posterFabricType: CreatePosterFabricType;
};

export type CreateOnePosterInput = {
  /** The record to create */
  poster: CreatePoster;
};

export type CreateOnePosterSeasonInput = {
  /** The record to create */
  posterSeason: CreatePosterSeason;
};

export type CreateOnePurchaseOrderTypeInput = {
  /** The record to create */
  purchaseOrderType: CreatePurchaseOrderType;
};

export type CreateOneQuoteInput = {
  quote: CreateQuote;
};

export type CreateOneSampleInput = {
  /** The record to create */
  sample: CreateSample;
};

export type CreateOneSampleMeshInput = {
  sampleMesh: CreateSampleMesh;
};

export type CreateOneSamplePhotoInput = {
  samplePhoto: CreateSamplePhoto;
};

export type CreateOneSampleStateInput = {
  /** The record to create */
  sampleState: CreateSampleState;
};

export type CreateOneSampleTypeInput = {
  /** The record to create */
  sampleType: CreateSampleType;
};

export type CreateOneSupplierAliasInput = {
  /** The record to create */
  supplierAlias: CreateSupplierAlias;
};

export type CreateOneSupplierInput = {
  /** The record to create */
  supplier: CreateSupplier;
};

export type CreateOneTicketCommentInput = {
  ticketComment: CreateTicketComment;
};

export type CreateOneTicketInput = {
  ticket: CreateTicket;
};

export type CreateOneUserArgs = {
  user: CreateOneUserInput;
};

export type CreateOneUserEventInput = {
  userEvent: CreateUserEvent;
};

export type CreateOneUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  userType: Scalars['ID'];
};

export type CreateOneUserTypeInput = {
  /** The record to create */
  userType: CreateUserType;
};

export type CreateOneWashTypeInput = {
  /** The record to create */
  washType: CreateWashType;
};

export type CreatePoster = {
  reference: Scalars['String'];
  photo: Scalars['Upload'];
  cover: Scalars['Upload'];
  specifications: Scalars['Upload'];
  story: Scalars['String'];
  meshKind: Scalars['String'];
  composition: Scalars['String'];
  weight: Scalars['Float'];
};

export type CreatePosterComposition = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreatePosterFabricType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreatePosterSeason = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreatePurchaseOrderType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateQuote = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  company: Scalars['String'];
  brand: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  requestType: Array<Scalars['String']>;
  categories: Array<Scalars['String']>;
  productGroup: Array<Scalars['String']>;
  estimatedQuantityYear?: Maybe<Scalars['Float']>;
  orderQuantityColor?: Maybe<Scalars['Float']>;
  specification: Scalars['String'];
  attachments?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateSample = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate?: Maybe<Scalars['DateTime']>;
  sketch?: Maybe<Scalars['String']>;
  fastTrack?: Maybe<Scalars['Boolean']>;
  hasMesh?: Maybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: Maybe<Scalars['String']>;
  shippingDate?: Maybe<Scalars['String']>;
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  decorationsReceivedAt?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type CreateSampleMesh = {
  mesh: Scalars['Float'];
  sample: Scalars['Float'];
};

export type CreateSamplePhoto = {
  upload: Scalars['Upload'];
  sample: Scalars['ID'];
};

export type CreateSampleState = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type CreateSampleType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type CreateSupplier = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  isMeshSupplier?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateSupplierAlias = {
  externalId: Scalars['String'];
  supplier: Scalars['ID'];
};

export type CreateTicket = {
  name: Scalars['String'];
  description: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  ticketFiles?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateTicketComment = {
  content: Scalars['String'];
  ticket: Scalars['Int'];
  ticketFiles?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateUserEvent = {
  name: Scalars['String'];
  data?: Maybe<Scalars['JSONObject']>;
};

export type CreateUserType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  todo?: Maybe<Array<SampleInputType>>;
  doing?: Maybe<Array<SampleInputType>>;
  done?: Maybe<Array<SampleInputType>>;
  todoCount?: Maybe<Scalars['Int']>;
  doingCount?: Maybe<Scalars['Int']>;
  doneCount?: Maybe<Scalars['Int']>;
};

export type CreateWashType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: Maybe<Scalars['Int']>;
  /** Paginate last */
  last?: Maybe<Scalars['Int']>;
};

export type DashPeriodStat = {
  __typename?: 'DashPeriodStat';
  reference: Scalars['Float'];
  current: Scalars['Float'];
};

export type DashPeriodStatInput = {
  reference: Scalars['Float'];
  current: Scalars['Float'];
};

export type DashUserStat = {
  __typename?: 'DashUserStat';
  week: DashPeriodStat;
  day: DashPeriodStat;
};

export type DashUserStatInput = {
  week: DashPeriodStatInput;
  day: DashPeriodStatInput;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};


export type DeleteManyAliasesInput = {
  /** Filter to find records to delete */
  filter: AliasDeleteFilter;
};

export type DeleteManyBrandsInput = {
  /** Filter to find records to delete */
  filter: BrandDeleteFilter;
};

export type DeleteManyMeshFabricTypesInput = {
  /** Filter to find records to delete */
  filter: MeshFabricTypeDeleteFilter;
};

export type DeleteManyMeshLocationsInput = {
  /** Filter to find records to delete */
  filter: MeshLocationDeleteFilter;
};

export type DeleteManyMeshesInput = {
  /** Filter to find records to delete */
  filter: MeshDeleteFilter;
};

export type DeleteManyNotificationsInput = {
  /** Filter to find records to delete */
  filter: NotificationDeleteFilter;
};

export type DeleteManyPosterCompositionsInput = {
  /** Filter to find records to delete */
  filter: PosterCompositionDeleteFilter;
};

export type DeleteManyPosterFabricTypesInput = {
  /** Filter to find records to delete */
  filter: PosterFabricTypeDeleteFilter;
};

export type DeleteManyPosterSeasonsInput = {
  /** Filter to find records to delete */
  filter: PosterSeasonDeleteFilter;
};

export type DeleteManyPostersInput = {
  /** Filter to find records to delete */
  filter: PosterDeleteFilter;
};

export type DeleteManyPurchaseOrderTypesInput = {
  /** Filter to find records to delete */
  filter: PurchaseOrderTypeDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManySampleMeshesInput = {
  /** Filter to find records to delete */
  filter: SampleMeshDeleteFilter;
};

export type DeleteManySampleStatesInput = {
  /** Filter to find records to delete */
  filter: SampleStateDeleteFilter;
};

export type DeleteManySampleTypesInput = {
  /** Filter to find records to delete */
  filter: SampleTypeDeleteFilter;
};

export type DeleteManySamplesInput = {
  /** Filter to find records to delete */
  filter: SampleDeleteFilter;
};

export type DeleteManySupplierAliasesInput = {
  /** Filter to find records to delete */
  filter: SupplierAliasDeleteFilter;
};

export type DeleteManyUserEventsInput = {
  /** Filter to find records to delete */
  filter: UserEventDeleteFilter;
};

export type DeleteManyUserTypesInput = {
  /** Filter to find records to delete */
  filter: UserTypeDeleteFilter;
};

export type DeleteManyWashTypesInput = {
  /** Filter to find records to delete */
  filter: WashTypeDeleteFilter;
};

export type DeleteOneAliasInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneBrandInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMeshConsumptionInput = {
  id: Scalars['ID'];
};

export type DeleteOneMeshFabricTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMeshInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMeshLocationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneNotificationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterCompositionInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterFabricTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePosterSeasonInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePurchaseOrderTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleMeshInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSampleTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSupplierAliasInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneUserEventInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneUserTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneWashTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type ExecuteMeshTransfers = {
  meshTransfers: Array<Scalars['ID']>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['ID']>;
  neq?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  like?: Maybe<Scalars['ID']>;
  notLike?: Maybe<Scalars['ID']>;
  iLike?: Maybe<Scalars['ID']>;
  notILike?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  notIn?: Maybe<Array<Scalars['ID']>>;
};

export type IntFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  between?: Maybe<IntFieldComparisonBetween>;
  notBetween?: Maybe<IntFieldComparisonBetween>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int'];
  upper: Scalars['Int'];
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['Float'];
  reference: Scalars['String'];
  order: Scalars['String'];
  description: Scalars['String'];
  sample: Scalars['String'];
};


export type JsonObjectFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['JSONObject']>;
  neq?: Maybe<Scalars['JSONObject']>;
  gt?: Maybe<Scalars['JSONObject']>;
  gte?: Maybe<Scalars['JSONObject']>;
  lt?: Maybe<Scalars['JSONObject']>;
  lte?: Maybe<Scalars['JSONObject']>;
  like?: Maybe<Scalars['JSONObject']>;
  notLike?: Maybe<Scalars['JSONObject']>;
  iLike?: Maybe<Scalars['JSONObject']>;
  notILike?: Maybe<Scalars['JSONObject']>;
  in?: Maybe<Array<Scalars['JSONObject']>>;
  notIn?: Maybe<Array<Scalars['JSONObject']>>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  meshLocations: Array<MeshLocation>;
};


export type LocationMeshLocationsArgs = {
  filter?: Maybe<MeshLocationFilter>;
  sorting?: Maybe<Array<MeshLocationSort>>;
};

export type LocationAggregateGroupBy = {
  __typename?: 'LocationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationAvgAggregate = {
  __typename?: 'LocationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<LocationEdge>;
};

export type LocationCountAggregate = {
  __typename?: 'LocationCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** The node containing the Location */
  node: Location;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type LocationFilter = {
  and?: Maybe<Array<LocationFilter>>;
  or?: Maybe<Array<LocationFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  meshLocations?: Maybe<LocationFilterMeshLocationFilter>;
};

export type LocationFilterMeshLocationFilter = {
  and?: Maybe<Array<LocationFilterMeshLocationFilter>>;
  or?: Maybe<Array<LocationFilterMeshLocationFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type LocationMaxAggregate = {
  __typename?: 'LocationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationMinAggregate = {
  __typename?: 'LocationMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationSort = {
  field: LocationSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum LocationSortFields {
  Id = 'id',
  Name = 'name'
}

export type LocationSumAggregate = {
  __typename?: 'LocationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type Mesh = {
  __typename?: 'Mesh';
  id: Scalars['Float'];
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  quantityUnit: Scalars['String'];
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived: Scalars['Float'];
  currentQuantity?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  inspectionDate: Scalars['DateTime'];
  receptionDate: Scalars['DateTime'];
  debitedToClient: Scalars['Boolean'];
  meshFabricType: MeshFabricType;
  inspectedBy: User;
  brand: Brand;
  supplier: Supplier;
  meshSupplier?: Maybe<Supplier>;
  purchaseOrderType?: Maybe<PurchaseOrderType>;
  washType?: Maybe<WashType>;
  meshLocations?: Maybe<Array<MeshLocation>>;
  meshConsumptions?: Maybe<Array<MeshConsumption>>;
  sampleMeshes?: Maybe<Array<SampleMesh>>;
};


export type MeshMeshLocationsArgs = {
  filter?: Maybe<MeshLocationFilter>;
  sorting?: Maybe<Array<MeshLocationSort>>;
};


export type MeshMeshConsumptionsArgs = {
  filter?: Maybe<MeshConsumptionFilter>;
  sorting?: Maybe<Array<MeshConsumptionSort>>;
};


export type MeshSampleMeshesArgs = {
  filter?: Maybe<SampleMeshFilter>;
  sorting?: Maybe<Array<SampleMeshSort>>;
};

export type MeshAggregateGroupBy = {
  __typename?: 'MeshAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshAvgAggregate = {
  __typename?: 'MeshAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Float']>;
};

export type MeshConnection = {
  __typename?: 'MeshConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Mesh>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MeshConsumption = {
  __typename?: 'MeshConsumption';
  id: Scalars['Float'];
  quantity: Scalars['Float'];
  purpose: Scalars['String'];
  unit: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  mesh: Mesh;
};

export type MeshConsumptionAggregateGroupBy = {
  __typename?: 'MeshConsumptionAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshConsumptionAvgAggregate = {
  __typename?: 'MeshConsumptionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type MeshConsumptionConnection = {
  __typename?: 'MeshConsumptionConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MeshConsumptionEdge>;
};

export type MeshConsumptionCountAggregate = {
  __typename?: 'MeshConsumptionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
};

export type MeshConsumptionEdge = {
  __typename?: 'MeshConsumptionEdge';
  /** The node containing the MeshConsumption */
  node: MeshConsumption;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MeshConsumptionFilter = {
  and?: Maybe<Array<MeshConsumptionFilter>>;
  or?: Maybe<Array<MeshConsumptionFilter>>;
  id?: Maybe<NumberFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
  purpose?: Maybe<StringFieldComparison>;
  unit?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type MeshConsumptionMaxAggregate = {
  __typename?: 'MeshConsumptionMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshConsumptionMinAggregate = {
  __typename?: 'MeshConsumptionMinAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshConsumptionSort = {
  field: MeshConsumptionSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MeshConsumptionSortFields {
  Id = 'id',
  Quantity = 'quantity',
  Purpose = 'purpose',
  Unit = 'unit',
  CreatedAt = 'createdAt'
}

export type MeshConsumptionSumAggregate = {
  __typename?: 'MeshConsumptionSumAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type MeshConsumptionUpdateFilter = {
  and?: Maybe<Array<MeshConsumptionUpdateFilter>>;
  or?: Maybe<Array<MeshConsumptionUpdateFilter>>;
  id?: Maybe<NumberFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
  purpose?: Maybe<StringFieldComparison>;
  unit?: Maybe<StringFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type MeshCountAggregate = {
  __typename?: 'MeshCountAggregate';
  id?: Maybe<Scalars['Int']>;
  supplierDocument?: Maybe<Scalars['Int']>;
  meshSupplierDocument?: Maybe<Scalars['Int']>;
  purchaseOrder?: Maybe<Scalars['Int']>;
  meshKind?: Maybe<Scalars['Int']>;
  composition?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  colorObservations?: Maybe<Scalars['Int']>;
  widthRequired?: Maybe<Scalars['Int']>;
  widthReceived?: Maybe<Scalars['Int']>;
  weightRequired?: Maybe<Scalars['Int']>;
  weightReceived?: Maybe<Scalars['Int']>;
  spiralityDeviation?: Maybe<Scalars['Int']>;
  quantityUnit?: Maybe<Scalars['Int']>;
  quantitySent?: Maybe<Scalars['Int']>;
  quantityReceived?: Maybe<Scalars['Int']>;
  currentQuantity?: Maybe<Scalars['Int']>;
  spoolCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
};

export type MeshDeleteFilter = {
  and?: Maybe<Array<MeshDeleteFilter>>;
  or?: Maybe<Array<MeshDeleteFilter>>;
  id?: Maybe<NumberFieldComparison>;
  supplierDocument?: Maybe<StringFieldComparison>;
  meshSupplierDocument?: Maybe<StringFieldComparison>;
  purchaseOrder?: Maybe<StringFieldComparison>;
  meshKind?: Maybe<StringFieldComparison>;
  composition?: Maybe<StringFieldComparison>;
  color?: Maybe<StringFieldComparison>;
  colorObservations?: Maybe<StringFieldComparison>;
  widthRequired?: Maybe<NumberFieldComparison>;
  widthReceived?: Maybe<NumberFieldComparison>;
  weightRequired?: Maybe<NumberFieldComparison>;
  weightReceived?: Maybe<NumberFieldComparison>;
  spiralityDeviation?: Maybe<NumberFieldComparison>;
  quantityUnit?: Maybe<StringFieldComparison>;
  quantitySent?: Maybe<NumberFieldComparison>;
  quantityReceived?: Maybe<NumberFieldComparison>;
  currentQuantity?: Maybe<NumberFieldComparison>;
  spoolCount?: Maybe<IntFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type MeshDeleteResponse = {
  __typename?: 'MeshDeleteResponse';
  id?: Maybe<Scalars['Float']>;
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  inspectionDate?: Maybe<Scalars['DateTime']>;
  receptionDate?: Maybe<Scalars['DateTime']>;
  debitedToClient?: Maybe<Scalars['Boolean']>;
};

export type MeshFabricType = {
  __typename?: 'MeshFabricType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  meshCount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MeshFabricTypeAggregateGroupBy = {
  __typename?: 'MeshFabricTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  meshCount?: Maybe<Scalars['Float']>;
};

export type MeshFabricTypeAvgAggregate = {
  __typename?: 'MeshFabricTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  meshCount?: Maybe<Scalars['Float']>;
};

export type MeshFabricTypeConnection = {
  __typename?: 'MeshFabricTypeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MeshFabricTypeEdge>;
};

export type MeshFabricTypeCountAggregate = {
  __typename?: 'MeshFabricTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  meshCount?: Maybe<Scalars['Int']>;
};

export type MeshFabricTypeDeleteFilter = {
  and?: Maybe<Array<MeshFabricTypeDeleteFilter>>;
  or?: Maybe<Array<MeshFabricTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
  meshCount?: Maybe<NumberFieldComparison>;
};

export type MeshFabricTypeDeleteResponse = {
  __typename?: 'MeshFabricTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  meshCount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MeshFabricTypeEdge = {
  __typename?: 'MeshFabricTypeEdge';
  /** The node containing the MeshFabricType */
  node: MeshFabricType;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MeshFabricTypeFilter = {
  and?: Maybe<Array<MeshFabricTypeFilter>>;
  or?: Maybe<Array<MeshFabricTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
  meshCount?: Maybe<NumberFieldComparison>;
};

export type MeshFabricTypeMaxAggregate = {
  __typename?: 'MeshFabricTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  meshCount?: Maybe<Scalars['Float']>;
};

export type MeshFabricTypeMinAggregate = {
  __typename?: 'MeshFabricTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  meshCount?: Maybe<Scalars['Float']>;
};

export type MeshFabricTypeSort = {
  field: MeshFabricTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MeshFabricTypeSortFields {
  Id = 'id',
  Name = 'name',
  IsActive = 'isActive',
  MeshCount = 'meshCount'
}

export type MeshFabricTypeSumAggregate = {
  __typename?: 'MeshFabricTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
  meshCount?: Maybe<Scalars['Float']>;
};

export type MeshFabricTypeUpdateFilter = {
  and?: Maybe<Array<MeshFabricTypeUpdateFilter>>;
  or?: Maybe<Array<MeshFabricTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
  meshCount?: Maybe<NumberFieldComparison>;
};

export type MeshFilter = {
  and?: Maybe<Array<MeshFilter>>;
  or?: Maybe<Array<MeshFilter>>;
  id?: Maybe<NumberFieldComparison>;
  supplierDocument?: Maybe<StringFieldComparison>;
  meshSupplierDocument?: Maybe<StringFieldComparison>;
  purchaseOrder?: Maybe<StringFieldComparison>;
  meshKind?: Maybe<StringFieldComparison>;
  composition?: Maybe<StringFieldComparison>;
  color?: Maybe<StringFieldComparison>;
  colorObservations?: Maybe<StringFieldComparison>;
  widthRequired?: Maybe<NumberFieldComparison>;
  widthReceived?: Maybe<NumberFieldComparison>;
  weightRequired?: Maybe<NumberFieldComparison>;
  weightReceived?: Maybe<NumberFieldComparison>;
  spiralityDeviation?: Maybe<NumberFieldComparison>;
  quantityUnit?: Maybe<StringFieldComparison>;
  quantitySent?: Maybe<NumberFieldComparison>;
  quantityReceived?: Maybe<NumberFieldComparison>;
  currentQuantity?: Maybe<NumberFieldComparison>;
  spoolCount?: Maybe<IntFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  washType?: Maybe<MeshFilterWashTypeFilter>;
  purchaseOrderType?: Maybe<MeshFilterPurchaseOrderTypeFilter>;
  meshSupplier?: Maybe<MeshFilterSupplierFilter>;
  supplier?: Maybe<MeshFilterSupplierFilter>;
  brand?: Maybe<MeshFilterBrandFilter>;
  inspectedBy?: Maybe<MeshFilterUserFilter>;
  meshFabricType?: Maybe<MeshFilterMeshFabricTypeFilter>;
};

export type MeshFilterBrandFilter = {
  and?: Maybe<Array<MeshFilterBrandFilter>>;
  or?: Maybe<Array<MeshFilterBrandFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type MeshFilterMeshFabricTypeFilter = {
  and?: Maybe<Array<MeshFilterMeshFabricTypeFilter>>;
  or?: Maybe<Array<MeshFilterMeshFabricTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
  meshCount?: Maybe<NumberFieldComparison>;
};

export type MeshFilterPurchaseOrderTypeFilter = {
  and?: Maybe<Array<MeshFilterPurchaseOrderTypeFilter>>;
  or?: Maybe<Array<MeshFilterPurchaseOrderTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type MeshFilterSupplierFilter = {
  and?: Maybe<Array<MeshFilterSupplierFilter>>;
  or?: Maybe<Array<MeshFilterSupplierFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isSupplier?: Maybe<BooleanFieldComparison>;
  isMeshSupplier?: Maybe<BooleanFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type MeshFilterUserFilter = {
  and?: Maybe<Array<MeshFilterUserFilter>>;
  or?: Maybe<Array<MeshFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type MeshFilterWashTypeFilter = {
  and?: Maybe<Array<MeshFilterWashTypeFilter>>;
  or?: Maybe<Array<MeshFilterWashTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type MeshLocation = {
  __typename?: 'MeshLocation';
  id: Scalars['Float'];
  mesh: Mesh;
  location: Location;
};

export type MeshLocationAggregateGroupBy = {
  __typename?: 'MeshLocationAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationAvgAggregate = {
  __typename?: 'MeshLocationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationConnection = {
  __typename?: 'MeshLocationConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MeshLocationEdge>;
};

export type MeshLocationCountAggregate = {
  __typename?: 'MeshLocationCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type MeshLocationDeleteFilter = {
  and?: Maybe<Array<MeshLocationDeleteFilter>>;
  or?: Maybe<Array<MeshLocationDeleteFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type MeshLocationDeleteResponse = {
  __typename?: 'MeshLocationDeleteResponse';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationEdge = {
  __typename?: 'MeshLocationEdge';
  /** The node containing the MeshLocation */
  node: MeshLocation;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MeshLocationFilter = {
  and?: Maybe<Array<MeshLocationFilter>>;
  or?: Maybe<Array<MeshLocationFilter>>;
  id?: Maybe<NumberFieldComparison>;
  mesh?: Maybe<MeshLocationFilterMeshFilter>;
};

export type MeshLocationFilterMeshFilter = {
  and?: Maybe<Array<MeshLocationFilterMeshFilter>>;
  or?: Maybe<Array<MeshLocationFilterMeshFilter>>;
  id?: Maybe<NumberFieldComparison>;
  supplierDocument?: Maybe<StringFieldComparison>;
  meshSupplierDocument?: Maybe<StringFieldComparison>;
  purchaseOrder?: Maybe<StringFieldComparison>;
  meshKind?: Maybe<StringFieldComparison>;
  composition?: Maybe<StringFieldComparison>;
  color?: Maybe<StringFieldComparison>;
  colorObservations?: Maybe<StringFieldComparison>;
  widthRequired?: Maybe<NumberFieldComparison>;
  widthReceived?: Maybe<NumberFieldComparison>;
  weightRequired?: Maybe<NumberFieldComparison>;
  weightReceived?: Maybe<NumberFieldComparison>;
  spiralityDeviation?: Maybe<NumberFieldComparison>;
  quantityUnit?: Maybe<StringFieldComparison>;
  quantitySent?: Maybe<NumberFieldComparison>;
  quantityReceived?: Maybe<NumberFieldComparison>;
  currentQuantity?: Maybe<NumberFieldComparison>;
  spoolCount?: Maybe<IntFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type MeshLocationMaxAggregate = {
  __typename?: 'MeshLocationMaxAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationMinAggregate = {
  __typename?: 'MeshLocationMinAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationSort = {
  field: MeshLocationSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MeshLocationSortFields {
  Id = 'id'
}

export type MeshLocationSumAggregate = {
  __typename?: 'MeshLocationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshLocationUpdateFilter = {
  and?: Maybe<Array<MeshLocationUpdateFilter>>;
  or?: Maybe<Array<MeshLocationUpdateFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type MeshMaxAggregate = {
  __typename?: 'MeshMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshMinAggregate = {
  __typename?: 'MeshMinAggregate';
  id?: Maybe<Scalars['Float']>;
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshSort = {
  field: MeshSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MeshSortFields {
  Id = 'id',
  SupplierDocument = 'supplierDocument',
  MeshSupplierDocument = 'meshSupplierDocument',
  PurchaseOrder = 'purchaseOrder',
  MeshKind = 'meshKind',
  Composition = 'composition',
  Color = 'color',
  ColorObservations = 'colorObservations',
  WidthRequired = 'widthRequired',
  WidthReceived = 'widthReceived',
  WeightRequired = 'weightRequired',
  WeightReceived = 'weightReceived',
  SpiralityDeviation = 'spiralityDeviation',
  QuantityUnit = 'quantityUnit',
  QuantitySent = 'quantitySent',
  QuantityReceived = 'quantityReceived',
  CurrentQuantity = 'currentQuantity',
  SpoolCount = 'spoolCount',
  CreatedAt = 'createdAt'
}

export type MeshSumAggregate = {
  __typename?: 'MeshSumAggregate';
  id?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  spoolCount?: Maybe<Scalars['Float']>;
};

export type MeshTransfer = {
  __typename?: 'MeshTransfer';
  id: Scalars['ID'];
  state: MeshTransferStates;
  type: MeshTransferTypes;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  mesh: Mesh;
  destination?: Maybe<Location>;
};

export type MeshTransferAggregateGroupBy = {
  __typename?: 'MeshTransferAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<MeshTransferStates>;
  type?: Maybe<MeshTransferTypes>;
};

export type MeshTransferAvgAggregate = {
  __typename?: 'MeshTransferAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MeshTransferConnection = {
  __typename?: 'MeshTransferConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<MeshTransfer>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MeshTransferCountAggregate = {
  __typename?: 'MeshTransferCountAggregate';
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type MeshTransferFilter = {
  and?: Maybe<Array<MeshTransferFilter>>;
  or?: Maybe<Array<MeshTransferFilter>>;
  id?: Maybe<IdFilterComparison>;
  state?: Maybe<MeshTransferStatesFilterComparison>;
  type?: Maybe<MeshTransferTypesFilterComparison>;
  destination?: Maybe<MeshTransferFilterLocationFilter>;
  mesh?: Maybe<MeshTransferFilterMeshFilter>;
};

export type MeshTransferFilterLocationFilter = {
  and?: Maybe<Array<MeshTransferFilterLocationFilter>>;
  or?: Maybe<Array<MeshTransferFilterLocationFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type MeshTransferFilterMeshFilter = {
  and?: Maybe<Array<MeshTransferFilterMeshFilter>>;
  or?: Maybe<Array<MeshTransferFilterMeshFilter>>;
  id?: Maybe<NumberFieldComparison>;
  supplierDocument?: Maybe<StringFieldComparison>;
  meshSupplierDocument?: Maybe<StringFieldComparison>;
  purchaseOrder?: Maybe<StringFieldComparison>;
  meshKind?: Maybe<StringFieldComparison>;
  composition?: Maybe<StringFieldComparison>;
  color?: Maybe<StringFieldComparison>;
  colorObservations?: Maybe<StringFieldComparison>;
  widthRequired?: Maybe<NumberFieldComparison>;
  widthReceived?: Maybe<NumberFieldComparison>;
  weightRequired?: Maybe<NumberFieldComparison>;
  weightReceived?: Maybe<NumberFieldComparison>;
  spiralityDeviation?: Maybe<NumberFieldComparison>;
  quantityUnit?: Maybe<StringFieldComparison>;
  quantitySent?: Maybe<NumberFieldComparison>;
  quantityReceived?: Maybe<NumberFieldComparison>;
  currentQuantity?: Maybe<NumberFieldComparison>;
  spoolCount?: Maybe<IntFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type MeshTransferMaxAggregate = {
  __typename?: 'MeshTransferMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<MeshTransferStates>;
  type?: Maybe<MeshTransferTypes>;
};

export type MeshTransferMinAggregate = {
  __typename?: 'MeshTransferMinAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<MeshTransferStates>;
  type?: Maybe<MeshTransferTypes>;
};

export type MeshTransferSort = {
  field: MeshTransferSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MeshTransferSortFields {
  Id = 'id',
  State = 'state',
  Type = 'type'
}

export enum MeshTransferStates {
  Created = 'CREATED',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED'
}

export type MeshTransferStatesFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<MeshTransferStates>;
  neq?: Maybe<MeshTransferStates>;
  gt?: Maybe<MeshTransferStates>;
  gte?: Maybe<MeshTransferStates>;
  lt?: Maybe<MeshTransferStates>;
  lte?: Maybe<MeshTransferStates>;
  like?: Maybe<MeshTransferStates>;
  notLike?: Maybe<MeshTransferStates>;
  iLike?: Maybe<MeshTransferStates>;
  notILike?: Maybe<MeshTransferStates>;
  in?: Maybe<Array<MeshTransferStates>>;
  notIn?: Maybe<Array<MeshTransferStates>>;
};

export type MeshTransferSumAggregate = {
  __typename?: 'MeshTransferSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export enum MeshTransferTypes {
  Purge = 'PURGE',
  Picking = 'PICKING'
}

export type MeshTransferTypesFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<MeshTransferTypes>;
  neq?: Maybe<MeshTransferTypes>;
  gt?: Maybe<MeshTransferTypes>;
  gte?: Maybe<MeshTransferTypes>;
  lt?: Maybe<MeshTransferTypes>;
  lte?: Maybe<MeshTransferTypes>;
  like?: Maybe<MeshTransferTypes>;
  notLike?: Maybe<MeshTransferTypes>;
  iLike?: Maybe<MeshTransferTypes>;
  notILike?: Maybe<MeshTransferTypes>;
  in?: Maybe<Array<MeshTransferTypes>>;
  notIn?: Maybe<Array<MeshTransferTypes>>;
};

export type MeshTransferUpdateFilter = {
  and?: Maybe<Array<MeshTransferUpdateFilter>>;
  or?: Maybe<Array<MeshTransferUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  state?: Maybe<MeshTransferStatesFilterComparison>;
  type?: Maybe<MeshTransferTypesFilterComparison>;
};

export type MeshUpdateFilter = {
  and?: Maybe<Array<MeshUpdateFilter>>;
  or?: Maybe<Array<MeshUpdateFilter>>;
  id?: Maybe<NumberFieldComparison>;
  supplierDocument?: Maybe<StringFieldComparison>;
  meshSupplierDocument?: Maybe<StringFieldComparison>;
  purchaseOrder?: Maybe<StringFieldComparison>;
  meshKind?: Maybe<StringFieldComparison>;
  composition?: Maybe<StringFieldComparison>;
  color?: Maybe<StringFieldComparison>;
  colorObservations?: Maybe<StringFieldComparison>;
  widthRequired?: Maybe<NumberFieldComparison>;
  widthReceived?: Maybe<NumberFieldComparison>;
  weightRequired?: Maybe<NumberFieldComparison>;
  weightReceived?: Maybe<NumberFieldComparison>;
  spiralityDeviation?: Maybe<NumberFieldComparison>;
  quantityUnit?: Maybe<StringFieldComparison>;
  quantitySent?: Maybe<NumberFieldComparison>;
  quantityReceived?: Maybe<NumberFieldComparison>;
  currentQuantity?: Maybe<NumberFieldComparison>;
  spoolCount?: Maybe<IntFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type Mutation = {
  __typename?: 'Mutation';
  setTypeOnUser: User;
  addCheckinSampleStatesToUser: User;
  setCheckinSampleStatesOnUser: User;
  setPassword: Scalars['String'];
  createOneUser: User;
  setSampleStateTypeOnSampleState: SampleState;
  setCheckinUserOnSampleState: SampleState;
  createOneSampleState: SampleState;
  createManySampleStates: Array<SampleState>;
  updateOneSampleState: SampleState;
  updateManySampleStates: UpdateManyResponse;
  deleteOneSampleState: SampleStateDeleteResponse;
  deleteManySampleStates: DeleteManyResponse;
  setCommercialOnBrand: Brand;
  setStylistOnBrand: Brand;
  addAliasesToBrand: Brand;
  setAliasesOnBrand: Brand;
  createOneBrand: Brand;
  createManyBrands: Array<Brand>;
  updateOneBrand: Brand;
  updateManyBrands: UpdateManyResponse;
  deleteOneBrand: BrandDeleteResponse;
  deleteManyBrands: DeleteManyResponse;
  setBrandOnAlias: Alias;
  removeBrandFromAlias: Alias;
  createOneAlias: Alias;
  createManyAliases: Array<Alias>;
  updateOneAlias: Alias;
  updateManyAliases: UpdateManyResponse;
  deleteOneAlias: AliasDeleteResponse;
  deleteManyAliases: DeleteManyResponse;
  setUserTypeOnChecklist: Checklist;
  addChecklistItemsToChecklist: Checklist;
  setChecklistItemsOnChecklist: Checklist;
  addMeshLocationsToLocation: Location;
  setMeshLocationsOnLocation: Location;
  createOneLocation: Location;
  createManyLocations: Array<Location>;
  setMeshFabricTypeOnMesh: Mesh;
  setInspectedByOnMesh: Mesh;
  setBrandOnMesh: Mesh;
  setSupplierOnMesh: Mesh;
  setMeshSupplierOnMesh: Mesh;
  setPurchaseOrderTypeOnMesh: Mesh;
  setWashTypeOnMesh: Mesh;
  addMeshLocationsToMesh: Mesh;
  setMeshLocationsOnMesh: Mesh;
  addMeshConsumptionsToMesh: Mesh;
  setMeshConsumptionsOnMesh: Mesh;
  addSampleMeshesToMesh: Mesh;
  setSampleMeshesOnMesh: Mesh;
  removeMeshLocationsFromMesh: Mesh;
  removeMeshConsumptionsFromMesh: Mesh;
  removeSampleMeshesFromMesh: Mesh;
  updateOneMesh: Mesh;
  updateManyMeshes: UpdateManyResponse;
  deleteOneMesh: MeshDeleteResponse;
  deleteManyMeshes: DeleteManyResponse;
  createOneMesh: Mesh;
  setUserOnMeshConsumption: MeshConsumption;
  setMeshOnMeshConsumption: MeshConsumption;
  updateOneMeshConsumption: MeshConsumption;
  updateManyMeshConsumptions: UpdateManyResponse;
  createOneMeshConsumption: MeshConsumption;
  deleteOneMeshConsumption: MeshConsumption;
  setMeshOnMeshLocation: MeshLocation;
  setLocationOnMeshLocation: MeshLocation;
  createOneMeshLocation: MeshLocation;
  createManyMeshLocations: Array<MeshLocation>;
  updateOneMeshLocation: MeshLocation;
  updateManyMeshLocations: UpdateManyResponse;
  deleteOneMeshLocation: MeshLocationDeleteResponse;
  deleteManyMeshLocations: DeleteManyResponse;
  createOneSampleType: SampleType;
  createManySampleTypes: Array<SampleType>;
  updateOneSampleType: SampleType;
  updateManySampleTypes: UpdateManyResponse;
  deleteOneSampleType: SampleTypeDeleteResponse;
  deleteManySampleTypes: DeleteManyResponse;
  setCurrentStateOnSample: Sample;
  setConfectionOverseerOnSample: Sample;
  setSampleTypeOnSample: Sample;
  setBrandOnSample: Sample;
  addSampleMeshesToSample: Sample;
  setSampleMeshesOnSample: Sample;
  addSamplePhotosToSample: Sample;
  setSamplePhotosOnSample: Sample;
  createOneSample: Sample;
  createManySamples: Array<Sample>;
  updateOneSample: Sample;
  updateManySamples: UpdateManyResponse;
  deleteOneSample: SampleDeleteResponse;
  deleteManySamples: DeleteManyResponse;
  createOneSampleMesh: SampleMesh;
  createManySampleMeshes: Array<SampleMesh>;
  setMeshOnSampleMesh: SampleMesh;
  setSampleOnSampleMesh: SampleMesh;
  setUserOnSampleMesh: SampleMesh;
  updateOneSampleMesh: SampleMesh;
  updateManySampleMeshes: UpdateManyResponse;
  deleteOneSampleMesh: SampleMeshDeleteResponse;
  deleteManySampleMeshes: DeleteManyResponse;
  executeMeshTransfers: Array<MeshTransfer>;
  setMeshOnMeshTransfer: MeshTransfer;
  setDestinationOnMeshTransfer: MeshTransfer;
  removeMeshFromMeshTransfer: MeshTransfer;
  removeDestinationFromMeshTransfer: MeshTransfer;
  updateOneMeshTransfer: MeshTransfer;
  updateManyMeshTransfers: UpdateManyResponse;
  setSampleOnSamplePhoto: SamplePhoto;
  createOneSamplePhoto: SamplePhoto;
  addUsersToUserType: UserType;
  setUsersOnUserType: UserType;
  createOneUserType: UserType;
  createManyUserTypes: Array<UserType>;
  updateOneUserType: UserType;
  updateManyUserTypes: UpdateManyResponse;
  deleteOneUserType: UserTypeDeleteResponse;
  deleteManyUserTypes: DeleteManyResponse;
  addSupplierAliasesToSupplier: Supplier;
  setSupplierAliasesOnSupplier: Supplier;
  removeSupplierAliasesFromSupplier: Supplier;
  createOneSupplier: Supplier;
  createManySuppliers: Array<Supplier>;
  updateOneSupplier: Supplier;
  updateManySuppliers: UpdateManyResponse;
  setUserOnTicket: Ticket;
  addTicketCommentsToTicket: Ticket;
  setTicketCommentsOnTicket: Ticket;
  addTicketFilesToTicket: Ticket;
  setTicketFilesOnTicket: Ticket;
  createOneTicket: Ticket;
  setUserOnTicketComment: TicketComment;
  addTicketFilesToTicketComment: TicketComment;
  setTicketFilesOnTicketComment: TicketComment;
  createOneTicketComment: TicketComment;
  createOneIssue: Issue;
  createOneWashType: WashType;
  createManyWashTypes: Array<WashType>;
  updateOneWashType: WashType;
  updateManyWashTypes: UpdateManyResponse;
  deleteOneWashType: WashTypeDeleteResponse;
  deleteManyWashTypes: DeleteManyResponse;
  createOnePurchaseOrderType: PurchaseOrderType;
  createManyPurchaseOrderTypes: Array<PurchaseOrderType>;
  updateOnePurchaseOrderType: PurchaseOrderType;
  updateManyPurchaseOrderTypes: UpdateManyResponse;
  deleteOnePurchaseOrderType: PurchaseOrderTypeDeleteResponse;
  deleteManyPurchaseOrderTypes: DeleteManyResponse;
  setUserOnUserEvent: UserEvent;
  updateOneUserEvent: UserEvent;
  updateManyUserEvents: UpdateManyResponse;
  deleteOneUserEvent: UserEventDeleteResponse;
  deleteManyUserEvents: DeleteManyResponse;
  createOneUserEvent: UserEvent;
  createOnePoster: Poster;
  createManyPosters: Array<Poster>;
  updateOnePoster: Poster;
  updateManyPosters: UpdateManyResponse;
  deleteOnePoster: PosterDeleteResponse;
  deleteManyPosters: DeleteManyResponse;
  createOnePosterComposition: PosterComposition;
  createManyPosterCompositions: Array<PosterComposition>;
  updateOnePosterComposition: PosterComposition;
  updateManyPosterCompositions: UpdateManyResponse;
  deleteOnePosterComposition: PosterCompositionDeleteResponse;
  deleteManyPosterCompositions: DeleteManyResponse;
  createOnePosterFabricType: PosterFabricType;
  createManyPosterFabricTypes: Array<PosterFabricType>;
  updateOnePosterFabricType: PosterFabricType;
  updateManyPosterFabricTypes: UpdateManyResponse;
  deleteOnePosterFabricType: PosterFabricTypeDeleteResponse;
  deleteManyPosterFabricTypes: DeleteManyResponse;
  sendResetPasswordEmail: Scalars['Boolean'];
  sendRequestAccessEmail: Scalars['Boolean'];
  createOneQuote: Quote;
  createOneMeshFabricType: MeshFabricType;
  createManyMeshFabricTypes: Array<MeshFabricType>;
  updateOneMeshFabricType: MeshFabricType;
  updateManyMeshFabricTypes: UpdateManyResponse;
  deleteOneMeshFabricType: MeshFabricTypeDeleteResponse;
  deleteManyMeshFabricTypes: DeleteManyResponse;
  setSupplierOnSupplierAlias: SupplierAlias;
  removeSupplierFromSupplierAlias: SupplierAlias;
  createOneSupplierAlias: SupplierAlias;
  createManySupplierAliases: Array<SupplierAlias>;
  deleteOneSupplierAlias: SupplierAliasDeleteResponse;
  deleteManySupplierAliases: DeleteManyResponse;
  createOneNotification: Notification;
  createManyNotifications: Array<Notification>;
  updateOneNotification: Notification;
  updateManyNotifications: UpdateManyResponse;
  deleteOneNotification: NotificationDeleteResponse;
  deleteManyNotifications: DeleteManyResponse;
  createOnePosterSeason: PosterSeason;
  createManyPosterSeasons: Array<PosterSeason>;
  updateOnePosterSeason: PosterSeason;
  updateManyPosterSeasons: UpdateManyResponse;
  deleteOnePosterSeason: PosterSeasonDeleteResponse;
  deleteManyPosterSeasons: DeleteManyResponse;
};


export type MutationSetTypeOnUserArgs = {
  input: SetTypeOnUserInput;
};


export type MutationAddCheckinSampleStatesToUserArgs = {
  input: AddCheckinSampleStatesToUserInput;
};


export type MutationSetCheckinSampleStatesOnUserArgs = {
  input: SetCheckinSampleStatesOnUserInput;
};


export type MutationSetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCreateOneUserArgs = {
  input: CreateOneUserArgs;
};


export type MutationSetSampleStateTypeOnSampleStateArgs = {
  input: SetSampleStateTypeOnSampleStateInput;
};


export type MutationSetCheckinUserOnSampleStateArgs = {
  input: SetCheckinUserOnSampleStateInput;
};


export type MutationCreateOneSampleStateArgs = {
  input: CreateOneSampleStateInput;
};


export type MutationCreateManySampleStatesArgs = {
  input: CreateManySampleStatesInput;
};


export type MutationUpdateOneSampleStateArgs = {
  input: UpdateOneSampleStateInput;
};


export type MutationUpdateManySampleStatesArgs = {
  input: UpdateManySampleStatesInput;
};


export type MutationDeleteOneSampleStateArgs = {
  input: DeleteOneSampleStateInput;
};


export type MutationDeleteManySampleStatesArgs = {
  input: DeleteManySampleStatesInput;
};


export type MutationSetCommercialOnBrandArgs = {
  input: SetCommercialOnBrandInput;
};


export type MutationSetStylistOnBrandArgs = {
  input: SetStylistOnBrandInput;
};


export type MutationAddAliasesToBrandArgs = {
  input: AddAliasesToBrandInput;
};


export type MutationSetAliasesOnBrandArgs = {
  input: SetAliasesOnBrandInput;
};


export type MutationCreateOneBrandArgs = {
  input: CreateOneBrandInput;
};


export type MutationCreateManyBrandsArgs = {
  input: CreateManyBrandsInput;
};


export type MutationUpdateOneBrandArgs = {
  input: UpdateOneBrandInput;
};


export type MutationUpdateManyBrandsArgs = {
  input: UpdateManyBrandsInput;
};


export type MutationDeleteOneBrandArgs = {
  input: DeleteOneBrandInput;
};


export type MutationDeleteManyBrandsArgs = {
  input: DeleteManyBrandsInput;
};


export type MutationSetBrandOnAliasArgs = {
  input: SetBrandOnAliasInput;
};


export type MutationRemoveBrandFromAliasArgs = {
  input: RemoveBrandFromAliasInput;
};


export type MutationCreateOneAliasArgs = {
  input: CreateOneAliasInput;
};


export type MutationCreateManyAliasesArgs = {
  input: CreateManyAliasesInput;
};


export type MutationUpdateOneAliasArgs = {
  input: UpdateOneAliasInput;
};


export type MutationUpdateManyAliasesArgs = {
  input: UpdateManyAliasesInput;
};


export type MutationDeleteOneAliasArgs = {
  input: DeleteOneAliasInput;
};


export type MutationDeleteManyAliasesArgs = {
  input: DeleteManyAliasesInput;
};


export type MutationSetUserTypeOnChecklistArgs = {
  input: SetUserTypeOnChecklistInput;
};


export type MutationAddChecklistItemsToChecklistArgs = {
  input: AddChecklistItemsToChecklistInput;
};


export type MutationSetChecklistItemsOnChecklistArgs = {
  input: SetChecklistItemsOnChecklistInput;
};


export type MutationAddMeshLocationsToLocationArgs = {
  input: AddMeshLocationsToLocationInput;
};


export type MutationSetMeshLocationsOnLocationArgs = {
  input: SetMeshLocationsOnLocationInput;
};


export type MutationCreateOneLocationArgs = {
  input: CreateOneLocationInput;
};


export type MutationCreateManyLocationsArgs = {
  input: CreateManyLocationsInput;
};


export type MutationSetMeshFabricTypeOnMeshArgs = {
  input: SetMeshFabricTypeOnMeshInput;
};


export type MutationSetInspectedByOnMeshArgs = {
  input: SetInspectedByOnMeshInput;
};


export type MutationSetBrandOnMeshArgs = {
  input: SetBrandOnMeshInput;
};


export type MutationSetSupplierOnMeshArgs = {
  input: SetSupplierOnMeshInput;
};


export type MutationSetMeshSupplierOnMeshArgs = {
  input: SetMeshSupplierOnMeshInput;
};


export type MutationSetPurchaseOrderTypeOnMeshArgs = {
  input: SetPurchaseOrderTypeOnMeshInput;
};


export type MutationSetWashTypeOnMeshArgs = {
  input: SetWashTypeOnMeshInput;
};


export type MutationAddMeshLocationsToMeshArgs = {
  input: AddMeshLocationsToMeshInput;
};


export type MutationSetMeshLocationsOnMeshArgs = {
  input: SetMeshLocationsOnMeshInput;
};


export type MutationAddMeshConsumptionsToMeshArgs = {
  input: AddMeshConsumptionsToMeshInput;
};


export type MutationSetMeshConsumptionsOnMeshArgs = {
  input: SetMeshConsumptionsOnMeshInput;
};


export type MutationAddSampleMeshesToMeshArgs = {
  input: AddSampleMeshesToMeshInput;
};


export type MutationSetSampleMeshesOnMeshArgs = {
  input: SetSampleMeshesOnMeshInput;
};


export type MutationRemoveMeshLocationsFromMeshArgs = {
  input: RemoveMeshLocationsFromMeshInput;
};


export type MutationRemoveMeshConsumptionsFromMeshArgs = {
  input: RemoveMeshConsumptionsFromMeshInput;
};


export type MutationRemoveSampleMeshesFromMeshArgs = {
  input: RemoveSampleMeshesFromMeshInput;
};


export type MutationUpdateOneMeshArgs = {
  input: UpdateOneMeshInput;
};


export type MutationUpdateManyMeshesArgs = {
  input: UpdateManyMeshesInput;
};


export type MutationDeleteOneMeshArgs = {
  input: DeleteOneMeshInput;
};


export type MutationDeleteManyMeshesArgs = {
  input: DeleteManyMeshesInput;
};


export type MutationCreateOneMeshArgs = {
  input: CreateOneMeshInput;
};


export type MutationSetUserOnMeshConsumptionArgs = {
  input: SetUserOnMeshConsumptionInput;
};


export type MutationSetMeshOnMeshConsumptionArgs = {
  input: SetMeshOnMeshConsumptionInput;
};


export type MutationUpdateOneMeshConsumptionArgs = {
  input: UpdateOneMeshConsumptionInput;
};


export type MutationUpdateManyMeshConsumptionsArgs = {
  input: UpdateManyMeshConsumptionsInput;
};


export type MutationCreateOneMeshConsumptionArgs = {
  input: CreateOneMeshConsumptionInput;
};


export type MutationDeleteOneMeshConsumptionArgs = {
  input: DeleteOneMeshConsumptionInput;
};


export type MutationSetMeshOnMeshLocationArgs = {
  input: SetMeshOnMeshLocationInput;
};


export type MutationSetLocationOnMeshLocationArgs = {
  input: SetLocationOnMeshLocationInput;
};


export type MutationCreateOneMeshLocationArgs = {
  input: CreateOneMeshLocationInput;
};


export type MutationCreateManyMeshLocationsArgs = {
  input: CreateManyMeshLocationsInput;
};


export type MutationUpdateOneMeshLocationArgs = {
  input: UpdateOneMeshLocationInput;
};


export type MutationUpdateManyMeshLocationsArgs = {
  input: UpdateManyMeshLocationsInput;
};


export type MutationDeleteOneMeshLocationArgs = {
  input: DeleteOneMeshLocationInput;
};


export type MutationDeleteManyMeshLocationsArgs = {
  input: DeleteManyMeshLocationsInput;
};


export type MutationCreateOneSampleTypeArgs = {
  input: CreateOneSampleTypeInput;
};


export type MutationCreateManySampleTypesArgs = {
  input: CreateManySampleTypesInput;
};


export type MutationUpdateOneSampleTypeArgs = {
  input: UpdateOneSampleTypeInput;
};


export type MutationUpdateManySampleTypesArgs = {
  input: UpdateManySampleTypesInput;
};


export type MutationDeleteOneSampleTypeArgs = {
  input: DeleteOneSampleTypeInput;
};


export type MutationDeleteManySampleTypesArgs = {
  input: DeleteManySampleTypesInput;
};


export type MutationSetCurrentStateOnSampleArgs = {
  input: SetCurrentStateOnSampleInput;
};


export type MutationSetConfectionOverseerOnSampleArgs = {
  input: SetConfectionOverseerOnSampleInput;
};


export type MutationSetSampleTypeOnSampleArgs = {
  input: SetSampleTypeOnSampleInput;
};


export type MutationSetBrandOnSampleArgs = {
  input: SetBrandOnSampleInput;
};


export type MutationAddSampleMeshesToSampleArgs = {
  input: AddSampleMeshesToSampleInput;
};


export type MutationSetSampleMeshesOnSampleArgs = {
  input: SetSampleMeshesOnSampleInput;
};


export type MutationAddSamplePhotosToSampleArgs = {
  input: AddSamplePhotosToSampleInput;
};


export type MutationSetSamplePhotosOnSampleArgs = {
  input: SetSamplePhotosOnSampleInput;
};


export type MutationCreateOneSampleArgs = {
  input: CreateOneSampleInput;
};


export type MutationCreateManySamplesArgs = {
  input: CreateManySamplesInput;
};


export type MutationUpdateOneSampleArgs = {
  input: UpdateOneSampleInput;
};


export type MutationUpdateManySamplesArgs = {
  input: UpdateManySamplesInput;
};


export type MutationDeleteOneSampleArgs = {
  input: DeleteOneSampleInput;
};


export type MutationDeleteManySamplesArgs = {
  input: DeleteManySamplesInput;
};


export type MutationCreateOneSampleMeshArgs = {
  input: CreateOneSampleMeshInput;
};


export type MutationCreateManySampleMeshesArgs = {
  input: CreateManySampleMeshesInput;
};


export type MutationSetMeshOnSampleMeshArgs = {
  input: SetMeshOnSampleMeshInput;
};


export type MutationSetSampleOnSampleMeshArgs = {
  input: SetSampleOnSampleMeshInput;
};


export type MutationSetUserOnSampleMeshArgs = {
  input: SetUserOnSampleMeshInput;
};


export type MutationUpdateOneSampleMeshArgs = {
  input: UpdateOneSampleMeshInput;
};


export type MutationUpdateManySampleMeshesArgs = {
  input: UpdateManySampleMeshesInput;
};


export type MutationDeleteOneSampleMeshArgs = {
  input: DeleteOneSampleMeshInput;
};


export type MutationDeleteManySampleMeshesArgs = {
  input: DeleteManySampleMeshesInput;
};


export type MutationExecuteMeshTransfersArgs = {
  input: ExecuteMeshTransfers;
};


export type MutationSetMeshOnMeshTransferArgs = {
  input: SetMeshOnMeshTransferInput;
};


export type MutationSetDestinationOnMeshTransferArgs = {
  input: SetDestinationOnMeshTransferInput;
};


export type MutationRemoveMeshFromMeshTransferArgs = {
  input: RemoveMeshFromMeshTransferInput;
};


export type MutationRemoveDestinationFromMeshTransferArgs = {
  input: RemoveDestinationFromMeshTransferInput;
};


export type MutationUpdateOneMeshTransferArgs = {
  input: UpdateOneMeshTransferInput;
};


export type MutationUpdateManyMeshTransfersArgs = {
  input: UpdateManyMeshTransfersInput;
};


export type MutationSetSampleOnSamplePhotoArgs = {
  input: SetSampleOnSamplePhotoInput;
};


export type MutationCreateOneSamplePhotoArgs = {
  input: CreateOneSamplePhotoInput;
};


export type MutationAddUsersToUserTypeArgs = {
  input: AddUsersToUserTypeInput;
};


export type MutationSetUsersOnUserTypeArgs = {
  input: SetUsersOnUserTypeInput;
};


export type MutationCreateOneUserTypeArgs = {
  input: CreateOneUserTypeInput;
};


export type MutationCreateManyUserTypesArgs = {
  input: CreateManyUserTypesInput;
};


export type MutationUpdateOneUserTypeArgs = {
  input: UpdateOneUserTypeInput;
};


export type MutationUpdateManyUserTypesArgs = {
  input: UpdateManyUserTypesInput;
};


export type MutationDeleteOneUserTypeArgs = {
  input: DeleteOneUserTypeInput;
};


export type MutationDeleteManyUserTypesArgs = {
  input: DeleteManyUserTypesInput;
};


export type MutationAddSupplierAliasesToSupplierArgs = {
  input: AddSupplierAliasesToSupplierInput;
};


export type MutationSetSupplierAliasesOnSupplierArgs = {
  input: SetSupplierAliasesOnSupplierInput;
};


export type MutationRemoveSupplierAliasesFromSupplierArgs = {
  input: RemoveSupplierAliasesFromSupplierInput;
};


export type MutationCreateOneSupplierArgs = {
  input: CreateOneSupplierInput;
};


export type MutationCreateManySuppliersArgs = {
  input: CreateManySuppliersInput;
};


export type MutationUpdateOneSupplierArgs = {
  input: UpdateOneSupplierInput;
};


export type MutationUpdateManySuppliersArgs = {
  input: UpdateManySuppliersInput;
};


export type MutationSetUserOnTicketArgs = {
  input: SetUserOnTicketInput;
};


export type MutationAddTicketCommentsToTicketArgs = {
  input: AddTicketCommentsToTicketInput;
};


export type MutationSetTicketCommentsOnTicketArgs = {
  input: SetTicketCommentsOnTicketInput;
};


export type MutationAddTicketFilesToTicketArgs = {
  input: AddTicketFilesToTicketInput;
};


export type MutationSetTicketFilesOnTicketArgs = {
  input: SetTicketFilesOnTicketInput;
};


export type MutationCreateOneTicketArgs = {
  input: CreateOneTicketInput;
};


export type MutationSetUserOnTicketCommentArgs = {
  input: SetUserOnTicketCommentInput;
};


export type MutationAddTicketFilesToTicketCommentArgs = {
  input: AddTicketFilesToTicketCommentInput;
};


export type MutationSetTicketFilesOnTicketCommentArgs = {
  input: SetTicketFilesOnTicketCommentInput;
};


export type MutationCreateOneTicketCommentArgs = {
  input: CreateOneTicketCommentInput;
};


export type MutationCreateOneIssueArgs = {
  input: CreateOneIssueInput;
};


export type MutationCreateOneWashTypeArgs = {
  input: CreateOneWashTypeInput;
};


export type MutationCreateManyWashTypesArgs = {
  input: CreateManyWashTypesInput;
};


export type MutationUpdateOneWashTypeArgs = {
  input: UpdateOneWashTypeInput;
};


export type MutationUpdateManyWashTypesArgs = {
  input: UpdateManyWashTypesInput;
};


export type MutationDeleteOneWashTypeArgs = {
  input: DeleteOneWashTypeInput;
};


export type MutationDeleteManyWashTypesArgs = {
  input: DeleteManyWashTypesInput;
};


export type MutationCreateOnePurchaseOrderTypeArgs = {
  input: CreateOnePurchaseOrderTypeInput;
};


export type MutationCreateManyPurchaseOrderTypesArgs = {
  input: CreateManyPurchaseOrderTypesInput;
};


export type MutationUpdateOnePurchaseOrderTypeArgs = {
  input: UpdateOnePurchaseOrderTypeInput;
};


export type MutationUpdateManyPurchaseOrderTypesArgs = {
  input: UpdateManyPurchaseOrderTypesInput;
};


export type MutationDeleteOnePurchaseOrderTypeArgs = {
  input: DeleteOnePurchaseOrderTypeInput;
};


export type MutationDeleteManyPurchaseOrderTypesArgs = {
  input: DeleteManyPurchaseOrderTypesInput;
};


export type MutationSetUserOnUserEventArgs = {
  input: SetUserOnUserEventInput;
};


export type MutationUpdateOneUserEventArgs = {
  input: UpdateOneUserEventInput;
};


export type MutationUpdateManyUserEventsArgs = {
  input: UpdateManyUserEventsInput;
};


export type MutationDeleteOneUserEventArgs = {
  input: DeleteOneUserEventInput;
};


export type MutationDeleteManyUserEventsArgs = {
  input: DeleteManyUserEventsInput;
};


export type MutationCreateOneUserEventArgs = {
  input: CreateOneUserEventInput;
};


export type MutationCreateOnePosterArgs = {
  input: CreateOnePosterInput;
};


export type MutationCreateManyPostersArgs = {
  input: CreateManyPostersInput;
};


export type MutationUpdateOnePosterArgs = {
  input: UpdateOnePosterInput;
};


export type MutationUpdateManyPostersArgs = {
  input: UpdateManyPostersInput;
};


export type MutationDeleteOnePosterArgs = {
  input: DeleteOnePosterInput;
};


export type MutationDeleteManyPostersArgs = {
  input: DeleteManyPostersInput;
};


export type MutationCreateOnePosterCompositionArgs = {
  input: CreateOnePosterCompositionInput;
};


export type MutationCreateManyPosterCompositionsArgs = {
  input: CreateManyPosterCompositionsInput;
};


export type MutationUpdateOnePosterCompositionArgs = {
  input: UpdateOnePosterCompositionInput;
};


export type MutationUpdateManyPosterCompositionsArgs = {
  input: UpdateManyPosterCompositionsInput;
};


export type MutationDeleteOnePosterCompositionArgs = {
  input: DeleteOnePosterCompositionInput;
};


export type MutationDeleteManyPosterCompositionsArgs = {
  input: DeleteManyPosterCompositionsInput;
};


export type MutationCreateOnePosterFabricTypeArgs = {
  input: CreateOnePosterFabricTypeInput;
};


export type MutationCreateManyPosterFabricTypesArgs = {
  input: CreateManyPosterFabricTypesInput;
};


export type MutationUpdateOnePosterFabricTypeArgs = {
  input: UpdateOnePosterFabricTypeInput;
};


export type MutationUpdateManyPosterFabricTypesArgs = {
  input: UpdateManyPosterFabricTypesInput;
};


export type MutationDeleteOnePosterFabricTypeArgs = {
  input: DeleteOnePosterFabricTypeInput;
};


export type MutationDeleteManyPosterFabricTypesArgs = {
  input: DeleteManyPosterFabricTypesInput;
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendRequestAccessEmailArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateOneQuoteArgs = {
  input: CreateOneQuoteInput;
};


export type MutationCreateOneMeshFabricTypeArgs = {
  input: CreateOneMeshFabricTypeInput;
};


export type MutationCreateManyMeshFabricTypesArgs = {
  input: CreateManyMeshFabricTypesInput;
};


export type MutationUpdateOneMeshFabricTypeArgs = {
  input: UpdateOneMeshFabricTypeInput;
};


export type MutationUpdateManyMeshFabricTypesArgs = {
  input: UpdateManyMeshFabricTypesInput;
};


export type MutationDeleteOneMeshFabricTypeArgs = {
  input: DeleteOneMeshFabricTypeInput;
};


export type MutationDeleteManyMeshFabricTypesArgs = {
  input: DeleteManyMeshFabricTypesInput;
};


export type MutationSetSupplierOnSupplierAliasArgs = {
  input: SetSupplierOnSupplierAliasInput;
};


export type MutationRemoveSupplierFromSupplierAliasArgs = {
  input: RemoveSupplierFromSupplierAliasInput;
};


export type MutationCreateOneSupplierAliasArgs = {
  input: CreateOneSupplierAliasInput;
};


export type MutationCreateManySupplierAliasesArgs = {
  input: CreateManySupplierAliasesInput;
};


export type MutationDeleteOneSupplierAliasArgs = {
  input: DeleteOneSupplierAliasInput;
};


export type MutationDeleteManySupplierAliasesArgs = {
  input: DeleteManySupplierAliasesInput;
};


export type MutationCreateOneNotificationArgs = {
  input: CreateOneNotificationInput;
};


export type MutationCreateManyNotificationsArgs = {
  input: CreateManyNotificationsInput;
};


export type MutationUpdateOneNotificationArgs = {
  input: UpdateOneNotificationInput;
};


export type MutationUpdateManyNotificationsArgs = {
  input: UpdateManyNotificationsInput;
};


export type MutationDeleteOneNotificationArgs = {
  input: DeleteOneNotificationInput;
};


export type MutationDeleteManyNotificationsArgs = {
  input: DeleteManyNotificationsInput;
};


export type MutationCreateOnePosterSeasonArgs = {
  input: CreateOnePosterSeasonInput;
};


export type MutationCreateManyPosterSeasonsArgs = {
  input: CreateManyPosterSeasonsInput;
};


export type MutationUpdateOnePosterSeasonArgs = {
  input: UpdateOnePosterSeasonInput;
};


export type MutationUpdateManyPosterSeasonsArgs = {
  input: UpdateManyPosterSeasonsInput;
};


export type MutationDeleteOnePosterSeasonArgs = {
  input: DeleteOnePosterSeasonInput;
};


export type MutationDeleteManyPosterSeasonsArgs = {
  input: DeleteManyPosterSeasonsInput;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  message: Scalars['String'];
  isRead: Scalars['Boolean'];
  kind: NotificationKinds;
  priority: NotificationPriorities;
  tab: Scalars['String'];
  isOpen: Scalars['Boolean'];
  templateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationAggregateGroupBy = {
  __typename?: 'NotificationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationAvgAggregate = {
  __typename?: 'NotificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Notification>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type NotificationCountAggregate = {
  __typename?: 'NotificationCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type NotificationDeleteFilter = {
  and?: Maybe<Array<NotificationDeleteFilter>>;
  or?: Maybe<Array<NotificationDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type NotificationDeleteResponse = {
  __typename?: 'NotificationDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<NotificationKinds>;
  priority?: Maybe<NotificationPriorities>;
  tab?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationFilter = {
  and?: Maybe<Array<NotificationFilter>>;
  or?: Maybe<Array<NotificationFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export enum NotificationKinds {
  Regular = 'REGULAR',
  StickyBottomLeft = 'STICKY_BOTTOM_LEFT'
}

export type NotificationMaxAggregate = {
  __typename?: 'NotificationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationMinAggregate = {
  __typename?: 'NotificationMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export enum NotificationPriorities {
  Low = 'LOW',
  High = 'HIGH'
}

export type NotificationSort = {
  field: NotificationSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum NotificationSortFields {
  Id = 'id'
}

export type NotificationSumAggregate = {
  __typename?: 'NotificationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type NotificationUpdateFilter = {
  and?: Maybe<Array<NotificationUpdateFilter>>;
  or?: Maybe<Array<NotificationUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type NumberFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  between?: Maybe<NumberFieldComparisonBetween>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: Maybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: Maybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type Poster = {
  __typename?: 'Poster';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  reference: Scalars['String'];
  photo: Scalars['String'];
  cover: Scalars['String'];
  specifications: Scalars['String'];
  story: Scalars['String'];
  weight: Scalars['Float'];
  type: PosterTypes;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  season: PosterSeason;
  fabricType: PosterFabricType;
  composition: PosterComposition;
};

export type PosterAggregateGroupBy = {
  __typename?: 'PosterAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  type?: Maybe<PosterTypes>;
};

export type PosterAvgAggregate = {
  __typename?: 'PosterAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PosterComposition = {
  __typename?: 'PosterComposition';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PosterCompositionAggregateGroupBy = {
  __typename?: 'PosterCompositionAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterCompositionAvgAggregate = {
  __typename?: 'PosterCompositionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterCompositionConnection = {
  __typename?: 'PosterCompositionConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<PosterComposition>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterCompositionCountAggregate = {
  __typename?: 'PosterCompositionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PosterCompositionDeleteFilter = {
  and?: Maybe<Array<PosterCompositionDeleteFilter>>;
  or?: Maybe<Array<PosterCompositionDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterCompositionDeleteResponse = {
  __typename?: 'PosterCompositionDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PosterCompositionFilter = {
  and?: Maybe<Array<PosterCompositionFilter>>;
  or?: Maybe<Array<PosterCompositionFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterCompositionMaxAggregate = {
  __typename?: 'PosterCompositionMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterCompositionMinAggregate = {
  __typename?: 'PosterCompositionMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterCompositionSort = {
  field: PosterCompositionSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PosterCompositionSortFields {
  Id = 'id',
  Name = 'name'
}

export type PosterCompositionSumAggregate = {
  __typename?: 'PosterCompositionSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterCompositionUpdateFilter = {
  and?: Maybe<Array<PosterCompositionUpdateFilter>>;
  or?: Maybe<Array<PosterCompositionUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterConnection = {
  __typename?: 'PosterConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Poster>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterCountAggregate = {
  __typename?: 'PosterCountAggregate';
  id?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type PosterDeleteFilter = {
  and?: Maybe<Array<PosterDeleteFilter>>;
  or?: Maybe<Array<PosterDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  reference?: Maybe<StringFieldComparison>;
  weight?: Maybe<NumberFieldComparison>;
  type?: Maybe<PosterTypesFilterComparison>;
};

export type PosterDeleteResponse = {
  __typename?: 'PosterDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  specifications?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  type?: Maybe<PosterTypes>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PosterFabricType = {
  __typename?: 'PosterFabricType';
  id: Scalars['ID'];
  name: Scalars['String'];
  posterType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PosterFabricTypeAggregateGroupBy = {
  __typename?: 'PosterFabricTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
};

export type PosterFabricTypeAvgAggregate = {
  __typename?: 'PosterFabricTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterFabricTypeConnection = {
  __typename?: 'PosterFabricTypeConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<PosterFabricType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterFabricTypeCountAggregate = {
  __typename?: 'PosterFabricTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  posterType?: Maybe<Scalars['Int']>;
};

export type PosterFabricTypeDeleteFilter = {
  and?: Maybe<Array<PosterFabricTypeDeleteFilter>>;
  or?: Maybe<Array<PosterFabricTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  posterType?: Maybe<StringFieldComparison>;
};

export type PosterFabricTypeDeleteResponse = {
  __typename?: 'PosterFabricTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PosterFabricTypeFilter = {
  and?: Maybe<Array<PosterFabricTypeFilter>>;
  or?: Maybe<Array<PosterFabricTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  posterType?: Maybe<StringFieldComparison>;
};

export type PosterFabricTypeMaxAggregate = {
  __typename?: 'PosterFabricTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
};

export type PosterFabricTypeMinAggregate = {
  __typename?: 'PosterFabricTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
};

export type PosterFabricTypeSort = {
  field: PosterFabricTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PosterFabricTypeSortFields {
  Id = 'id',
  Name = 'name',
  PosterType = 'posterType'
}

export type PosterFabricTypeSumAggregate = {
  __typename?: 'PosterFabricTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterFabricTypeUpdateFilter = {
  and?: Maybe<Array<PosterFabricTypeUpdateFilter>>;
  or?: Maybe<Array<PosterFabricTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  posterType?: Maybe<StringFieldComparison>;
};

export type PosterFilter = {
  and?: Maybe<Array<PosterFilter>>;
  or?: Maybe<Array<PosterFilter>>;
  id?: Maybe<IdFilterComparison>;
  reference?: Maybe<StringFieldComparison>;
  weight?: Maybe<NumberFieldComparison>;
  type?: Maybe<PosterTypesFilterComparison>;
  composition?: Maybe<PosterFilterPosterCompositionFilter>;
  fabricType?: Maybe<PosterFilterPosterFabricTypeFilter>;
  season?: Maybe<PosterFilterPosterSeasonFilter>;
};

export type PosterFilterPosterCompositionFilter = {
  and?: Maybe<Array<PosterFilterPosterCompositionFilter>>;
  or?: Maybe<Array<PosterFilterPosterCompositionFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterFilterPosterFabricTypeFilter = {
  and?: Maybe<Array<PosterFilterPosterFabricTypeFilter>>;
  or?: Maybe<Array<PosterFilterPosterFabricTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  posterType?: Maybe<StringFieldComparison>;
};

export type PosterFilterPosterSeasonFilter = {
  and?: Maybe<Array<PosterFilterPosterSeasonFilter>>;
  or?: Maybe<Array<PosterFilterPosterSeasonFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterMaxAggregate = {
  __typename?: 'PosterMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  type?: Maybe<PosterTypes>;
};

export type PosterMinAggregate = {
  __typename?: 'PosterMinAggregate';
  id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  type?: Maybe<PosterTypes>;
};

export type PosterSeason = {
  __typename?: 'PosterSeason';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PosterSeasonAggregateGroupBy = {
  __typename?: 'PosterSeasonAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterSeasonAvgAggregate = {
  __typename?: 'PosterSeasonAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterSeasonConnection = {
  __typename?: 'PosterSeasonConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<PosterSeason>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PosterSeasonCountAggregate = {
  __typename?: 'PosterSeasonCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PosterSeasonDeleteFilter = {
  and?: Maybe<Array<PosterSeasonDeleteFilter>>;
  or?: Maybe<Array<PosterSeasonDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterSeasonDeleteResponse = {
  __typename?: 'PosterSeasonDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PosterSeasonFilter = {
  and?: Maybe<Array<PosterSeasonFilter>>;
  or?: Maybe<Array<PosterSeasonFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterSeasonMaxAggregate = {
  __typename?: 'PosterSeasonMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterSeasonMinAggregate = {
  __typename?: 'PosterSeasonMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PosterSeasonSort = {
  field: PosterSeasonSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PosterSeasonSortFields {
  Id = 'id',
  Name = 'name'
}

export type PosterSeasonSumAggregate = {
  __typename?: 'PosterSeasonSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PosterSeasonUpdateFilter = {
  and?: Maybe<Array<PosterSeasonUpdateFilter>>;
  or?: Maybe<Array<PosterSeasonUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PosterSort = {
  field: PosterSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PosterSortFields {
  Id = 'id',
  Reference = 'reference',
  Weight = 'weight',
  Type = 'type'
}

export type PosterSumAggregate = {
  __typename?: 'PosterSumAggregate';
  id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export enum PosterTypes {
  Mesh = 'MESH',
  Finishing = 'FINISHING'
}

export type PosterTypesFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<PosterTypes>;
  neq?: Maybe<PosterTypes>;
  gt?: Maybe<PosterTypes>;
  gte?: Maybe<PosterTypes>;
  lt?: Maybe<PosterTypes>;
  lte?: Maybe<PosterTypes>;
  like?: Maybe<PosterTypes>;
  notLike?: Maybe<PosterTypes>;
  iLike?: Maybe<PosterTypes>;
  notILike?: Maybe<PosterTypes>;
  in?: Maybe<Array<PosterTypes>>;
  notIn?: Maybe<Array<PosterTypes>>;
};

export type PosterUpdateFilter = {
  and?: Maybe<Array<PosterUpdateFilter>>;
  or?: Maybe<Array<PosterUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  reference?: Maybe<StringFieldComparison>;
  weight?: Maybe<NumberFieldComparison>;
  type?: Maybe<PosterTypesFilterComparison>;
};

export type PurchaseOrderType = {
  __typename?: 'PurchaseOrderType';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PurchaseOrderTypeAggregateGroupBy = {
  __typename?: 'PurchaseOrderTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseOrderTypeAvgAggregate = {
  __typename?: 'PurchaseOrderTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PurchaseOrderTypeConnection = {
  __typename?: 'PurchaseOrderTypeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<PurchaseOrderTypeEdge>;
};

export type PurchaseOrderTypeCountAggregate = {
  __typename?: 'PurchaseOrderTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderTypeDeleteFilter = {
  and?: Maybe<Array<PurchaseOrderTypeDeleteFilter>>;
  or?: Maybe<Array<PurchaseOrderTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PurchaseOrderTypeDeleteResponse = {
  __typename?: 'PurchaseOrderTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PurchaseOrderTypeEdge = {
  __typename?: 'PurchaseOrderTypeEdge';
  /** The node containing the PurchaseOrderType */
  node: PurchaseOrderType;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type PurchaseOrderTypeFilter = {
  and?: Maybe<Array<PurchaseOrderTypeFilter>>;
  or?: Maybe<Array<PurchaseOrderTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type PurchaseOrderTypeMaxAggregate = {
  __typename?: 'PurchaseOrderTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseOrderTypeMinAggregate = {
  __typename?: 'PurchaseOrderTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseOrderTypeSort = {
  field: PurchaseOrderTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PurchaseOrderTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type PurchaseOrderTypeSumAggregate = {
  __typename?: 'PurchaseOrderTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PurchaseOrderTypeUpdateFilter = {
  and?: Maybe<Array<PurchaseOrderTypeUpdateFilter>>;
  or?: Maybe<Array<PurchaseOrderTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  users: UserConnection;
  me: User;
  sampleState?: Maybe<SampleState>;
  sampleStates: SampleStateConnection;
  brand?: Maybe<Brand>;
  brands: BrandConnection;
  alias?: Maybe<Alias>;
  aliases: AliasConnection;
  checklist?: Maybe<Checklist>;
  checklists: ChecklistConnection;
  checklistItem?: Maybe<ChecklistItem>;
  checklistItems: ChecklistItemConnection;
  commonIssues: CommonIssueConnection;
  location?: Maybe<Location>;
  locations: LocationConnection;
  mesh?: Maybe<Mesh>;
  meshes: MeshConnection;
  meshConsumption?: Maybe<MeshConsumption>;
  meshConsumptions: MeshConsumptionConnection;
  meshLocation?: Maybe<MeshLocation>;
  meshLocations: MeshLocationConnection;
  sampleType?: Maybe<SampleType>;
  sampleTypes: SampleTypeConnection;
  sample?: Maybe<Sample>;
  samples: SampleConnection;
  sampleMesh?: Maybe<SampleMesh>;
  sampleMeshes: SampleMeshConnection;
  meshTransfer?: Maybe<MeshTransfer>;
  meshTransfers: MeshTransferConnection;
  supportTopic?: Maybe<SupportTopic>;
  supportTopics: SupportTopicConnection;
  userType?: Maybe<UserType>;
  userTypes: UserTypeConnection;
  supplier?: Maybe<Supplier>;
  suppliers: SupplierConnection;
  tickets: TicketConnection;
  ticket?: Maybe<Ticket>;
  ticketComment?: Maybe<TicketComment>;
  ticketComments: TicketCommentConnection;
  washType?: Maybe<WashType>;
  washTypes: WashTypeConnection;
  purchaseOrderType?: Maybe<PurchaseOrderType>;
  purchaseOrderTypes: PurchaseOrderTypeConnection;
  configuration?: Maybe<Configuration>;
  configurations: ConfigurationConnection;
  userEvent?: Maybe<UserEvent>;
  userEvents: UserEventConnection;
  status: Scalars['String'];
  poster?: Maybe<Poster>;
  posters: PosterConnection;
  posterComposition?: Maybe<PosterComposition>;
  posterCompositions: PosterCompositionConnection;
  posterFabricType?: Maybe<PosterFabricType>;
  posterFabricTypes: PosterFabricTypeConnection;
  autocompleteMeshes: Array<AutocompleteMesh>;
  meshFabricType?: Maybe<MeshFabricType>;
  meshFabricTypes: MeshFabricTypeConnection;
  supplierAlias?: Maybe<SupplierAlias>;
  supplierAliases: SupplierAliasConnection;
  notification?: Maybe<Notification>;
  notifications: NotificationConnection;
  posterSeason?: Maybe<PosterSeason>;
  posterSeasons: PosterSeasonConnection;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<UserFilter>;
  sorting?: Maybe<Array<UserSort>>;
};


export type QuerySampleStateArgs = {
  id: Scalars['ID'];
};


export type QuerySampleStatesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SampleStateFilter>;
  sorting?: Maybe<Array<SampleStateSort>>;
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<BrandFilter>;
  sorting?: Maybe<Array<BrandSort>>;
};


export type QueryAliasArgs = {
  id: Scalars['ID'];
};


export type QueryAliasesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<AliasFilter>;
  sorting?: Maybe<Array<AliasSort>>;
};


export type QueryChecklistArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ChecklistFilter>;
  sorting?: Maybe<Array<ChecklistSort>>;
};


export type QueryChecklistItemArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistItemsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ChecklistItemFilter>;
  sorting?: Maybe<Array<ChecklistItemSort>>;
};


export type QueryCommonIssuesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<CommonIssueFilter>;
  sorting?: Maybe<Array<CommonIssueSort>>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<LocationFilter>;
  sorting?: Maybe<Array<LocationSort>>;
};


export type QueryMeshArgs = {
  id: Scalars['ID'];
};


export type QueryMeshesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<MeshFilter>;
  sorting?: Maybe<Array<MeshSort>>;
};


export type QueryMeshConsumptionArgs = {
  id: Scalars['ID'];
};


export type QueryMeshConsumptionsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<MeshConsumptionFilter>;
  sorting?: Maybe<Array<MeshConsumptionSort>>;
};


export type QueryMeshLocationArgs = {
  id: Scalars['ID'];
};


export type QueryMeshLocationsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<MeshLocationFilter>;
  sorting?: Maybe<Array<MeshLocationSort>>;
};


export type QuerySampleTypeArgs = {
  id: Scalars['ID'];
};


export type QuerySampleTypesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SampleTypeFilter>;
  sorting?: Maybe<Array<SampleTypeSort>>;
};


export type QuerySampleArgs = {
  id: Scalars['ID'];
};


export type QuerySamplesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<SampleFilter>;
  sorting?: Maybe<Array<SampleSort>>;
};


export type QuerySampleMeshArgs = {
  id: Scalars['ID'];
};


export type QuerySampleMeshesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SampleMeshFilter>;
  sorting?: Maybe<Array<SampleMeshSort>>;
};


export type QueryMeshTransferArgs = {
  id: Scalars['ID'];
};


export type QueryMeshTransfersArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<MeshTransferFilter>;
  sorting?: Maybe<Array<MeshTransferSort>>;
};


export type QuerySupportTopicArgs = {
  id: Scalars['ID'];
};


export type QuerySupportTopicsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SupportTopicFilter>;
  sorting?: Maybe<Array<SupportTopicSort>>;
};


export type QueryUserTypeArgs = {
  id: Scalars['ID'];
};


export type QueryUserTypesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<UserTypeFilter>;
  sorting?: Maybe<Array<UserTypeSort>>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QuerySuppliersArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SupplierFilter>;
  sorting?: Maybe<Array<SupplierSort>>;
};


export type QueryTicketsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TicketFilter>;
  sorting?: Maybe<Array<TicketSort>>;
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};


export type QueryTicketCommentArgs = {
  id: Scalars['ID'];
};


export type QueryTicketCommentsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TicketCommentFilter>;
  sorting?: Maybe<Array<TicketCommentSort>>;
};


export type QueryWashTypeArgs = {
  id: Scalars['ID'];
};


export type QueryWashTypesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<WashTypeFilter>;
  sorting?: Maybe<Array<WashTypeSort>>;
};


export type QueryPurchaseOrderTypeArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderTypesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<PurchaseOrderTypeFilter>;
  sorting?: Maybe<Array<PurchaseOrderTypeSort>>;
};


export type QueryConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryConfigurationsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<ConfigurationFilter>;
  sorting?: Maybe<Array<ConfigurationSort>>;
};


export type QueryUserEventArgs = {
  id: Scalars['ID'];
};


export type QueryUserEventsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<UserEventFilter>;
  sorting?: Maybe<Array<UserEventSort>>;
};


export type QueryPosterArgs = {
  id: Scalars['ID'];
};


export type QueryPostersArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PosterFilter>;
  sorting?: Maybe<Array<PosterSort>>;
};


export type QueryPosterCompositionArgs = {
  id: Scalars['ID'];
};


export type QueryPosterCompositionsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PosterCompositionFilter>;
  sorting?: Maybe<Array<PosterCompositionSort>>;
};


export type QueryPosterFabricTypeArgs = {
  id: Scalars['ID'];
};


export type QueryPosterFabricTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PosterFabricTypeFilter>;
  sorting?: Maybe<Array<PosterFabricTypeSort>>;
};


export type QueryAutocompleteMeshesArgs = {
  fullPurchaseOrder: Scalars['String'];
};


export type QueryMeshFabricTypeArgs = {
  id: Scalars['ID'];
};


export type QueryMeshFabricTypesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<MeshFabricTypeFilter>;
  sorting?: Maybe<Array<MeshFabricTypeSort>>;
};


export type QuerySupplierAliasArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierAliasesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<SupplierAliasFilter>;
  sorting?: Maybe<Array<SupplierAliasSort>>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<NotificationFilter>;
  sorting?: Maybe<Array<NotificationSort>>;
};


export type QueryPosterSeasonArgs = {
  id: Scalars['ID'];
};


export type QueryPosterSeasonsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PosterSeasonFilter>;
  sorting?: Maybe<Array<PosterSeasonSort>>;
};

export type Quote = {
  __typename?: 'Quote';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  company: Scalars['String'];
  brand: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  requestType: Array<Scalars['String']>;
  categories: Array<Scalars['String']>;
  productGroup: Array<Scalars['String']>;
  estimatedQuantityYear?: Maybe<Scalars['Float']>;
  orderQuantityColor?: Maybe<Scalars['Float']>;
  specification: Scalars['String'];
  attachments?: Maybe<Array<Scalars['Upload']>>;
};

export type RemoveBrandFromAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveDestinationFromMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMeshConsumptionsFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMeshFromMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMeshLocationsFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSampleMeshesFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSupplierAliasesFromSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSupplierFromSupplierAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type Sample = {
  __typename?: 'Sample';
  id: Scalars['ID'];
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate: Scalars['DateTime'];
  sketch: Scalars['String'];
  fastTrack: Scalars['Boolean'];
  hasMesh: Scalars['Boolean'];
  hasMeshUpdatedAt: Scalars['String'];
  shippingDate: Scalars['String'];
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt: Scalars['String'];
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt: Scalars['String'];
  decorationsReceivedAt: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  isVirtual: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  foreseenDateExceededReason: Scalars['String'];
  quantity: Scalars['Float'];
  currentState: SampleState;
  confectionOverseer?: Maybe<User>;
  sampleType: SampleType;
  brand: Brand;
  sampleMeshes: Array<SampleMesh>;
  samplePhotos: Array<SamplePhoto>;
};


export type SampleSampleMeshesArgs = {
  filter?: Maybe<SampleMeshFilter>;
  sorting?: Maybe<Array<SampleMeshSort>>;
};


export type SampleSamplePhotosArgs = {
  filter?: Maybe<SamplePhotoFilter>;
  sorting?: Maybe<Array<SamplePhotoSort>>;
};

export type SampleAggregateGroupBy = {
  __typename?: 'SampleAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleAvgAggregate = {
  __typename?: 'SampleAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleConnection = {
  __typename?: 'SampleConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Sample>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SampleCountAggregate = {
  __typename?: 'SampleCountAggregate';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  orderReference?: Maybe<Scalars['Int']>;
  isVirtual?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type SampleDeleteFilter = {
  and?: Maybe<Array<SampleDeleteFilter>>;
  or?: Maybe<Array<SampleDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  order?: Maybe<StringFieldComparison>;
  orderReference?: Maybe<NumberFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
  isArchived?: Maybe<BooleanFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
};

export type SampleDeleteResponse = {
  __typename?: 'SampleDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate?: Maybe<Scalars['DateTime']>;
  sketch?: Maybe<Scalars['String']>;
  fastTrack?: Maybe<Scalars['Boolean']>;
  hasMesh?: Maybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: Maybe<Scalars['String']>;
  shippingDate?: Maybe<Scalars['String']>;
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  decorationsReceivedAt?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleFilter = {
  and?: Maybe<Array<SampleFilter>>;
  or?: Maybe<Array<SampleFilter>>;
  id?: Maybe<IdFilterComparison>;
  order?: Maybe<StringFieldComparison>;
  orderReference?: Maybe<NumberFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
  isArchived?: Maybe<BooleanFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
  brand?: Maybe<SampleFilterBrandFilter>;
  sampleType?: Maybe<SampleFilterSampleTypeFilter>;
  currentState?: Maybe<SampleFilterSampleStateFilter>;
};

export type SampleFilterBrandFilter = {
  and?: Maybe<Array<SampleFilterBrandFilter>>;
  or?: Maybe<Array<SampleFilterBrandFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type SampleFilterSampleStateFilter = {
  and?: Maybe<Array<SampleFilterSampleStateFilter>>;
  or?: Maybe<Array<SampleFilterSampleStateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
};

export type SampleFilterSampleTypeFilter = {
  and?: Maybe<Array<SampleFilterSampleTypeFilter>>;
  or?: Maybe<Array<SampleFilterSampleTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
};

export type SampleInputType = {
  id: Scalars['ID'];
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate: Scalars['DateTime'];
  sketch: Scalars['String'];
  fastTrack: Scalars['Boolean'];
  hasMesh: Scalars['Boolean'];
  hasMeshUpdatedAt: Scalars['String'];
  shippingDate: Scalars['String'];
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt: Scalars['String'];
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt: Scalars['String'];
  decorationsReceivedAt: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  isVirtual: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  foreseenDateExceededReason: Scalars['String'];
  quantity: Scalars['Float'];
};

export type SampleMaxAggregate = {
  __typename?: 'SampleMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleMesh = {
  __typename?: 'SampleMesh';
  id: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  mesh: Mesh;
  sample: Sample;
  user?: Maybe<User>;
};

export type SampleMeshAggregateGroupBy = {
  __typename?: 'SampleMeshAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshAvgAggregate = {
  __typename?: 'SampleMeshAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshConnection = {
  __typename?: 'SampleMeshConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SampleMeshEdge>;
};

export type SampleMeshCountAggregate = {
  __typename?: 'SampleMeshCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type SampleMeshDeleteFilter = {
  and?: Maybe<Array<SampleMeshDeleteFilter>>;
  or?: Maybe<Array<SampleMeshDeleteFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type SampleMeshDeleteResponse = {
  __typename?: 'SampleMeshDeleteResponse';
  id?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SampleMeshEdge = {
  __typename?: 'SampleMeshEdge';
  /** The node containing the SampleMesh */
  node: SampleMesh;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SampleMeshFilter = {
  and?: Maybe<Array<SampleMeshFilter>>;
  or?: Maybe<Array<SampleMeshFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type SampleMeshMaxAggregate = {
  __typename?: 'SampleMeshMaxAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshMinAggregate = {
  __typename?: 'SampleMeshMinAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshSort = {
  field: SampleMeshSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleMeshSortFields {
  Id = 'id'
}

export type SampleMeshSumAggregate = {
  __typename?: 'SampleMeshSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshUpdateFilter = {
  and?: Maybe<Array<SampleMeshUpdateFilter>>;
  or?: Maybe<Array<SampleMeshUpdateFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type SampleMinAggregate = {
  __typename?: 'SampleMinAggregate';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SamplePhoto = {
  __typename?: 'SamplePhoto';
  id: Scalars['ID'];
  photo: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sample: Sample;
};

export type SamplePhotoAggregateGroupBy = {
  __typename?: 'SamplePhotoAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoAvgAggregate = {
  __typename?: 'SamplePhotoAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SamplePhotoCountAggregate = {
  __typename?: 'SamplePhotoCountAggregate';
  id?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['Int']>;
};

export type SamplePhotoEdge = {
  __typename?: 'SamplePhotoEdge';
  /** The node containing the SamplePhoto */
  node: SamplePhoto;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SamplePhotoFilter = {
  and?: Maybe<Array<SamplePhotoFilter>>;
  or?: Maybe<Array<SamplePhotoFilter>>;
  id?: Maybe<IdFilterComparison>;
  photo?: Maybe<StringFieldComparison>;
};

export type SamplePhotoMaxAggregate = {
  __typename?: 'SamplePhotoMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoMinAggregate = {
  __typename?: 'SamplePhotoMinAggregate';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoSort = {
  field: SamplePhotoSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SamplePhotoSortFields {
  Id = 'id',
  Photo = 'photo'
}

export type SamplePhotoSumAggregate = {
  __typename?: 'SamplePhotoSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleSort = {
  field: SampleSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleSortFields {
  Id = 'id',
  Order = 'order',
  OrderReference = 'orderReference',
  IsVirtual = 'isVirtual',
  IsArchived = 'isArchived',
  Quantity = 'quantity'
}

export type SampleState = {
  __typename?: 'SampleState';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sampleStateTypeId: Scalars['Float'];
  sampleStateType: SampleStateType;
  checkinUser?: Maybe<User>;
};

export type SampleStateAggregateGroupBy = {
  __typename?: 'SampleStateAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateAvgAggregate = {
  __typename?: 'SampleStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateConnection = {
  __typename?: 'SampleStateConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SampleStateEdge>;
};

export type SampleStateCountAggregate = {
  __typename?: 'SampleStateCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  sampleStateTypeId?: Maybe<Scalars['Int']>;
};

export type SampleStateDeleteFilter = {
  and?: Maybe<Array<SampleStateDeleteFilter>>;
  or?: Maybe<Array<SampleStateDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
};

export type SampleStateDeleteResponse = {
  __typename?: 'SampleStateDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateEdge = {
  __typename?: 'SampleStateEdge';
  /** The node containing the SampleState */
  node: SampleState;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SampleStateFilter = {
  and?: Maybe<Array<SampleStateFilter>>;
  or?: Maybe<Array<SampleStateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
  sampleStateType?: Maybe<SampleStateFilterSampleStateTypeFilter>;
};

export type SampleStateFilterSampleStateTypeFilter = {
  and?: Maybe<Array<SampleStateFilterSampleStateTypeFilter>>;
  or?: Maybe<Array<SampleStateFilterSampleStateTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type SampleStateMaxAggregate = {
  __typename?: 'SampleStateMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateMinAggregate = {
  __typename?: 'SampleStateMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateSort = {
  field: SampleStateSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleStateSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  SampleStateTypeId = 'sampleStateTypeId'
}

export type SampleStateSumAggregate = {
  __typename?: 'SampleStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateType = {
  __typename?: 'SampleStateType';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  order: Scalars['Float'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SampleStateUpdateFilter = {
  and?: Maybe<Array<SampleStateUpdateFilter>>;
  or?: Maybe<Array<SampleStateUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
};

export type SampleSumAggregate = {
  __typename?: 'SampleSumAggregate';
  id?: Maybe<Scalars['Float']>;
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleType = {
  __typename?: 'SampleType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isVirtual: Scalars['Boolean'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type SampleTypeAggregateGroupBy = {
  __typename?: 'SampleTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
};

export type SampleTypeAvgAggregate = {
  __typename?: 'SampleTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleTypeConnection = {
  __typename?: 'SampleTypeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SampleTypeEdge>;
};

export type SampleTypeCountAggregate = {
  __typename?: 'SampleTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  isVirtual?: Maybe<Scalars['Int']>;
};

export type SampleTypeDeleteFilter = {
  and?: Maybe<Array<SampleTypeDeleteFilter>>;
  or?: Maybe<Array<SampleTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
};

export type SampleTypeDeleteResponse = {
  __typename?: 'SampleTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type SampleTypeEdge = {
  __typename?: 'SampleTypeEdge';
  /** The node containing the SampleType */
  node: SampleType;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SampleTypeFilter = {
  and?: Maybe<Array<SampleTypeFilter>>;
  or?: Maybe<Array<SampleTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
};

export type SampleTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isVirtual: Scalars['Boolean'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type SampleTypeMaxAggregate = {
  __typename?: 'SampleTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleTypeMinAggregate = {
  __typename?: 'SampleTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleTypeSort = {
  field: SampleTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleTypeSortFields {
  Id = 'id',
  Name = 'name',
  IsVirtual = 'isVirtual'
}

export type SampleTypeSumAggregate = {
  __typename?: 'SampleTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleTypeUpdateFilter = {
  and?: Maybe<Array<SampleTypeUpdateFilter>>;
  or?: Maybe<Array<SampleTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
};

export type SampleUpdateFilter = {
  and?: Maybe<Array<SampleUpdateFilter>>;
  or?: Maybe<Array<SampleUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  order?: Maybe<StringFieldComparison>;
  orderReference?: Maybe<NumberFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
  isArchived?: Maybe<BooleanFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
};

export type SetAliasesOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetBrandOnAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetBrandOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetBrandOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCheckinSampleStatesOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetCheckinUserOnSampleStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetChecklistItemsOnChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetCommercialOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConfectionOverseerOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCurrentStateOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDestinationOnMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetInspectedByOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetLocationOnMeshLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshConsumptionsOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeshFabricTypeOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshLocationsOnLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeshLocationsOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeshOnMeshConsumptionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshOnMeshLocationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshOnMeshTransferInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshSupplierOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetPurchaseOrderTypeOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleMeshesOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSampleMeshesOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSampleOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleOnSamplePhotoInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSamplePhotosOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSampleStateTypeOnSampleStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleTypeOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetStylistOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSupplierAliasesOnSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSupplierOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSupplierOnSupplierAliasInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetTicketCommentsOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTicketFilesOnTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTicketFilesOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTypeOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnMeshConsumptionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnUserEventInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserTypeOnChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUsersOnUserTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWashTypeOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  notLike?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  notILike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  name: Scalars['String'];
  isSupplier: Scalars['Boolean'];
  isMeshSupplier: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  supplierAliases: Array<SupplierAlias>;
};


export type SupplierSupplierAliasesArgs = {
  filter?: Maybe<SupplierAliasFilter>;
  sorting?: Maybe<Array<SupplierAliasSort>>;
};

export type SupplierAggregateGroupBy = {
  __typename?: 'SupplierAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  isMeshSupplier?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierAlias = {
  __typename?: 'SupplierAlias';
  id: Scalars['ID'];
  externalId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  supplier: Supplier;
};

export type SupplierAliasAggregateGroupBy = {
  __typename?: 'SupplierAliasAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
};

export type SupplierAliasAvgAggregate = {
  __typename?: 'SupplierAliasAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierAliasConnection = {
  __typename?: 'SupplierAliasConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<SupplierAlias>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SupplierAliasCountAggregate = {
  __typename?: 'SupplierAliasCountAggregate';
  id?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
};

export type SupplierAliasDeleteFilter = {
  and?: Maybe<Array<SupplierAliasDeleteFilter>>;
  or?: Maybe<Array<SupplierAliasDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  externalId?: Maybe<StringFieldComparison>;
};

export type SupplierAliasDeleteResponse = {
  __typename?: 'SupplierAliasDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierAliasFilter = {
  and?: Maybe<Array<SupplierAliasFilter>>;
  or?: Maybe<Array<SupplierAliasFilter>>;
  id?: Maybe<IdFilterComparison>;
  externalId?: Maybe<StringFieldComparison>;
  supplier?: Maybe<SupplierAliasFilterSupplierFilter>;
};

export type SupplierAliasFilterSupplierFilter = {
  and?: Maybe<Array<SupplierAliasFilterSupplierFilter>>;
  or?: Maybe<Array<SupplierAliasFilterSupplierFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isSupplier?: Maybe<BooleanFieldComparison>;
  isMeshSupplier?: Maybe<BooleanFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type SupplierAliasMaxAggregate = {
  __typename?: 'SupplierAliasMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
};

export type SupplierAliasMinAggregate = {
  __typename?: 'SupplierAliasMinAggregate';
  id?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
};

export type SupplierAliasSort = {
  field: SupplierAliasSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SupplierAliasSortFields {
  Id = 'id',
  ExternalId = 'externalId'
}

export type SupplierAliasSumAggregate = {
  __typename?: 'SupplierAliasSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierAvgAggregate = {
  __typename?: 'SupplierAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SupplierEdge>;
};

export type SupplierCountAggregate = {
  __typename?: 'SupplierCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  isSupplier?: Maybe<Scalars['Int']>;
  isMeshSupplier?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
};

export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  /** The node containing the Supplier */
  node: Supplier;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SupplierFilter = {
  and?: Maybe<Array<SupplierFilter>>;
  or?: Maybe<Array<SupplierFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isSupplier?: Maybe<BooleanFieldComparison>;
  isMeshSupplier?: Maybe<BooleanFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type SupplierInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isSupplier: Scalars['Boolean'];
  isMeshSupplier: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type SupplierMaxAggregate = {
  __typename?: 'SupplierMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierMinAggregate = {
  __typename?: 'SupplierMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierSort = {
  field: SupplierSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SupplierSortFields {
  Id = 'id',
  Name = 'name',
  IsSupplier = 'isSupplier',
  IsMeshSupplier = 'isMeshSupplier',
  CreatedAt = 'createdAt'
}

export type SupplierSumAggregate = {
  __typename?: 'SupplierSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierUpdateFilter = {
  and?: Maybe<Array<SupplierUpdateFilter>>;
  or?: Maybe<Array<SupplierUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isSupplier?: Maybe<BooleanFieldComparison>;
  isMeshSupplier?: Maybe<BooleanFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type SupportTopic = {
  __typename?: 'SupportTopic';
  id: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  loom?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SupportTopicAggregateGroupBy = {
  __typename?: 'SupportTopicAggregateGroupBy';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicConnection = {
  __typename?: 'SupportTopicConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SupportTopicEdge>;
};

export type SupportTopicCountAggregate = {
  __typename?: 'SupportTopicCountAggregate';
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type SupportTopicEdge = {
  __typename?: 'SupportTopicEdge';
  /** The node containing the SupportTopic */
  node: SupportTopic;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SupportTopicFilter = {
  and?: Maybe<Array<SupportTopicFilter>>;
  or?: Maybe<Array<SupportTopicFilter>>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  type?: Maybe<StringFieldComparison>;
};

export type SupportTopicMaxAggregate = {
  __typename?: 'SupportTopicMaxAggregate';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicMinAggregate = {
  __typename?: 'SupportTopicMinAggregate';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicSort = {
  field: SupportTopicSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SupportTopicSortFields {
  Name = 'name',
  Description = 'description',
  State = 'state',
  Type = 'type'
}

export type Ticket = {
  __typename?: 'Ticket';
  id: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  isPublic: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  ticketComments?: Maybe<Array<TicketComment>>;
  ticketFiles?: Maybe<Array<TicketFile>>;
};


export type TicketTicketCommentsArgs = {
  filter?: Maybe<TicketCommentFilter>;
  sorting?: Maybe<Array<TicketCommentSort>>;
};


export type TicketTicketFilesArgs = {
  filter?: Maybe<TicketFileFilter>;
  sorting?: Maybe<Array<TicketFileSort>>;
};

export type TicketAggregateGroupBy = {
  __typename?: 'TicketAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type TicketAvgAggregate = {
  __typename?: 'TicketAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type TicketComment = {
  __typename?: 'TicketComment';
  id: Scalars['Float'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  ticketFiles?: Maybe<Array<TicketFile>>;
};


export type TicketCommentTicketFilesArgs = {
  filter?: Maybe<TicketFileFilter>;
  sorting?: Maybe<Array<TicketFileSort>>;
};

export type TicketCommentAggregateGroupBy = {
  __typename?: 'TicketCommentAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentConnection = {
  __typename?: 'TicketCommentConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<TicketCommentEdge>;
};

export type TicketCommentCountAggregate = {
  __typename?: 'TicketCommentCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
};

export type TicketCommentEdge = {
  __typename?: 'TicketCommentEdge';
  /** The node containing the TicketComment */
  node: TicketComment;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type TicketCommentFilter = {
  and?: Maybe<Array<TicketCommentFilter>>;
  or?: Maybe<Array<TicketCommentFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type TicketCommentMaxAggregate = {
  __typename?: 'TicketCommentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentMinAggregate = {
  __typename?: 'TicketCommentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentSort = {
  field: TicketCommentSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TicketCommentSortFields {
  CreatedAt = 'createdAt'
}

export type TicketConnection = {
  __typename?: 'TicketConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<TicketEdge>;
};

export type TicketCountAggregate = {
  __typename?: 'TicketCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Int']>;
};

export type TicketEdge = {
  __typename?: 'TicketEdge';
  /** The node containing the Ticket */
  node: Ticket;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type TicketFile = {
  __typename?: 'TicketFile';
  id: Scalars['Float'];
  file: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TicketFileFilter = {
  and?: Maybe<Array<TicketFileFilter>>;
  or?: Maybe<Array<TicketFileFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type TicketFileSort = {
  field: TicketFileSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TicketFileSortFields {
  CreatedAt = 'createdAt'
}

export type TicketFilter = {
  and?: Maybe<Array<TicketFilter>>;
  or?: Maybe<Array<TicketFilter>>;
  id?: Maybe<NumberFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  isPublic?: Maybe<BooleanFieldComparison>;
  user?: Maybe<TicketFilterUserFilter>;
};

export type TicketFilterUserFilter = {
  and?: Maybe<Array<TicketFilterUserFilter>>;
  or?: Maybe<Array<TicketFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type TicketMaxAggregate = {
  __typename?: 'TicketMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TicketMinAggregate = {
  __typename?: 'TicketMinAggregate';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TicketSort = {
  field: TicketSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TicketSortFields {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  State = 'state',
  IsPublic = 'isPublic'
}

export type TicketSumAggregate = {
  __typename?: 'TicketSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UpdateAlias = {
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateBrand = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  commercial?: Maybe<UserInput>;
  stylist?: Maybe<UserInput>;
};

export type UpdateManyAliasesInput = {
  /** Filter used to find fields to update */
  filter: AliasUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateAlias;
};

export type UpdateManyBrandsInput = {
  /** Filter used to find fields to update */
  filter: BrandUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateBrand;
};

export type UpdateManyMeshConsumptionsInput = {
  /** Filter used to find fields to update */
  filter: MeshConsumptionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshConsumption;
};

export type UpdateManyMeshFabricTypesInput = {
  /** Filter used to find fields to update */
  filter: MeshFabricTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshFabricType;
};

export type UpdateManyMeshLocationsInput = {
  /** Filter used to find fields to update */
  filter: MeshLocationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshLocation;
};

export type UpdateManyMeshTransfersInput = {
  /** Filter used to find fields to update */
  filter: MeshTransferUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshTransfer;
};

export type UpdateManyMeshesInput = {
  /** Filter used to find fields to update */
  filter: MeshUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMesh;
};

export type UpdateManyNotificationsInput = {
  /** Filter used to find fields to update */
  filter: NotificationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateNotification;
};

export type UpdateManyPosterCompositionsInput = {
  /** Filter used to find fields to update */
  filter: PosterCompositionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePosterComposition;
};

export type UpdateManyPosterFabricTypesInput = {
  /** Filter used to find fields to update */
  filter: PosterFabricTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePosterFabricType;
};

export type UpdateManyPosterSeasonsInput = {
  /** Filter used to find fields to update */
  filter: PosterSeasonUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePosterSeason;
};

export type UpdateManyPostersInput = {
  /** Filter used to find fields to update */
  filter: PosterUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePoster;
};

export type UpdateManyPurchaseOrderTypesInput = {
  /** Filter used to find fields to update */
  filter: PurchaseOrderTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePurchaseOrderType;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManySampleMeshesInput = {
  /** Filter used to find fields to update */
  filter: SampleMeshUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleMesh;
};

export type UpdateManySampleStatesInput = {
  /** Filter used to find fields to update */
  filter: SampleStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleState;
};

export type UpdateManySampleTypesInput = {
  /** Filter used to find fields to update */
  filter: SampleTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleType;
};

export type UpdateManySamplesInput = {
  /** Filter used to find fields to update */
  filter: SampleUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSample;
};

export type UpdateManySuppliersInput = {
  /** Filter used to find fields to update */
  filter: SupplierUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSupplier;
};

export type UpdateManyUserEventsInput = {
  /** Filter used to find fields to update */
  filter: UserEventUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUserEvent;
};

export type UpdateManyUserTypesInput = {
  /** Filter used to find fields to update */
  filter: UserTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUserType;
};

export type UpdateManyWashTypesInput = {
  /** Filter used to find fields to update */
  filter: WashTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateWashType;
};

export type UpdateMesh = {
  purchaseOrder?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['ID']>;
  supplierDocument?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['ID']>;
  meshSupplier?: Maybe<Scalars['ID']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  receptionDate?: Maybe<Scalars['DateTime']>;
  inspectionDate?: Maybe<Scalars['DateTime']>;
  purchaseOrderType: Scalars['ID'];
  washType?: Maybe<Scalars['ID']>;
  spoolCount?: Maybe<Scalars['Int']>;
  debitedToClient?: Maybe<Scalars['Boolean']>;
  meshFabricType?: Maybe<Scalars['ID']>;
};

export type UpdateMeshConsumption = {
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateMeshFabricType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  meshCount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateMeshLocation = {
  id?: Maybe<Scalars['Float']>;
};

export type UpdateMeshTransfer = {
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<MeshTransferStates>;
  type?: Maybe<MeshTransferTypes>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateNotification = {
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<NotificationKinds>;
  priority?: Maybe<NotificationPriorities>;
  tab?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateOneAliasInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateAlias;
};

export type UpdateOneBrandInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateBrand;
};

export type UpdateOneMeshConsumptionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshConsumption;
};

export type UpdateOneMeshFabricTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshFabricType;
};

export type UpdateOneMeshInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMesh;
};

export type UpdateOneMeshLocationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshLocation;
};

export type UpdateOneMeshTransferInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshTransfer;
};

export type UpdateOneNotificationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateNotification;
};

export type UpdateOnePosterCompositionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePosterComposition;
};

export type UpdateOnePosterFabricTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePosterFabricType;
};

export type UpdateOnePosterInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePoster;
};

export type UpdateOnePosterSeasonInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePosterSeason;
};

export type UpdateOnePurchaseOrderTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePurchaseOrderType;
};

export type UpdateOneSampleInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSample;
};

export type UpdateOneSampleMeshInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleMesh;
};

export type UpdateOneSampleStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleState;
};

export type UpdateOneSampleTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleType;
};

export type UpdateOneSupplierInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSupplier;
};

export type UpdateOneUserEventInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUserEvent;
};

export type UpdateOneUserTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUserType;
};

export type UpdateOneWashTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateWashType;
};

export type UpdatePoster = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  specifications?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  type?: Maybe<PosterTypes>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePosterComposition = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePosterFabricType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  posterType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePosterSeason = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePurchaseOrderType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSample = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate?: Maybe<Scalars['DateTime']>;
  sketch?: Maybe<Scalars['String']>;
  fastTrack?: Maybe<Scalars['Boolean']>;
  hasMesh?: Maybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: Maybe<Scalars['String']>;
  shippingDate?: Maybe<Scalars['String']>;
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  decorationsReceivedAt?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type UpdateSampleMesh = {
  id?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateSampleState = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type UpdateSampleType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type UpdateSupplier = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  isMeshSupplier?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateUserEvent = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateUserType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  todo?: Maybe<Array<SampleInputType>>;
  doing?: Maybe<Array<SampleInputType>>;
  done?: Maybe<Array<SampleInputType>>;
  todoCount?: Maybe<Scalars['Int']>;
  doingCount?: Maybe<Scalars['Int']>;
  doneCount?: Maybe<Scalars['Int']>;
};

export type UpdateWashType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  isSupervisor: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  type: UserType;
  stats: DashUserStat;
  checkinSampleStates: Array<SampleState>;
};


export type UserCheckinSampleStatesArgs = {
  filter?: Maybe<SampleStateFilter>;
  sorting?: Maybe<Array<SampleStateSort>>;
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<UserEdge>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** The node containing the User */
  node: User;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type UserEvent = {
  __typename?: 'UserEvent';
  id: Scalars['ID'];
  name: Scalars['String'];
  data?: Maybe<Scalars['JSONObject']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type UserEventAggregateGroupBy = {
  __typename?: 'UserEventAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONObject']>;
};

export type UserEventAvgAggregate = {
  __typename?: 'UserEventAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserEventConnection = {
  __typename?: 'UserEventConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<UserEvent>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserEventCountAggregate = {
  __typename?: 'UserEventCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['Int']>;
};

export type UserEventDeleteFilter = {
  and?: Maybe<Array<UserEventDeleteFilter>>;
  or?: Maybe<Array<UserEventDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  data?: Maybe<JsonObjectFilterComparison>;
};

export type UserEventDeleteResponse = {
  __typename?: 'UserEventDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserEventFilter = {
  and?: Maybe<Array<UserEventFilter>>;
  or?: Maybe<Array<UserEventFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  data?: Maybe<JsonObjectFilterComparison>;
};

export type UserEventMaxAggregate = {
  __typename?: 'UserEventMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONObject']>;
};

export type UserEventMinAggregate = {
  __typename?: 'UserEventMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONObject']>;
};

export type UserEventSort = {
  field: UserEventSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserEventSortFields {
  Id = 'id',
  Name = 'name',
  Data = 'data'
}

export type UserEventSumAggregate = {
  __typename?: 'UserEventSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserEventUpdateFilter = {
  and?: Maybe<Array<UserEventUpdateFilter>>;
  or?: Maybe<Array<UserEventUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  data?: Maybe<JsonObjectFilterComparison>;
};

export type UserFilter = {
  and?: Maybe<Array<UserFilter>>;
  or?: Maybe<Array<UserFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type UserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  isSupervisor: Scalars['Boolean'];
  updated: Scalars['DateTime'];
  type?: Maybe<UserTypeInput>;
  stats: DashUserStatInput;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserSort = {
  field: UserSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserSortFields {
  Id = 'id',
  Name = 'name'
}

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  todo: Array<Sample>;
  doing: Array<Sample>;
  done: Array<Sample>;
  todoCount: Scalars['Int'];
  doingCount: Scalars['Int'];
  doneCount: Scalars['Int'];
  users: Array<User>;
};


export type UserTypeUsersArgs = {
  filter?: Maybe<UserFilter>;
  sorting?: Maybe<Array<UserSort>>;
};

export type UserTypeAggregateGroupBy = {
  __typename?: 'UserTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<UserTypeEdge>;
};

export type UserTypeCountAggregate = {
  __typename?: 'UserTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserTypeDeleteFilter = {
  and?: Maybe<Array<UserTypeDeleteFilter>>;
  or?: Maybe<Array<UserTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type UserTypeDeleteResponse = {
  __typename?: 'UserTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  todo?: Maybe<Array<Sample>>;
  doing?: Maybe<Array<Sample>>;
  done?: Maybe<Array<Sample>>;
  todoCount?: Maybe<Scalars['Int']>;
  doingCount?: Maybe<Scalars['Int']>;
  doneCount?: Maybe<Scalars['Int']>;
};

export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** The node containing the UserType */
  node: UserType;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type UserTypeFilter = {
  and?: Maybe<Array<UserTypeFilter>>;
  or?: Maybe<Array<UserTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type UserTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  todo: Array<SampleInputType>;
  doing: Array<SampleInputType>;
  done: Array<SampleInputType>;
  todoCount: Scalars['Int'];
  doingCount: Scalars['Int'];
  doneCount: Scalars['Int'];
};

export type UserTypeMaxAggregate = {
  __typename?: 'UserTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeMinAggregate = {
  __typename?: 'UserTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeSort = {
  field: UserTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type UserTypeUpdateFilter = {
  and?: Maybe<Array<UserTypeUpdateFilter>>;
  or?: Maybe<Array<UserTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type WashType = {
  __typename?: 'WashType';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type WashTypeAggregateGroupBy = {
  __typename?: 'WashTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WashTypeAvgAggregate = {
  __typename?: 'WashTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WashTypeConnection = {
  __typename?: 'WashTypeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<WashTypeEdge>;
};

export type WashTypeCountAggregate = {
  __typename?: 'WashTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type WashTypeDeleteFilter = {
  and?: Maybe<Array<WashTypeDeleteFilter>>;
  or?: Maybe<Array<WashTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type WashTypeDeleteResponse = {
  __typename?: 'WashTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WashTypeEdge = {
  __typename?: 'WashTypeEdge';
  /** The node containing the WashType */
  node: WashType;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type WashTypeFilter = {
  and?: Maybe<Array<WashTypeFilter>>;
  or?: Maybe<Array<WashTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type WashTypeMaxAggregate = {
  __typename?: 'WashTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WashTypeMinAggregate = {
  __typename?: 'WashTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WashTypeSort = {
  field: WashTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum WashTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type WashTypeSumAggregate = {
  __typename?: 'WashTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WashTypeUpdateFilter = {
  and?: Maybe<Array<WashTypeUpdateFilter>>;
  or?: Maybe<Array<WashTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type AutocompleteMeshesQueryVariables = Exact<{
  fullPurchaseOrder: Scalars['String'];
}>;


export type AutocompleteMeshesQuery = (
  { __typename?: 'Query' }
  & { autocompleteMeshes: Array<(
    { __typename?: 'AutocompleteMesh' }
    & Pick<AutocompleteMesh, 'description' | 'meshKind' | 'composition' | 'color' | 'widthRequired' | 'weightRequired' | 'quantityUnit' | 'quantitySent'>
    & { brand?: Maybe<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'name'>
    )>, supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    )>, meshFabricType?: Maybe<(
      { __typename?: 'MeshFabricType' }
      & Pick<MeshFabricType, 'id' | 'name'>
    )> }
  )> }
);

export type MeshFabricTypesQueryVariables = Exact<{
  filter?: Maybe<MeshFabricTypeFilter>;
  sorting?: Maybe<Array<MeshFabricTypeSort> | MeshFabricTypeSort>;
}>;


export type MeshFabricTypesQuery = (
  { __typename?: 'Query' }
  & { meshFabricTypes: (
    { __typename?: 'MeshFabricTypeConnection' }
    & { edges: Array<(
      { __typename?: 'MeshFabricTypeEdge' }
      & { node: (
        { __typename?: 'MeshFabricType' }
        & Pick<MeshFabricType, 'id' | 'name' | 'isActive'>
      ) }
    )> }
  ) }
);

export type ScanMeshQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ScanMeshQuery = (
  { __typename?: 'Query' }
  & { mesh?: Maybe<(
    { __typename?: 'Mesh' }
    & Pick<Mesh, 'id' | 'composition' | 'color' | 'meshKind' | 'purchaseOrder' | 'weightReceived' | 'quantityUnit' | 'receptionDate' | 'spoolCount' | 'currentQuantity'>
    & { purchaseOrderType?: Maybe<(
      { __typename?: 'PurchaseOrderType' }
      & Pick<PurchaseOrderType, 'id' | 'name'>
    )>, brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'name'>
    ), meshLocations?: Maybe<Array<(
      { __typename?: 'MeshLocation' }
      & Pick<MeshLocation, 'id'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      ) }
    )>> }
  )> }
);

export type MeshAttributesFragment = (
  { __typename?: 'Mesh' }
  & Pick<Mesh, 'id' | 'purchaseOrder' | 'color' | 'composition' | 'meshKind' | 'currentQuantity' | 'widthReceived' | 'weightReceived' | 'receptionDate' | 'widthAfterWash' | 'widthBeforeWash' | 'lengthAfterWash' | 'lengthBeforeWash' | 'quantityUnit'>
  & { purchaseOrderType?: Maybe<(
    { __typename?: 'PurchaseOrderType' }
    & Pick<PurchaseOrderType, 'id' | 'name'>
  )>, brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ), supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  ), meshSupplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )>, meshLocations?: Maybe<Array<(
    { __typename?: 'MeshLocation' }
    & Pick<MeshLocation, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    ) }
  )>>, meshFabricType: (
    { __typename?: 'MeshFabricType' }
    & Pick<MeshFabricType, 'id' | 'name'>
  ) }
);

export type MeshDetailsFragment = (
  { __typename?: 'Mesh' }
  & Pick<Mesh, 'id' | 'currentQuantity' | 'widthRequired' | 'widthReceived' | 'supplierDocument' | 'meshSupplierDocument' | 'purchaseOrder' | 'meshKind' | 'composition' | 'color' | 'colorObservations' | 'weightRequired' | 'weightReceived' | 'widthBeforeWash' | 'widthAfterWash' | 'widthShrinkageObservations' | 'lengthBeforeWash' | 'lengthAfterWash' | 'lengthShrinkageObservations' | 'spiralityDeviation' | 'spiralityObservations' | 'quantityUnit' | 'quantitySent' | 'quantityReceived' | 'createdAt' | 'updatedAt' | 'inspectionDate' | 'receptionDate' | 'spoolCount' | 'debitedToClient'>
  & { washType?: Maybe<(
    { __typename?: 'WashType' }
    & Pick<WashType, 'id' | 'name'>
  )>, purchaseOrderType?: Maybe<(
    { __typename?: 'PurchaseOrderType' }
    & Pick<PurchaseOrderType, 'id' | 'name'>
  )>, brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ), supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  ), meshSupplier?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  )>, inspectedBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ), meshConsumptions?: Maybe<Array<(
    { __typename?: 'MeshConsumption' }
    & Pick<MeshConsumption, 'id' | 'quantity' | 'purpose' | 'unit' | 'createdAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    ) }
  )>>, sampleMeshes?: Maybe<Array<(
    { __typename?: 'SampleMesh' }
    & Pick<SampleMesh, 'id' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )>, sample: (
      { __typename?: 'Sample' }
      & Pick<Sample, 'order' | 'orderReference'>
      & { sampleType: (
        { __typename?: 'SampleType' }
        & Pick<SampleType, 'name'>
      ), brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'name'>
      ) }
    ) }
  )>>, meshLocations?: Maybe<Array<(
    { __typename?: 'MeshLocation' }
    & Pick<MeshLocation, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    ) }
  )>>, meshFabricType: (
    { __typename?: 'MeshFabricType' }
    & Pick<MeshFabricType, 'id' | 'name'>
  ) }
);

export type SampleFragment = (
  { __typename?: 'Sample' }
  & Pick<Sample, 'id' | 'order' | 'orderReference' | 'clientReference' | 'sketch'>
  & { brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  ), sampleType: (
    { __typename?: 'SampleType' }
    & Pick<SampleType, 'id' | 'name'>
  ), currentState: (
    { __typename?: 'SampleState' }
    & { sampleStateType: (
      { __typename?: 'SampleStateType' }
      & Pick<SampleStateType, 'name' | 'color'>
    ) }
  ) }
);

export type SupplierAttributesFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'name' | 'isSupplier' | 'isMeshSupplier' | 'createdAt' | 'updatedAt'>
);

export type TicketAttributesFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'id' | 'name' | 'description' | 'state' | 'isPublic' | 'createdAt' | 'updatedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>, ticketFiles?: Maybe<Array<(
    { __typename?: 'TicketFile' }
    & Pick<TicketFile, 'id' | 'file'>
  )>>, ticketComments?: Maybe<Array<(
    { __typename?: 'TicketComment' }
    & Pick<TicketComment, 'id' | 'content' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, ticketFiles?: Maybe<Array<(
      { __typename?: 'TicketFile' }
      & Pick<TicketFile, 'id' | 'file'>
    )>> }
  )>> }
);

export type BrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandsQuery = (
  { __typename?: 'Query' }
  & { brands: (
    { __typename?: 'BrandConnection' }
    & { edges: Array<(
      { __typename?: 'BrandEdge' }
      & { node: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type ChecklistsQueryVariables = Exact<{ [key: string]: never; }>;


export type ChecklistsQuery = (
  { __typename?: 'Query' }
  & { checklists: (
    { __typename?: 'ChecklistConnection' }
    & { edges: Array<(
      { __typename?: 'ChecklistEdge' }
      & { node: (
        { __typename?: 'Checklist' }
        & Pick<Checklist, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CommonIssuesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommonIssuesQuery = (
  { __typename?: 'Query' }
  & { commonIssues: (
    { __typename?: 'CommonIssueConnection' }
    & { edges: Array<(
      { __typename?: 'CommonIssueEdge' }
      & { node: (
        { __typename?: 'CommonIssue' }
        & Pick<CommonIssue, 'id' | 'key' | 'value'>
      ) }
    )> }
  ) }
);

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & { configuration?: Maybe<(
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'key' | 'value'>
  )> }
);

export type LocationsQueryVariables = Exact<{
  filter?: Maybe<LocationFilter>;
}>;


export type LocationsQuery = (
  { __typename?: 'Query' }
  & { locations: (
    { __typename?: 'LocationConnection' }
    & { edges: Array<(
      { __typename?: 'LocationEdge' }
      & { node: (
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
        & { meshLocations: Array<(
          { __typename?: 'MeshLocation' }
          & Pick<MeshLocation, 'id'>
          & { mesh: (
            { __typename?: 'Mesh' }
            & Pick<Mesh, 'id'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'avatar' | 'isSupervisor'>
    & { type: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'name'>
    ) }
  ) }
);

export type MeshesQueryVariables = Exact<{
  filter?: Maybe<MeshFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<MeshSort> | MeshSort>;
}>;


export type MeshesQuery = (
  { __typename?: 'Query' }
  & { meshes: (
    { __typename?: 'MeshConnection' }
    & Pick<MeshConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'OffsetPageInfo' }
      & Pick<OffsetPageInfo, 'hasNextPage' | 'hasPreviousPage'>
    ), nodes: Array<(
      { __typename?: 'Mesh' }
      & MeshAttributesFragment
    )> }
  ) }
);

export type MeshDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MeshDetailsQuery = (
  { __typename?: 'Query' }
  & { mesh?: Maybe<(
    { __typename?: 'Mesh' }
    & MeshDetailsFragment
  )> }
);

export type PurchaseOrderTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchaseOrderTypesQuery = (
  { __typename?: 'Query' }
  & { purchaseOrderTypes: (
    { __typename?: 'PurchaseOrderTypeConnection' }
    & { edges: Array<(
      { __typename?: 'PurchaseOrderTypeEdge' }
      & { node: (
        { __typename?: 'PurchaseOrderType' }
        & Pick<PurchaseOrderType, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type SamplesQueryVariables = Exact<{
  filter?: Maybe<SampleFilter>;
  paging?: Maybe<OffsetPaging>;
}>;


export type SamplesQuery = (
  { __typename?: 'Query' }
  & { samples: (
    { __typename?: 'SampleConnection' }
    & Pick<SampleConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'OffsetPageInfo' }
      & Pick<OffsetPageInfo, 'hasNextPage' | 'hasPreviousPage'>
    ), nodes: Array<(
      { __typename?: 'Sample' }
      & SampleFragment
    )> }
  ) }
);

export type SampleMeshSamplesWizardQueryVariables = Exact<{
  filter?: Maybe<SampleFilter>;
}>;


export type SampleMeshSamplesWizardQuery = (
  { __typename?: 'Query' }
  & { samples: (
    { __typename?: 'SampleConnection' }
    & Pick<SampleConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'OffsetPageInfo' }
      & Pick<OffsetPageInfo, 'hasNextPage' | 'hasPreviousPage'>
    ), nodes: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'order' | 'orderReference'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
      ), sampleType: (
        { __typename?: 'SampleType' }
        & Pick<SampleType, 'id' | 'name'>
      ), sampleMeshes: Array<(
        { __typename?: 'SampleMesh' }
        & { mesh: (
          { __typename?: 'Mesh' }
          & Pick<Mesh, 'id'>
        ) }
      )> }
    )> }
  ) }
);

export type SampleMeshMeshesWizardQueryVariables = Exact<{
  filter?: Maybe<MeshFilter>;
}>;


export type SampleMeshMeshesWizardQuery = (
  { __typename?: 'Query' }
  & { meshes: (
    { __typename?: 'MeshConnection' }
    & { nodes: Array<(
      { __typename?: 'Mesh' }
      & Pick<Mesh, 'id' | 'purchaseOrder' | 'color' | 'composition' | 'currentQuantity' | 'widthReceived' | 'weightReceived' | 'quantityUnit'>
      & { purchaseOrderType?: Maybe<(
        { __typename?: 'PurchaseOrderType' }
        & Pick<PurchaseOrderType, 'id' | 'name'>
      )>, sampleMeshes?: Maybe<Array<(
        { __typename?: 'SampleMesh' }
        & { sample: (
          { __typename?: 'Sample' }
          & Pick<Sample, 'id' | 'order' | 'orderReference'>
        ) }
      )>> }
    )> }
  ) }
);

export type SampleDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SampleDetailsQuery = (
  { __typename?: 'Query' }
  & { sample?: Maybe<(
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'orderReference' | 'clientReference'>
    & { sampleMeshes: Array<(
      { __typename?: 'SampleMesh' }
      & Pick<SampleMesh, 'id'>
      & { mesh: (
        { __typename?: 'Mesh' }
        & MeshAttributesFragment
      ) }
    )> }
  )> }
);

export type SuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type SuppliersQuery = (
  { __typename?: 'Query' }
  & { suppliers: (
    { __typename?: 'SupplierConnection' }
    & { edges: Array<(
      { __typename?: 'SupplierEdge' }
      & { node: (
        { __typename?: 'Supplier' }
        & SupplierAttributesFragment
      ) }
    )> }
  ) }
);

export type SupplierAliasesQueryVariables = Exact<{
  filter?: Maybe<SupplierAliasFilter>;
}>;


export type SupplierAliasesQuery = (
  { __typename?: 'Query' }
  & { supplierAliases: (
    { __typename?: 'SupplierAliasConnection' }
    & { nodes: Array<(
      { __typename?: 'SupplierAlias' }
      & Pick<SupplierAlias, 'id' | 'externalId'>
    )> }
  ) }
);

export type CreateOneSupplierAliasMutationVariables = Exact<{
  input: CreateOneSupplierAliasInput;
}>;


export type CreateOneSupplierAliasMutation = (
  { __typename?: 'Mutation' }
  & { createOneSupplierAlias: (
    { __typename?: 'SupplierAlias' }
    & Pick<SupplierAlias, 'id'>
  ) }
);

export type DeleteOneSupplierAliasMutationVariables = Exact<{
  input: DeleteOneSupplierAliasInput;
}>;


export type DeleteOneSupplierAliasMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneSupplierAlias: (
    { __typename?: 'SupplierAliasDeleteResponse' }
    & Pick<SupplierAliasDeleteResponse, 'id'>
  ) }
);

export type TicketQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TicketQuery = (
  { __typename?: 'Query' }
  & { ticket?: Maybe<(
    { __typename?: 'Ticket' }
    & TicketAttributesFragment
  )> }
);

export type TicketsQueryVariables = Exact<{
  filter?: Maybe<TicketFilter>;
}>;


export type TicketsQuery = (
  { __typename?: 'Query' }
  & { tickets: (
    { __typename?: 'TicketConnection' }
    & { edges: Array<(
      { __typename?: 'TicketEdge' }
      & { node: (
        { __typename?: 'Ticket' }
        & TicketAttributesFragment
      ) }
    )> }
  ) }
);

export type SupportTopicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SupportTopicQuery = (
  { __typename?: 'Query' }
  & { supportTopic?: Maybe<(
    { __typename?: 'SupportTopic' }
    & Pick<SupportTopic, 'id' | 'name' | 'description' | 'loom' | 'file' | 'state' | 'type' | 'createdAt' | 'updatedAt'>
  )> }
);

export type SupportTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type SupportTopicsQuery = (
  { __typename?: 'Query' }
  & { supportTopics: (
    { __typename?: 'SupportTopicConnection' }
    & { edges: Array<(
      { __typename?: 'SupportTopicEdge' }
      & { node: (
        { __typename?: 'SupportTopic' }
        & Pick<SupportTopic, 'id' | 'name' | 'description' | 'loom' | 'file' | 'state' | 'type' | 'createdAt' | 'updatedAt'>
      ) }
    )> }
  ) }
);

export type WashTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type WashTypesQuery = (
  { __typename?: 'Query' }
  & { washTypes: (
    { __typename?: 'WashTypeConnection' }
    & { edges: Array<(
      { __typename?: 'WashTypeEdge' }
      & { node: (
        { __typename?: 'WashType' }
        & Pick<WashType, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateOneIssueMutationVariables = Exact<{
  input: CreateOneIssueInput;
}>;


export type CreateOneIssueMutation = (
  { __typename?: 'Mutation' }
  & { createOneIssue: (
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'reference' | 'order' | 'description'>
  ) }
);

export type CreateOneMeshMutationVariables = Exact<{
  input: CreateOneMeshInput;
}>;


export type CreateOneMeshMutation = (
  { __typename?: 'Mutation' }
  & { createOneMesh: (
    { __typename?: 'Mesh' }
    & Pick<Mesh, 'id'>
  ) }
);

export type CreateOneMeshConsumptionMutationVariables = Exact<{
  input: CreateOneMeshConsumptionInput;
}>;


export type CreateOneMeshConsumptionMutation = (
  { __typename?: 'Mutation' }
  & { createOneMeshConsumption: (
    { __typename?: 'MeshConsumption' }
    & Pick<MeshConsumption, 'id' | 'quantity' | 'purpose' | 'unit'>
  ) }
);

export type CreateOneMeshLocationMutationVariables = Exact<{
  input: CreateOneMeshLocationInput;
}>;


export type CreateOneMeshLocationMutation = (
  { __typename?: 'Mutation' }
  & { createOneMeshLocation: (
    { __typename?: 'MeshLocation' }
    & Pick<MeshLocation, 'id'>
  ) }
);

export type CreateOneSampleMeshMutationVariables = Exact<{
  input: CreateOneSampleMeshInput;
}>;


export type CreateOneSampleMeshMutation = (
  { __typename?: 'Mutation' }
  & { createOneSampleMesh: (
    { __typename?: 'SampleMesh' }
    & Pick<SampleMesh, 'id'>
  ) }
);

export type CreateManySampleMeshesMutationVariables = Exact<{
  input: CreateManySampleMeshesInput;
}>;


export type CreateManySampleMeshesMutation = (
  { __typename?: 'Mutation' }
  & { createManySampleMeshes: Array<(
    { __typename?: 'SampleMesh' }
    & Pick<SampleMesh, 'id'>
  )> }
);

export type CreateOneSupplierMutationVariables = Exact<{
  input: CreateOneSupplierInput;
}>;


export type CreateOneSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createOneSupplier: (
    { __typename?: 'Supplier' }
    & SupplierAttributesFragment
  ) }
);

export type CreateOneTicketMutationVariables = Exact<{
  input: CreateOneTicketInput;
}>;


export type CreateOneTicketMutation = (
  { __typename?: 'Mutation' }
  & { createOneTicket: (
    { __typename?: 'Ticket' }
    & TicketAttributesFragment
  ) }
);

export type CreateOneTicketCommentMutationVariables = Exact<{
  input: CreateOneTicketCommentInput;
}>;


export type CreateOneTicketCommentMutation = (
  { __typename?: 'Mutation' }
  & { createOneTicketComment: (
    { __typename?: 'TicketComment' }
    & Pick<TicketComment, 'id' | 'content'>
  ) }
);

export type DeleteOneMeshMutationVariables = Exact<{
  input: DeleteOneMeshInput;
}>;


export type DeleteOneMeshMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneMesh: (
    { __typename?: 'MeshDeleteResponse' }
    & Pick<MeshDeleteResponse, 'id'>
  ) }
);

export type DeleteOneMeshConsumptionMutationVariables = Exact<{
  input: DeleteOneMeshConsumptionInput;
}>;


export type DeleteOneMeshConsumptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneMeshConsumption: (
    { __typename?: 'MeshConsumption' }
    & Pick<MeshConsumption, 'id'>
  ) }
);

export type DeleteOneMeshLocationMutationVariables = Exact<{
  input: DeleteOneMeshLocationInput;
}>;


export type DeleteOneMeshLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneMeshLocation: (
    { __typename?: 'MeshLocationDeleteResponse' }
    & Pick<MeshLocationDeleteResponse, 'id'>
  ) }
);

export type DeleteOneSampleMeshMutationVariables = Exact<{
  input: DeleteOneSampleMeshInput;
}>;


export type DeleteOneSampleMeshMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneSampleMesh: (
    { __typename?: 'SampleMeshDeleteResponse' }
    & Pick<SampleMeshDeleteResponse, 'id'>
  ) }
);

export type UpdateOneMeshMutationVariables = Exact<{
  input: UpdateOneMeshInput;
}>;


export type UpdateOneMeshMutation = (
  { __typename?: 'Mutation' }
  & { updateOneMesh: (
    { __typename?: 'Mesh' }
    & Pick<Mesh, 'id'>
  ) }
);

export type UpdateOneSupplierMutationVariables = Exact<{
  input: UpdateOneSupplierInput;
}>;


export type UpdateOneSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateOneSupplier: (
    { __typename?: 'Supplier' }
    & SupplierAttributesFragment
  ) }
);

export type MeshTransfersQueryVariables = Exact<{
  filter?: Maybe<MeshTransferFilter>;
  sorting?: Maybe<Array<MeshTransferSort> | MeshTransferSort>;
  paging?: Maybe<OffsetPaging>;
}>;


export type MeshTransfersQuery = (
  { __typename?: 'Query' }
  & { meshTransfers: (
    { __typename?: 'MeshTransferConnection' }
    & Pick<MeshTransferConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'MeshTransfer' }
      & Pick<MeshTransfer, 'id' | 'state'>
      & { mesh: (
        { __typename?: 'Mesh' }
        & Pick<Mesh, 'id'>
        & { meshLocations?: Maybe<Array<(
          { __typename?: 'MeshLocation' }
          & Pick<MeshLocation, 'id'>
          & { location: (
            { __typename?: 'Location' }
            & Pick<Location, 'name'>
          ) }
        )>> }
      ), destination?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type ExecuteMeshTransfersMutationVariables = Exact<{
  input: ExecuteMeshTransfers;
}>;


export type ExecuteMeshTransfersMutation = (
  { __typename?: 'Mutation' }
  & { executeMeshTransfers: Array<(
    { __typename?: 'MeshTransfer' }
    & Pick<MeshTransfer, 'id'>
  )> }
);

export type UpdateOneMeshTransferMutationVariables = Exact<{
  input: UpdateOneMeshTransferInput;
}>;


export type UpdateOneMeshTransferMutation = (
  { __typename?: 'Mutation' }
  & { updateOneMeshTransfer: (
    { __typename?: 'MeshTransfer' }
    & Pick<MeshTransfer, 'id' | 'state'>
  ) }
);

export type UpdateManyMeshTransfersMutationVariables = Exact<{
  input: UpdateManyMeshTransfersInput;
}>;


export type UpdateManyMeshTransfersMutation = (
  { __typename?: 'Mutation' }
  & { updateManyMeshTransfers: (
    { __typename?: 'UpdateManyResponse' }
    & Pick<UpdateManyResponse, 'updatedCount'>
  ) }
);

export const MeshAttributesFragmentDoc = gql`
    fragment meshAttributes on Mesh {
  id
  purchaseOrder
  purchaseOrderType {
    id
    name
  }
  color
  composition
  meshKind
  currentQuantity
  widthReceived
  weightReceived
  receptionDate
  widthAfterWash
  widthBeforeWash
  lengthAfterWash
  lengthBeforeWash
  quantityUnit
  brand {
    id
    name
  }
  supplier {
    id
    name
  }
  meshSupplier {
    id
    name
  }
  meshLocations {
    id
    location {
      id
      name
    }
  }
  meshFabricType {
    id
    name
  }
}
    `;
export const MeshDetailsFragmentDoc = gql`
    fragment meshDetails on Mesh {
  id
  currentQuantity
  widthRequired
  widthReceived
  washType {
    id
    name
  }
  supplierDocument
  meshSupplierDocument
  purchaseOrder
  purchaseOrderType {
    id
    name
  }
  meshKind
  composition
  color
  colorObservations
  weightRequired
  weightReceived
  widthBeforeWash
  widthAfterWash
  widthShrinkageObservations
  lengthBeforeWash
  lengthAfterWash
  lengthShrinkageObservations
  spiralityDeviation
  spiralityObservations
  quantityUnit
  quantitySent
  quantityReceived
  createdAt
  updatedAt
  inspectionDate
  receptionDate
  spoolCount
  debitedToClient
  brand {
    id
    name
  }
  supplier {
    id
    name
  }
  meshSupplier {
    id
    name
  }
  inspectedBy {
    id
    name
  }
  meshConsumptions(sorting: {field: createdAt, direction: DESC}) {
    id
    quantity
    purpose
    unit
    user {
      id
      name
    }
    createdAt
  }
  sampleMeshes {
    id
    user {
      name
    }
    createdAt
    sample {
      order
      orderReference
      sampleType {
        name
      }
      brand {
        name
      }
    }
  }
  meshLocations {
    id
    location {
      id
      name
    }
  }
  meshFabricType {
    id
    name
  }
}
    `;
export const SampleFragmentDoc = gql`
    fragment sample on Sample {
  id
  order
  orderReference
  clientReference
  sketch
  brand {
    id
    name
  }
  sampleType {
    id
    name
  }
  currentState {
    sampleStateType {
      name
      color
    }
  }
}
    `;
export const SupplierAttributesFragmentDoc = gql`
    fragment supplierAttributes on Supplier {
  id
  name
  isSupplier
  isMeshSupplier
  createdAt
  updatedAt
}
    `;
export const TicketAttributesFragmentDoc = gql`
    fragment ticketAttributes on Ticket {
  id
  name
  description
  state
  isPublic
  createdAt
  updatedAt
  user {
    id
    name
  }
  ticketFiles {
    id
    file
  }
  ticketComments {
    id
    content
    createdAt
    user {
      id
      name
    }
    ticketFiles {
      id
      file
    }
  }
}
    `;
export const AutocompleteMeshesDocument = gql`
    query autocompleteMeshes($fullPurchaseOrder: String!) {
  autocompleteMeshes(fullPurchaseOrder: $fullPurchaseOrder) {
    description
    meshKind
    composition
    color
    widthRequired
    weightRequired
    quantityUnit
    quantitySent
    brand {
      id
      name
    }
    supplier {
      id
      name
    }
    meshFabricType {
      id
      name
    }
  }
}
    `;

/**
 * __useAutocompleteMeshesQuery__
 *
 * To run a query within a React component, call `useAutocompleteMeshesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteMeshesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteMeshesQuery({
 *   variables: {
 *      fullPurchaseOrder: // value for 'fullPurchaseOrder'
 *   },
 * });
 */
export function useAutocompleteMeshesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AutocompleteMeshesQuery, AutocompleteMeshesQueryVariables>) {
        return ApolloReactHooks.useQuery<AutocompleteMeshesQuery, AutocompleteMeshesQueryVariables>(AutocompleteMeshesDocument, baseOptions);
      }
export function useAutocompleteMeshesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AutocompleteMeshesQuery, AutocompleteMeshesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AutocompleteMeshesQuery, AutocompleteMeshesQueryVariables>(AutocompleteMeshesDocument, baseOptions);
        }
export type AutocompleteMeshesQueryHookResult = ReturnType<typeof useAutocompleteMeshesQuery>;
export type AutocompleteMeshesLazyQueryHookResult = ReturnType<typeof useAutocompleteMeshesLazyQuery>;
export type AutocompleteMeshesQueryResult = ApolloReactCommon.QueryResult<AutocompleteMeshesQuery, AutocompleteMeshesQueryVariables>;
export const MeshFabricTypesDocument = gql`
    query meshFabricTypes($filter: MeshFabricTypeFilter, $sorting: [MeshFabricTypeSort!]) {
  meshFabricTypes(filter: $filter, sorting: $sorting, paging: {first: 9999}) {
    edges {
      node {
        id
        name
        isActive
      }
    }
  }
}
    `;

/**
 * __useMeshFabricTypesQuery__
 *
 * To run a query within a React component, call `useMeshFabricTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeshFabricTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeshFabricTypesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMeshFabricTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeshFabricTypesQuery, MeshFabricTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<MeshFabricTypesQuery, MeshFabricTypesQueryVariables>(MeshFabricTypesDocument, baseOptions);
      }
export function useMeshFabricTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeshFabricTypesQuery, MeshFabricTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeshFabricTypesQuery, MeshFabricTypesQueryVariables>(MeshFabricTypesDocument, baseOptions);
        }
export type MeshFabricTypesQueryHookResult = ReturnType<typeof useMeshFabricTypesQuery>;
export type MeshFabricTypesLazyQueryHookResult = ReturnType<typeof useMeshFabricTypesLazyQuery>;
export type MeshFabricTypesQueryResult = ApolloReactCommon.QueryResult<MeshFabricTypesQuery, MeshFabricTypesQueryVariables>;
export const ScanMeshDocument = gql`
    query scanMesh($id: ID!) {
  mesh(id: $id) {
    id
    composition
    color
    meshKind
    purchaseOrder
    weightReceived
    quantityUnit
    receptionDate
    spoolCount
    purchaseOrderType {
      id
      name
    }
    currentQuantity
    brand {
      name
    }
    meshLocations {
      id
      location {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useScanMeshQuery__
 *
 * To run a query within a React component, call `useScanMeshQuery` and pass it any options that fit your needs.
 * When your component renders, `useScanMeshQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScanMeshQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScanMeshQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScanMeshQuery, ScanMeshQueryVariables>) {
        return ApolloReactHooks.useQuery<ScanMeshQuery, ScanMeshQueryVariables>(ScanMeshDocument, baseOptions);
      }
export function useScanMeshLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScanMeshQuery, ScanMeshQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScanMeshQuery, ScanMeshQueryVariables>(ScanMeshDocument, baseOptions);
        }
export type ScanMeshQueryHookResult = ReturnType<typeof useScanMeshQuery>;
export type ScanMeshLazyQueryHookResult = ReturnType<typeof useScanMeshLazyQuery>;
export type ScanMeshQueryResult = ApolloReactCommon.QueryResult<ScanMeshQuery, ScanMeshQueryVariables>;
export const BrandsDocument = gql`
    query brands {
  brands(
    filter: {isActive: {is: true}}
    sorting: [{field: name, direction: ASC}]
    paging: {first: 999}
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        return ApolloReactHooks.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
      }
export function useBrandsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = ApolloReactCommon.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const ChecklistsDocument = gql`
    query checklists {
  checklists {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useChecklistsQuery__
 *
 * To run a query within a React component, call `useChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChecklistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChecklistsQuery, ChecklistsQueryVariables>) {
        return ApolloReactHooks.useQuery<ChecklistsQuery, ChecklistsQueryVariables>(ChecklistsDocument, baseOptions);
      }
export function useChecklistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChecklistsQuery, ChecklistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChecklistsQuery, ChecklistsQueryVariables>(ChecklistsDocument, baseOptions);
        }
export type ChecklistsQueryHookResult = ReturnType<typeof useChecklistsQuery>;
export type ChecklistsLazyQueryHookResult = ReturnType<typeof useChecklistsLazyQuery>;
export type ChecklistsQueryResult = ApolloReactCommon.QueryResult<ChecklistsQuery, ChecklistsQueryVariables>;
export const CommonIssuesDocument = gql`
    query commonIssues {
  commonIssues(filter: {key: {eq: "COMMON_ISSUES"}}) {
    edges {
      node {
        id
        key
        value
      }
    }
  }
}
    `;

/**
 * __useCommonIssuesQuery__
 *
 * To run a query within a React component, call `useCommonIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonIssuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommonIssuesQuery, CommonIssuesQueryVariables>) {
        return ApolloReactHooks.useQuery<CommonIssuesQuery, CommonIssuesQueryVariables>(CommonIssuesDocument, baseOptions);
      }
export function useCommonIssuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommonIssuesQuery, CommonIssuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommonIssuesQuery, CommonIssuesQueryVariables>(CommonIssuesDocument, baseOptions);
        }
export type CommonIssuesQueryHookResult = ReturnType<typeof useCommonIssuesQuery>;
export type CommonIssuesLazyQueryHookResult = ReturnType<typeof useCommonIssuesLazyQuery>;
export type CommonIssuesQueryResult = ApolloReactCommon.QueryResult<CommonIssuesQuery, CommonIssuesQueryVariables>;
export const PermissionsDocument = gql`
    query permissions {
  configuration(id: 27) {
    id
    key
    value
  }
}
    `;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
      }
export function usePermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = ApolloReactCommon.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const LocationsDocument = gql`
    query locations($filter: LocationFilter) {
  locations(filter: $filter) {
    edges {
      node {
        id
        name
        meshLocations {
          id
          mesh {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
      }
export function useLocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = ApolloReactCommon.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    avatar
    isSupervisor
    type {
      id
      name
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const MeshesDocument = gql`
    query meshes($filter: MeshFilter, $paging: OffsetPaging, $sorting: [MeshSort!]) {
  meshes(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ...meshAttributes
    }
  }
}
    ${MeshAttributesFragmentDoc}`;

/**
 * __useMeshesQuery__
 *
 * To run a query within a React component, call `useMeshesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeshesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeshesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMeshesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeshesQuery, MeshesQueryVariables>) {
        return ApolloReactHooks.useQuery<MeshesQuery, MeshesQueryVariables>(MeshesDocument, baseOptions);
      }
export function useMeshesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeshesQuery, MeshesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeshesQuery, MeshesQueryVariables>(MeshesDocument, baseOptions);
        }
export type MeshesQueryHookResult = ReturnType<typeof useMeshesQuery>;
export type MeshesLazyQueryHookResult = ReturnType<typeof useMeshesLazyQuery>;
export type MeshesQueryResult = ApolloReactCommon.QueryResult<MeshesQuery, MeshesQueryVariables>;
export const MeshDetailsDocument = gql`
    query meshDetails($id: ID!) {
  mesh(id: $id) {
    ...meshDetails
  }
}
    ${MeshDetailsFragmentDoc}`;

/**
 * __useMeshDetailsQuery__
 *
 * To run a query within a React component, call `useMeshDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeshDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeshDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeshDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeshDetailsQuery, MeshDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<MeshDetailsQuery, MeshDetailsQueryVariables>(MeshDetailsDocument, baseOptions);
      }
export function useMeshDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeshDetailsQuery, MeshDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeshDetailsQuery, MeshDetailsQueryVariables>(MeshDetailsDocument, baseOptions);
        }
export type MeshDetailsQueryHookResult = ReturnType<typeof useMeshDetailsQuery>;
export type MeshDetailsLazyQueryHookResult = ReturnType<typeof useMeshDetailsLazyQuery>;
export type MeshDetailsQueryResult = ApolloReactCommon.QueryResult<MeshDetailsQuery, MeshDetailsQueryVariables>;
export const PurchaseOrderTypesDocument = gql`
    query purchaseOrderTypes {
  purchaseOrderTypes {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePurchaseOrderTypesQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchaseOrderTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseOrderTypesQuery, PurchaseOrderTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseOrderTypesQuery, PurchaseOrderTypesQueryVariables>(PurchaseOrderTypesDocument, baseOptions);
      }
export function usePurchaseOrderTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseOrderTypesQuery, PurchaseOrderTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseOrderTypesQuery, PurchaseOrderTypesQueryVariables>(PurchaseOrderTypesDocument, baseOptions);
        }
export type PurchaseOrderTypesQueryHookResult = ReturnType<typeof usePurchaseOrderTypesQuery>;
export type PurchaseOrderTypesLazyQueryHookResult = ReturnType<typeof usePurchaseOrderTypesLazyQuery>;
export type PurchaseOrderTypesQueryResult = ApolloReactCommon.QueryResult<PurchaseOrderTypesQuery, PurchaseOrderTypesQueryVariables>;
export const SamplesDocument = gql`
    query samples($filter: SampleFilter, $paging: OffsetPaging) {
  samples(filter: $filter, paging: $paging) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ...sample
    }
  }
}
    ${SampleFragmentDoc}`;

/**
 * __useSamplesQuery__
 *
 * To run a query within a React component, call `useSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamplesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useSamplesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SamplesQuery, SamplesQueryVariables>) {
        return ApolloReactHooks.useQuery<SamplesQuery, SamplesQueryVariables>(SamplesDocument, baseOptions);
      }
export function useSamplesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SamplesQuery, SamplesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SamplesQuery, SamplesQueryVariables>(SamplesDocument, baseOptions);
        }
export type SamplesQueryHookResult = ReturnType<typeof useSamplesQuery>;
export type SamplesLazyQueryHookResult = ReturnType<typeof useSamplesLazyQuery>;
export type SamplesQueryResult = ApolloReactCommon.QueryResult<SamplesQuery, SamplesQueryVariables>;
export const SampleMeshSamplesWizardDocument = gql`
    query sampleMeshSamplesWizard($filter: SampleFilter) {
  samples(filter: $filter) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      order
      orderReference
      brand {
        id
        name
      }
      sampleType {
        id
        name
      }
      sampleMeshes {
        mesh {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useSampleMeshSamplesWizardQuery__
 *
 * To run a query within a React component, call `useSampleMeshSamplesWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleMeshSamplesWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleMeshSamplesWizardQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSampleMeshSamplesWizardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SampleMeshSamplesWizardQuery, SampleMeshSamplesWizardQueryVariables>) {
        return ApolloReactHooks.useQuery<SampleMeshSamplesWizardQuery, SampleMeshSamplesWizardQueryVariables>(SampleMeshSamplesWizardDocument, baseOptions);
      }
export function useSampleMeshSamplesWizardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SampleMeshSamplesWizardQuery, SampleMeshSamplesWizardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SampleMeshSamplesWizardQuery, SampleMeshSamplesWizardQueryVariables>(SampleMeshSamplesWizardDocument, baseOptions);
        }
export type SampleMeshSamplesWizardQueryHookResult = ReturnType<typeof useSampleMeshSamplesWizardQuery>;
export type SampleMeshSamplesWizardLazyQueryHookResult = ReturnType<typeof useSampleMeshSamplesWizardLazyQuery>;
export type SampleMeshSamplesWizardQueryResult = ApolloReactCommon.QueryResult<SampleMeshSamplesWizardQuery, SampleMeshSamplesWizardQueryVariables>;
export const SampleMeshMeshesWizardDocument = gql`
    query sampleMeshMeshesWizard($filter: MeshFilter) {
  meshes(filter: $filter) {
    nodes {
      id
      purchaseOrder
      purchaseOrderType {
        id
        name
      }
      color
      composition
      currentQuantity
      widthReceived
      weightReceived
      quantityUnit
      sampleMeshes {
        sample {
          id
          order
          orderReference
        }
      }
    }
  }
}
    `;

/**
 * __useSampleMeshMeshesWizardQuery__
 *
 * To run a query within a React component, call `useSampleMeshMeshesWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleMeshMeshesWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleMeshMeshesWizardQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSampleMeshMeshesWizardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SampleMeshMeshesWizardQuery, SampleMeshMeshesWizardQueryVariables>) {
        return ApolloReactHooks.useQuery<SampleMeshMeshesWizardQuery, SampleMeshMeshesWizardQueryVariables>(SampleMeshMeshesWizardDocument, baseOptions);
      }
export function useSampleMeshMeshesWizardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SampleMeshMeshesWizardQuery, SampleMeshMeshesWizardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SampleMeshMeshesWizardQuery, SampleMeshMeshesWizardQueryVariables>(SampleMeshMeshesWizardDocument, baseOptions);
        }
export type SampleMeshMeshesWizardQueryHookResult = ReturnType<typeof useSampleMeshMeshesWizardQuery>;
export type SampleMeshMeshesWizardLazyQueryHookResult = ReturnType<typeof useSampleMeshMeshesWizardLazyQuery>;
export type SampleMeshMeshesWizardQueryResult = ApolloReactCommon.QueryResult<SampleMeshMeshesWizardQuery, SampleMeshMeshesWizardQueryVariables>;
export const SampleDetailsDocument = gql`
    query sampleDetails($id: ID!) {
  sample(id: $id) {
    id
    orderReference
    clientReference
    sampleMeshes(sorting: [{field: id, direction: ASC}]) {
      id
      mesh {
        ...meshAttributes
      }
    }
  }
}
    ${MeshAttributesFragmentDoc}`;

/**
 * __useSampleDetailsQuery__
 *
 * To run a query within a React component, call `useSampleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSampleDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SampleDetailsQuery, SampleDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<SampleDetailsQuery, SampleDetailsQueryVariables>(SampleDetailsDocument, baseOptions);
      }
export function useSampleDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SampleDetailsQuery, SampleDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SampleDetailsQuery, SampleDetailsQueryVariables>(SampleDetailsDocument, baseOptions);
        }
export type SampleDetailsQueryHookResult = ReturnType<typeof useSampleDetailsQuery>;
export type SampleDetailsLazyQueryHookResult = ReturnType<typeof useSampleDetailsLazyQuery>;
export type SampleDetailsQueryResult = ApolloReactCommon.QueryResult<SampleDetailsQuery, SampleDetailsQueryVariables>;
export const SuppliersDocument = gql`
    query suppliers {
  suppliers(paging: {first: 999}, sorting: {field: name, direction: ASC}) {
    edges {
      node {
        ...supplierAttributes
      }
    }
  }
}
    ${SupplierAttributesFragmentDoc}`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        return ApolloReactHooks.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, baseOptions);
      }
export function useSuppliersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, baseOptions);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = ApolloReactCommon.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const SupplierAliasesDocument = gql`
    query supplierAliases($filter: SupplierAliasFilter) {
  supplierAliases(filter: $filter) {
    nodes {
      id
      externalId
    }
  }
}
    `;

/**
 * __useSupplierAliasesQuery__
 *
 * To run a query within a React component, call `useSupplierAliasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierAliasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierAliasesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSupplierAliasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupplierAliasesQuery, SupplierAliasesQueryVariables>) {
        return ApolloReactHooks.useQuery<SupplierAliasesQuery, SupplierAliasesQueryVariables>(SupplierAliasesDocument, baseOptions);
      }
export function useSupplierAliasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupplierAliasesQuery, SupplierAliasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupplierAliasesQuery, SupplierAliasesQueryVariables>(SupplierAliasesDocument, baseOptions);
        }
export type SupplierAliasesQueryHookResult = ReturnType<typeof useSupplierAliasesQuery>;
export type SupplierAliasesLazyQueryHookResult = ReturnType<typeof useSupplierAliasesLazyQuery>;
export type SupplierAliasesQueryResult = ApolloReactCommon.QueryResult<SupplierAliasesQuery, SupplierAliasesQueryVariables>;
export const CreateOneSupplierAliasDocument = gql`
    mutation createOneSupplierAlias($input: CreateOneSupplierAliasInput!) {
  createOneSupplierAlias(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateOneSupplierAliasMutation__
 *
 * To run a mutation, you first call `useCreateOneSupplierAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSupplierAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSupplierAliasMutation, { data, loading, error }] = useCreateOneSupplierAliasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSupplierAliasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneSupplierAliasMutation, CreateOneSupplierAliasMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneSupplierAliasMutation, CreateOneSupplierAliasMutationVariables>(CreateOneSupplierAliasDocument, baseOptions);
      }
export type CreateOneSupplierAliasMutationHookResult = ReturnType<typeof useCreateOneSupplierAliasMutation>;
export type CreateOneSupplierAliasMutationResult = ApolloReactCommon.MutationResult<CreateOneSupplierAliasMutation>;
export type CreateOneSupplierAliasMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneSupplierAliasMutation, CreateOneSupplierAliasMutationVariables>;
export const DeleteOneSupplierAliasDocument = gql`
    mutation deleteOneSupplierAlias($input: DeleteOneSupplierAliasInput!) {
  deleteOneSupplierAlias(input: $input) {
    id
  }
}
    `;

/**
 * __useDeleteOneSupplierAliasMutation__
 *
 * To run a mutation, you first call `useDeleteOneSupplierAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSupplierAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSupplierAliasMutation, { data, loading, error }] = useDeleteOneSupplierAliasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSupplierAliasMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneSupplierAliasMutation, DeleteOneSupplierAliasMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneSupplierAliasMutation, DeleteOneSupplierAliasMutationVariables>(DeleteOneSupplierAliasDocument, baseOptions);
      }
export type DeleteOneSupplierAliasMutationHookResult = ReturnType<typeof useDeleteOneSupplierAliasMutation>;
export type DeleteOneSupplierAliasMutationResult = ApolloReactCommon.MutationResult<DeleteOneSupplierAliasMutation>;
export type DeleteOneSupplierAliasMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneSupplierAliasMutation, DeleteOneSupplierAliasMutationVariables>;
export const TicketDocument = gql`
    query ticket($id: ID!) {
  ticket(id: $id) {
    ...ticketAttributes
  }
}
    ${TicketAttributesFragmentDoc}`;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TicketQuery, TicketQueryVariables>) {
        return ApolloReactHooks.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, baseOptions);
      }
export function useTicketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, baseOptions);
        }
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketQueryResult = ApolloReactCommon.QueryResult<TicketQuery, TicketQueryVariables>;
export const TicketsDocument = gql`
    query tickets($filter: TicketFilter) {
  tickets(
    paging: {first: 999}
    sorting: [{field: id, direction: DESC}]
    filter: $filter
  ) {
    edges {
      node {
        ...ticketAttributes
      }
    }
  }
}
    ${TicketAttributesFragmentDoc}`;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
        return ApolloReactHooks.useQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, baseOptions);
      }
export function useTicketsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketsQuery, TicketsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, baseOptions);
        }
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = ApolloReactCommon.QueryResult<TicketsQuery, TicketsQueryVariables>;
export const SupportTopicDocument = gql`
    query supportTopic($id: ID!) {
  supportTopic(id: $id) {
    id
    name
    description
    loom
    file
    state
    type
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSupportTopicQuery__
 *
 * To run a query within a React component, call `useSupportTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupportTopicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTopicQuery, SupportTopicQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTopicQuery, SupportTopicQueryVariables>(SupportTopicDocument, baseOptions);
      }
export function useSupportTopicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTopicQuery, SupportTopicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTopicQuery, SupportTopicQueryVariables>(SupportTopicDocument, baseOptions);
        }
export type SupportTopicQueryHookResult = ReturnType<typeof useSupportTopicQuery>;
export type SupportTopicLazyQueryHookResult = ReturnType<typeof useSupportTopicLazyQuery>;
export type SupportTopicQueryResult = ApolloReactCommon.QueryResult<SupportTopicQuery, SupportTopicQueryVariables>;
export const SupportTopicsDocument = gql`
    query supportTopics {
  supportTopics(filter: {state: {eq: "PUBLISHED"}}) {
    edges {
      node {
        id
        name
        description
        loom
        file
        state
        type
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useSupportTopicsQuery__
 *
 * To run a query within a React component, call `useSupportTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportTopicsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTopicsQuery, SupportTopicsQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTopicsQuery, SupportTopicsQueryVariables>(SupportTopicsDocument, baseOptions);
      }
export function useSupportTopicsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTopicsQuery, SupportTopicsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTopicsQuery, SupportTopicsQueryVariables>(SupportTopicsDocument, baseOptions);
        }
export type SupportTopicsQueryHookResult = ReturnType<typeof useSupportTopicsQuery>;
export type SupportTopicsLazyQueryHookResult = ReturnType<typeof useSupportTopicsLazyQuery>;
export type SupportTopicsQueryResult = ApolloReactCommon.QueryResult<SupportTopicsQuery, SupportTopicsQueryVariables>;
export const WashTypesDocument = gql`
    query washTypes {
  washTypes {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useWashTypesQuery__
 *
 * To run a query within a React component, call `useWashTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWashTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWashTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWashTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WashTypesQuery, WashTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<WashTypesQuery, WashTypesQueryVariables>(WashTypesDocument, baseOptions);
      }
export function useWashTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WashTypesQuery, WashTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WashTypesQuery, WashTypesQueryVariables>(WashTypesDocument, baseOptions);
        }
export type WashTypesQueryHookResult = ReturnType<typeof useWashTypesQuery>;
export type WashTypesLazyQueryHookResult = ReturnType<typeof useWashTypesLazyQuery>;
export type WashTypesQueryResult = ApolloReactCommon.QueryResult<WashTypesQuery, WashTypesQueryVariables>;
export const CreateOneIssueDocument = gql`
    mutation createOneIssue($input: CreateOneIssueInput!) {
  createOneIssue(input: $input) {
    id
    reference
    order
    description
  }
}
    `;

/**
 * __useCreateOneIssueMutation__
 *
 * To run a mutation, you first call `useCreateOneIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneIssueMutation, { data, loading, error }] = useCreateOneIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneIssueMutation, CreateOneIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneIssueMutation, CreateOneIssueMutationVariables>(CreateOneIssueDocument, baseOptions);
      }
export type CreateOneIssueMutationHookResult = ReturnType<typeof useCreateOneIssueMutation>;
export type CreateOneIssueMutationResult = ApolloReactCommon.MutationResult<CreateOneIssueMutation>;
export type CreateOneIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneIssueMutation, CreateOneIssueMutationVariables>;
export const CreateOneMeshDocument = gql`
    mutation createOneMesh($input: CreateOneMeshInput!) {
  createOneMesh(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateOneMeshMutation__
 *
 * To run a mutation, you first call `useCreateOneMeshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneMeshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneMeshMutation, { data, loading, error }] = useCreateOneMeshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneMeshMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneMeshMutation, CreateOneMeshMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneMeshMutation, CreateOneMeshMutationVariables>(CreateOneMeshDocument, baseOptions);
      }
export type CreateOneMeshMutationHookResult = ReturnType<typeof useCreateOneMeshMutation>;
export type CreateOneMeshMutationResult = ApolloReactCommon.MutationResult<CreateOneMeshMutation>;
export type CreateOneMeshMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneMeshMutation, CreateOneMeshMutationVariables>;
export const CreateOneMeshConsumptionDocument = gql`
    mutation createOneMeshConsumption($input: CreateOneMeshConsumptionInput!) {
  createOneMeshConsumption(input: $input) {
    id
    quantity
    purpose
    unit
  }
}
    `;

/**
 * __useCreateOneMeshConsumptionMutation__
 *
 * To run a mutation, you first call `useCreateOneMeshConsumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneMeshConsumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneMeshConsumptionMutation, { data, loading, error }] = useCreateOneMeshConsumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneMeshConsumptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneMeshConsumptionMutation, CreateOneMeshConsumptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneMeshConsumptionMutation, CreateOneMeshConsumptionMutationVariables>(CreateOneMeshConsumptionDocument, baseOptions);
      }
export type CreateOneMeshConsumptionMutationHookResult = ReturnType<typeof useCreateOneMeshConsumptionMutation>;
export type CreateOneMeshConsumptionMutationResult = ApolloReactCommon.MutationResult<CreateOneMeshConsumptionMutation>;
export type CreateOneMeshConsumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneMeshConsumptionMutation, CreateOneMeshConsumptionMutationVariables>;
export const CreateOneMeshLocationDocument = gql`
    mutation createOneMeshLocation($input: CreateOneMeshLocationInput!) {
  createOneMeshLocation(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateOneMeshLocationMutation__
 *
 * To run a mutation, you first call `useCreateOneMeshLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneMeshLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneMeshLocationMutation, { data, loading, error }] = useCreateOneMeshLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneMeshLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneMeshLocationMutation, CreateOneMeshLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneMeshLocationMutation, CreateOneMeshLocationMutationVariables>(CreateOneMeshLocationDocument, baseOptions);
      }
export type CreateOneMeshLocationMutationHookResult = ReturnType<typeof useCreateOneMeshLocationMutation>;
export type CreateOneMeshLocationMutationResult = ApolloReactCommon.MutationResult<CreateOneMeshLocationMutation>;
export type CreateOneMeshLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneMeshLocationMutation, CreateOneMeshLocationMutationVariables>;
export const CreateOneSampleMeshDocument = gql`
    mutation createOneSampleMesh($input: CreateOneSampleMeshInput!) {
  createOneSampleMesh(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateOneSampleMeshMutation__
 *
 * To run a mutation, you first call `useCreateOneSampleMeshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSampleMeshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSampleMeshMutation, { data, loading, error }] = useCreateOneSampleMeshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSampleMeshMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneSampleMeshMutation, CreateOneSampleMeshMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneSampleMeshMutation, CreateOneSampleMeshMutationVariables>(CreateOneSampleMeshDocument, baseOptions);
      }
export type CreateOneSampleMeshMutationHookResult = ReturnType<typeof useCreateOneSampleMeshMutation>;
export type CreateOneSampleMeshMutationResult = ApolloReactCommon.MutationResult<CreateOneSampleMeshMutation>;
export type CreateOneSampleMeshMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneSampleMeshMutation, CreateOneSampleMeshMutationVariables>;
export const CreateManySampleMeshesDocument = gql`
    mutation createManySampleMeshes($input: CreateManySampleMeshesInput!) {
  createManySampleMeshes(input: $input) {
    id
  }
}
    `;

/**
 * __useCreateManySampleMeshesMutation__
 *
 * To run a mutation, you first call `useCreateManySampleMeshesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManySampleMeshesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManySampleMeshesMutation, { data, loading, error }] = useCreateManySampleMeshesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManySampleMeshesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateManySampleMeshesMutation, CreateManySampleMeshesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateManySampleMeshesMutation, CreateManySampleMeshesMutationVariables>(CreateManySampleMeshesDocument, baseOptions);
      }
export type CreateManySampleMeshesMutationHookResult = ReturnType<typeof useCreateManySampleMeshesMutation>;
export type CreateManySampleMeshesMutationResult = ApolloReactCommon.MutationResult<CreateManySampleMeshesMutation>;
export type CreateManySampleMeshesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateManySampleMeshesMutation, CreateManySampleMeshesMutationVariables>;
export const CreateOneSupplierDocument = gql`
    mutation createOneSupplier($input: CreateOneSupplierInput!) {
  createOneSupplier(input: $input) {
    ...supplierAttributes
  }
}
    ${SupplierAttributesFragmentDoc}`;

/**
 * __useCreateOneSupplierMutation__
 *
 * To run a mutation, you first call `useCreateOneSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSupplierMutation, { data, loading, error }] = useCreateOneSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneSupplierMutation, CreateOneSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneSupplierMutation, CreateOneSupplierMutationVariables>(CreateOneSupplierDocument, baseOptions);
      }
export type CreateOneSupplierMutationHookResult = ReturnType<typeof useCreateOneSupplierMutation>;
export type CreateOneSupplierMutationResult = ApolloReactCommon.MutationResult<CreateOneSupplierMutation>;
export type CreateOneSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneSupplierMutation, CreateOneSupplierMutationVariables>;
export const CreateOneTicketDocument = gql`
    mutation createOneTicket($input: CreateOneTicketInput!) {
  createOneTicket(input: $input) {
    ...ticketAttributes
  }
}
    ${TicketAttributesFragmentDoc}`;

/**
 * __useCreateOneTicketMutation__
 *
 * To run a mutation, you first call `useCreateOneTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTicketMutation, { data, loading, error }] = useCreateOneTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneTicketMutation, CreateOneTicketMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneTicketMutation, CreateOneTicketMutationVariables>(CreateOneTicketDocument, baseOptions);
      }
export type CreateOneTicketMutationHookResult = ReturnType<typeof useCreateOneTicketMutation>;
export type CreateOneTicketMutationResult = ApolloReactCommon.MutationResult<CreateOneTicketMutation>;
export type CreateOneTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneTicketMutation, CreateOneTicketMutationVariables>;
export const CreateOneTicketCommentDocument = gql`
    mutation createOneTicketComment($input: CreateOneTicketCommentInput!) {
  createOneTicketComment(input: $input) {
    id
    content
  }
}
    `;

/**
 * __useCreateOneTicketCommentMutation__
 *
 * To run a mutation, you first call `useCreateOneTicketCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTicketCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTicketCommentMutation, { data, loading, error }] = useCreateOneTicketCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneTicketCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneTicketCommentMutation, CreateOneTicketCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneTicketCommentMutation, CreateOneTicketCommentMutationVariables>(CreateOneTicketCommentDocument, baseOptions);
      }
export type CreateOneTicketCommentMutationHookResult = ReturnType<typeof useCreateOneTicketCommentMutation>;
export type CreateOneTicketCommentMutationResult = ApolloReactCommon.MutationResult<CreateOneTicketCommentMutation>;
export type CreateOneTicketCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneTicketCommentMutation, CreateOneTicketCommentMutationVariables>;
export const DeleteOneMeshDocument = gql`
    mutation deleteOneMesh($input: DeleteOneMeshInput!) {
  deleteOneMesh(input: $input) {
    id
  }
}
    `;

/**
 * __useDeleteOneMeshMutation__
 *
 * To run a mutation, you first call `useDeleteOneMeshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneMeshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneMeshMutation, { data, loading, error }] = useDeleteOneMeshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneMeshMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneMeshMutation, DeleteOneMeshMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneMeshMutation, DeleteOneMeshMutationVariables>(DeleteOneMeshDocument, baseOptions);
      }
export type DeleteOneMeshMutationHookResult = ReturnType<typeof useDeleteOneMeshMutation>;
export type DeleteOneMeshMutationResult = ApolloReactCommon.MutationResult<DeleteOneMeshMutation>;
export type DeleteOneMeshMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneMeshMutation, DeleteOneMeshMutationVariables>;
export const DeleteOneMeshConsumptionDocument = gql`
    mutation deleteOneMeshConsumption($input: DeleteOneMeshConsumptionInput!) {
  deleteOneMeshConsumption(input: $input) {
    id
  }
}
    `;

/**
 * __useDeleteOneMeshConsumptionMutation__
 *
 * To run a mutation, you first call `useDeleteOneMeshConsumptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneMeshConsumptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneMeshConsumptionMutation, { data, loading, error }] = useDeleteOneMeshConsumptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneMeshConsumptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneMeshConsumptionMutation, DeleteOneMeshConsumptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneMeshConsumptionMutation, DeleteOneMeshConsumptionMutationVariables>(DeleteOneMeshConsumptionDocument, baseOptions);
      }
export type DeleteOneMeshConsumptionMutationHookResult = ReturnType<typeof useDeleteOneMeshConsumptionMutation>;
export type DeleteOneMeshConsumptionMutationResult = ApolloReactCommon.MutationResult<DeleteOneMeshConsumptionMutation>;
export type DeleteOneMeshConsumptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneMeshConsumptionMutation, DeleteOneMeshConsumptionMutationVariables>;
export const DeleteOneMeshLocationDocument = gql`
    mutation deleteOneMeshLocation($input: DeleteOneMeshLocationInput!) {
  deleteOneMeshLocation(input: $input) {
    id
  }
}
    `;

/**
 * __useDeleteOneMeshLocationMutation__
 *
 * To run a mutation, you first call `useDeleteOneMeshLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneMeshLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneMeshLocationMutation, { data, loading, error }] = useDeleteOneMeshLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneMeshLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneMeshLocationMutation, DeleteOneMeshLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneMeshLocationMutation, DeleteOneMeshLocationMutationVariables>(DeleteOneMeshLocationDocument, baseOptions);
      }
export type DeleteOneMeshLocationMutationHookResult = ReturnType<typeof useDeleteOneMeshLocationMutation>;
export type DeleteOneMeshLocationMutationResult = ApolloReactCommon.MutationResult<DeleteOneMeshLocationMutation>;
export type DeleteOneMeshLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneMeshLocationMutation, DeleteOneMeshLocationMutationVariables>;
export const DeleteOneSampleMeshDocument = gql`
    mutation deleteOneSampleMesh($input: DeleteOneSampleMeshInput!) {
  deleteOneSampleMesh(input: $input) {
    id
  }
}
    `;

/**
 * __useDeleteOneSampleMeshMutation__
 *
 * To run a mutation, you first call `useDeleteOneSampleMeshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSampleMeshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSampleMeshMutation, { data, loading, error }] = useDeleteOneSampleMeshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSampleMeshMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOneSampleMeshMutation, DeleteOneSampleMeshMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOneSampleMeshMutation, DeleteOneSampleMeshMutationVariables>(DeleteOneSampleMeshDocument, baseOptions);
      }
export type DeleteOneSampleMeshMutationHookResult = ReturnType<typeof useDeleteOneSampleMeshMutation>;
export type DeleteOneSampleMeshMutationResult = ApolloReactCommon.MutationResult<DeleteOneSampleMeshMutation>;
export type DeleteOneSampleMeshMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOneSampleMeshMutation, DeleteOneSampleMeshMutationVariables>;
export const UpdateOneMeshDocument = gql`
    mutation updateOneMesh($input: UpdateOneMeshInput!) {
  updateOneMesh(input: $input) {
    id
  }
}
    `;

/**
 * __useUpdateOneMeshMutation__
 *
 * To run a mutation, you first call `useUpdateOneMeshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneMeshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneMeshMutation, { data, loading, error }] = useUpdateOneMeshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneMeshMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOneMeshMutation, UpdateOneMeshMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOneMeshMutation, UpdateOneMeshMutationVariables>(UpdateOneMeshDocument, baseOptions);
      }
export type UpdateOneMeshMutationHookResult = ReturnType<typeof useUpdateOneMeshMutation>;
export type UpdateOneMeshMutationResult = ApolloReactCommon.MutationResult<UpdateOneMeshMutation>;
export type UpdateOneMeshMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOneMeshMutation, UpdateOneMeshMutationVariables>;
export const UpdateOneSupplierDocument = gql`
    mutation updateOneSupplier($input: UpdateOneSupplierInput!) {
  updateOneSupplier(input: $input) {
    ...supplierAttributes
  }
}
    ${SupplierAttributesFragmentDoc}`;

/**
 * __useUpdateOneSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateOneSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSupplierMutation, { data, loading, error }] = useUpdateOneSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOneSupplierMutation, UpdateOneSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOneSupplierMutation, UpdateOneSupplierMutationVariables>(UpdateOneSupplierDocument, baseOptions);
      }
export type UpdateOneSupplierMutationHookResult = ReturnType<typeof useUpdateOneSupplierMutation>;
export type UpdateOneSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateOneSupplierMutation>;
export type UpdateOneSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOneSupplierMutation, UpdateOneSupplierMutationVariables>;
export const MeshTransfersDocument = gql`
    query meshTransfers($filter: MeshTransferFilter, $sorting: [MeshTransferSort!], $paging: OffsetPaging) {
  meshTransfers(filter: $filter, sorting: $sorting, paging: $paging) {
    totalCount
    nodes {
      id
      state
      mesh {
        id
        meshLocations {
          id
          location {
            name
          }
        }
      }
      destination {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMeshTransfersQuery__
 *
 * To run a query within a React component, call `useMeshTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeshTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeshTransfersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useMeshTransfersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeshTransfersQuery, MeshTransfersQueryVariables>) {
        return ApolloReactHooks.useQuery<MeshTransfersQuery, MeshTransfersQueryVariables>(MeshTransfersDocument, baseOptions);
      }
export function useMeshTransfersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeshTransfersQuery, MeshTransfersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeshTransfersQuery, MeshTransfersQueryVariables>(MeshTransfersDocument, baseOptions);
        }
export type MeshTransfersQueryHookResult = ReturnType<typeof useMeshTransfersQuery>;
export type MeshTransfersLazyQueryHookResult = ReturnType<typeof useMeshTransfersLazyQuery>;
export type MeshTransfersQueryResult = ApolloReactCommon.QueryResult<MeshTransfersQuery, MeshTransfersQueryVariables>;
export const ExecuteMeshTransfersDocument = gql`
    mutation executeMeshTransfers($input: ExecuteMeshTransfers!) {
  executeMeshTransfers(input: $input) {
    id
  }
}
    `;

/**
 * __useExecuteMeshTransfersMutation__
 *
 * To run a mutation, you first call `useExecuteMeshTransfersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteMeshTransfersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeMeshTransfersMutation, { data, loading, error }] = useExecuteMeshTransfersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteMeshTransfersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteMeshTransfersMutation, ExecuteMeshTransfersMutationVariables>) {
        return ApolloReactHooks.useMutation<ExecuteMeshTransfersMutation, ExecuteMeshTransfersMutationVariables>(ExecuteMeshTransfersDocument, baseOptions);
      }
export type ExecuteMeshTransfersMutationHookResult = ReturnType<typeof useExecuteMeshTransfersMutation>;
export type ExecuteMeshTransfersMutationResult = ApolloReactCommon.MutationResult<ExecuteMeshTransfersMutation>;
export type ExecuteMeshTransfersMutationOptions = ApolloReactCommon.BaseMutationOptions<ExecuteMeshTransfersMutation, ExecuteMeshTransfersMutationVariables>;
export const UpdateOneMeshTransferDocument = gql`
    mutation updateOneMeshTransfer($input: UpdateOneMeshTransferInput!) {
  updateOneMeshTransfer(input: $input) {
    id
    state
  }
}
    `;

/**
 * __useUpdateOneMeshTransferMutation__
 *
 * To run a mutation, you first call `useUpdateOneMeshTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneMeshTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneMeshTransferMutation, { data, loading, error }] = useUpdateOneMeshTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneMeshTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOneMeshTransferMutation, UpdateOneMeshTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOneMeshTransferMutation, UpdateOneMeshTransferMutationVariables>(UpdateOneMeshTransferDocument, baseOptions);
      }
export type UpdateOneMeshTransferMutationHookResult = ReturnType<typeof useUpdateOneMeshTransferMutation>;
export type UpdateOneMeshTransferMutationResult = ApolloReactCommon.MutationResult<UpdateOneMeshTransferMutation>;
export type UpdateOneMeshTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOneMeshTransferMutation, UpdateOneMeshTransferMutationVariables>;
export const UpdateManyMeshTransfersDocument = gql`
    mutation updateManyMeshTransfers($input: UpdateManyMeshTransfersInput!) {
  updateManyMeshTransfers(input: $input) {
    updatedCount
  }
}
    `;

/**
 * __useUpdateManyMeshTransfersMutation__
 *
 * To run a mutation, you first call `useUpdateManyMeshTransfersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyMeshTransfersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyMeshTransfersMutation, { data, loading, error }] = useUpdateManyMeshTransfersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyMeshTransfersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateManyMeshTransfersMutation, UpdateManyMeshTransfersMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateManyMeshTransfersMutation, UpdateManyMeshTransfersMutationVariables>(UpdateManyMeshTransfersDocument, baseOptions);
      }
export type UpdateManyMeshTransfersMutationHookResult = ReturnType<typeof useUpdateManyMeshTransfersMutation>;
export type UpdateManyMeshTransfersMutationResult = ApolloReactCommon.MutationResult<UpdateManyMeshTransfersMutation>;
export type UpdateManyMeshTransfersMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateManyMeshTransfersMutation, UpdateManyMeshTransfersMutationVariables>;
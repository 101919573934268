import { Card, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useAuth } from '../../services/auth';
import { useChangeHandler, useUserRedirection } from '../../services/Hooks';
import styles from './Login.module.scss';

const Login = () => {

    const [user, setUser] = useState({ email: "", password: "" });
    const [validationError, setValidationError] = useState({ message: '' });
    const changeHandler = useChangeHandler(user, setUser);
    const { login } = useAuth()

    useUserRedirection();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        login(user.email, user.password).then((response: boolean) => {
            if (!response) {
                setValidationError({ message: "Não foi possível iniciar sessão." })
            }
        })
    };

    return (
        <Grid container justifyContent="center" alignItems="center" className={styles.Login}>
            <Grid item xs={3}>
                <Grid container direction="column">
                    <h1>MyPedrosa</h1>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit} className={styles.LoginForm}>
                                <div data-cy="errors" className={styles.ValidationError}>
                                    {validationError && validationError.message}
                                </div>
                                <TextField
                                    data-cy="email"
                                    name="email"
                                    label="Email"
                                    required
                                    fullWidth
                                    autoFocus
                                    onChange={changeHandler}
                                />
                                <TextField
                                    data-cy="password"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    required
                                    fullWidth
                                    onChange={changeHandler}
                                />
                                <Grid container justifyContent="center">
                                    <Button
                                        data-cy="submit"
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                    >
                                        iniciar sessão
                                    </Button>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Login;

import { Checkbox, Grid, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { SampleFragment } from '../../generated/graphql';
import { backgroundImage } from '../../services/Utils';
import styles from './SampleTile.module.scss';

interface SampleTileProps {
    sample: SampleFragment
    isSelected: (sampleId: number) => boolean
    onSelect: (sampleId: number) => void
}

const SampleTile: FC<SampleTileProps> = props => {

    const { sample, isSelected, onSelect } = props

    const color = sample.currentState.sampleStateType.color
    const selected = isSelected(parseInt(sample.id))
    const imgStyle = { backgroundImage: backgroundImage(sample.sketch) }
    const tileStyle = { borderBottom: `solid 5px ${color}` }

    return (
        <Grid direction="column" justifyContent="center" className={styles.SampleTile} style={tileStyle}>

            <Checkbox
                onClick={() => onSelect(parseInt(sample.id))}
                color="primary"
                checked={selected}
                inputProps={{
                    'aria-labelledby': "labelId",
                }}
            />

            <div className={styles.ImageOverview} style={imgStyle} />

            <Grid container direction="column" justifyContent="space-between" className={styles.References}>

                <Grid container direction="column" alignItems="center">

                    <div>
                        <Tooltip title="marca">
                            <small>{sample.brand.name}</small>
                        </Tooltip>
                    </div>

                    <div>
                        <Tooltip title="Referência do cliente">
                            <p className={styles.ClientReference}>
                                {sample.clientReference}
                            </p>
                        </Tooltip>
                    </div>

                    <div>
                        <Tooltip title="Referência do interna">
                            <small className={styles.TextCenter}>
                                Ref.: {sample.orderReference}<br />
                            </small>
                        </Tooltip>
                    </div>

                </Grid>

                <div className={styles.SampleType}>
                    <span>{sample.sampleType.name}</span>
                    <span className={styles.Order}>({sample.order})</span>
                </div>

            </Grid>
        </Grid >
    )
}

export default SampleTile;

import JsBarcode from 'jsbarcode';
import { BrandsQuery, LocationsQuery, MeshFabricTypesQuery, PurchaseOrderTypesQuery, SuppliersQuery, WashTypesQuery } from "../generated/graphql";
import Config from "./Config";

/** returns a humanized version of the ticket state name*/
export const ticketStateName = (key: string): string => {
    const names: any = {
        NEW: 'Criado',
        PROGRESS: 'Em Progresso',
        CLOSED: 'Concluído',
        PENDING: 'Pendente',
        CANCELED: 'Cancelado'
    }
    return names[key]
}

/** Returns the absolute path to a file in the api */
export const staticFile = (relative: string): string => {
    return Config.MEDIA_URL + relative
}

/** returns a string that can be used to set an image as background image */
export const backgroundImage = (relative: string): string => {
    const image = staticFile(relative);
    return `url(${image})`;
}

/** returns an object that can be passed to the style prop */
export const backgroundImageStyle = (relative: string): {} => {
    return { backgroundImage: backgroundImage(relative) }
}

// https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript/680982

/** get filename extesion */
export const extractExtension = (file: string) => {
    const re = /(?:\.([^.]+))?$/;
    // @ts-ignore
    return re.exec(file)[1];
}

/**
* starts download of file from the frontend
* @param url - the url of file to download
* @param filename - the name of the file
*
* @example downloadRequest(url, 'folhasala.pdf')
*/
export const downloadRequest = (url: string, filename: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function() {
        const type = xhr.getResponseHeader("content-type");
        const arrayBufferView = new Uint8Array(this.response);
        // @ts-ignore
        const blob = new Blob([arrayBufferView], { type });
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
    };
    xhr.send();
}

/**
* given an array of objects order the array by a property of the object
* @param property - the object property to order by
*
* @example
* People.sort(dynamicSort("Name"));
* People.sort(dynamicSort("Surname"));
*
* check: https://stackoverflow.com/a/4760279
*/
export const orderByProperty = (property: string) => {
    let order = 1;
    if (property[0] === "-") {
        order = -1;
        property = property.substr(1);
    }
    return function(a: any, b: any) {
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * order;
    }
}

/**
* given an array of objects that represent a route and route options return the object
* that represent the current active route
*
* @param match - react router match object
* @param fallback / default
*/
export const activeRouteOption = (options: any, match: any) => {
    // @ts-ignore
    const matched = options.filter(option => option.route === match.path)

    // if no option is matched?

    const active = matched[0]
    return active
}

/**
* returns a random string
*/
export const randomString = () => {
    let output = Math.random().toString(36).substring(7);
    return output
}

/**
* Returns the name of the first property in an object
* if more than one property exists it is not guaranteed
* that the returned property will be the same
* see: https://stackoverflow.com/questions/983267/how-to-access-the-first-property-of-a-javascript-object
*
* @param checkOnlyOne - throws errror if more than one property exists in the object
*/
export const firstProperty = (obj: object, checkOnlyOne?: boolean) => {
    const count = Object.keys(obj).length
    if (checkOnlyOne && count !== 1) {
        throw new Error("Object has more or less than one property")
    }
    return Object.keys(obj)[0]
}

/** isto é para ser usado com o autocomplete **/
export const brandsOptions = (brands?: BrandsQuery) => {
    if (!brands) return []
    return brands.brands.edges
}

/** isto é para ser usado com o autocomplete **/
export const suppliersOptions = (suppliers?: SuppliersQuery) => {
    if (!suppliers) return []
    return suppliers.suppliers.edges.filter(
        supplier => supplier.node.isSupplier
    )
}

/** isto é para ser usado com o autocomplete **/
export const meshSuppliersOptions = (suppliers?: SuppliersQuery) => {
    if (!suppliers) return []
    return suppliers.suppliers.edges.filter(
        supplier => supplier.node.isMeshSupplier
    )
}

/** isto é para ser usado com o autocomplete **/
export const locationsOptions = (locations?: LocationsQuery) => {
    if (!locations) return []
    return locations.locations.edges
}

/** isto é para ser usado com o autocomplete **/
export const purchaseOrderTypesOptions = (purchaseOrderTypes?: PurchaseOrderTypesQuery) => {
    if (!purchaseOrderTypes) return []
    return purchaseOrderTypes.purchaseOrderTypes.edges
}


/** isto é para ser usado com o autocomplete **/
export const washTypesOptions = (washTypes?: WashTypesQuery) => {
    if (!washTypes) return []
    return washTypes.washTypes.edges
}

/** isto é para ser usado com o autocomplete **/
export const meshFabricTypesOptions = (query?: MeshFabricTypesQuery) => {
    if (!query) return []
    return query?.meshFabricTypes?.edges
}

/** usado pelo print  **/
/** existe uma função interna do jsPDF para isto, ver PrintLabel **/
// https://stackoverflow.com/a/37471896
export const breakLines = (str: string, max: number = 14): string[] => {
    var names = str.split(' '),
        // @ts-ignore
        lines = [],
        line = 0,
        lineLength = 0;

    // @ts-ignore
    names.forEach(function(name) {
        if (lineLength + name.length + 1 > max) {
            line += 1;
            lineLength = 0;
        } else {
            lineLength += 1;
        }
        // @ts-ignore
        lines[line] = lines[line] || [];

        // @ts-ignoreb
        lines[line].push(name);

        lineLength += name.length;
    });

    // @ts-ignore
    return lines.map(function(line) {
        return line.join(' ');
    });
}

// https://stackoverflow.com/a/35703395
export const textToBase64Barcode = (text: string | null | undefined) => {
    if (!text) return null
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, text, { format: "CODE128" });
    return canvas.toDataURL("image/png");
}

/**
    verifica se algum elemento de uma array é null ou undefined
    se existir um elemento null or undefined retorna true
    retorna false caso contrário
    caso a array esteja vazia retorna false
 **/
export const anyNullOrUndefined = (arr: any[]) => {
    return !arr.every((element: any) => element !== null && element !== undefined)
}

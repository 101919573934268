import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';
import React, { FC, MouseEvent, ReactChild, ReactChildren, useState } from 'react';

// https://stackoverflow.com/questions/53688899/typescript-and-react-children-type
interface ConfirmButtonProps {
    children: ReactChild | ReactChildren,
    title?: string,
    message?: string,
    confirmText?: string,
    cancelText?: string,
    toast?: string | "off"
    onClick: () => Promise<any>,
    duration?: number
}

const ConfirmButton: FC<ConfirmButtonProps> = (props) => {

    const {
        title = "Continuar?",
        children,
        cancelText = "Cancelar",
        confirmText = "Continuar",
        message = "Tem a certeza que quer continuar, a ação é irreverísvel",
        toast = "Registo removido",
        duration = 3000,
        onClick
    } = props

    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const handleClick = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        setOpen(true)
    }

    const handleConfirm = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        onClick().then((response: any) => {
            if (response && toast !== "off") enqueueSnackbar(toast, { autoHideDuration: duration })
        })
        setOpen(false);
    };

    const handleCancel = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        setOpen(false);
    }

    return <>

        <IconButton onClick={handleClick}>
            {children}
        </IconButton>

        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {cancelText}
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}


export default ConfirmButton;

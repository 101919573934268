import { Grid, TextField } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { Autocomplete, CheckboxWithLabel } from 'formik-mui';
import { useBrandsQuery, usePurchaseOrderTypesQuery } from '../../generated/graphql';
import { useAuth } from '../../services/auth';
import { useHasPermission } from '../../services/permissions';
import { brandsOptions, purchaseOrderTypesOptions } from '../../services/Utils';
import FieldText from './FieldText';
import styles from './MeshView.module.scss';
import { getOptionLabel } from './MeshViewUtils';

const OrderSection = () => {

    const { data: brands } = useBrandsQuery()
    const { data: purchaseOrderTypes } = usePurchaseOrderTypesQuery()
    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")
    const { values, errors } = useFormikContext()
    const auth = useAuth()

    // @ts-ignore
    const { id } = values

    // @ts-ignore
    const errorsBrand = errors['brand']
    // @ts-ignore
    const errorsCategory = errors['brand']

    return (
        <div className={styles.Section}>
            <h3>Requisição</h3>

            <Grid container justifyContent="flex-start" spacing={2} className={styles.FormLine}>

                <Grid item xs={4}>

                    <FastField
                        name="purchaseOrderType"
                        disableClearable
                        component={Autocomplete}
                        options={purchaseOrderTypesOptions(purchaseOrderTypes)}
                        getOptionLabel={getOptionLabel}
                        disabled={!canUpdate}
                        renderInput={(params: any) => {
                            return (
                                <TextField
                                    {...params}
                                    label="Categoria"
                                    autoFocus={id ? false : true}
                                    error={errorsCategory}
                                    helperText={errorsCategory}

                                />
                            )
                        }}
                    />

                </Grid>

                <Grid item xs={6}>
                    <FastField
                        component={FieldText}
                        name="purchaseOrder"
                        label="Requisição"
                        disabled={!canUpdate}
                    />

                </Grid>

            </Grid>

            <Grid container justifyContent="flex-start" className={styles.FormLine}>
                <Grid item xs={10}>

                    <FastField
                        name="brand"
                        disableClearable
                        component={Autocomplete}
                        options={brandsOptions(brands)}
                        getOptionLabel={getOptionLabel}
                        disabled={!canUpdate}
                        renderInput={(params: any) => {
                            return (
                                <TextField
                                    {...params}
                                    label="Marca"
                                    error={errorsBrand}
                                    helperText={errorsBrand}

                                />
                            )
                        }}
                    />
                </Grid>
            </Grid>

            {auth?.user?.isSupervisor && <Grid container justifyContent="flex-start" className={styles.FormLine}>
                <Grid item xs={10}>
                    <FastField component={CheckboxWithLabel} type="checkbox" name="debitedToClient" Label={{ label: 'DEBITADO AO CLIENTE' }} />
                </Grid>
            </Grid>}


        </div >
    )
}

export default OrderSection

import { Grid } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as Yup from "yup";
import { useCreateOneSupplierMutation, useUpdateOneSupplierMutation } from '../../generated/graphql';
import Show from '../Show/Show';
import SupplierAliases from '../SupplierAliases/SupplierAliases';
import styles from './SupplierConfiguration.module.scss';

const CreateSupplier = ({ selected, onIdle, refetch }: { selected: any, onIdle: any, refetch: any }) => {

    const [createSupplier] = useCreateOneSupplierMutation({
        onError() {
            enqueueSnackbar("Erro ao criar fornecedor!", { variant: "error" })
        }
    })
    const [updateSupplier] = useUpdateOneSupplierMutation({
        onError() {
            enqueueSnackbar("Erro ao atualizar fornecedor!", { variant: "error" })
        }
    })

    const { enqueueSnackbar } = useSnackbar();

    const actionName = selected ? 'Atualizar Fornecedor' : 'Criar Fornecedor'

    const createSchema = Yup.object().shape({
        name: Yup.string().required(),
    })

    const initialValues = {
        name: selected ? selected.node.name : '',
    }

    const handleSubmit = async (values: any) => {

        if (selected?.node?.id) {
            // updating supplier
            return updateSupplier({
                variables: {
                    input: {
                        id: selected.node.id,
                        update: { ...values }
                    }
                }
            })
        } else {
            // creating supplier
            return createSupplier({
                variables: {
                    input: {
                        supplier: {
                            isSupplier: true, isMeshSupplier: true, ...values
                        }
                    }
                }
            }).then((response: any) => {
                if (response?.data?.createOneSupplier?.id) {
                    refetch()
                }
            });
        }
    }




    return (
        <Card className={styles.SupplierConfiguration}>
            <Grid container justifyContent="space-between" alignItems="center" className={styles.CardHeader}>
                <p>{selected ? selected.node.name : ''}</p>
                <IconButton onClick={onIdle} className={styles.Close}>
                    <CloseIcon />
                </IconButton>
            </Grid>

            <CardContent>
                <Formik
                    validationSchema={createSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ submitForm, isSubmitting, isValid }) => {
                        return (
                            <Form autoComplete="off">
                                <Field
                                    component={TextField}
                                    name="name"
                                    type="text"
                                    label="Nome Fornecedor"
                                    fullWidth
                                    autoFocus={!selected ? true : false}
                                />

                                <Show on={selected?.node?.id}>
                                    <SupplierAliases supplierId={selected?.node?.id} />
                                </Show>

                                <br />
                                <br />

                                <Button
                                    onClick={submitForm}
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    disabled={Boolean(isSubmitting || !isValid)}
                                    style={{ float: 'right', marginBottom: '15px' }}
                                >
                                    {actionName}
                                </Button>
                            </Form>
                        )
                    }
                    }
                </Formik>
            </CardContent>
        </Card >
    )
}

enum ConfigurationState {
    IDLE,
    CREATING,
    EDITING
}

const SupplierConfiguration = (props: any) => {

    const [state, setState] = useState<ConfigurationState>(ConfigurationState.IDLE);

    if (props.selected && state !== ConfigurationState.EDITING) {
        setState(ConfigurationState.EDITING)
    }

    if (state === ConfigurationState.IDLE) {
        return (
            <Grid container direction="column"
                justifyContent="flex-start" alignItems="center" className={styles.Idle}>
                <p>Selecione um item</p>
                <p>- ou -</p>
                <div>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setState(ConfigurationState.CREATING)}
                    >
                        Criar Novo Fornecedor
                    </Button>
                </div>
            </Grid>
        )
    }

    const handleIdle = () => {
        props.close()
        setState(ConfigurationState.IDLE)
    }

    return (
        <div>
            <CreateSupplier selected={props.selected} onIdle={handleIdle} refetch={props.refetch} />
        </div>
    )
}

export default SupplierConfiguration;

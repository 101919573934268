import React from 'react';
import { useGuestRedirection } from '../../services/Hooks';
import Header from '../Header/Header';
import Leading from '../Leading/Leading';
import Sidemenu from '../Sidemenu/Sidemenu';

const StandardView = () => {

    useGuestRedirection()

    return (
        <>
            <Header />
            <Leading />
            <Sidemenu />
        </>
    )
}


export default StandardView;

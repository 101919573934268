import React from 'react';
import { MeshFabricTypeSortFields, SortDirection, useMeshFabricTypesQuery } from '../../generated/graphql';
import DataFilter from '../DataFilter/DataFilter';

interface SupplierFilterProps {
    value: any,
    onChange: any,
    disableUnderline?: boolean
}

export default function MeshFabricTypeDataFilter(props: SupplierFilterProps) {

    const {
        value,
        onChange,
        disableUnderline = false
    } = props

    const { data } = useMeshFabricTypesQuery({
        variables: {
            filter: { meshCount: { gt: 0 } },
            sorting: { field: MeshFabricTypeSortFields.Name, direction: SortDirection.Asc }
        }
    })

    return (
        <DataFilter
            data={data}
            label="Tipo Malha"
            name="fabricType"
            value={value}
            onChange={onChange}
            disableUnderline={disableUnderline}
        />
    )
}

import { Checkbox, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Pending from '@mui/icons-material/Pending';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import { FC, MouseEvent } from 'react';
import { MeshTransfer, MeshTransferSortFields, MeshTransferStates, MeshTransferTypes, SortDirection, useExecuteMeshTransfersMutation, useMeshTransfersQuery, useUpdateOneMeshTransferMutation } from "../../generated/graphql";
import useBulkActions from '../../hooks/useBulkActions';
import useToast from '../../hooks/useToast';
import { EmptyView } from '../Empty/Empty';
import Hide from '../Hide/Hide';
import Loading from '../Loading/Loading';
import { getMeshCode } from '../MeshView/MeshBarcode';
import Show from '../Show/Show';
import transfersStyles from '../Transfers/Transfers.module.scss';
import styles from './PurgeTransfers.module.scss';

interface MoveButtonProps {
    onClick: () => void
    inFlight?: boolean
    text?: string
    subtext?: string
}

export const MoveButton: FC<MoveButtonProps> = props => {
    const { onClick, inFlight = false, text = "Mover Malhas", subtext = "para MA101" } = props

    const _onClick = () => {
        if (!inFlight) onClick()
    }

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" className={transfersStyles.MoveButton} onClick={_onClick}>
            {inFlight ? <CircularProgress color="inherit" /> :
                <>
                    <p>{text}</p>
                    <Hide on={!subtext}>
                        <p>{subtext}</p>
                    </Hide>
                </>
            }
        </Grid>
    )

}


const PurgeTransfers = () => {

    const { data, loading, refetch } = useMeshTransfersQuery({
        variables: {
            filter: { state: { in: [MeshTransferStates.Created, MeshTransferStates.Pending] }, type: { eq: MeshTransferTypes.Purge } },
            paging: { limit: 999 },
            sorting: [{
                field: MeshTransferSortFields.Id, direction: SortDirection.Desc
            }]
        }
    })
    const [executeMeshTransfers, { loading: executeInFlight }] = useExecuteMeshTransfersMutation()
    const [updateMeshTransfer] = useUpdateOneMeshTransferMutation()
    const { successToast, errorToast } = useToast()
    const { selected, select, selectMany, isSelected, deselectAll } = useBulkActions<string>()

    const handleMainCheckbox = () => {
        selected.length >= 1 ? deselectAll() : selectAll()
    }

    const getCreatedMeshTransfers = () => {
        const createdMeshTransfers: string[] = []
        data?.meshTransfers.nodes.forEach(meshTransfer => {
            if (meshTransfer?.state === MeshTransferStates.Created) {
                createdMeshTransfers.push(meshTransfer?.id)
            }
        })
        return createdMeshTransfers
    }

    const selectAll = () => {
        const newSelected: string[] = getCreatedMeshTransfers()
        if (newSelected?.length) {
            selectMany(newSelected)
        }
    }

    const handleCheckboxClick = (event: MouseEvent<any>, meshTransfer: MeshTransfer) => {
        event.stopPropagation()
        if (meshTransfer?.state === MeshTransferStates.Created) {
            select(meshTransfer?.id)
        }
    };

    const handleTransfer = async () => {
        const response = await executeMeshTransfers({ variables: { input: { meshTransfers: selected } } })
        if (!response?.errors?.length) {
            successToast("Transferência bem sucedida.")
            deselectAll()
            refetch()
        } else {
            errorToast("Erro ao transferir malha.")
        }
    }

    const handlePendingClick = async (event: MouseEvent<HTMLButtonElement>, meshTransfer: MeshTransfer) => {
        event.stopPropagation()
        const state = meshTransfer?.state === MeshTransferStates.Created ? MeshTransferStates.Pending : MeshTransferStates.Created
        const response = await updateMeshTransfer({
            variables: {
                input: { id: meshTransfer?.id, update: { state } }
            }
        })
        if (!response?.errors?.length) {
            successToast("Transferência atualizada.")
            deselectAll()
            refetch()
        } else {
            errorToast("Erro ao atualizar transferência.")
        }
    }

    const isPurgePending = (meshTransfer: MeshTransfer) => {
        return meshTransfer?.state === MeshTransferStates.Pending
    }

    const isIndeterminate = () => selected?.length > 0 && selected?.length !== data?.meshTransfers?.nodes?.length
    const isMainCheckboxSelected = selected?.length === data?.meshTransfers.nodes.length
    const meshTransfers = data?.meshTransfers?.nodes
    const isEmpty = !loading && !meshTransfers?.length

    return (
        <>
            <Show on={!isEmpty}>
                <TableContainer component={Paper} className={transfersStyles.TransfersList} >
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#F1F1F1" }}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        onClick={handleMainCheckbox}
                                        color="primary"
                                        checked={isMainCheckboxSelected}
                                        indeterminate={isIndeterminate()}
                                        inputProps={{
                                            'aria-labelledby': "labelId",
                                        }}
                                    />
                                </TableCell>
                                <TableCell>Malha</TableCell>
                                <TableCell>Localizações</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {meshTransfers?.map(meshTransfer => {
                                const isItemSelected = isSelected(meshTransfer?.id);
                                const isPending = isPurgePending(meshTransfer as MeshTransfer)
                                const classes = clsx({ [styles.PendingRow]: isPending })
                                return (

                                    <TableRow key={meshTransfer?.id} hover onClick={(event) => handleCheckboxClick(event, meshTransfer as MeshTransfer)} className={classes}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => handleCheckboxClick(event, meshTransfer as MeshTransfer)}
                                                color="primary"
                                                checked={isItemSelected}
                                                disabled={isPending}
                                                inputProps={{
                                                    'aria-labelledby': "labelId",
                                                    disabled: isPending
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell className={styles.MeshCode}>{getMeshCode(meshTransfer?.mesh?.id)}</TableCell>
                                        <TableCell>
                                            {meshTransfer?.mesh?.meshLocations?.map(
                                                meshLocation => <p key={meshLocation?.id} className={styles.Location}>{meshLocation?.location?.name}</p>)}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={event => handlePendingClick(event, meshTransfer as MeshTransfer)}>
                                                <Pending className={styles.Pending} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Show>

            <Show on={loading}>
                <Loading />
            </Show>

            <Show on={isEmpty}>
                <EmptyView message="Sem transferências pendentes" />
            </Show>

            <Show on={!!selected?.length}>
                <MoveButton onClick={handleTransfer} inFlight={executeInFlight} />
            </Show>
        </>

    )
}

export default PurgeTransfers

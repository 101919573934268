import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useGuestRedirection } from '../../services/Hooks';
import Header from '../Header/Header';
import Leading from '../Leading/Leading';
import Sidemenu from '../Sidemenu/Sidemenu';
import TicketsGrid from '../TicketsGrid/TicketsGrid';
import TopicsGrid from '../TopicsGrid/TopicsGrid';
import styles from './Help.module.scss';

const HelpView = () => {

    useGuestRedirection()

    return (
        <>
            <Header />
            <Leading />
            <Sidemenu />
            <Grid container direction="column" className={styles.HelpView}>
                <TopicsGrid />
                <TicketsGrid />
            </Grid>
        </>
    )
}

export default HelpView;

import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSuppliersQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import { activeRouteOption } from '../../services/Utils';
import ConfigurationEditor from '../ConfigurationEditor/ConfigurationEditor';
import ConfigurationSelectList from '../ConfigurationSelectList/ConfigurationSelectList';
import Header from '../Header/Header';
import Leading from '../Leading/Leading';
import Loading from '../Loading/Loading';
import Sidemenu from '../Sidemenu/Sidemenu';
import SupplierConfiguration from '../SupplierConfiguration/SupplierConfiguration';
import styles from './Configurations.module.scss';


type ConfigurationOption = {
    label: string,
    route: string,
    func?: Function
    editor?: any
}

const Configurations = ({ match }: { match: any }) => {

    useGuestRedirection()


    const options: ConfigurationOption[] = [
        {
            label: 'acabamentos',
            route: '/configurations/finishing-types',
        },
        {
            label: 'amostras',
            route: '/configurations/sample-types',
        },
        {
            label: 'checklists',
            route: '/configurations/checklists',
        },
        {
            label: 'fornecedores',
            route: '/configurations/suppliers',
            func: useSuppliersQuery,
            editor: <SupplierConfiguration />
        },
        {
            label: 'marcas',
            route: '/configurations/samples'
        },
        {
            label: 'notificações',
            route: '/configurations/notifications',
        },
        {
            label: 'ornamentos',
            route: '/configurations/decorations',
        },
        {
            label: 'preços',
            route: '/configurations/prices',
        }
    ]

    const active = activeRouteOption(options, match)

    return (
        <>
            <Header />
            <Sidemenu />
            <Grid container alignItems="flex-end" className={styles.ConfigurationToolbar}>
                {
                    options.map((option: ConfigurationOption) => (
                        <NavLink key={option.route} to={option.route}
                            className={styles.ChecklistOption} activeClassName={styles.Active}>
                            {option.label}
                        </NavLink>)
                    )}
            </Grid>
            <ConfigurationsData active={active} />
        </>
    )
}

const ConfigurationsData = ({ active }: { active: any }) => {


    const [selected, setSelected] = useState<any>();
    const { data, refetch } = active.func ? active.func() : ''

    // temporary
    if (!active?.func) {
        return (
            <div style={{ fontSize: 28, fontWeight: 300, color: '#444', margin: 50 }}>
                Temporariamente Indisponível.
            </div>
        )
    }

    if (!data) {
        return <Loading />
    }

    const handleSelected = (item: any) => {
        setSelected(item)
    }

    const onClose = () => {
        setSelected(null);
    }

    return (
        <>
            <Grid container className={styles.ConfigurationArea}>
                <Grid item xs={5}>
                    <ConfigurationSelectList data={data} onSelected={handleSelected} />
                </Grid>
                {active?.editor && <Grid item xs={7}>
                    <ConfigurationEditor component={active.editor} selected={selected} close={onClose} refetch={refetch} />
                </Grid>}
            </Grid>
        </>
    )
}

export default Configurations;

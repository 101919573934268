import { jsPDF } from "jspdf";
import { breakLines, textToBase64Barcode } from '../../services/Utils';
import { convertKilos2Meters, convertMeters2Kilos } from "../MeshView/Conversion";
import { getMeshCode } from "../MeshView/MeshBarcode";
import { fullPurchaseOrder } from "../MeshView/MeshViewUtils";
import Logo from './Logo';
import { prepareField } from "./PrintUtils";
import Roboto from './Roboto';
import RobotoBold from './RobotoBold';

// CUSTOM FONTS
const ROBOTO_REGULAR = "Roboto-Regular"
const ROBOTO_BOLD = "Roboto-Bold"


// A4 - 210 x 297 mm
const A4 = {
    width: 210,
    height: 297
}

const MARGINS = {
    top: 10,
    left: 10,
    bottom: 10,
    right: 10,
}

// Padding

const PADDING = {
    left: 1,
    top: 3
}


const BOXES = {
    small: {
        width: 24,
        height: 4
    },
    half: {
        width: 26,
        height: 4
    },
    smallong: {
        width: 28,
        height: 4
    },
    tall: {
        width: 28,
        height: 8
    },
    medium: {
        width: 48,
        height: 4
    },
    intermediate: {
        width: 52,
        height: 4
    },
    squared: {
        width: 24,
        height: 16
    },
    long: {
        width: 80,
        height: 4
    },
    big: {
        width: 52,
        height: 28
    },
    double: {
        width: 48,
        height: 8
    },
    custom1: { // NORMAL (preenchimento tipo lavagem)
        width: 24,
        height: 8
    },
    custom2: {
        width: 52, // MEDIDAS ANTES LAVAGEM
        height: 8
    },
}

const COL1 = 10
const COL1_PADDED = COL1 + PADDING.left
const COL2 = 63
const COL2_PADDED = COL2 + PADDING.left
const COL3 = 92
const COL3_PADDED = COL3 + PADDING.left
const COL4 = 120
const COL4_PADDED = COL4 + PADDING.left
const COL5 = COL4 + 28
const COL5_5 = COL5 + 26
const COL5_PADDED = COL5 + PADDING.left
const COL6 = COL4 + 56
const COL6_PADDED = COL6 + PADDING.left

const ROW_HEIGHT = 4

const ROW1 = 12
const ROW1_PADDED = ROW1 + PADDING.top
const ROW2 = ROW1 + ROW_HEIGHT
const ROW2_PADDED = ROW2 + PADDING.top
const ROW3 = ROW2 + ROW_HEIGHT
const ROW3_PADDED = ROW3 + PADDING.top
const ROW4 = ROW3 + ROW_HEIGHT
const ROW4_PADDED = ROW4 + PADDING.top
const ROW5 = ROW4 + ROW_HEIGHT
const ROW5_PADDED = ROW5 + PADDING.top
const ROW6 = ROW5 + ROW_HEIGHT
const ROW6_PADDED = ROW6 + PADDING.top
const ROW7 = ROW6 + ROW_HEIGHT
const ROW7_PADDED = ROW7 + PADDING.top
const ROW8 = ROW7 + ROW_HEIGHT
const ROW8_PADDED = ROW8 + PADDING.top
const ROW9 = ROW8 + ROW_HEIGHT

// SECTION B
const ROW10 = ROW9 + ROW_HEIGHT
const ROW10_PADDED = ROW10 + PADDING.top
const ROW11 = ROW10 + ROW_HEIGHT
const ROW11_PADDED = ROW11 + PADDING.top
const ROW12 = ROW11 + ROW_HEIGHT
const ROW12_PADDED = ROW12 + PADDING.top
const ROW13 = ROW12 + ROW_HEIGHT
const ROW13_PADDED = ROW13 + PADDING.top
const ROW14 = ROW13 + ROW_HEIGHT
const ROW14_PADDED = ROW14 + PADDING.top
const ROW15 = ROW14 + ROW_HEIGHT
const ROW15_PADDED = ROW15 + PADDING.top
const ROW16 = ROW15 + ROW_HEIGHT
const ROW16_PADDED = ROW16 + PADDING.top
const ROW17 = ROW16 + ROW_HEIGHT
const ROW17_PADDED = ROW17 + PADDING.top
const ROW18 = ROW17 + ROW_HEIGHT
const ROW18_PADDED = ROW18 + PADDING.top

// SECTION C
const ROW19 = ROW18 + ROW_HEIGHT + 10 // section C offset
const ROW19_PADDED = ROW19 + PADDING.top
const ROW20 = ROW19 + ROW_HEIGHT
const ROW20_PADDED = ROW20 + PADDING.top
const ROW21 = ROW20 + ROW_HEIGHT
const ROW21_PADDED = ROW21 + PADDING.top
const ROW22 = ROW21 + ROW_HEIGHT
const ROW22_PADDED = ROW22 + PADDING.top
const ROW23 = ROW22 + ROW_HEIGHT
const ROW23_PADDED = ROW23 + PADDING.top
const ROW24 = ROW23 + ROW_HEIGHT
const ROW24_PADDED = ROW24 + PADDING.top
const ROW25 = ROW24 + ROW_HEIGHT
const ROW25_PADDED = ROW25 + PADDING.top
const ROW26 = ROW25 + ROW_HEIGHT
const ROW26_PADDED = ROW26 + PADDING.top
const ROW27 = ROW26 + ROW_HEIGHT
const ROW27_PADDED = ROW27 + PADDING.top
const ROW28 = ROW27 + ROW_HEIGHT
const ROW28_PADDED = ROW28 + PADDING.top

// SECTION D
const ROW29 = ROW28 + ROW_HEIGHT + 4 // section D offset
const ROW29_PADDED = ROW29 + PADDING.top
const ROW30 = ROW29 + ROW_HEIGHT
const ROW30_PADDED = ROW30 + PADDING.top
const ROW31 = ROW30 + ROW_HEIGHT
const ROW31_PADDED = ROW31 + PADDING.top
const ROW32 = ROW31 + ROW_HEIGHT
const ROW32_PADDED = ROW32 + PADDING.top
const ROW33 = ROW32 + ROW_HEIGHT
const ROW33_PADDED = ROW33 + PADDING.top
const ROW34 = ROW33 + ROW_HEIGHT
const ROW34_PADDED = ROW34 + PADDING.top
const ROW35 = ROW34 + ROW_HEIGHT
const ROW35_PADDED = ROW35 + PADDING.top
const ROW36 = ROW35 + ROW_HEIGHT
const ROW36_PADDED = ROW36 + PADDING.top
const ROW37 = ROW36 + ROW_HEIGHT
const ROW37_PADDED = ROW37 + PADDING.top
const ROW38 = ROW37 + ROW_HEIGHT
const ROW38_PADDED = ROW38 + PADDING.top
const ROW39 = ROW38 + ROW_HEIGHT
const ROW39_PADDED = ROW39 + PADDING.top

// SECTION E
const ROW40 = ROW39 + ROW_HEIGHT + 0.3 // section E offset
const ROW40_PADDED = ROW40 + PADDING.top
const ROW41 = ROW40 + ROW_HEIGHT
const ROW41_PADDED = ROW41 + PADDING.top


// font sizes
const SMALL = 5
const REGULAR = 7

// colors
const LIGHTGRAY = "#F4F4F4"

const FILLED = "FD" // filled with stroke

const print = (payload: any) => {


    if (!payload) return

    const quantityInPCS = payload.quantityUnit === 'PCS'

    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF();


    // add regular
    doc.addFileToVFS('Roboto-Regular-normal.ttf', Roboto);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');

    // add bold
    doc.addFileToVFS('Roboto-Bold-normal.ttf', RobotoBold);
    doc.addFont('Roboto-Bold-normal.ttf', 'Roboto-Bold', 'normal');


    doc.addImage(Logo, 'JPEG', COL1, ROW1, 27, 16)
    doc.setFillColor(LIGHTGRAY); // gray rectangle
    doc.setDrawColor(200, 200, 200); // stroke color

    // column1

    // surfaces
    doc.rect(COL1, ROW11, BOXES.medium.width, BOXES.medium.height, "FD")
    doc.rect(COL1, ROW12, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW12, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW13, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW13, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW14, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW14, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW15, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW15, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW16, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW16, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW17, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW17, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW18, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW18, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW18 + 4, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW18 + 4, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1, ROW18 + 8, BOXES.small.width, BOXES.small.height)
    doc.rect(COL1 + BOXES.small.width, ROW18 + 8, BOXES.small.width, BOXES.small.height)


    doc.rect(COL1, ROW22, BOXES.medium.width, BOXES.medium.height, FILLED)
    doc.rect(COL1, ROW24, BOXES.medium.width, BOXES.medium.height, FILLED)
    doc.rect(COL1, ROW26, BOXES.medium.width, BOXES.medium.height, FILLED)

    if (!quantityInPCS) {
        doc.rect(COL1, ROW28, BOXES.medium.width, BOXES.medium.height, FILLED)
    }

    doc.rect(COL1, ROW30, BOXES.double.width, BOXES.double.height, FILLED)
    doc.rect(COL1, ROW35, BOXES.medium.width, BOXES.medium.height, FILLED)
    doc.rect(COL1, ROW37, BOXES.medium.width, BOXES.medium.height, FILLED)
    doc.rect(COL1, ROW39, BOXES.medium.width, BOXES.medium.height, FILLED)
    doc.rect(COL1, ROW41, BOXES.medium.width, BOXES.medium.height, FILLED)

    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(SMALL)
    doc.text("Sample fabric inspection and tests report", COL1_PADDED, ROW7_PADDED)
    doc.text("Articles", COL1_PADDED, ROW10_PADDED)
    doc.text("Width (cm)", COL1_PADDED, ROW21_PADDED)
    doc.text("Weight (gr/sqm)", COL1_PADDED, ROW23_PADDED)

    if (!quantityInPCS) {
        doc.text("Quantity (kg)", COL1_PADDED, ROW25_PADDED)
        doc.text("Quantity (m)", COL1_PADDED, ROW27_PADDED)
    } else {
        doc.text("Quantity (pcs)", COL1_PADDED, ROW25_PADDED)
    }

    doc.text("Wash type", COL1_PADDED, ROW29_PADDED)
    doc.text("Width shrinkage", COL1_PADDED, ROW34_PADDED)
    doc.text("Length shrinkage", COL1_PADDED, ROW36_PADDED)
    doc.text("Spirality", COL1_PADDED, ROW38_PADDED)
    doc.text("Test executed by", COL1_PADDED, ROW40_PADDED)

    doc.setFont(ROBOTO_BOLD)
    doc.setFontSize(REGULAR)
    doc.text("RELATÓRIO DE TESTES MALHA DE AMOSTRAS (TMA)", COL1_PADDED, ROW8_PADDED)
    doc.line(COL1, ROW9, COL1 + (A4.width - MARGINS.left - MARGINS.right), ROW9)
    doc.text("ARTIGOS", COL1_PADDED, ROW11_PADDED)
    doc.text("LARGURA (cm)", COL1_PADDED, ROW22_PADDED)
    doc.text("GRAMAGEM (gr/m2)", COL1_PADDED, ROW24_PADDED)

    if (!quantityInPCS) {
        doc.text("QUANTIDADE (kg)", COL1_PADDED, ROW26_PADDED)
        doc.text("QUANTIDADE (m)", COL1_PADDED, ROW28_PADDED)
    } else {
        doc.text("QUANTIDADE (pcs)", COL1_PADDED, ROW26_PADDED)
    }

    doc.text("TIPO LAVAGEM", COL1_PADDED, ROW30_PADDED)
    doc.text("ENCOLHIMENTO LARGURA", COL1_PADDED, ROW35_PADDED)
    doc.text("ENCOLHIMENTO COMPRIMENTO", COL1_PADDED, ROW37_PADDED)
    doc.text("TORÇÃO", COL1_PADDED, ROW39_PADDED)
    doc.text("TESTE EXECUTADO POR", COL1_PADDED, ROW41_PADDED)

    // column2

    // surfaces
    doc.setFillColor(LIGHTGRAY); // gray rectangle
    doc.rect(COL2, ROW11, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL2, ROW12, BOXES.small.width, BOXES.small.height)
    doc.rect(COL2, ROW14, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL2, ROW15, BOXES.squared.width, BOXES.squared.height + 8)
    doc.rect(COL2, ROW20, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL2, ROW22, BOXES.small.width, BOXES.small.height)
    doc.rect(COL2, ROW24, BOXES.small.width, BOXES.small.height)

    // mostra campos de acordo com unidade
    if (payload?.quantityUnit !== "MTS") {
        doc.rect(COL2, ROW26, BOXES.small.width, BOXES.small.height)
    } else {
        doc.rect(COL2, ROW28, BOXES.small.width, BOXES.small.height)
    }

    doc.rect(COL2, ROW30, BOXES.custom1.width, BOXES.custom1.height)
    doc.rect(COL2, ROW33, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL2, ROW35, BOXES.small.width, BOXES.small.height)
    doc.rect(COL2, ROW37, BOXES.small.width, BOXES.small.height)
    doc.rect(COL2, ROW39, BOXES.small.width, BOXES.small.height)
    doc.rect(COL2, ROW41, BOXES.small.width, BOXES.small.height)

    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(SMALL)
    doc.text("State assessment", COL2_PADDED, ROW1_PADDED)
    doc.text("Date", COL2_PADDED, ROW3_PADDED)
    doc.text("Account Manager", COL2_PADDED, ROW5_PADDED)
    doc.text("Purchase order", COL2_PADDED, ROW10_PADDED)
    doc.text("Client", COL2_PADDED, ROW13_PADDED)
    doc.text("Required", COL2_PADDED, ROW19_PADDED)
    doc.text("Received", COL2_PADDED, ROW32_PADDED)

    doc.setFont(ROBOTO_BOLD)
    doc.setFontSize(REGULAR)
    doc.text("Indicar avaliação", COL2_PADDED, ROW2_PADDED)
    doc.text("DATA", COL2_PADDED, ROW4_PADDED)
    doc.text("COMERCIAL", COL2_PADDED, ROW6_PADDED)
    doc.text("REQUISIÇÃO", COL2_PADDED, ROW11_PADDED)
    doc.text("CLIENTE", COL2_PADDED, ROW14_PADDED)
    doc.text("PRETENDIDA", COL2_PADDED, ROW20_PADDED)
    doc.text("OBTIDA", COL2_PADDED, ROW33_PADDED)

    // column3

    //// surfaces
    doc.setFillColor(LIGHTGRAY); // gray rectangle
    doc.rect(COL3 - 7, ROW2, BOXES.smallong.width - 7, BOXES.smallong.height, FILLED) // ***
    doc.rect(COL3 - 7, ROW3, BOXES.tall.width - 7, BOXES.tall.height) // ***
    doc.rect(COL3 - 7, ROW5, BOXES.tall.width - 7, BOXES.tall.height) // ***
    doc.rect(COL3, ROW11, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL3, ROW12, BOXES.small.width, BOXES.small.height)
    doc.rect(COL3, ROW14, BOXES.intermediate.width, BOXES.intermediate.height, FILLED)
    doc.rect(COL3, ROW15, BOXES.intermediate.width, BOXES.intermediate.height)
    doc.rect(COL3, ROW17, BOXES.intermediate.width, BOXES.intermediate.height, FILLED)
    doc.rect(COL3, ROW18, BOXES.intermediate.width, BOXES.intermediate.height + 8)
    doc.rect(COL3, ROW20, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL3, ROW22, BOXES.small.width, BOXES.small.height)
    doc.rect(COL3, ROW24, BOXES.small.width, BOXES.small.height)
    doc.rect(COL3, ROW26, BOXES.small.width, BOXES.small.height)

    if (!quantityInPCS) {
        doc.rect(COL3, ROW28, BOXES.small.width, BOXES.small.height)
    }
    doc.rect(COL3, ROW30, BOXES.custom2.width, BOXES.custom2.height, FILLED)
    doc.rect(COL3, ROW33, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL3, ROW35, BOXES.small.width, BOXES.small.height)
    doc.rect(COL3, ROW37, BOXES.small.width, BOXES.small.height)
    doc.rect(COL3, ROW39, BOXES.small.width, BOXES.small.height)
    doc.rect(COL3, ROW41, BOXES.small.width, BOXES.small.height, FILLED)


    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(SMALL)
    doc.text("Approved", COL3_PADDED - 7, ROW1_PADDED)
    doc.text("Supplier", COL3_PADDED, ROW10_PADDED)
    doc.text("Fabric Type", COL3_PADDED, ROW13_PADDED)
    doc.text("Composition", COL3_PADDED, ROW16_PADDED)
    doc.text("Received", COL3_PADDED, ROW19_PADDED)
    doc.text("Delta", COL3_PADDED, ROW32_PADDED)
    doc.text("Date of fabric reception", COL3_PADDED, ROW40_PADDED)

    doc.setFont(ROBOTO_BOLD)
    doc.setFontSize(REGULAR)
    doc.text("APROVADO", COL3_PADDED - 7, ROW2_PADDED) // ***
    doc.text("FORNECEDOR", COL3_PADDED, ROW11_PADDED)
    doc.text("TIPO DE MALHA", COL3_PADDED, ROW14_PADDED)
    doc.text("COMPOSIÇÃO", COL3_PADDED, ROW17_PADDED)
    doc.text("OBTIDA", COL3_PADDED, ROW20_PADDED)
    doc.text("MEDIDAS ANTES DA LAVAGEM (cm):", COL3_PADDED, ROW30_PADDED)
    doc.text("QUADRADO / ESQUELETO", COL3_PADDED, ROW31_PADDED)
    doc.text("RESULTADO (%)", COL3_PADDED, ROW33_PADDED)
    doc.text("DATA RECEÇÃO", COL3_PADDED, ROW41_PADDED)

    // column4
    //// surfaces
    doc.setFillColor(LIGHTGRAY); // gray rectangle
    doc.rect(COL4 - 14, ROW2, BOXES.smallong.width - 7, BOXES.smallong.height, FILLED) // ***
    doc.rect(COL4 - 14, ROW3, BOXES.tall.width - 7, BOXES.tall.height) // ***
    doc.rect(COL4 - 14, ROW5, BOXES.tall.width - 7, BOXES.tall.height) // ***
    doc.rect(COL4, ROW12, BOXES.small.width, BOXES.small.height)
    doc.rect(COL4, ROW11, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL4, ROW20, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL4, ROW22, BOXES.small.width, BOXES.small.height)
    doc.rect(COL4, ROW24, BOXES.small.width, BOXES.small.height)

    // mostra campos de acordo com unidade
    if (payload?.quantityUnit !== "MTS") {
        doc.rect(COL4, ROW26, BOXES.small.width, BOXES.small.height)
    } else {
        doc.rect(COL4, ROW28, BOXES.small.width, BOXES.small.height)
    }

    doc.rect(COL4, ROW33, BOXES.long.width, BOXES.long.height, FILLED)
    doc.rect(COL4, ROW35, BOXES.long.width, BOXES.long.height)
    doc.rect(COL4, ROW37, BOXES.long.width, BOXES.long.height)
    doc.rect(COL4, ROW39, BOXES.long.width, BOXES.long.height)
    doc.rect(COL4, ROW41, BOXES.small.width, BOXES.small.height)

    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(SMALL)
    doc.text("Rejected", COL4_PADDED - 14, ROW1_PADDED)
    doc.text("Delta", COL4_PADDED, ROW19_PADDED)
    doc.text("Notes", COL4_PADDED, ROW32_PADDED)
    doc.text("Supplier doc", COL4_PADDED, ROW10_PADDED)

    doc.setFont(ROBOTO_BOLD)
    doc.setFontSize(REGULAR)
    doc.text("REJEITADO", COL4_PADDED - 14, ROW2_PADDED) // ***
    doc.text("GUIA FORN.", COL4_PADDED, ROW11_PADDED)
    doc.text("DESVIO", COL4_PADDED, ROW20_PADDED)
    doc.text("OBSERVAÇÕES", COL4_PADDED, ROW33_PADDED)


    // column5
    //// surfaces
    doc.setFillColor(LIGHTGRAY);
    doc.rect(COL5 - 21, ROW2, BOXES.intermediate.width - 12, BOXES.intermediate.height, FILLED) // ***
    doc.rect(COL5 - 21, ROW3, BOXES.half.width, BOXES.half.height)  // ***
    doc.rect(COL5 - 21, ROW4, BOXES.half.width, BOXES.half.height)  // ***
    doc.rect(COL5 - 21, ROW5, BOXES.half.width, BOXES.half.height) // ***
    doc.rect(COL5 - 21, ROW6, BOXES.half.width, BOXES.half.height) // ***

    doc.rect(COL5, ROW11, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL5, ROW12, BOXES.small.width, BOXES.small.height)
    doc.rect(COL5, ROW14, BOXES.intermediate.width, BOXES.intermediate.height, FILLED)
    doc.rect(COL5, ROW15, BOXES.intermediate.width, BOXES.intermediate.height)
    doc.rect(COL5, ROW17, BOXES.intermediate.width, BOXES.intermediate.height, FILLED)
    doc.rect(COL5, ROW18, BOXES.intermediate.width, BOXES.intermediate.height + 8)
    doc.rect(COL5, ROW20, BOXES.intermediate.width, BOXES.intermediate.height, FILLED)
    doc.rect(COL5, ROW22, BOXES.big.width, BOXES.big.height)
    doc.rect(COL5, ROW30, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL5, ROW31, BOXES.small.width, BOXES.small.height)
    doc.rect(COL5, ROW41, BOXES.small.width, BOXES.small.height, FILLED)

    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(SMALL)
    doc.text("Management", COL5_PADDED - 21, ROW1_PADDED) // ***
    doc.text("Fabric Supplier doc", COL5_PADDED, ROW10_PADDED)
    doc.text("Colour", COL5_PADDED, ROW13_PADDED)
    doc.text("Notes", COL5_PADDED, ROW16_PADDED)
    doc.text("Acceptance thresholds", COL5_PADDED, ROW19_PADDED)
    doc.text("Width (cm)", COL5_PADDED, ROW29_PADDED)
    doc.text("Date of test", COL5_PADDED, ROW40_PADDED)


    doc.setFont(ROBOTO_BOLD)
    doc.setFontSize(REGULAR)
    doc.text("TRATAMENTO", COL5_PADDED - 21, ROW2_PADDED)  // ***
    doc.text("100% INSPECTION", COL5_PADDED - 21, ROW3_PADDED)  // ***
    doc.text("REPAIR", COL5_PADDED - 21, ROW4_PADDED)  // ***
    doc.text("RETURN", COL5_PADDED - 21, ROW5_PADDED)  // ***
    doc.text("OTHER", COL5_PADDED - 21, ROW6_PADDED)  // ***

    doc.text("REQ. MALHEIRO", COL5_PADDED, ROW11_PADDED)
    doc.text("COR", COL5_PADDED, ROW14_PADDED)
    doc.text("OBSERVAÇÕES", COL5_PADDED, ROW17_PADDED)
    doc.text("INTERVALOS DE ACEITAÇÃO", COL5_PADDED, ROW20_PADDED)
    doc.text("LARG. (cm)", COL5_PADDED, ROW30_PADDED)
    doc.text("DATA TESTE", COL5_PADDED, ROW41_PADDED)


    // column5_5
    doc.setFillColor(LIGHTGRAY);
    doc.rect(COL5_5 - 21, ROW3, BOXES.half.width - 12, BOXES.half.height) // ***
    doc.rect(COL5_5 - 21, ROW4, BOXES.half.width - 12, BOXES.half.height) // ***
    doc.rect(COL5_5 - 21, ROW5, BOXES.half.width - 12, BOXES.half.height) // ***
    doc.rect(COL5_5 - 21, ROW6, BOXES.half.width - 12, BOXES.half.height) // ***


    // column6
    doc.setFillColor(LIGHTGRAY);
    doc.rect(COL6, ROW11, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL6, ROW12, BOXES.small.width, BOXES.small.height)
    doc.rect(COL6, ROW30, BOXES.small.width, BOXES.small.height, FILLED)
    doc.rect(COL6, ROW31, BOXES.small.width, BOXES.small.height)
    doc.rect(COL6, ROW41, BOXES.small.width, BOXES.small.height)

    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(SMALL)
    doc.text("Fabric Supplier", COL6_PADDED, ROW10_PADDED)
    doc.text("Length (cm)", COL6_PADDED, ROW29_PADDED)

    doc.setFont(ROBOTO_BOLD)
    doc.setFontSize(REGULAR)
    doc.text("MALHEIRO", COL6_PADDED, ROW11_PADDED)
    doc.text("COMP. (cm)", COL6_PADDED, ROW30_PADDED)


    // section E

    // swatch box definiton
    const swatch_box_x = 10
    const swatch_box_width = A4.width - swatch_box_x * 2
    const swatch_box_height = 86
    const swatch_box_y = 196

    doc.rect(swatch_box_x, swatch_box_y, swatch_box_width, swatch_box_height)
    doc.text("Imprimir o teste e agafar aqui um pedaço de malha recebida", 32, 234)
    doc.setFont(ROBOTO_REGULAR)
    doc.text("\\ Print the test report and staple a swatch of received fabric here", 100, 234)


    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(SMALL)

    // disclaimer
    let disclaimerXStart = 149
    let disclaimerYStart = 103 + 6
    let disclaimerLineHeight = 2.2
    doc.text("Gramagem: NOTA: Pesos superiores a 100grm: tol. +- 10gr;", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 0)
    doc.text("pesos inferiores a 100grm: tol +- 5gr / Weight: Note: For weights", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 1)
    doc.text("over 100gr/sqm: apply tolerance of +/- 10gr; for weights below", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 2)
    doc.text("100gr/sqm: apply tolerance of +- 5gr", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 3)


    disclaimerYStart = 117 + 6
    doc.text("Quantidade: NOTA: Partidas até 50kg: 18%máx; partidas acima", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 0)
    doc.text("de 50kg: 12 % máx; estampados a metro / malhas lyocell: 18 ", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 1)
    doc.text("% máx / Quantity: Note: Batches up to 50kg: 18 % max; batches", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 2)
    doc.text("above 50kg: 12 % max; prints by the meter or lyocell fabrics: ", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 3)
    doc.text("18 % max", disclaimerXStart, disclaimerYStart + disclaimerLineHeight * 4)

    // version
    doc.text("v.20220322", COL6 + 10, A4.height - MARGINS.bottom)



    /********
     ****
     *** data interpolation
     ****
     *********/

    doc.setFont(ROBOTO_REGULAR)
    doc.setFontSize(REGULAR)

    const slots = [
        // first column of articles
        { x: COL1_PADDED, y: ROW12_PADDED },
        { x: COL1_PADDED, y: ROW13_PADDED },
        { x: COL1_PADDED, y: ROW14_PADDED },
        { x: COL1_PADDED, y: ROW15_PADDED },
        { x: COL1_PADDED, y: ROW16_PADDED },
        { x: COL1_PADDED, y: ROW17_PADDED },
        { x: COL1_PADDED, y: ROW18_PADDED },
        // second column of articles
        { x: COL1_PADDED + BOXES.small.width + PADDING.left, y: ROW12_PADDED },
        { x: COL1_PADDED + BOXES.small.width + PADDING.left, y: ROW13_PADDED },
        { x: COL1_PADDED + BOXES.small.width + PADDING.left, y: ROW14_PADDED },
        { x: COL1_PADDED + BOXES.small.width + PADDING.left, y: ROW15_PADDED },
        { x: COL1_PADDED + BOXES.small.width + PADDING.left, y: ROW16_PADDED },
        { x: COL1_PADDED + BOXES.small.width + PADDING.left, y: ROW17_PADDED },
        { x: COL1_PADDED + BOXES.small.width + PADDING.left, y: ROW18_PADDED },
    ]

    // column1
    if (payload !== undefined && payload !== null) {
        const { sampleMeshes } = payload
        if (sampleMeshes !== undefined && sampleMeshes !== null) {
            // @ts-ignore
            sampleMeshes.map((sampleMesh, index) => doc.text("" + sampleMesh.sample.orderReference, slots[index].x, slots[index].y))
        }
    }
    // column2
    // @ts-ignore
    doc.text(fullPurchaseOrder(payload), COL2_PADDED, ROW12_PADDED)

    const meshSupplier = payload?.meshSupplier?.node?.name || payload?.meshSupplier?.name
    const supplier = payload?.supplier?.node?.name || payload?.supplier?.name
    const washType = payload?.washType?.node?.name || payload?.washType?.name
    const meshFabricType = payload?.meshFabricType?.node?.name || payload?.meshFabricType?.name

    const brand = payload?.brand?.node?.name || payload?.brand?.name
    const lines = breakLines(brand)

    const composition_lines = breakLines(payload.composition, 36)


    // quantidade em kilos - converter para metros
    lines.map((line, index) => doc.text(line, COL2_PADDED, ROW15_PADDED + index * PADDING.top))


    // column2
    doc.text(prepareField(payload?.widthRequired), COL2_PADDED, ROW22_PADDED)
    doc.text(prepareField(payload?.weightRequired), COL2_PADDED, ROW24_PADDED)


    if (payload?.quantityUnit === "KGS") {
        // quantidade inicial em kilos
        doc.text(prepareField(payload?.quantitySent), COL2_PADDED, ROW26_PADDED)
    } else if (payload?.quantityUnit === "MTS") {
        // quantidade inicial em metros
        doc.text(prepareField(payload?.quantitySent), COL2_PADDED, ROW28_PADDED)
    } else {
        doc.text(prepareField(payload?.quantitySent), COL2_PADDED, ROW26_PADDED)
    }

    doc.text(prepareField(washType), COL2_PADDED, ROW30_PADDED)
    doc.text(prepareField(payload.widthAfterWash), COL2_PADDED, ROW35_PADDED)
    doc.text(prepareField(payload.lengthAfterWash), COL2_PADDED, ROW37_PADDED)
    doc.text(prepareField(payload?.spiralityDeviation), COL2_PADDED, ROW39_PADDED)
    doc.text(prepareField(payload.inspectedBy.name), COL2_PADDED, ROW41_PADDED)

    // column3
    doc.text(prepareField(supplier), COL3_PADDED, ROW12_PADDED)
    doc.text(prepareField(meshFabricType), COL3_PADDED, ROW15_PADDED)
    composition_lines.map((line, index) => doc.text(line, COL3_PADDED, ROW18_PADDED + index * PADDING.top))
    doc.text(prepareField(payload?.widthReceived), COL3_PADDED, ROW22_PADDED)
    doc.text(prepareField(payload?.weightReceived), COL3_PADDED, ROW24_PADDED)


    if (payload?.quantityUnit === "KGS") {
        const quantityReceivedMeters = convertKilos2Meters(payload.widthReceived, payload.weightReceived, payload.quantityReceived)
        doc.text(prepareField(payload?.quantityReceived), COL3_PADDED, ROW26_PADDED)
        doc.text(prepareField(quantityReceivedMeters), COL3_PADDED, ROW28_PADDED)

    } else if (payload?.quantityUnit === "MTS") {
        const quantityReceivedKilos = convertMeters2Kilos(payload.widthReceived, payload.weightReceived, payload.quantityReceived)
        doc.text(prepareField(quantityReceivedKilos), COL3_PADDED, ROW26_PADDED)
        doc.text(prepareField(payload?.quantityReceived), COL3_PADDED, ROW28_PADDED)
    } else {
        doc.text(prepareField(payload?.quantityReceived), COL3_PADDED, ROW26_PADDED)
    }


    doc.text(prepareField(payload?.widthShrinkageRate, " %"), COL3_PADDED, ROW35_PADDED)
    doc.text(prepareField(payload?.lengthShrinkageRate, " %"), COL3_PADDED, ROW37_PADDED)
    doc.text(prepareField(payload?.spiralityResult, " %"), COL3_PADDED, ROW39_PADDED)

    // column4
    doc.text(prepareField(payload.supplierDocument), COL4_PADDED, ROW12_PADDED)
    doc.text(prepareField(payload.meshSupplierDocument), COL5_PADDED, ROW12_PADDED)
    doc.text(prepareField(payload?.widthDeviation, " cm"), COL4_PADDED, ROW22_PADDED)
    doc.text(prepareField(payload?.weightDeviation, " gr/m²"), COL4_PADDED, ROW24_PADDED)


    if (payload?.quantityUnit === "KGS") {
        // quantidade inicial em kilos
        doc.text(prepareField(payload?.quantityDifference, " %"), COL4_PADDED, ROW26_PADDED)
    } else if (payload?.quantityUnit === "MTS") {
        // quantidade inicial em metros
        doc.text(prepareField(payload?.quantityDifference, " %"), COL4_PADDED, ROW28_PADDED)
    } else {
        // quantidade inicial em pcs
        doc.text(prepareField(payload?.quantityDifference, " %"), COL4_PADDED, ROW26_PADDED)
    }

    doc.text(prepareField(payload.widthShrinkageObservations), COL4_PADDED, ROW35_PADDED)
    doc.text(prepareField(payload.lengthShrinkageObservations), COL4_PADDED, ROW37_PADDED)
    doc.text(prepareField(payload.spiralityObservations), COL4_PADDED, ROW39_PADDED)
    doc.text(prepareField(payload.receptionDate.split("T")[0]), COL4_PADDED, ROW41_PADDED)

    // column5
    doc.text(prepareField(payload?.meshSupplierDocument), COL5_PADDED, ROW12_PADDED)
    doc.text(prepareField(meshSupplier), COL6_PADDED, ROW12_PADDED)
    doc.text(prepareField(payload.color), COL5_PADDED, ROW15_PADDED)

    breakLines(payload?.colorObservations, 36).map((line, index) => doc.text(line, COL5_PADDED, ROW18_PADDED + index * PADDING.top))

    doc.text(prepareField(payload?.widthBeforeWash), COL5_PADDED, ROW31_PADDED)

    // column6
    doc.text(prepareField(payload.inspectionDate.split("T")[0]), COL6_PADDED, ROW41_PADDED)
    doc.text(prepareField(payload?.lengthBeforeWash), COL6_PADDED, ROW31_PADDED)

    // barcode
    const code = textToBase64Barcode(getMeshCode(payload.id))
    if (code) {
        doc.addImage(code, 'JPEG', 170, 15, 32, 23)
    }

    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');
}

export default print;

import { Grid } from '@material-ui/core';
import { FastField, useFormikContext } from "formik";
import { useHasPermission } from '../../services/permissions';
import { DeviationSpirality } from './Deviation';
import FieldText from './FieldText';
import styles from './MeshView.module.scss';

const SpiralitySection = (props: any) => {
    const { values } = useFormikContext()
    // @ts-ignore
    const { washType, spiralityDeviation, lengthAfterWash } = values

    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")

    return (

        <>
            {washType ? <> < div className={styles.Section} >
                <h3>Torção</h3>

                <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item xs={3}>
                        <FastField
                            component={FieldText}
                            name="spiralityDeviation"
                            label="Desvio"
                            type="number"
                            disabled={!canUpdate}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
                            <DeviationSpirality
                                a={spiralityDeviation}
                                b={lengthAfterWash}
                                forwardedRef={props.forwardedRef}
                            />

                        </div>
                    </Grid>
                </Grid>

                <Grid container className={styles.LineForm}>
                    <Grid item xs={10}>
                        <FastField
                            component={FieldText}
                            name="spiralityObservations"
                            type="text"
                            label="Observações"
                            fullWidth
                            autoComplete="off"
                            disabled={!canUpdate}
                        />
                    </Grid>
                </Grid>

            </div > </> : null}
        </>
    )
}

export default SpiralitySection

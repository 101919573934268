import { FC } from 'react';
import { Mesh } from '../../generated/graphql';
import { anyNullOrUndefined } from '../../services/Utils';
import { DeepPartial } from '../../typings/custom';
import { Meters2Kilos } from '../MeshView/Conversion';
import styles from './CurrentQuantity.module.scss';

interface CurrentQuantityProps {
    mesh: DeepPartial<Mesh>
}

const CurrentQuantity: FC<CurrentQuantityProps> = props => {
    const { mesh } = props
    const { quantityUnit, widthReceived, weightReceived, currentQuantity } = mesh

    if (quantityUnit === 'PCS') {
        return (
            <>
                <span className={styles.Quantity}>
                    {currentQuantity}
                </span>
                peças
            </>
        )
    }

    if (anyNullOrUndefined([widthReceived, weightReceived, currentQuantity])) {
        return <span>erro!</span>
    }

    return (
        <>
            <span className={styles.Quantity}>
                {currentQuantity} mts
            </span>
            <span>
                (
                <Meters2Kilos
                    width={widthReceived}
                    weight={weightReceived}
                    quantity={currentQuantity} /> kgs
                )
            </span>
        </>
    )
}

export default CurrentQuantity;

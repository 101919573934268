import { FormikValues } from "formik"
import { BrandEdge, Mesh, MeshDetailsQuery, MeshFabricTypeEdge, SupplierEdge } from "../../generated/graphql"
import { DeepPartial } from "../../typings/custom"

export const extractNodeValue = (value: any) => {
    try {
        return value.node.id
    } catch (e) {
        try {
            return value.id
        } catch (e) {
            return null
        }
    }
}

// option pode ser undefined ou
// pode ser {id: '6', name: 'SIDONIOS', __typename: 'Supplier'}
// ou pode ser {node: {…}, __typename: 'SupplierEdge'}
export const getOptionLabel = (option: SupplierEdge | BrandEdge | MeshFabricTypeEdge) => {
    if (option.node) return option.node.name
    // @ts-ignore
    return option?.name || ""
}

export const getInitialValues = (data?: MeshDetailsQuery) => {

    const today = new Date()

    if (data === undefined) return {
        // order section
        purchaseOrder: "",
        purchaseOrderType: "",
        brand: "",
        // width section
        widthReceived: "",
        widthRequired: "",
        // weight section
        weightReceived: "",
        weightRequired: "",
        // kind and color section
        color: "",
        colorObservations: "",
        composition: "",
        meshKind: "",
        meshFabricType: null,
        // shrinkage section
        washType: null,
        widthBeforeWash: 50,
        widthAfterWash: "",
        widthShrinkageObservations: "",
        lengthBeforeWash: 50,
        lengthAfterWash: "",
        lengthShrinkageObservations: "",
        // quantity section
        quantityUnit: "KGS",
        quantitySent: "",
        quantityReceived: "",
        // quantityObservations: ""
        // spirality section
        spiralityDeviation: "",
        spiralityObservations: "",
        // spiralityResult: "",

        // supplier section
        supplier: "",
        supplierDocument: "",
        meshSupplier: null,
        meshSupplierDocument: "",

        // inspection section
        inspectionDate: today,
        receptionDate: today,
        inspectedBy: "",
        debitedToClient: false
    }

    return {
        // order section
        id: data?.mesh?.id,
        purchaseOrderType: data?.mesh?.purchaseOrderType,
        purchaseOrder: data?.mesh?.purchaseOrder,
        brand: data?.mesh?.brand,

        // width section
        widthReceived: data.mesh?.widthReceived,
        widthRequired: data.mesh?.widthRequired,

        // weight section
        weightReceived: data.mesh?.weightReceived,
        weightRequired: data.mesh?.weightRequired,

        // kind and color section
        meshKind: data.mesh?.meshKind || "",
        color: data.mesh?.color,
        composition: data.mesh?.composition,
        colorObservations: data.mesh?.colorObservations,
        meshFabricType: data.mesh?.meshFabricType,

        // shrinkage section
        washType: data.mesh?.washType,
        widthBeforeWash: data.mesh?.widthBeforeWash,
        widthAfterWash: data.mesh?.widthAfterWash || "",
        widthShrinkageObservations: data.mesh?.widthShrinkageObservations,
        lengthBeforeWash: data.mesh?.lengthBeforeWash,
        lengthAfterWash: data.mesh?.lengthAfterWash || "",
        lengthShrinkageObservations: data.mesh?.lengthShrinkageObservations,

        // quantity section
        quantityUnit: data.mesh?.quantityUnit,
        quantitySent: data.mesh?.quantitySent,
        quantityReceived: data.mesh?.quantityReceived,

        // spirality section
        spiralityDeviation: data.mesh?.spiralityDeviation || "",
        spiralityObservations: data.mesh?.spiralityObservations,
        // spiralityResult: "",

        // supplier section
        supplier: data?.mesh?.supplier,
        supplierDocument: data.mesh?.supplierDocument,
        meshSupplier: data?.mesh?.meshSupplier,
        meshSupplierDocument: data.mesh?.meshSupplierDocument,

        // inspection section
        inspectedBy: data?.mesh?.inspectedBy,
        inspectionDate: data.mesh?.inspectionDate,
        receptionDate: data.mesh?.receptionDate,

        //
        spoolCount: data?.mesh?.spoolCount,
        debitedToClient: data?.mesh?.debitedToClient
    }
}



export const prepareCreateData = (values: FormikValues, id?: number) => {

    const {
        purchaseOrderType,
        purchaseOrder,
        supplierDocument,
        meshKind,
        color,
        colorObservations,
        widthReceived,
        widthRequired,
        widthObservations,
        quantityUnit,
        quantityReceived,
        quantitySent,
        quantityObservations,
        brand,
        supplier,
        location,
        washType,
        weightRequired,
        weightReceived,
        weightObservations,
        widthBeforeWash,
        widthAfterWash,
        widthShrinkageObservations,
        lengthBeforeWash,
        lengthAfterWash,
        lengthShrinkageObservations,
        spiralityDeviation,
        spiralityObservations,
        composition,
        meshSupplier,
        meshSupplierDocument,
        receptionDate,
        inspectionDate,
        quantitySentMeters,
        spoolCount,
        debitedToClient,
        meshFabricType
    } = values;

    const output = {
        purchaseOrder,
        supplierDocument,
        meshKind,
        color,
        colorObservations,
        composition,
        widthReceived: widthReceived || null,
        widthRequired: widthRequired || null,
        widthObservations,
        weightRequired: weightRequired || null,
        weightReceived: weightReceived || null,
        weightObservations,
        quantityUnit,
        quantitySent: quantitySent || null,
        quantityReceived: quantityReceived,
        quantityObservations,
        // relations
        brand: brand?.node?.id || brand.id,
        location: location?.node?.id,
        supplier: supplier?.node?.id || supplier.id,
        meshSupplier: meshSupplier?.node?.id || meshSupplier?.id,
        washType: washType?.node?.id || washType?.id,
        purchaseOrderType: purchaseOrderType?.node?.id || purchaseOrderType?.id,
        meshFabricType: meshFabricType?.node?.id || meshFabricType?.id,
        //
        meshSupplierDocument,
        widthBeforeWash,
        widthAfterWash: widthAfterWash || null,
        widthShrinkageObservations,
        lengthBeforeWash,
        lengthAfterWash: lengthAfterWash || null,
        lengthShrinkageObservations,
        spiralityDeviation: spiralityDeviation || null,
        spiralityObservations,
        receptionDate,
        inspectionDate,
        quantitySentMeters,
        spoolCount,
        debitedToClient
    }

    return output

}

export const fullPurchaseOrder = (mesh: DeepPartial<Mesh>) => {
    return mesh?.purchaseOrder ? `${mesh?.purchaseOrderType?.name}/${mesh.purchaseOrder}` : `${mesh?.purchaseOrderType?.name}`
}

import { setLocale } from 'yup';

function getEnv(envname: string) {
    // @ts-ignore
    return window._env_ ? window._env_[envname] : ''
}

const ErrorMap = [
    {
        key: "Available Quantity is not Enough!",
        value: "Quantidade de malha insuficiente.",
    },
    {
        key: "Unauthorized",
        value: "Não autorizado.",
    },
    {
        key: "duplicate key value violates unique constraint \"api_samplemesh_sample_id_mesh_id_9b788b1c_uniq\"",
        value: "Não é possível adicionar duas vezes a mesma referência."
    },
    {
        key: "Failed to fetch",
        value: "Erro de conexão."
    },
    {
        key: "Unable to find SampleMesh with id: 4574578",
        value: "Amostra não encontrada."
    }
]

const Config = {
    // @ts-ignore
    GRAPHQL_API: window._env_ ? window._env_.GRAPHQL_API : '',
    // @ts-ignore
    MEDIA_URL: window._env_ ? window._env_.MEDIA_URL : '',
    JWT_AUTH_HEADER_PREFIX: "Bearer",
    AUTH_TOKEN_KEY: "satellizer_token",
    MAIN_APP: "https://mypedrosa.com/",
    ERROR_MAP: ErrorMap,
    START_ROUTE: '/meshes',
    PAGE_SIZE: 25,
    // @ts-ignore
    AUTH_ENDPOINT: window._env_ ? window._env_.GRAPHQL_API + "/auth/login/" : '',
    DEFAULT_USER_AVATAR: 'default/user_avatar_740x781.png',

    // FEATURE TOGGLE
    ENABLE_MESH_AUTOCOMPLETE: getEnv("ENABLE_MESH_AUTOCOMPLETE") === "1",
    NO_OPTIONS_TEXT: "Sem Opções",
    DEFAULT_DATE_FORMAT: "dd'/'MM'/'yyyy"
};


// yup message configuration
setLocale({
    mixed: {
        default: 'Não é válido',
        required: 'Campo obrigatório.'
    },
    number: {
        // no-template-curly-in-string
        // as variáveis não são definidas
        // neste contexto, pelo que não se podemos
        // usar template strings
        // eslint-disable-next-line
        min: 'Tem que ser maior que ${min}',
        // eslint-disable-next-line
        max: 'Tem que ser menor que ${max}',
        integer: 'Campo tem que ser um número inteiro',
        positive: 'Tem que ser um número positivo'
    },
    string: {
        length: 'Tem que ter 8 digitos.',
        // eslint-disable-next-line
        min: 'Tem que ser maior que ${min}',
        // eslint-disable-next-line
        max: 'No máximo ${max} caracteres.'
    }
});

export default Config;

import { useMutation, useQuery } from '@apollo/react-hooks';
import { Grid, IconButton } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CameraIcon from '@material-ui/icons/Camera';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MenuIcon from '@material-ui/icons/Menu';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SettingsIcon from '@material-ui/icons/Settings';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import WavesIcon from '@material-ui/icons/Waves';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import LOGO from '../../assets/images/logo_pedrosa.png';
import { AuthUser, useAuth } from '../../services/auth';
import Config from '../../services/Config';
import { IS_MENU_OPEN, SET_MENU } from '../../services/Local';
import styles from './Sidemenu.module.scss';

type MenuItem = {
    id: number,
    route: Maybe<string>,
    url: Maybe<string>,
    label: string,
    handler: Function,
    icon: any,
    show?: Maybe<string | string[]>
}

interface SideMenuItemProps {
    user: AuthUser
    show?: Maybe<string | string[]>
}

const EnabledMenuItem = (item: any) => {
    if (item.url) {
        return (
            <a href={item.url}>
                <Grid container alignItems="center">
                    {item.icon} {item.label}
                </Grid>
            </a>
        )
    }
    return (
        <Link to={item.route} onClick={item.handler}>
            <Grid container alignItems="center">
                {item.icon} {item.label}
            </Grid>
        </Link>
    )
}

const authorized = (user: AuthUser, show: string | string[]) => {
    if (typeof show === "string" && user?.userType === show) return true
    if (show.includes(user?.userType)) return true
    return false
}

const SidemenuItem: FC<SideMenuItemProps> = (props) => {
    const { user, show } = props
    if (show && !authorized(user, show)) {
        return null;
    }
    return <EnabledMenuItem {...props} />;
};

const Sidemenu = () => {

    const { data } = useQuery(IS_MENU_OPEN);
    const [setMenu] = useMutation(SET_MENU);
    const { user } = useAuth()


    const closeMenu = () => {
        setMenu({ variables: { state: false } });
    };

    const onRouteChange = () => {
        closeMenu();
    };

    const menuItems: MenuItem[] = [
        {
            id: 1,
            route: null,
            url: Config.MAIN_APP + 'dashboard',
            label: "Dashboard",
            handler: onRouteChange,
            icon: <DashboardIcon />
        },
        {
            id: 2,
            route: null,
            url: Config.MAIN_APP + '?ordering=-order_reference',
            label: "Planeamento",
            handler: onRouteChange,
            icon: <DateRangeIcon />
        },
        {
            id: 3,
            route: null,
            url: Config.MAIN_APP + 'precos/?is_price_closed=false&ordering=-brand__priority',
            label: "Preços",
            handler: onRouteChange,
            icon: <LocalOfferIcon />
        },
        {
            id: 4,
            route: null,
            url: Config.MAIN_APP + 'registos',
            label: "Registo",
            handler: onRouteChange,
            icon: <AssignmentIcon />
        },
        {
            id: 5,
            route: '/meshes',
            url: null,
            label: "Malhas",
            handler: onRouteChange,
            icon: <WavesIcon />,
        },
        {
            id: 6,
            route: "/samples",
            url: null,
            label: "Associar Malhas",
            handler: onRouteChange,
            icon: <PlaylistAdd />
        },
        {
            id: 7,
            route: "/transfers",
            url: null,
            label: "Transferências",
            handler: onRouteChange,
            icon: <SwapHorizIcon />,
            show: ["TECH", "WAREHOUSE"]
        },
        {
            id: 8,
            route: '/scan',
            url: null,
            label: "Scan",
            handler: onRouteChange,
            icon: <CameraIcon />,
        },
        {
            id: 9,
            route: null,
            url: Config.MAIN_APP + 'utilizadores',
            label: "Utilizadores",
            handler: onRouteChange,
            icon: <AccountCircleIcon />
        },
        {
            id: 10,
            route: null,
            url: Config.MAIN_APP + 'relatorios',
            label: "Relatórios",
            handler: onRouteChange,
            icon: <AssessmentIcon />
        },
        {
            id: 11,
            route: null,
            url: Config.MAIN_APP + 'anuncios',
            label: "Anúncios",
            handler: onRouteChange,
            icon: <AnnouncementIcon />
        },
        {
            id: 12,
            route: null,
            url: Config.MAIN_APP + 'configuracoes/marcas',
            label: "Configurações",
            handler: onRouteChange,
            icon: <SettingsIcon />
        },
        {
            id: 13,
            route: '/configurations/suppliers',
            url: null,
            label: "Settings",
            handler: onRouteChange,
            icon: <SettingsIcon />,
            show: 'TECH'
        },
        {
            id: 14,
            route: '/suporte',
            url: null,
            label: "Suporte",
            handler: onRouteChange,
            icon: <ContactSupportIcon />
        },
        {
            id: 15,
            route: '/ocorrencias',
            url: null,
            label: "Ocorrências",
            handler: onRouteChange,
            icon: <ReportProblemIcon />
        }
    ];

    return (
        <Drawer open={data?.isMenuOpen} onClose={() => false} onBackdropClick={() => closeMenu()}>
            <Grid className={styles.SideMenuHeader} container justifyContent="flex-start" alignItems="center">
                <IconButton onClick={closeMenu}>
                    <MenuIcon />
                </IconButton>
                <img src={LOGO} alt="" />
            </Grid>
            <Grid className={styles.Sidemenu}>
                <Grid container direction="column" className={styles.Buttons}>
                    {menuItems.map(item => <SidemenuItem key={item.id} {...item} user={user} />)}
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default Sidemenu;

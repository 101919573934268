import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { ChangeEvent, FC } from 'react';
import { Mesh, MeshesQuery, MeshSortFields, Sample, SamplesQuery, SortDirection, useMeshesLazyQuery, useSamplesLazyQuery } from '../../generated/graphql';
import { DeepPartial, MuiInputVariant } from '../../typings/custom';
import CurrentQuantity from '../CurrentQuantity/CurrentQuantity';
import { getMeshCode } from '../MeshView/MeshBarcode';
import { fullPurchaseOrder } from '../MeshView/MeshViewUtils';
import styles from './SampleMeshSearch.module.scss';

interface SampleMeshSearchProps {
    onChange: (event: any) => void,
    variant?: MuiInputVariant
}

// fake event
export interface SampleMeshSearchByMeshSelected {
    target: {
        name: string,
        value: number
    }
}

const SampleMeshSearch: FC<SampleMeshSearchProps> = (props) => {
    const { onChange, variant } = props
    const [getSamples, { loading, data }] = useSamplesLazyQuery()

    /**
     * https://v4.mui.com/api/autocomplete/
     */
    const handle = (_: ChangeEvent<{}>, sample: DeepPartial<Sample>) => {
        if (sample?.id) {
            const event = {
                target: { name: "samplemesh", value: sample?.id }
            }
            onChange(event)
        }
    }

    /**
     * https://v4.mui.com/api/autocomplete/
     */
    const handleInputChange = (_: ChangeEvent<{}>, value: string) => {

        const filter = {
            orderReference: { eq: parseInt(value) },
            sampleType: { isVirtual: { is: false } },
            currentState: { sampleStateTypeId: { notIn: [9, 10] } }
        }

        // só fazer pedido à api se tivermos uma referencia completa
        if (value?.length === 6) {
            getSamples({ variables: { filter } })
        }
    }

    const renderOption = (sample: DeepPartial<Sample>) => {
        return (
            <div className={styles.RenderOption}>
                <p className={styles.Reference}>
                    #{sample?.orderReference} | {sample?.sampleType?.name} ({sample?.order})</p>
                <p>
                    <span className={styles.Brand}>
                        {sample?.brand?.name}
                    </span>
                </p>
            </div>
        )
    }

    const getOptionLabel = (sample: DeepPartial<Sample>) => {
        return `${sample?.orderReference}`
    }

    const getOptions = (data?: SamplesQuery) => {
        if (!data?.samples) return []
        return data?.samples.nodes
    }

    return (
        <Autocomplete
            id="process"
            options={getOptions(data)}
            onChange={handle}
            onInputChange={handleInputChange}
            loading={loading}
            renderOption={renderOption}
            disableClearable
            fullWidth
            getOptionLabel={getOptionLabel}
            noOptionsText="Sem Resultados"
            renderInput={
                (params) => {
                    return <TextField
                        {...params}
                        label="Pesquisar referência"
                        variant={variant}
                        data-cy="sampleMeshInput"
                    />
                }
            }
        />
    )
}

export const SampleMeshSearchByMesh: FC<SampleMeshSearchProps> = (props) => {
    const { onChange, variant } = props
    const [getMeshes, { loading, data }] = useMeshesLazyQuery()

    const handle = (_: ChangeEvent<{}>, mesh: DeepPartial<Mesh>) => {
        if (mesh?.id) {
            const event: SampleMeshSearchByMeshSelected = {
                target: { name: "samplemesh", value: mesh?.id }
            }
            onChange(event)
        }
    }

    const handleInputChange = (_: ChangeEvent<{}>, value: string) => {
        const filter = {
            id: { eq: parseInt(value) }
        }
        getMeshes({ variables: { filter, sorting: [{ field: MeshSortFields.Id, direction: SortDirection.Asc }] } })
    }

    const renderOption = (mesh: DeepPartial<Mesh>) => {
        return (
            <div className={styles.RenderOption}>
                <p className={styles.Reference}>
                    #{getMeshCode(mesh?.id)} | {fullPurchaseOrder(mesh)}</p>
                <p>
                    <span className={styles.Brand}>
                        {mesh?.brand?.name} <small>{mesh?.meshFabricType?.name} {mesh?.color ? `- ${mesh?.color}` : null}</small>
                    </span>
                </p>
                <p>
                    <CurrentQuantity mesh={mesh} />
                </p>
            </div>
        )
    }

    const getOptionLabel = (mesh: DeepPartial<Mesh>) => {
        return `${mesh?.id}`
    }

    const getOptions = (data?: MeshesQuery) => {
        return data?.meshes?.nodes || []
    }

    return (
        <>
            <br />
            <Autocomplete
                options={getOptions(data)}
                onChange={handle}
                onInputChange={handleInputChange}
                loading={loading}
                renderOption={renderOption}
                disableClearable
                fullWidth
                getOptionLabel={getOptionLabel}
                noOptionsText="Sem Resultados"
                renderInput={
                    (params) => {
                        return <TextField
                            {...params}
                            label="Pesquisar por id de malha"
                            helperText="não introduzir zeros à esquerda"
                            variant={variant}
                        />
                    }
                }
            />
        </>
    )
}

export default SampleMeshSearch;

import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import styles from './Deviation.module.scss';

const FieldText = ({ field, form, ...props }: { field: any, form: any, props: any }) => {

    const {
        // @ts-ignore
        disabled = false
    } = props

    const formikContext = useFormikContext()
    // @ts-ignore
    const { label } = props
    const error = (form.errors[field.name]) ? true : false
    const helperText = form.errors[field.name]
    // @ts-ignore
    const _type = props.type ? props.type : "text"
    // @ts-ignore
    const autoComplete = props.autoComplete ? props.autoComplete : "off"
    // @ts-ignore
    const autoFocus = props.autoFocus ? props.autoFocus : false

    // @ts-ignore
    const fullWidth = props.fullWidth ? props.fullWidth : false

    // @ts-ignore
    const InputLabelProps = props.InputLabelProps ? props.InputLabelProps : {}

    const className = formikContext.isSubmitting ? styles.Error : styles.Normal

    return (
        <TextField
            {...field}
            label={label}
            error={error}
            helperText={helperText}
            type={_type}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            InputProps={{ className }}
            fullWidth={fullWidth}
            disabled={disabled}
            InputLabelProps={InputLabelProps}
        />
    )
}

export default FieldText

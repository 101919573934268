import { createContext, useContext } from "react";
import { usePermissionsQuery } from "../generated/graphql";
import { AuthUser, useAuth } from "./auth";

const PermissionsContext = createContext<any | null>(null)

const buildPermissionName = (model: string, key: string) => {
    // para permissões a nível do objeto (create, read, update, delete)
    if (key.startsWith("_")) {
        return `${model}_${key.toUpperCase()}`
    }
    // para permissões a nível do campo
    return `${model}__${key.toUpperCase()}`
}

// vamos retornar uma lista de permissões para este utilizador
// calculadas a partir do utlizador e do tipo de utilizador
export const getUserPermissions = (user: AuthUser, permissions_list: any) => {

    const permissions: string[] = []

    Object.keys(permissions_list).forEach(model => {
        Object.keys(permissions_list[model]).forEach(key => {
            const list = permissions_list[model][key]
            if (list.includes("ALL") || list.includes(user.userType) || list.includes(user.id)) {
                const permission = buildPermissionName(model, key)
                permissions.push(permission)
            }
        })
    })
    return permissions
}

export const checkPermission = (user: AuthUser, permissions_list: any, permission: string) => {
    if (user.isSupervisor) return true
    const permissions = getUserPermissions(user, permissions_list)
    return permissions.includes(permission)
}


// hooks
export const useHasPermission = () => {
    const { user } = useAuth()
    const permissions_list = useContext(PermissionsContext)
    return (permission: string) => {
        if (!user) return false
        return checkPermission(user, permissions_list, permission)
    }
}

const PermissionsProvider = (props: any) => {
    const { data } = usePermissionsQuery()
    const value = data?.configuration?.value
    let permissions = {}
    if (value) {
        permissions = JSON.parse(value)
    }
    return <PermissionsContext.Provider value={permissions} {...props} />

}

export default PermissionsProvider

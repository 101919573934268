import { Button, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { CreateSampleMesh, useCreateManySampleMeshesMutation } from '../../generated/graphql';
import { SampleMeshSearchByMeshSelected } from '../SampleMeshSearch/SampleMeshSearch';
import SampleMeshWizardPrimary from './SampleMeshWizardPrimary';
import SampleMeshWizardSecondary from './SampleMeshWizardSecondary';
import styles from './SampleMeshWizard.module.scss'

export enum SampleMeshWizardMode {
    MESHES = "MESHES",
    SAMPLES = "SAMPLES",
}

export const inSamplesMode = (mode: SampleMeshWizardMode) => mode === SampleMeshWizardMode.SAMPLES
export const inMeshesMode = (mode: SampleMeshWizardMode) => mode === SampleMeshWizardMode.MESHES


interface SampleMeshWizardProps {
    selection: number[],
    mode: SampleMeshWizardMode,
    onClose: () => void
}

/**
 * selection é a selecção de itens para o primary component
 * selected é a selecção de itens para o secondary component
 */
const SampleMeshWizard: FC<SampleMeshWizardProps> = (props) => {

    const [selected, setSelected] = useState<number[]>([])
    const [error, setError] = useState<boolean>(false)
    const [createManySampleMeshes] = useCreateManySampleMeshesMutation()
    const { enqueueSnackbar } = useSnackbar();

    const { selection, mode, onClose } = props

    const preparePayload = () => {
        const sampleMeshes: CreateSampleMesh[] = []
        if (inMeshesMode(mode)) {
            selection?.forEach(mesh => {
                selected.forEach(sample => {
                    // @ts-ignore
                    sampleMeshes.push({ mesh, sample: parseInt(sample) })

                })
            })
        } else {
            selection?.forEach(sample => {
                selected.forEach(mesh => {
                    sampleMeshes.push({ mesh, sample })
                })
            })
        }

        return sampleMeshes
    }

    const handleSelect = (data: SampleMeshSearchByMeshSelected) => {
        setSelected([...selected, data.target.value])
    }

    const successToast = () => {
        enqueueSnackbar('Associações criadas com sucesso.', { variant: "success" });
    }

    const errorToast = () => {
        enqueueSnackbar('Erro! Não foi possível criar associações.', { variant: "error" });
    }

    const onSuccess = () => {
        successToast();
        onClose();
    }

    const handleClick = () => {
        const sampleMeshes = preparePayload()
        createManySampleMeshes({ variables: { input: { sampleMeshes } } }).then(
            response => {
                response?.data?.createManySampleMeshes.length ? onSuccess() : errorToast()
            }
        )
    }

    const handleRemove = (id: number) => {
        const newSelected = selected.filter(selected => selected !== id)
        setSelected(newSelected)
        return Promise.resolve(true);
    }

    const isEmpty = selected.length === 0
    const isDisabled = isEmpty || error

    return (
        <Grid container direction="row" justifyContent="space-between" className={styles.SampleMeshWizard}>
            <Grid container direction="column">
                <Grid item xs={6}>
                    <SampleMeshWizardPrimary selection={selection} mode={mode} />
                </Grid>
                <Grid item xs={6}>
                    <SampleMeshWizardSecondary selection={selection} selected={selected} onSelect={handleSelect} onRemove={handleRemove} onError={setError} mode={mode} />
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Button onClick={handleClick} color="primary" variant="contained" disabled={isDisabled}>Associar</Button>
            </Grid>
        </Grid>

    )
}

export default SampleMeshWizard;

import React, { FC } from 'react';
import styles from './Deviation.module.scss';

interface DeviationProps {
    a: any,
    b: any,
    units?: string,
    forwardedRef?: any,
}

interface DeviationWeightProps {
    received: any,
    required: any,
    units?: string,
    forwardedRef?: any,
}


interface DeviationShrinkageProps {
    a: any,
    b: any,
    units?: string,
    forwardedRef?: any,
}

interface DeviationSpiralityProps {
    a: any,
    b: any,
    places?: number,
    forwardedRef?: any,
}


interface DeviationQuantityProps {
    received: any,
    sent: any,
    unit: string,
    forwardRef?: any,
    places?: number
}


export const difference = (a: any, b: any) => {
    return a - b
}


// formatacao condicional - verde e vermelho
export const DeviationWidth: FC<DeviationProps> = ({ a, b, units, forwardedRef }) => {

    const deviation = difference(a, b)

    if (!a || !b) return null;

    let classes = styles.Info

    if (deviation < -5.1 || deviation > 5.1) {
        classes = styles.Error
    }

    if (!forwardedRef) {
        return (
            <span className={classes}><span>{deviation}</span>{units}</span>
        )
    }

    return (
        <span className={classes}><span ref={forwardedRef}>{deviation}</span>{units}</span>
    )
}

// formatacao condicional - verde (dentro do intervalo) e vermelho (fora do intervalo)
export const DeviationWeight: FC<DeviationWeightProps> = ({ received, required, units, forwardedRef }) => {

    const deviation = difference(received, required)

    if (!received || !required) return null;

    let classes = styles.Info

    const threshold = required < 100 ? 5 : 10

    if (deviation < -threshold || deviation > threshold) {
        classes = styles.Error
    }

    if (!forwardedRef) {
        return (
            <span className={classes}><span>{deviation}</span>{units}</span>
        )
    }

    return (
        <span className={classes}><span ref={forwardedRef}>{deviation}</span>{units}</span>
    )
}


// isto é uma reusable util, tem que se migrado para outra parte do projeto
// este método não garante dois lugares decimais, garante que não há mais
// do que dois lugares, pelo que não vai acontecer 27.80 ou 78.00
export const fixedFloat = (a: number, places: number = 2) => {
    return parseFloat(a.toFixed(places))
}

export const DeviationQuantity: FC<DeviationQuantityProps> = ({ received, sent, unit, forwardRef, places = 2 }) => {

    let deviation = difference(received, sent);

    if (!received || !sent || !deviation) return null;

    deviation = fixedFloat(deviation / sent * 100, places)

    const threshold = (unit === "KGS" && sent > 50) ? 12 : 18

    let classes = styles.Info

    if (deviation < -threshold || deviation > threshold) {
        classes = styles.Error
    }

    if (!forwardRef) {
        return (
            <span className={classes}><span>{deviation}</span>%</span>
        )
    }

    return (
        <span className={classes}><span ref={forwardRef}>{deviation}</span>%</span>
    )
}


export const DeviationSpirality: FC<DeviationSpiralityProps> = ({ a, b, forwardedRef, places = 2 }) => {

    if (!a || !b) return null;

    const deviation = fixedFloat(a / b * 100, places)

    const threshold = 6

    let classes = styles.Info

    if (deviation < -threshold || deviation > threshold) {
        classes = styles.Error
    }

    if (!forwardedRef) {
        return (
            <span className={classes}><span>{deviation}</span>%</span>
        )
    }

    return (
        <span className={classes}><span ref={forwardedRef}>{deviation}</span>%</span>
    )
}

// formatacao condicional - verde e vermelho
export const DeviationShrinkage: FC<DeviationShrinkageProps> = ({ a, b, units, forwardedRef }) => {

    if (!a || !b) return null;

    const deviation = fixedFloat((difference(a, b) / b * 100), 2)

    const threshold = 6

    let classes = styles.Info

    if (deviation < -threshold || deviation > threshold) {
        classes = styles.Error
    }

    if (!forwardedRef) {
        return (
            <span className={classes}><span>{deviation}</span>{units}</span>
        )
    }

    return (
        <span className={classes}><span ref={forwardedRef}>{deviation}</span>{units}</span>
    )
}

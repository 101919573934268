import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

interface AutoTextFieldProps {
    value: any,
    name: any,
    onSave: any,
    interval?: number,
    fullWidth?: boolean,
    clearable?: boolean,
    placeholder: string
    InputProps?: any
}

const Clearable = ({ onClear }: { onClear: any }) => {
    return (
        <IconButton onClick={onClear}>
            < CloseIcon fontSize="small" />
        </IconButton>
    )
}


const AutoTextField = ({ onSave, value, name, fullWidth, clearable, ...props }: AutoTextFieldProps) => {

    const AUTOSAVE_INTERVAL = props.interval || 2000;

    const [lastData, setLastData] = useState(null);
    const [data, setData] = useState(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const timer = window.setTimeout(() => {
            // @ts-ignore
            if (lastData[name] !== data[name]) {
                onSave(data);
                setLastData(data);
            }
        }, AUTOSAVE_INTERVAL);
        return () => window.clearTimeout(timer);
    }, [data]);

    const handleChange = (event: any) => {
        // @ts-ignore
        setData({ [name]: event.target.value });
    };

    const handleClear = () => {
        // @ts-ignore
        setData({ [name]: '' })
    }


    if (!data) {
        // @ts-ignore
        setData({ [name]: value });
        // @ts-ignore
        setLastData({ [name]: value });
    }

    // @ts-ignore
    const v = data && data[name] ? data[name] : ''

    const endAdornment = clearable && value ? <Clearable onClear={handleClear} /> : null

    return (
        <TextField value={v}
            name={name}
            fullWidth={fullWidth}
            onChange={handleChange} {...props}
            InputProps={{
                endAdornment
            }}
        />
    );
};

export default AutoTextField;

import { Grid, IconButton, TextField } from '@material-ui/core/';
import { Add, Close } from '@material-ui/icons';
import { FC, useState } from 'react';
import { useCreateOneSupplierAliasMutation, useDeleteOneSupplierAliasMutation, useSupplierAliasesQuery } from '../../generated/graphql';
import useToast from '../../hooks/useToast';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import styles from './SupplierAliases.module.scss';

interface SupplierAliasesProps {
    supplierId: string
}

const SupplierAliases: FC<SupplierAliasesProps> = props => {
    const [externalId, setExternalId] = useState<string>("")

    const { supplierId } = props

    const { data, refetch } = useSupplierAliasesQuery({ variables: { filter: { supplier: { id: { eq: supplierId } } } } })
    const [createSupplierAlias] = useCreateOneSupplierAliasMutation()
    const [deleteSupplierAlias] = useDeleteOneSupplierAliasMutation()
    const { successToast, errorToast } = useToast()

    const handleChange = (event: any) => {
        setExternalId(event?.target?.value)
    }

    const handleCreate = async (externalId: string) => {
        try {
            const response = await createSupplierAlias({
                variables: { input: { supplierAlias: { externalId, supplier: supplierId } } }
            })
            if (!response?.errors?.length) {
                successToast("Alias criado com sucesso")
                setExternalId("")
                refetch()
            } else {
                errorToast("Erro ao criar alias")
            }
        } catch (e: unknown) {
            if (e !== null && typeof e === "object" && "message" in e) {
                const errorMessage: string = (e as { message: string }).message;
                if (errorMessage.includes("duplicate key value violates unique constraint")) {
                    errorToast("Erro ao criar alias. Verifique o identificador, este alias já existe.");
                }
            }
        }
    }

    const handleDelete = async (alias: any) => {
        return deleteSupplierAlias({ variables: { input: { id: alias?.id } } }).then(() =>
            refetch()
        )
    }

    return (
        <Grid container className={styles.SupplierAliases}>
            <h3>Identificadores Macwin</h3>
            {data?.supplierAliases?.nodes?.map(alias => (

                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={8}>
                        {alias?.externalId}
                    </Grid>
                    <Grid item xs={2}>
                        <ConfirmButton onClick={() => handleDelete(alias)}>
                            <Close fontSize="small" />
                        </ConfirmButton>
                    </Grid>
                </Grid>

            ))}
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={8}>
                    <TextField
                        placeholder="Novo alias"
                        name="externalId"
                        value={externalId} onChange={handleChange} />
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={() => handleCreate(externalId)}>
                        <Add fontSize="small" />
                    </IconButton>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default SupplierAliases

import React, { FC } from 'react';
import { Maybe } from '../../generated/graphql';
import { fixedFloat } from './Deviation';

interface Kilos2MetersProps {
    width?: Maybe<number | string>,
    weight?: Maybe<number | string>,
    quantity?: Maybe<number>
}

export const Kilos2Meters: FC<Kilos2MetersProps> = ({ width, weight, quantity }) => {
    if (!width || !weight || !quantity) return null
    // @ts-ignore
    const _width: number = typeof (width === "string") ? parseFloat(width) : width
    // @ts-ignore
    const _weight: number = typeof (weight === "string") ? parseFloat(weight) : weight

    const result = (((1 / (_width / 100)) / (_weight / 1000)) * quantity)

    return <span>{fixedFloat(result)} metros</span>
}

interface Meters2KilosProps {
    width?: Maybe<number | string>,
    weight?: Maybe<number | string>,
    quantity?: Maybe<number>
}

export const convertKilos2Meters = (width: number, weight: number, quantity: number): number => {
    const result = (((1 / (width / 100)) / (weight / 1000)) * quantity)
    return fixedFloat(result)
}

// https://www.onlineclothingstudy.com/2015/10/how-to-convert-knitted-fabric_7.html
export const convertMeters2Kilos = (width: number, weight: number, quantity: number) => {
    const result = ((quantity * 100) * width * weight) / 10000000
    return fixedFloat(result)
}

/**
 * converte uma quantidade de malha dada em metros num quantidade em kgs
 *
 * width = mesh.widthReceived
 * weight = mesh.weightReceived
 * quantity = mesh.currentQuantity
 */
export const Meters2Kilos: FC<Meters2KilosProps> = ({ width, weight, quantity }) => {

    if (!width || !weight || quantity === undefined || quantity === null) return null
    // @ts-ignore
    const _width: number = typeof (width === "string") ? parseFloat(width) : width
    // @ts-ignore
    const _weight: number = typeof (weight === "string") ? parseFloat(weight) : weight

    return <span>{convertMeters2Kilos(_width, _weight, quantity)}</span>
}

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC } from 'react';
import { LocationsQuery, useLocationsLazyQuery } from '../../generated/graphql';
import styles from './MeshLocationSearch.module.scss';

interface MeshLocationSearchProps {
    onChange: (event: any) => void,
    variant?: any
}

const MeshLocationSearch: FC<MeshLocationSearchProps> = ({ onChange, variant }) => {

    const [getLocations, { loading, data }] = useLocationsLazyQuery()

    const handle = (_: any, data: any) => {
        if (data?.node?.id) {
            const event = {
                target: { name: "samplemesh", value: data?.node?.id }
            }
            onChange(event)
        }
    }

    const handleInputChange = (event: any) => {
        const value = event.target.value

        const filter = {
            name: { iLike: `%${value}%` }
        }
        getLocations({ variables: { filter } })
    }

    const renderOption = (option: any) => {
        return (
            <div className={styles.RenderOption}>
                <p className={styles.Reference}>
                    {option?.node?.name}
                </p>
            </div>
        )
    }

    const getOptionLabel = (option: any) => {
        return "" + option?.node?.name
    }

    const getOptions = (data?: LocationsQuery) => {
        if (!data?.locations) return []
        return data?.locations.edges
    }

    return (
        <Autocomplete
            options={getOptions(data)}
            onChange={handle}
            onInputChange={handleInputChange}
            loading={loading}
            renderOption={renderOption}
            disableClearable
            fullWidth
            getOptionLabel={getOptionLabel}
            noOptionsText="Sem Resultados"
            renderInput={
                (params) => {
                    return <TextField
                        {...params}
                        label="Pesquisar localização"
                        variant={variant}
                    />
                }
            }
        />
    )
}

export default MeshLocationSearch;

import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { SystemUpdateAlt } from '@material-ui/icons';
import { useFormikContext } from "formik";
import { FC, useEffect, useState } from 'react';
import { AutocompleteMesh, useAutocompleteMeshesLazyQuery } from "../../generated/graphql";
import CustomModal from "../CustomModal/CustomModal";
import Empty from '../Empty/Empty';
import styles from './Autocompletes.module.scss';

interface AutocompletesProps {
    isActive: any
}


interface AutoCompleteMeshesListProps {
    autoCompleteMeshes: any
    onSelect: (autocompleteMesh: any) => void
}

const AutoCompleteMeshesList = (props: AutoCompleteMeshesListProps) => {
    const { autoCompleteMeshes, onSelect } = props
    return (
        <Grid container justifyContent="space-between" className={styles.AutoCompleteMeshesList}>
            <Grid container className={styles.Header}>
                <Grid item xs={8}>Descrição</Grid>
                <Grid item xs={3}>Cor</Grid>
                <Grid item xs={1}></Grid>
            </Grid>
            {
                autoCompleteMeshes?.map((autocompleteMesh: AutocompleteMesh) => (
                    <Grid container key={autocompleteMesh?.description} alignItems="center" className={styles.Row}>
                        <Grid item xs={8}>
                            {autocompleteMesh?.description}
                        </Grid>
                        <Grid item xs={3}>
                            {autocompleteMesh?.color}
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => onSelect(autocompleteMesh)}>
                                <SystemUpdateAlt fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))
            }
        </Grid>
    )
}


const Autocompletes: FC<AutocompletesProps> = props => {

    const { isActive } = props
    const context: any = useFormikContext()
    const [autocomplete, { data, loading }] = useAutocompleteMeshesLazyQuery()
    const [showModal, setShowModal] = useState<boolean>(false)

    const purchaseOrderType = context?.values?.purchaseOrderType?.node?.name as string
    const purchaseOrder = context?.values?.purchaseOrder as string

    const canMakeAutocompleteRequest = () => {
        // @ts-ignore
        return purchaseOrderType && purchaseOrder?.length === 8 && !loading
    }

    useEffect(() => {
        if (canMakeAutocompleteRequest()) {
            autocomplete({ variables: { fullPurchaseOrder: `${purchaseOrderType}+${purchaseOrder}` } })
            setShowModal(true)
        }
        // @ts-ignore
    }, [purchaseOrderType, purchaseOrder]);

    const handleSelect = (autocompleteMesh: any) => {
        Object.entries(autocompleteMesh).forEach(entry => {
            const [key, value] = entry
            if (value !== 'AutocompleteMesh') {
                context?.setFieldValue(key, value)
            }
        })
        setShowModal(false)
    }

    if (!isActive) return null

    return (
        <>
            <CustomModal isActive={showModal} handleClose={() => setShowModal(false)} maxWidth="md">
                <Grid container direction="column" className={styles.AutoCompletesWrapper}>
                    <h3>
                        Importar Malha
                    </h3>

                    {
                        data?.autocompleteMeshes?.length ?
                            <AutoCompleteMeshesList autoCompleteMeshes={data?.autocompleteMeshes} onSelect={handleSelect} />
                            : <Empty message="Malhas não encontradas." />}

                    <Grid container justifyContent="flex-end">
                    </Grid>
                </Grid>
            </CustomModal>
        </>
    )
}

export default Autocompletes

import { Card, CardContent, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DescriptionIcon from '@material-ui/icons/Description';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import React, { FC, useState } from 'react';
import { SupportTopic, SupportTopicsQuery, useSupportTopicsQuery } from '../../generated/graphql';
import CustomModal from '../CustomModal/CustomModal';
import FormatDate from '../FormatDate/FormatDate';
import Loading from '../Loading/Loading';
import TopicDetails from '../TopicDetails/TopicDetails';
import styles from './TopicsGrid.module.scss';

const TopicIcon = ({ type }: { type: string | undefined | null }) => {
    const icons: any = {
        TEXT: <ContactSupportIcon className={styles.TopicIcon} />,
        PDF: <DescriptionIcon className={styles.TopicIcon} />,
        VIDEO: <OndemandVideoIcon className={styles.TopicIcon} />
    };
    return type ? icons[type] : <ContactSupportIcon className={styles.TopicIcon} />
}

const TopicPreview = ({ topic, show }: { topic: SupportTopic, show: any }) => {
    return (
        <Card onClick={() => show(topic)} className={styles.TopicPreview}>
            <CardContent className={styles.TopicPreviewContent}>
                <Grid container direction="column" justifyContent="space-between">
                    <TopicIcon type={topic.type} />
                    <Typography variant="body2" className={styles.TopicName}>
                        {topic.name}
                    </Typography>
                    <Grid item className={styles.LastUpdate}>
                        <Tooltip title="última atualização">
                            <Typography variant="caption">
                                <FormatDate date={topic.updatedAt} />
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const TopicsEmptyState = () => {
    return <div>Não existem tópicos de ajuda.</div>
}

interface TopicsGridProps {
    data: SupportTopicsQuery | undefined,
    show: (topic: SupportTopic) => void
}

const TopicsGrid: FC<TopicsGridProps> = ({ data, show }) => {
    if (!data) return <Loading />
    if (!data.supportTopics.edges.length) return <TopicsEmptyState />
    return (
        <Grid container>
            {data.supportTopics.edges.map(edge => <TopicPreview key={edge.node.id} topic={edge.node} show={show} />)}
        </Grid>
    )
}

const TopicsView = () => {
    const { data } = useSupportTopicsQuery();
    const [showTopic, setShowTopic] = useState(0)

    const show = (topic: SupportTopic) => {
        setShowTopic(topic.id)
    }

    return (
        <Grid container direction="column" alignItems="center">
            <Typography variant="h2">
                FAQs & Documentação
            </Typography>
            <Typography variant="subtitle1" paragraph>
                Questões frequentes e documentação do software e processos
            </Typography>
            <TopicsGrid data={data} show={show} />
            <CustomModal isActive={Boolean(showTopic)} handleClose={() => setShowTopic(0)} maxWidth="md">
                <TopicDetails id={showTopic} />
            </CustomModal>
        </Grid>
    )
}

export default TopicsView;

import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';


const rawTheme = createTheme({
    palette: {
        primary: {
            // @ts-ignore
            ligth: '#fff',
            main: '#8b0000',
            dark: '#db051b',

        },
        secondary: {
            light: '#fff5f8',
            main: '#8b0000',
            dark: '#e62958',
        },
        warning: {
            main: '#ffc071',
            dark: '#ffb25e',
        },
        error: {
            main: red[500],
            dark: red[700],
        },
        success: {
            main: green[50],
            dark: green[700],
        },
    },
    typography: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 14,
        fontWeightLight: 300, // Work Sans
        fontWeightRegular: 400, // Work Sans
        fontWeightMedium: 500, // Roboto Condensed
        // fontFamilySecondary: "'Roboto', sans-serif",
        // useNextVariants: true,
    },
});

const fontHeader = {
    color: rawTheme.palette.text.primary,
    fontWeight: rawTheme.typography.fontWeightMedium,
    // @ts-ignore
    fontFamily: rawTheme.typography.fontFamilySecondary,
    textTransform: 'uppercase',
};

const theme = {
    ...rawTheme,
    palette: {
        ...rawTheme.palette,
        background: {
            ...rawTheme.palette.background,
            default: rawTheme.palette.common.white,
            placeholder: grey[200],
        },
    },
    typography: {
        ...rawTheme.typography,
        fontHeader,
        h1: {
            ...rawTheme.typography.h1,
            ...fontHeader,
            letterSpacing: 0,
            fontSize: 36,
        },
        h2: {
            ...rawTheme.typography.h2,
            ...fontHeader,
            textTransform: 'none',
            fontSize: 28,
            color: '#4a4a4a',
            fontWeight: 600
        },
        h3: {
            ...rawTheme.typography.h3,
            ...fontHeader,
            fontSize: 24,
        },
        h4: {
            ...rawTheme.typography.h4,
            ...fontHeader,
            textTransform: 'none',
            fontSize: 22,
        },
        h5: {
            ...rawTheme.typography.h5,
            fontSize: 20,
            fontWeight: rawTheme.typography.fontWeightLight,
        },
        h6: {
            ...rawTheme.typography.h6,
            ...fontHeader,
        },
        subtitle1: {
            ...rawTheme.typography.subtitle1,
            fontSize: 16,
            fontWeight: 300,
            color: '#4a4a4a'
        },
        body1: {
            ...rawTheme.typography.body1,
            fontSize: 16,
            color: '#4a4a4a'
        },
        body2: {
            ...rawTheme.typography.body1,
            fontSize: 14,
        },
        caption: {
            ...rawTheme.typography.body1,
            fontSize: 10
        }
    },
};

export default theme;

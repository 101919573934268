import { Checkbox, Fade, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { MouseEvent } from 'react';
import { MeshTransferStates, MeshTransferTypes, useMeshTransfersQuery, useUpdateManyMeshTransfersMutation } from "../../generated/graphql";
import useBulkActions from '../../hooks/useBulkActions';
import useToast from '../../hooks/useToast';
import { EmptyView } from '../Empty/Empty';
import Loading from '../Loading/Loading';
import { getMeshCode } from '../MeshView/MeshBarcode';
import { MoveButton } from '../PurgeTransfers/PurgeTransfers';
import Show from '../Show/Show';
import styles from '../Transfers/Transfers.module.scss';

const PickingTransfers = () => {
    const { data, loading, refetch } = useMeshTransfersQuery({ variables: { filter: { state: { eq: MeshTransferStates.Created }, type: { eq: MeshTransferTypes.Picking } }, paging: { limit: 999 } } })
    const [updateMeshTransfers, { loading: updateInFlight }] = useUpdateManyMeshTransfersMutation()
    const { successToast, errorToast } = useToast()
    const { selected, select, selectMany, isSelected, deselectAll } = useBulkActions<string>()

    const handleMainCheckbox = () => {
        selected.length >= 1 ? deselectAll() : selectAll()
    }

    const selectAll = () => {
        const newSelected: string[] | undefined = data?.meshTransfers.nodes.map(meshTransfer => meshTransfer?.id)
        if (newSelected?.length) {
            selectMany(newSelected)
        }
    }

    const handleCheckboxClick = (event: MouseEvent<any>, id: string) => {
        event.stopPropagation()
        select(id)
    };

    const handleTransfer = async () => {
        const response = await updateMeshTransfers({ variables: { input: { filter: { id: { in: selected } }, update: { state: MeshTransferStates.Completed } } } })
        if (!response?.errors?.length) {
            successToast("Transferência bem sucedida.")
            deselectAll()
            refetch()
        } else {
            errorToast("Erro ao transferir malha.")
        }
    }

    const isIndeterminate = () => selected?.length > 0 && selected?.length !== data?.meshTransfers?.nodes?.length
    const isMainCheckboxSelected = selected?.length === data?.meshTransfers.nodes.length
    const meshTransfers = data?.meshTransfers?.nodes
    const isEmpty = !loading && !meshTransfers?.length

    return (
        <Fade in={!loading}>
            <>
                <Show on={loading}>
                    <Loading />
                </Show>

                <Show on={!isEmpty}>
                    <TableContainer component={Paper} className={styles.TransfersList} >
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: "#F1F1F1", color: "yellow" }}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            onClick={handleMainCheckbox}
                                            color="primary"
                                            checked={isMainCheckboxSelected}
                                            indeterminate={isIndeterminate()}
                                            inputProps={{
                                                'aria-labelledby': "labelId",
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>Malha</TableCell>
                                    <TableCell>Localizações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {meshTransfers?.map(meshTransfer => {
                                    const isItemSelected = isSelected(meshTransfer?.id);
                                    return (

                                        <TableRow key={meshTransfer.id} hover onClick={(event) => handleCheckboxClick(event, meshTransfer.id)}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleCheckboxClick(event, meshTransfer.id)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': "labelId",
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell className={styles.MeshCode}>{getMeshCode(meshTransfer?.mesh?.id)}</TableCell>
                                            <TableCell>
                                                <Show on={!meshTransfer?.mesh?.meshLocations?.length}>
                                                    <small className={styles.Location}>Sem Localização</small>
                                                </Show>
                                                {meshTransfer?.mesh?.meshLocations?.map(
                                                    meshLocation => <p key={meshLocation.id} className={styles.Location}>
                                                        {meshLocation?.location?.name || "Sem Localização"}
                                                    </p>)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Show>

                <Show on={loading}>
                    <Loading />
                </Show>

                <Show on={isEmpty}>
                    <EmptyView message="Sem transferências pendentes" />
                </Show>

                <Show on={!!selected?.length}>
                    <MoveButton text="Feito" subtext=" " onClick={handleTransfer} inFlight={updateInFlight} />
                </Show>
            </>
        </Fade>

    )
}

export default PickingTransfers

import { Grid, TextField } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import React, { FC } from 'react';
import { useWashTypesQuery } from '../../generated/graphql';
import { useHasPermission } from '../../services/permissions';
import { washTypesOptions } from '../../services/Utils';
import { DeviationShrinkage } from './Deviation';
import FieldText from './FieldText';
import meshStyles from './MeshView.module.scss';
import { getOptionLabel } from './MeshViewUtils';
import styles from './ShrinkageSection.module.scss';

interface ShrinkageSectionProps {
    widthRateRef: any,
    lengthRateRef: any
}

const ShrinkageSection: FC<ShrinkageSectionProps> = (props: any) => {
    const { data } = useWashTypesQuery()
    const { values, errors } = useFormikContext()

    // @ts-ignore
    const { washType, widthAfterWash, widthBeforeWash, lengthAfterWash, lengthBeforeWash } = values

    // @ts-ignore
    const errorsWashType = errors['washType']

    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")

    const options = washTypesOptions(data) || []

    return (
        <div className={meshStyles.Section}>
            <h3>Encolhimento</h3>

            {options.length > 0 && <Grid container className={meshStyles.FormLine}>
                <Grid item xs={10}>
                    <FastField
                        name="washType"
                        component={Autocomplete}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        disabled={!canUpdate}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                error={errorsWashType}
                                helperText={errorsWashType}
                                label="Lavagem"
                            />
                        )}
                    />
                </Grid>
            </Grid>}

            {washType ? <>

                <p className={styles.SubSectionTitle}>largura</p>

                <Grid container justifyContent="space-between" alignItems="flex-end">
                    <Grid item xs={3}>
                        <FastField
                            component={FieldText}
                            name="widthBeforeWash"
                            label="Antes"
                            type="number"
                            disabled={!canUpdate}
                        />

                    </Grid>

                    <Grid item xs={3}>
                        <FastField
                            component={FieldText}
                            name="widthAfterWash"
                            label="Depois"
                            type="number"
                            disabled={!canUpdate}
                        />

                    </Grid>

                    <Grid item xs={3}>
                        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
                            <DeviationShrinkage
                                a={widthAfterWash}
                                b={widthBeforeWash}
                                units="%"
                                forwardedRef={props.widthRateRef}

                            />
                        </div>
                    </Grid>



                </Grid>

                <Grid container className={meshStyles.LineForm}>
                    <Grid item xs={10}>
                        <FastField
                            component={FieldText}
                            name="widthShrinkageObservations"
                            type="text"
                            label="Observações"
                            fullWidth
                            autoComplete="off"
                            disabled={!canUpdate}
                        />
                    </Grid>
                </Grid>

                <p className={styles.SubSectionTitle}>comprimento</p>

                <Grid container justifyContent="space-between" alignItems="flex-end">

                    <Grid item xs={3}>
                        <FastField
                            component={FieldText}
                            name="lengthBeforeWash"
                            label="Antes"
                            type="number"
                            disabled={!canUpdate}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <FastField
                            component={FieldText}
                            name="lengthAfterWash"
                            label="Depois"
                            type="number"
                            disabled={!canUpdate}
                        />

                    </Grid>

                    <Grid item xs={3}>
                        <div style={{ paddingBottom: 10, marginLeft: -15 }}>
                            <DeviationShrinkage
                                a={lengthAfterWash}
                                b={lengthBeforeWash}
                                units="%"
                                forwardedRef={props.lengthRateRef}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container className={meshStyles.LineForm}>
                    <Grid item xs={10}>
                        <FastField
                            component={FieldText}
                            name="lengthShrinkageObservations"
                            type="text"
                            label="Observações"
                            fullWidth
                            autoComplete="off"
                            disabled={!canUpdate}
                        />
                    </Grid>
                </Grid>
            </> : null}
        </div >
    )
}

export default ShrinkageSection

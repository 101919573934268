import { Grid, TextField } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import React from 'react';
import { MeshFabricTypeSortFields, SortDirection, useMeshFabricTypesQuery } from '../../generated/graphql';
import Config from '../../services/Config';
import { useHasPermission } from '../../services/permissions';
import { meshFabricTypesOptions } from '../../services/Utils';
import FieldText from './FieldText';
import styles from './MeshView.module.scss';
import { getOptionLabel } from './MeshViewUtils';

const KindAndColorSection = () => {
    const hasPermission = useHasPermission()
    const canUpdate = hasPermission("MESH__UPDATE")
    const { data } = useMeshFabricTypesQuery({ variables: { sorting: { field: MeshFabricTypeSortFields.Name, direction: SortDirection.Asc } } })
    const { values, errors } = useFormikContext()

    // @ts-ignore
    const { meshFabricType } = values

    // @ts-ignore
    const meshFabricTypeErrors = errors['meshFabricType']


    const handleDisabled = (option: any) => {
        return !option.node.isActive
    }

    return (
        <div className={styles.Section}>
            <h3>Tipo e Cor</h3>

            <Grid container justifyContent="flex-start" className={styles.FormLine}>
                <Grid item xs={10}>
                    <FastField
                        name="meshFabricType"
                        component={Autocomplete}
                        disableClearable
                        options={meshFabricTypesOptions(data)}
                        getOptionLabel={getOptionLabel}
                        noOptionsText={Config.NO_OPTIONS_TEXT}
                        value={meshFabricType}
                        getOptionDisabled={handleDisabled}
                        disabled={!canUpdate}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                error={meshFabricTypeErrors}
                                helperText={meshFabricTypeErrors}
                                label="Tipo Malha"
                                fullWidth={true}
                            />
                        )}
                    />

                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" className={styles.FormLine}>
                <Grid item xs={10}>
                    <FastField
                        component={FieldText}
                        name="meshKind"
                        label="Detalhes Tipo de Malha"
                        fullWidth
                        disabled={!canUpdate}
                    />
                </Grid>
            </Grid>


            <Grid container justifyContent="flex-start" className={styles.FormLine}>
                <Grid item xs={10}>
                    <FastField
                        component={FieldText}
                        name="composition"
                        label="Composição"
                        fullWidth
                        disabled={!canUpdate}
                    />

                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" className={styles.FormLine}>
                <Grid item xs={10}>
                    <FastField
                        component={FieldText}
                        name="color"
                        label="Cor"
                        fullWidth
                        disabled={!canUpdate}
                    />

                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" className={styles.FormLine}>
                <Grid item xs={10}>
                    <FastField
                        component={FieldText}
                        name="colorObservations"
                        label="Observações"
                        fullWidth
                        disabled={!canUpdate}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default KindAndColorSection

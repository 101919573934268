import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { ApolloError } from 'apollo-client';
import { truncate } from 'lodash';
import React, { FC } from 'react';
import { useCreateOneSampleMeshMutation, useDeleteOneSampleMeshMutation } from '../../generated/graphql';
import { useErrorMessages } from '../../services/Hooks';
import { useHasPermission } from '../../services/permissions';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Empty from '../Empty/Empty';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import FormatDate from '../FormatDate/FormatDate';
import MoreInfoButton from '../MoreInfoButton/MoreInfoButton';
import SampleMeshSearch from '../SampleMeshSearch/SampleMeshSearch';
import meshViewStyles from './../MeshView/MeshView.module.scss';
import styles from './MeshSamples.module.scss';


interface MeshSamplesProps {
    mesh: any,
    refetch: any
}

const MeshSampleItem = (props: any) => {
    const { sampleMesh, onDelete, canDelete } = props

    return (
        <Grid
            item xs={10}
            container
            justify="space-between"
            alignItems="center"
            className={styles.MeshSampleItem}
        >
            <div>
                <p className={styles.Reference}>
                    #{sampleMesh?.sample?.orderReference} | {sampleMesh?.sample?.sampleType?.name} ({sampleMesh?.sample?.order})</p>
                <span className={styles.Brand}>{truncate(sampleMesh?.sample?.brand?.name)}</span>
            </div>


            <div>
                <MoreInfoButton className={styles.Confirm}>
                    <div style={{ padding: 20 }}>
                        Associado em <FormatDate date={sampleMesh?.createdAt} /> {sampleMesh?.user?.name ? <span>por {sampleMesh?.user?.name}</span> : <span></span>}
                    </div>
                </MoreInfoButton>

                {canDelete ? <ConfirmButton onClick={() => onDelete(sampleMesh?.id)} >
                    <DeleteIcon fontSize="small" color="primary" className={styles.Confirm} />
                </ConfirmButton> : null}
            </div>
        </Grid>
    )
}

const MeshSamples: FC<MeshSamplesProps> = (props) => {
    const { mesh, refetch } = props
    const { sampleMeshes = [] } = mesh

    const [createSampleMesh] = useCreateOneSampleMeshMutation()
    const [deleteSampleMesh] = useDeleteOneSampleMeshMutation()
    const [errors, setErrors] = useErrorMessages()

    const hasPermission = useHasPermission()
    const canCreate = hasPermission("SAMPLE_MESH__CREATE")
    const canDelete = hasPermission("SAMPLE_MESH__DELETE")

    const handleChange = async (event: any) => {
        const sample = parseInt(event.target.value)
        try {
            await createSampleMesh({
                variables: {
                    input: { sampleMesh: { sample, mesh: mesh.id } }
                }
            }).then(refetch)
        } catch (e) {
            setErrors(e as ApolloError)
        }
    }

    const handleDelete = async (id: any) => {
        try {
            return await deleteSampleMesh({ variables: { input: { id } } }).then(refetch)
        } catch (e) {
            setErrors(e as ApolloError)
        }
    }

    return (
        <div className={meshViewStyles.Section}>
            <h3>Artigos</h3>
            {!sampleMeshes.length && <Empty message="Sem artigos associados." />}

            <Grid container>
                {canCreate ? <Grid item xs={10}>
                    <SampleMeshSearch onChange={handleChange} />
                </Grid> : null}
                <ErrorMessages data={errors} />
            </Grid>


            <Grid container direction="column" id="sample-meshes-list" className={styles.MeshSampleList}>
                {sampleMeshes.map((sampleMesh: any, index: number) => {
                    return <MeshSampleItem key={index} sampleMesh={sampleMesh} onDelete={handleDelete} canDelete={canDelete} />
                }
                )}
            </Grid>


        </div>
    )

};


export default MeshSamples;

import { List, ListItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import styles from './ConfigurationSelectList.module.scss';

const ConfigurationSelectList = ({ data, onSelected }: { data: any, onSelected: Function }) => {

    const key = Object.keys(data)[0]

    // @ts-ignore
    const handleSelect = (item) => {
        onSelected(item)
    }

    return (
        <Card className={styles.ConfigurationSelectList}>
            <CardContent className={styles.ListLimit}>
                <List data-cy="select_list_items">
                    {data[key].edges?.map((item: any) => (
                        <ListItem
                            key={item.node.id}
                            onClick={() => handleSelect(item)}
                            className={styles.SelectOption}
                        >
                            {item.node.name}
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    )
}

export default ConfigurationSelectList;
